import React from "react";
import {useNavigate} from "react-router-dom";
import Col from "react-bootstrap/Col";
import {sumTaxes} from "../../../../../../../utils/functions";
import useFetch from "../../../../../../../hooks/useFetch";
import {editItem} from "../../../../../../../api/items";

import ItemCard from "./ItemCard";

const ItemComponent = ({item, refresh, category}) => {
    const nav = useNavigate();
    const editItemState = useFetch(editItem, {
        itemId: item?.id,
        data: {enabled: 0},
    });

    const disableItem = () => {
        editItemState.sendRequest().then(() => {
            refresh();
        });
    };

    const duplicateItem = () => {
        //under development
    };

    const updateItem = () => {
        nav(`${item.id}/edit`);
    };

    return (
        <>
            <Col sm={3} onClick={() => nav(`${item.id}/view`)}>
                <ItemCard
                    name={item.name}
                    category={category ? category.name : 'N/A'}
                    taxes={item.taxes?.length ? `${sumTaxes(item.taxes)}%` : "0%"}
                    description={item?.description}
                    created_at={item.created_at.slice(0, 10)}
                    sale_price={item.sale_price}
                    purchase_price={item.purchase_price}
                    disableItem={disableItem}
                    updateItem={updateItem}
                />
            </Col>
        </>
    );
};

export default ItemComponent;
