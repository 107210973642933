import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useNavigate } from "react-router-dom";

import { useParams, Outlet } from "react-router-dom";

import "./CRM.css";

function CRMHome() {

  const nav = useNavigate();
  const { companyId } = useParams();

  return (
    <Container>
      <Row>
        <Col sm={12} xs={12}>
          <Row>   
            <Col sm={12}>
              <h1
                style={{
                  fontFamily: "'Inter', arial",
                  color: "#003172",
                  marginTop: "30px",
                }}
              >
                CRM
              </h1>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <div style={{display: "flex"}} className="crmsections">

          <button onClick={() => {
            nav(`/${companyId}/crm/`)
            }} className="crmsection">Rapoarte
          </button>

          <button onClick={() => {
            nav(`/${companyId}/crm/contacts`)
            }} className="crmsection">Contacte
          </button>

          <button onClick={() => {
            nav(`/${companyId}/crm/companies`)
            }} className="crmsection">Companii
          </button>

          <button onClick={() => nav(`/${companyId}/crm/deals`)} className="crmsection">Contracte</button>

          {/*<button onClick={() => nav(`/${companyId}/crm/tasks`)} className="crmsection">Atribuţii</button>*/}

          {/*<button onClick={() => nav(`/${companyId}/crm/projects`)} className="crmsection">Proiecte</button>*/}

        </div>
      </Row>

      <Outlet />

    </Container>
  );
}

export default CRMHome;
