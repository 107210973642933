import {useRef, useState} from "react";
import {login} from "../../../api/auth";
import {Navigate, useNavigate} from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";
import useAuth from "../../../hooks/useAuth";
import useFetch from "../../../hooks/useFetch";
import {setAuthToStorage} from "../../../utils/cache/cache";
import Loading from "../Components/Loading/Loading";
import jwtDecode from "jwt-decode";
import {Link} from "react-router-dom";

import "./Login.css";

const Login = () => {
    const nav = useNavigate();
    const emailRef = useRef("");
    const passwordRef = useRef("");

    const auth = useAuth();
    const loginState = useFetch(login, {});

    const handleLogin = () => {
        if(!emailRef.current.value || !passwordRef.current.value)
            return;

        loginState
            .sendRequest({
                email: emailRef.current.value, password: passwordRef.current.value,
            })
            .then((res) => {
                auth.setAuthStatus(res?.token);
                auth.setData(jwtDecode(res?.token));
                setAuthToStorage(res?.token, res?.sessionid);
                nav("/");
            });
    };

    const [rememberMe, setRememberMe] = useState(false);

    const handleCheck = (event) => {
        setRememberMe(event.target.checked);
    };

    return (<>
            {loginState.loading ? (<Loading/>) : (<>
                    <MainNavbar/>
                    <div style={{minHeight: "92vh"}}>
                        <Container>
                            <Row>
                                <Col
                                    id="logintext"
                                    style={{
                                        fontFamily: "'Inter', arial", marginTop: "150px", marginBottom: "150px",
                                    }}
                                >
                                    <h1
                                        style={{
                                            fontFamily: "'Inter', arial",
                                            fontSize: "75px",
                                            fontWeight: "bold",
                                            color: "#003172",
                                        }}
                                    >
                                        Autentifică-te, e sIMMple ca bună ziua.
                                    </h1>
                                    <h2
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                            marginRight: "100px",
                                            color: "#003172",
                                        }}
                                    >
                                        sIMMple te ajută să îți monitorizezi finanțele ca un expert.
                                    </h2>
                                </Col>
                                <Col style={{fontFamily: "'Inter', arial"}}>

                                    <Card
                                        style={{border: "none"}}
                                        className="loginform shadow-lg p-3 mb-5 bg-white rounded"
                                    >
                                        <Card.Body>
                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label>Adresa Email</Form.Label>
                                                <Form.Control
                                                    required
                                                    name="email"
                                                    type="email"
                                                    placeholder="Enter email"
                                                    ref={emailRef}
                                                />
                                                <Form.Text className="text-muted"></Form.Text>
                                            </Form.Group>

                                            <Form.Group
                                                className="mb-4"
                                                controlId="formBasicPassword"
                                            >
                                                <Form.Label>Parola</Form.Label>
                                                <Form.Control
                                                    required
                                                    name="password"
                                                    type="password"
                                                    placeholder="Password"
                                                    ref={passwordRef}
                                                />
                                            </Form.Group>

                                            <Form.Group
                                                style={{marginLeft: "10px"}}
                                                className="mb-4"
                                                controlId="formBasicCheckbox"
                                            >
                                                <Form.Check
                                                    className="text-muted"
                                                    name="checkbox"
                                                    type="checkbox"
                                                    label="Ține-mă minte"
                                                    onChange={handleCheck}
                                                />
                                            </Form.Group>

                                            <div
                                                className="g-recaptcha"
                                                data-sitekey="6LfADqkgAAAAAG6Fv8kNA-TCbcRn_wOsneKzBLoY"
                                                style={{marginBottom: "15px"}}
                                            ></div>

                                            <Button
                                                style={{background: "#003172", border: "none"}}
                                                onClick={handleLogin}
                                            >
                                                Autentificare
                                            </Button>

                                            {loginState.error && (loginState.error?.code === 'ERR_NETWORK' ?
                                                    <Navigate to='/servers_down'/> :
                                                    <div
                                                        style={{
                                                            marginTop: "10px", color: "red", fontWeight: "bold",
                                                        }}
                                                    >
                                                        {loginState.error?.response.data.error === 'not_active' ?
                                                        'Contul trebuie verificat' :
                                                         'Email sau parolă incorecte'
                                                        }
                                                    </div>)}

                                            <div style={{marginTop: "10px"}}>
                                                <Link
                                                    to={"/forgot_password"}
                                                    className="text-decoration-none text-muted"
                                                >
                                                    Ai uitat parola?
                                                </Link>
                                                <Link
                                                    to={'/register'}
                                                    className="text-decoration-none text-muted"
                                                    style={{float: "right"}}
                                                >
                                                    Înregistrează un cont nou
                                                </Link>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer/>
                </>)}
        </>);
};

export default Login;
