import "./ProfilePage.css";
import useFetch from "../../../hooks/useFetch";
import {loadUserData} from "../../../api/user";
import {getAuthFromStorage} from "../../../utils/cache/cache";
import Loading from "../Components/Loading/Loading";

const ProfilePage = () => {
    const userData = useFetch(loadUserData, {userId: getAuthFromStorage()?.userId}, true);
    return (<>
        {userData.loading && <Loading/>}
        {userData.data && <>
            <div className="profile-content">
                <div className="profile-header">
                    <h1>{userData.data?.name}</h1>
                    {userData.data?.is_staff && <p>Administrator</p>}
                </div>
                <div className="profile-details">
                    <div className="profile-item">
                        <span className="label">Email:</span>
                        <span className="value">{userData.data?.email}</span>
                    </div>
                    <div className="profile-item">
                        <span className="label">Creat la:</span>
                        <span className="value">{userData.data?.created_at?.substring(0, 10)}</span>
                    </div>
                    <div className="profile-item">
                        <span className="label">Actualizat la:</span>
                        <span className="value">{userData.data?.updated_at?.substring(0, 10)}</span>
                    </div>
                </div>
            </div>
        </>}
    </>);
}

export default ProfilePage;