import getUuidByString from "uuid-by-string";

export const getItem = (name) =>
    localStorage.getItem(name)
        ? localStorage.getItem(name)
        : sessionStorage.getItem(name);

export const requestId = (url, args = {}) =>
    getUuidByString(JSON.stringify({url: url, args: args}));

export const formatDate = (date) => {
    try {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    } catch (e) {
        console.log(date);
    }

};

export const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    let time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    return `${year}-${month}-${day} ${time}`;
}

export const filterDate = (data, start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    return data.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
    });
};

export const limitWords = (word = '', limit = 10) => word?.length > 25 ? word?.slice(0, 25) + '...' : word;

export const copyButExclude = (source, excludedProps) => {
    const result = {};

    for (const key in source) {
        if (source.hasOwnProperty(key) && !excludedProps.includes(key)) {
            result[key] = source[key];
        }
    }

    return result;
};

