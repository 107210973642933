import {forEach} from "react-bootstrap/ElementChildren";

export const mapByCategory = (list) => {
    if (!list) return {};

    const map = {};
    list.forEach((entry) => {
        const quantities = JSON.parse(entry.quantities);
        if (entry.items && entry.items.length > 0) {
            entry.items.forEach((item, index) => {
                const categoryId = item.category;
                const purchasePrice = item.purchase_price;
                const quantity = parseFloat(quantities[index]);

                // Calculate the total value for this item
                const totalValue = purchasePrice * quantity;

                if (!map[categoryId]) {
                    map[categoryId] = 0;
                }

                map[categoryId] += totalValue;
            });
        }
    });
    return map;
};

export const contactStats = (interval, contacts) => {
    const arr = [];
    const contactsDict = {};

    contacts.forEach(c => {
        const index = c.created_at?.substring(0, 10);
        if (!contactsDict[index]) contactsDict[index] = 1; else contactsDict[index] += 1;
    });


    interval.forEach((intervalDate) => {
        const date = intervalDate.substring(0, 10);
        if (!contactsDict[date]) contactsDict[date] = 0;

        arr.push({
            date: new Date(date).getTime(), noi: contactsDict[date],
        });
    });

    return arr;
};

export const dealStats = (interval, deals) => {
    const arr = [];
    const dealsDict = {};

    deals.forEach(c => {
        let index = c.updated_at?.substring(0, 10);
        if (c.status === 'CASTIGAT' || c.status === 'PIERDUT') index = c.date?.substring(0, 10);
        if (!dealsDict[index]) dealsDict[index] = {
            castigat: 0, incurs: 0, blocat: 0, pierdut: 0,
        };

        if (c.status === 'IN CURS') dealsDict[index].incurs += 1; else dealsDict[index][c.status?.toLowerCase()] += 1;
    });


    interval.forEach((intervalDate) => {
        const date = intervalDate.substring(0, 10);
        if (!dealsDict[date]) dealsDict[date] = {
            castigat: 0, incurs: 0, blocat: 0, pierdut: 0,
        };
        arr.push({...dealsDict[date], date: new Date(date).getTime()});
    });

    return arr;
};

export const returnRateDeals = (deals, start, end) => {
    const dealsDict = {};

    deals?.forEach(d => {
        let date = d.updated_at?.substring(0, 10);
        if (d.status === 'CASTIGAT' || d.status === 'PIERDUT') date = d.date?.substring(0, 10);
        if (!(new Date(date) >= new Date(start) && new Date(date) <= new Date(end)))
            return;

        if (!dealsDict[d.status]) {
            dealsDict[d.status] = 1;
        } else dealsDict[d.status] += 1;
    });

    return [{name: 'Castigat', value: dealsDict['CASTIGAT'] || 0}, {
        name: 'In curs', value: dealsDict['IN CURS'] || 0
    }, {name: 'Blocat', value: dealsDict['BLOCAT'] || 0}, {name: 'Pierdut', value: dealsDict['PIERDUT'] || 0},];

};

