import "./NoInvoices.css";
import Col from "react-bootstrap/Col";

import { useNavigate } from "react-router-dom";

const InvoicesButton = ({label, icon, path}) => {

    const navigate = useNavigate();


    return (
        <>
            <Col sm={6} className="invoicescol">
                    <button onClick={() => navigate(path)} className="invoicesbutton">
                    {icon}
                    {label}</button>
            </Col>
        </>
    );
}

export default InvoicesButton;