import React from "react";
import Col from "react-bootstrap/Col";
import Loading from "../Components/Loading/Loading";
import useFetch from "../../../hooks/useFetch";
import { loadLicense } from "../../../api/license";

const LicensePage = () => {

  return (
    <>
      {/* <Col style={{}} sm={8}>
        Licente
        {license.loading && <Loading />}
        {license.data?.data && license.data.data.map((l, index) => <p>Cod licenta {index+1} : {l?.code}</p>)}
      </Col> */}
    </>
  );
};

export default LicensePage;
