import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
    Navigate, useNavigate, useOutletContext, useParams,
} from "react-router-dom";
import {CONTACT_TYPE, REGEXP} from "../../../../../../utils/constants";
import {createContact, editContact} from "../../../../../../api/contacts";
import Loading from "../../../../../MainPages/Components/Loading/Loading";
import useFetch from "../../../../../../hooks/useFetch";
import {removeContacts} from "../../../../../../utils/cache/functions";
import {copyButExclude, formatDateTime} from "../../../../../../utils/utils";
import ErrorField, {validateForm} from "../../../../../../utils/functions/validation";
import DisableObject from "../../../../../../components/Modals/DisableObject";

function VendorNew({viewPage = false}) {
    const {companyId} = useParams();

    const outletContext = useOutletContext();
    const contact = outletContext?.contact ? outletContext?.contact : undefined;

    const initialState = {
        name: "",
        email: "",
        phone: "",
        website: "",
        notes: [],
        currency_code: "RON",
        tax_number: "",
        city: "",
        postal: "",
        province_state: "",
        country: "",
        type: CONTACT_TYPE.MULTIPLE,
        company: companyId,
    };
    const [errors, setErrors] = useState({});

    const [form, setForm] = useState(contact ? {
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        website: contact.website,
        notes: JSON.parse(contact?.notes),
        currency_code: contact.currency_code,
        tax_number: contact.tax_number,
        city: contact.address && JSON.parse(contact.address)?.city,
        postal: contact.address && JSON.parse(contact.address)?.postal,
        province_state: contact.address && JSON.parse(contact.address)?.province_state,
        country: contact.address && JSON.parse(contact.address)?.country,
        type: contact?.type || CONTACT_TYPE.VENDOR,
        company: companyId,
    } : initialState);

    const nav = useNavigate();
    const navigate = useNavigate();

    const createContactState = useFetch(createContact, {});
    const editContactState = useFetch(editContact, {});

    const [newNote, setNewNote] = useState('');
    const [notes, setNotes] = useState(contact?.notes ? JSON.parse(contact?.notes) : []);

    const removeNote = (index) => {
        const updated = notes.filter((c, i) => i !== index);
        setNotes(updated);
    };


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    };

    const saveContact = () => {
        const validatedData = validateForm(initialState, copyButExclude(form, ['notes', 'currency_code', 'tax_number', 'type', 'company']), {
            email: REGEXP.EMAIL, phone: REGEXP.PHONE, postal: REGEXP.INTEGER_ABOVE_ZERO_OR_EQUAL
        });
        if (Object.entries(validatedData).length > 0) {
            setErrors(validatedData);
            return;
        }
        setErrors({});

        removeContacts(companyId);
        const address = JSON.stringify({
            city: form.city, postal: form.postal, province_state: form.province_state, country: form.country,
        });
        if (contact) {
            editContactState.sendRequest({
                contactId: contact.id, data: {...form, address: address, notes: JSON.stringify(notes)}
            }).then(() => {
                nav(-1);
            });
        }
        else createContactState.sendRequest({
            data: {
                ...form, address: address, notes: JSON.stringify(notes)
            }
        }).then(() => {
            nav(-1);
        });
    };

    const disableContact = () => {
        editContactState.sendRequest({contactId: contact.id, data: {...contact, enabled: 0}})
            .then(() => {
                navigate('../../');
            });
    };

    return (<>
        {(createContactState.error || editContactState.error) && (<Navigate to="/logout"/>)}
        {createContactState.loading || editContactState.loading ? (<Loading/>) : (<Col>
            <div>
                <Container>

                    <h2
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                        }}
                    >
                        Contact nou
                    </h2>

                    <Row className="formsection">
                        <h3
                            style={{
                                fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            General
                        </h3>

                        <p
                            style={{
                                fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Informaţiile contactului vor apărea în facturi şi în profilul
                            acestuia. Puteţi adăuga informaţiile de contact şi logo-ul
                            acestuia pentru a fi folosite în facturi.
                        </p>
                        <hr></hr>
                        <Col className="align-self-center">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group
                                        style={{
                                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                        }}
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Nume</Form.Label>
                                        <Form.Control
                                            disabled={viewPage}
                                            className="forms"
                                            required
                                            name="name"
                                            type="text"
                                            placeholder="Nume"
                                            value={form.name}
                                            onChange={handleInputChange}
                                        />
                                        <ErrorField enabled={errors['name']}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group
                                        style={{
                                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                        }}
                                        className="sm-auto"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            disabled={viewPage}
                                            className="forms"
                                            required
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            value={form.email}
                                            onChange={handleInputChange}
                                        />
                                        <ErrorField enabled={errors['email']}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group
                                        style={{
                                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                        }}
                                        className="sm-auto"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label>Telefon</Form.Label>
                                        <Form.Control
                                            disabled={viewPage}
                                            className="forms"
                                            required
                                            name="phone"
                                            type="text"
                                            placeholder="Telefon"
                                            value={form.phone}
                                            onChange={handleInputChange}
                                        />
                                        <ErrorField enabled={errors['phone']}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group
                                        style={{
                                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                        }}
                                        className="sm-auto"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control
                                            disabled={viewPage}
                                            className="forms"
                                            required
                                            name="website"
                                            type="text"
                                            placeholder="Website"
                                            value={form.website}
                                            onChange={handleInputChange}
                                        />
                                        <ErrorField enabled={errors['website']}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="formsection">
                        <Col
                            xs={12}
                            className="align-self-center"
                        >
                            <h3
                                style={{
                                    fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Adresă
                            </h3>

                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Adresa este necesară pentru facturi.
                            </p>
                            <hr></hr>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label> Oraş </Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="city"
                                    type="text"
                                    value={form.city}
                                    placeholder="Oraş"
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['city']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Cod Poştal</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="postal"
                                    type="number"
                                    value={form.postal}
                                    placeholder="Cod Poştal"
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['postal']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label> Judeţ / Provincie </Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="province_state"
                                    value={form.province_state}
                                    type="text"
                                    placeholder="Judeţ / Provincie"
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['province_state']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Ţară</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="country"
                                    type="text"
                                    value={form.country}
                                    placeholder="Ţară"
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['country']}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="formsection">
                        <h3
                            style={{
                                fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Notiţe
                        </h3>

                        <p
                            style={{
                                fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Istoricul interacţiunilor cu contactul
                        </p>

                        <hr></hr>

                        <Col sm={12}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Conţinut</Form.Label>
                                <Form.Control
                                    disabled={viewPage} as="textarea" name="content" rows={3} value={newNote}
                                    onChange={e => setNewNote(e.target.value)}/>

                                <Button
                                    disabled={viewPage} style={{
                                    marginTop: "20px",
                                    background: "#003172",
                                    height: "36px",
                                    borderRadius: '8px',
                                    border: 'none',
                                    color: 'white',
                                    padding: "0px 10px 0px 10px"
                                }} onClick={() => {
                                    if (newNote) {
                                        setNotes([...notes, {
                                            date: formatDateTime(new Date()), content: newNote
                                        }]);
                                        setNewNote('');
                                    }
                                }}>Adaugă notiţa</Button>

                                {notes?.length !== 0 ? <hr></hr> : <></>}

                                {notes.map((note, index) => {
                                    return (<div style={{
                                        marginTop: "20px", borderLeft: "3px solid #003172", paddingLeft: "20px"
                                    }}>
                                        <div>
                                            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span
                                                        style={{
                                                            color: "#003172", fontSize: "20px"
                                                        }}>[{note.date}]</span>
                                                <Button
                                                    disabled={viewPage} style={{
                                                    background: "#003172",
                                                    height: "36px",
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                    color: 'white',
                                                    padding: "0px 10px 0px 10px"
                                                }} onClick={() => removeNote(index)}>
                                                    Şterge
                                                </Button>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                {note.content}
                                            </p>
                                        </div>
                                    </div>);
                                })}
                            </Form.Group>


                        </Col>

                    </Row>


                    <div
                        style={{
                            textAlign: "right", marginTop: "50px", height: "100px",
                        }}
                    >
                        <Button
                            onClick={() => navigate(-1)}
                            style={{
                                margin: "5px",
                                fontFamily: "'Inter', arial",
                                background: "none",
                                border: "none",
                                boxShadow: "none !important",
                                color: "gray",
                            }}
                        >
                            Inapoi
                        </Button>
                        <Button
                            hidden={!viewPage}
                            style={{
                                margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                            }}
                            onClick={() => navigate('../edit')}
                        >Editeaza
                        </Button>

                        {viewPage && <DisableObject name='furnizorul' disableFunction={disableContact}/>}

                        <Button
                            hidden={viewPage}
                            style={{
                                margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                            }}
                            onClick={saveContact}
                        >{contact ? 'Actualizează' : 'Salvează'}
                        </Button>
                    </div>

                </Container>
            </div>
        </Col>)}
    </>);
}

export default VendorNew;
