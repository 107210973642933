import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";

import {AiOutlineUserAdd} from "react-icons/ai";

import {BsPersonCheck} from "react-icons/bs";
import {Navigate, useParams} from "react-router-dom";
import {searchObject} from "../../../utils/functions";

import "./AddCrmCompany.css";

function AddCrmCompany({
                           isDisabled = false, defaultValue = undefined, companiesParam = [], returnCompany
                       }) {
    const {companyId} = useParams();

    const [selectedCompany, setSelectedCompany] = useState(defaultValue ?? "");
    const [companiesArr, setCompaniesArr] = useState(companiesParam || []);

    const [search, setSearch] = useState("");

    const [Pages, setPages] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 6;
    const paginationLength = !(companiesArr.length % itemsPerPage) ? companiesArr.length / itemsPerPage : Math.floor(companiesArr.length / itemsPerPage) + 1;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        if (selectedCompany === "") {
            // //form check
            // if (form.name !== "") {
            //     saveContact();
            //     handleClose();
            // } else console.log("invalid form");
        } else {
            returnCompany(selectedCompany);
            handleClose();
        }
    };

    useEffect(() => {
        setCompaniesArr(searchObject(companiesParam, search));
        setPages(Array.from(Array(paginationLength)).map((e, i) => i + 1));
    }, [companiesParam, paginationLength, search]);

    return (<>
        <div
            onClick={!isDisabled && handleShow}
            id="addCustomerCard"
            className="rounded"
            style={{
                width: "220px", height: "120px", display: "inline-block", border: "1px solid gray",
            }}
        >
            <div
                style={{
                    position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                }}
            >
                {selectedCompany ? (<BsPersonCheck size={45}/>) : (<AiOutlineUserAdd size={45}/>)}
                <p
                    style={{
                        fontFamily: "'Inter', arial", fontSize: "15px", color: "#003172",
                    }}
                >
                    {selectedCompany ? selectedCompany?.company_name : "Adaugă companie"}
                </p>
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{border: "none"}}>
                <Modal.Title>
                    Adaugă o companie la
                    factură
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{border: "none", height: "550px"}}>
                <Tabs className="text-muted" justify>
                    <Tab
                        eventKey="select"
                        title={`Selectează o companie existent`}
                    >
                        <Row>
                            <Col sm={1}></Col>
                            <Col sm={10}>
                                <input
                                    placeholder={`Caută companii...`}
                                    style={{
                                        paddingTop: "20px",
                                        marginBottom: "20px",
                                        fontSize: "20px",
                                        fontFamily: "'Inter', arial",
                                        color: "#003172",
                                        background: "none",
                                        width: "100%",
                                        border: "none",
                                        outline: "none",
                                        borderBottom: "1px solid gray",
                                    }}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <Form>
                                    {companiesArr.map((contact, index) => index >= itemsPerPage * (activePage - 1) && index < itemsPerPage * activePage ? (
                                        <div key={contact.id}>
                                            <Row style={{paddingTop: "15px"}}>
                                                <Col sm={12}>
                                                    <label>
                                                        <Form.Check
                                                            inline
                                                            type="switch"
                                                            name="group1"
                                                            id={contact.id}
                                                            checked={selectedCompany === contact}
                                                            onChange={() => setSelectedCompany(selectedCompany === contact ? "" : contact)}
                                                            style={{marginRight: "5px"}}
                                                        />
                                                        <span className="checkmark"></span>
                                                        <p
                                                            style={{
                                                                fontFamily: "'Inter', arial",
                                                                color: "#003172",
                                                                fontSize: "20px",
                                                                display: "inline",
                                                            }}
                                                        >
                                                            {contact.company_name}
                                                        </p>
                                                    </label>
                                                </Col>
                                            </Row>
                                        </div>) : (<div key={contact.id}></div>))}
                                </Form>
                            </Col>
                        </Row>
                        <Pagination
                            style={{
                                outline: "none",
                                boxShadow: "none",
                                position: "absolute",
                                bottom: "10px",
                                left: "50%",
                                transform: "translate(-50%, 0%)",
                            }}
                        >
                            <Pagination.Prev
                                onClick={() => setActivePage(Math.max(activePage - 1, 1))}
                            />
                            {Pages.map((page, index) => page === activePage ? (<Pagination.Item
                                onClick={() => setActivePage(page)}
                                key={index}
                                active
                            >
                                {" "}
                                {page}{" "}
                            </Pagination.Item>) : (<Pagination.Item
                                onClick={() => setActivePage(page)}
                                key={index}
                            >
                                {" "}
                                {page}{" "}
                            </Pagination.Item>))}
                            <Pagination.Next
                                onClick={() => setActivePage(Math.min(activePage + 1, paginationLength))}
                            />
                        </Pagination>
                    </Tab>
                    {/*<Tab*/}
                    {/*    eventKey="create"*/}
                    {/*    title={`Creează un contact `}*/}
                    {/*>*/}
                    {/*    <Tabs*/}
                    {/*        className="text-muted"*/}
                    {/*        justify*/}
                    {/*        style={{marginTop: "30px"}}*/}
                    {/*    >*/}
                    {/*        <Tab eventKey="general" title="General" className="text-muted">*/}
                    {/*            <>*/}
                    {/*                {createContactState.error && <Navigate to="/dashboard"/>}*/}
                    {/*                {createContactState.loading ? (<Loading/>) : (<Col>*/}
                    {/*                    <div>*/}
                    {/*                        <Row>*/}
                    {/*                            <Col sm={1}></Col>*/}
                    {/*                            <Col sm={10} xs={12} className="align-self-center">*/}
                    {/*                                <Row>*/}
                    {/*                                    <Col sm={12}>*/}
                    {/*                                        <Form.Group*/}
                    {/*                                            style={{*/}
                    {/*                                                fontFamily: "'Inter', arial",*/}
                    {/*                                                color: "#003172",*/}
                    {/*                                                marginTop: "20px",*/}
                    {/*                                            }}*/}
                    {/*                                            controlId="formBasicEmail"*/}
                    {/*                                        >*/}
                    {/*                                            <Form.Label>Name</Form.Label>*/}
                    {/*                                            <Form.Control*/}
                    {/*                                                className="forms"*/}
                    {/*                                                required*/}
                    {/*                                                name="name"*/}
                    {/*                                                type="text"*/}
                    {/*                                                placeholder="Enter Name"*/}
                    {/*                                                value={form.name}*/}
                    {/*                                                onChange={handleInputChange}*/}
                    {/*                                            />*/}
                    {/*                                        </Form.Group>*/}
                    {/*                                    </Col>*/}
                    {/*                                    <Col sm={12}>*/}
                    {/*                                        <Form.Group*/}
                    {/*                                            style={{*/}
                    {/*                                                fontFamily: "'Inter', arial",*/}
                    {/*                                                color: "#003172",*/}
                    {/*                                                marginTop: "20px",*/}
                    {/*                                            }}*/}
                    {/*                                            className="sm-auto"*/}
                    {/*                                            controlId="formBasicPassword"*/}
                    {/*                                        >*/}
                    {/*                                            <Form.Label>Email</Form.Label>*/}
                    {/*                                            <Form.Control*/}
                    {/*                                                className="forms"*/}
                    {/*                                                required*/}
                    {/*                                                name="email"*/}
                    {/*                                                type="text"*/}
                    {/*                                                placeholder="Enter Email"*/}
                    {/*                                                value={form.email}*/}
                    {/*                                                onChange={handleInputChange}*/}
                    {/*                                            />*/}
                    {/*                                        </Form.Group>*/}
                    {/*                                    </Col>*/}
                    {/*                                    <Col sm={12}>*/}
                    {/*                                        <Form.Group*/}
                    {/*                                            style={{*/}
                    {/*                                                fontFamily: "'Inter', arial",*/}
                    {/*                                                color: "#003172",*/}
                    {/*                                                marginTop: "20px",*/}
                    {/*                                            }}*/}
                    {/*                                            className="sm-auto"*/}
                    {/*                                            controlId="formBasicPassword"*/}
                    {/*                                        >*/}
                    {/*                                            <Form.Label>Phone</Form.Label>*/}
                    {/*                                            <Form.Control*/}
                    {/*                                                className="forms"*/}
                    {/*                                                required*/}
                    {/*                                                name="phone"*/}
                    {/*                                                type="text"*/}
                    {/*                                                placeholder="Enter Phone"*/}
                    {/*                                                value={form.phone}*/}
                    {/*                                                onChange={handleInputChange}*/}
                    {/*                                            />*/}
                    {/*                                        </Form.Group>*/}
                    {/*                                    </Col>*/}
                    {/*                                </Row>*/}
                    {/*                            </Col>*/}
                    {/*                        </Row>*/}

                    {/*                        <Row>*/}
                    {/*                            <Col sm={1}></Col>*/}
                    {/*                            <Col sm={10} xs={12} className="align-self-center">*/}
                    {/*                                <Row>*/}
                    {/*                                    <Col sm={12}>*/}
                    {/*                                        <Form.Group*/}
                    {/*                                            style={{*/}
                    {/*                                                fontFamily: "'Inter', arial",*/}
                    {/*                                                color: "#003172",*/}
                    {/*                                                marginTop: "20px",*/}
                    {/*                                            }}*/}
                    {/*                                            controlId="formBasicEmail"*/}
                    {/*                                        >*/}
                    {/*                                            <Form.Label>Currency</Form.Label>*/}
                    {/*                                            <Form.Control*/}
                    {/*                                                className="forms"*/}
                    {/*                                                required*/}
                    {/*                                                name="currency_code"*/}
                    {/*                                                type="text"*/}
                    {/*                                                placeholder="Enter currency code"*/}
                    {/*                                                value={form.currency_code}*/}
                    {/*                                                onChange={handleInputChange}*/}
                    {/*                                            />*/}
                    {/*                                        </Form.Group>*/}
                    {/*                                    </Col>*/}
                    {/*                                    <Col sm={12}>*/}
                    {/*                                        <Form.Group*/}
                    {/*                                            style={{*/}
                    {/*                                                fontFamily: "'Inter', arial",*/}
                    {/*                                                color: "#003172",*/}
                    {/*                                                marginTop: "20px",*/}
                    {/*                                            }}*/}
                    {/*                                            className="sm-auto"*/}
                    {/*                                            controlId="formBasicPassword"*/}
                    {/*                                        >*/}
                    {/*                                            <Form.Label>Tax Number</Form.Label>*/}
                    {/*                                            <Form.Control*/}
                    {/*                                                className="forms"*/}
                    {/*                                                required*/}
                    {/*                                                name="tax_number"*/}
                    {/*                                                type="text"*/}
                    {/*                                                value={form.tax_number}*/}
                    {/*                                                placeholder="Enter Tax"*/}
                    {/*                                                onChange={handleInputChange}*/}
                    {/*                                            />*/}
                    {/*                                        </Form.Group>*/}
                    {/*                                    </Col>*/}
                    {/*                                </Row>*/}
                    {/*                            </Col>*/}
                    {/*                        </Row>*/}
                    {/*                    </div>*/}
                    {/*                </Col>)}*/}
                    {/*            </>*/}
                    {/*        </Tab>*/}
                    {/*        <Tab eventKey="address" title="Adresă" className="text-muted">*/}
                    {/*            <Row>*/}
                    {/*                <Col sm={1}></Col>*/}
                    {/*                <Col sm={10} xs={12} className="align-self-center">*/}
                    {/*                    <Row>*/}
                    {/*                        <Col sm={12}>*/}
                    {/*                            <Form.Group*/}
                    {/*                                style={{*/}
                    {/*                                    fontFamily: "'Inter', arial",*/}
                    {/*                                    color: "#003172",*/}
                    {/*                                    marginTop: "20px",*/}
                    {/*                                }}*/}
                    {/*                                controlId="formBasicEmail"*/}
                    {/*                            >*/}
                    {/*                                <Form.Label>Town / City </Form.Label>*/}
                    {/*                                <Form.Control*/}
                    {/*                                    className="forms"*/}
                    {/*                                    required*/}
                    {/*                                    name="city"*/}
                    {/*                                    type="text"*/}
                    {/*                                    value={form.city}*/}
                    {/*                                    placeholder="Enter Town / City"*/}
                    {/*                                    onChange={handleInputChange}*/}
                    {/*                                />*/}
                    {/*                            </Form.Group>*/}
                    {/*                        </Col>*/}
                    {/*                        <Col sm={12}>*/}
                    {/*                            <Form.Group*/}
                    {/*                                style={{*/}
                    {/*                                    fontFamily: "'Inter', arial",*/}
                    {/*                                    color: "#003172",*/}
                    {/*                                    marginTop: "20px",*/}
                    {/*                                }}*/}
                    {/*                                className="sm-auto"*/}
                    {/*                                controlId="formBasicPassword"*/}
                    {/*                            >*/}
                    {/*                                <Form.Label>Postal / Zip Code</Form.Label>*/}
                    {/*                                <Form.Control*/}
                    {/*                                    className="forms"*/}
                    {/*                                    required*/}
                    {/*                                    name="postal"*/}
                    {/*                                    type="number"*/}
                    {/*                                    value={form.postal}*/}
                    {/*                                    placeholder="Enter Postal / Zip Code"*/}
                    {/*                                    onChange={handleInputChange}*/}
                    {/*                                />*/}
                    {/*                            </Form.Group>*/}
                    {/*                        </Col>*/}
                    {/*                        <Col sm={12}>*/}
                    {/*                            <Form.Group*/}
                    {/*                                style={{*/}
                    {/*                                    fontFamily: "'Inter', arial",*/}
                    {/*                                    color: "#003172",*/}
                    {/*                                    marginTop: "20px",*/}
                    {/*                                }}*/}
                    {/*                                className="sm-auto"*/}
                    {/*                                controlId="formBasicPassword"*/}
                    {/*                            >*/}
                    {/*                                <Form.Label>Province / State </Form.Label>*/}
                    {/*                                <Form.Control*/}
                    {/*                                    className="forms"*/}
                    {/*                                    required*/}
                    {/*                                    name="province_state"*/}
                    {/*                                    value={form.province_state}*/}
                    {/*                                    type="text"*/}
                    {/*                                    placeholder="Enter Province / State"*/}
                    {/*                                    onChange={handleInputChange}*/}
                    {/*                                />*/}
                    {/*                            </Form.Group>*/}
                    {/*                        </Col>*/}
                    {/*                        <Col sm={12}>*/}
                    {/*                            <Form.Group*/}
                    {/*                                style={{*/}
                    {/*                                    fontFamily: "'Inter', arial",*/}
                    {/*                                    color: "#003172",*/}
                    {/*                                    marginTop: "20px",*/}
                    {/*                                }}*/}
                    {/*                                className="sm-auto"*/}
                    {/*                                controlId="formBasicPassword"*/}
                    {/*                            >*/}
                    {/*                                <Form.Label>Country</Form.Label>*/}
                    {/*                                <Form.Control*/}
                    {/*                                    className="forms"*/}
                    {/*                                    required*/}
                    {/*                                    name="country"*/}
                    {/*                                    type="text"*/}
                    {/*                                    value={form.country}*/}
                    {/*                                    placeholder="Enter Country"*/}
                    {/*                                    onChange={handleInputChange}*/}
                    {/*                                />*/}
                    {/*                            </Form.Group>*/}
                    {/*                        </Col>*/}
                    {/*                    </Row>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Tab>*/}
                    {/*        <Tab eventKey="others" title="Altele" className="text-muted">*/}
                    {/*            <Row>*/}
                    {/*                <Col sm={1}></Col>*/}
                    {/*                <Col sm={10}>*/}
                    {/*                    <Row>*/}
                    {/*                        <Col>*/}
                    {/*                            <Row></Row>*/}
                    {/*                            <Form.Group*/}
                    {/*                                style={{*/}
                    {/*                                    fontFamily: "'Inter', arial",*/}
                    {/*                                    color: "#003172",*/}
                    {/*                                    marginTop: "20px",*/}
                    {/*                                }}*/}
                    {/*                                className="sm-auto"*/}
                    {/*                                controlId="formBasicPassword"*/}
                    {/*                            >*/}
                    {/*                                <Form.Label>Website</Form.Label>*/}
                    {/*                                <Form.Control*/}
                    {/*                                    className="forms"*/}
                    {/*                                    required*/}
                    {/*                                    name="website"*/}
                    {/*                                    type="text"*/}
                    {/*                                    placeholder="Enter Website"*/}
                    {/*                                    value={form.website}*/}
                    {/*                                    onChange={handleInputChange}*/}
                    {/*                                />*/}
                    {/*                            </Form.Group>*/}
                    {/*                        </Col>*/}
                    {/*                        <Col sm={12}>*/}
                    {/*                            <Form.Group*/}
                    {/*                                style={{*/}
                    {/*                                    fontFamily: "'Inter', arial",*/}
                    {/*                                    color: "#003172",*/}
                    {/*                                    marginTop: "20px",*/}
                    {/*                                }}*/}
                    {/*                                className="sm-auto"*/}
                    {/*                                controlId="formBasicPassword"*/}
                    {/*                            >*/}
                    {/*                                <Form.Label>Reference</Form.Label>*/}
                    {/*                                <Form.Control*/}
                    {/*                                    className="forms"*/}
                    {/*                                    required*/}
                    {/*                                    name="reference"*/}
                    {/*                                    type="text"*/}
                    {/*                                    placeholder="Enter Reference"*/}
                    {/*                                    value={form.reference}*/}
                    {/*                                    onChange={handleInputChange}*/}
                    {/*                                />*/}
                    {/*                            </Form.Group>*/}
                    {/*                        </Col>*/}
                    {/*                    </Row>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Tab>*/}
                    {/*    </Tabs>*/}
                    {/*</Tab>*/}
                </Tabs>
            </Modal.Body>
            <Modal.Body style={{border: "none"}}></Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleClose}
                    style={{
                        margin: "5px", fontFamily: "'Inter', arial", background: "none", border: "none", color: "gray",
                    }}
                >
                    Anulează
                </Button>
                <Button
                    style={{
                        margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                    }}
                    onClick={handleSave}
                >
                    {selectedCompany === "" ? "Salvează" : "Selecteaza"}
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default AddCrmCompany;
