import Modal from "react-bootstrap/Modal";
import React, { useState } from 'react';

import useFetch from "../../../hooks/useFetch";
import {loadCompanies} from "../../../api/companies";
import {removeCompanies} from "../../../utils/cache/functions";
import {Navigate, useNavigate} from "react-router-dom";
import { MdBusiness } from "react-icons/md";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";

const SwitchCompanies = ({domain}) => {

    const nav = useNavigate();

    const companies = useFetch(loadCompanies, {}, true);

    const refreshCompanies = () => {
        removeCompanies();
        companies.sendRequest();
    };

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    return (
        <>
            <p onClick={handleShow} style={{display: "inline"}}>{domain}</p>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Schimbă compania</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {companies.error && <Navigate to="/logout"/>}
                        {companies.data?.length ?
                            companies.data?.map((company, index) => (
                                <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p style={{fontSize: "20px", fontFamily: "'Inter', arial", color: '#003172', marginBottom: "30px"}}>
                                        <MdBusiness style={{paddingBottom: "2px"}} />
                                        <span style={{marginLeft: "5px"}}>
                                            {company.domain}
                                        </span>
                                    </p>
                                    <button
                                        style={{border: 'none', height: "36px", color: 'white', background: '#003172', padding: "0px 10px 0px 10px", borderRadius: "8px"}}>
                                        <Link to={`/${company.id}`} style={{color: "white", textDecoration: "none", fontFamily: "'Inter', arial"}}>
                                            Conectare
                                        </Link>
                                    </button>
                                </div>
                            ))
                            :
                            ''
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SwitchCompanies;