//menu
export const SIDEBAR_BUTTON = {
    ADD: 'add', SETTINGS: 'settings', ACCOUNT: 'account',
}

export const CONTACT_TYPE = {
    CUSTOMER: 'customer', VENDOR: 'vendor', MULTIPLE: 'multiple',
};

export const INVOICE_TYPE = {
    INVOICE: 'invoice', BILL: 'bill',
}

export const CONTACT_DICT = {
    'customer': 'Client', 'vendor': 'Furnizor', 'multiple': 'Multiplu',
}

export const CATEGORY_TYPE = {
    ITEM: 'item', BILL: 'bill', INVOICE: 'invoice', COMPANY: 'company'
};

export const TAX_TYPE = {
    ITEM: 'item',
};

//sorting
export const SORT_BY = {
    CREATED_AT: 'created_at',
};

export const ORDER = {
    ASC: 1, DESC: -1,
};

//invoices
export const INVOICE_STATUS = {
    DRAFT: 'draft', SENT: 'sent', PAID: 'paid', PAID_OVERDUE: 'paid_overdue'
};

export const REGEXP = {
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
    EMAIL: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
    PHONE: /^(07\d{8})$/,
    INTEGER_ABOVE_ZERO_OR_EQUAL: /^(0|[1-9]\d*)$/,
    INTEGER_ABOVE_ZERO: /^[1-9][0-9]*$/,
    FLOAT_ABOVE_ZERO_OR_EQUAL: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,

}
