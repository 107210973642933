import React from 'react';
import './DealCard.css'; // import CSS file for styling
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';
import {AiOutlineStar, AiOutlineSearch, AiOutlineUser, AiOutlineFileText} from "react-icons/ai";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {TbBuildingStore} from "react-icons/tb";
import {useNavigate} from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";

const DealCard = ({id, name, salesperson, business, date, value, invoices, status}) => {
    const nav = useNavigate();

    return (
        <div className="dealcard" onClick={() => {
            nav(`${id}/view`);
        }}>
            <div style={{margin: "0px -20px 0px -20px", padding: "16px 23px 16px 23px"}} className="dealcard-header">
                <Row>
                    <Col sm={9} style={{textAlign: "left", paddingLeft: "20px"}}><h3>{name}</h3></Col>
                    <Col style={{textAlign: "right", color: "white"}}><BiDotsVerticalRounded size={25}/></Col>
                </Row>
            </div>
            <div className="dealcard-body">
                <p><TbBuildingStore size={20} style={{marginBottom: "5px", marginRight: "5px"}}/> {business}</p>
                <p><AiOutlineUser size={20} style={{marginBottom: "5px", marginRight: "5px"}}/> {salesperson}</p>
                <p><AiOutlineFileText size={20} style={{marginBottom: "5px", marginRight: "5px"}}/> {invoices}</p>
                <p><AiFillCheckCircle size={20} style={{marginBottom: "5px", marginRight: "5px"}}/> {status}</p>
            </div>
            <div className="dealcard-footer">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <p><i className="fa fa-calendar" aria-hidden="true"></i> {date} </p>
                    <p style={{fontFamily: "'Inter', arial",}}>{value}</p>
                </div>
            </div>
        </div>
    );
}

export default DealCard;
