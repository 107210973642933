import {requestId} from "../utils";
import {cache} from "./cache";

export const removeCompanies = () => cache.remove(requestId(`/api/companies/owner/`, {}));

export const removeItems = (companyId) => cache.remove(requestId(`/api/items/owner/`, {companyId: companyId}));

export const removeCategories = (companyId) => cache.remove(requestId(`/api/categories/owner/`, {companyId: companyId}));

export const removeTaxes = (companyId) => cache.remove(requestId(`/api/taxes/owner/`, {companyId: companyId}));

export const removeContacts = (companyId) => cache.remove(requestId(`/api/contacts/owner/`, {companyId: companyId}));

export const removeInvoices = (companyId) => {
    cache.remove(requestId(`/api/invoices/owner/`, {companyId: companyId}));
    cache.remove(requestId(`/api/invoice_items/owner/`, {companyId: companyId}));
};

export const removeLicenses = (companyId) => cache.remove(requestId(`/api/license/owner/${companyId}/`));

export const removeBills = (companyId) => {
    cache.remove(requestId(`/api/bills/owner/`, {companyId: companyId}));
    cache.remove(requestId(`/api/bill_items/owner/`, {companyId: companyId}));
};

export const getView = () => (localStorage.getItem('view') ? localStorage.getItem('view') : 'Tabel');
export const saveView = (value) => {
    localStorage.setItem('view', value);
    return value;
};
