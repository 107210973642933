import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {useParams} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {createActivity, editActivity} from "../../api/activities";


function NewActivity({isDisabled=false,refreshActivities, activity = undefined}) {
    const {companyId} = useParams();

    const [show, setShow] = useState(false);
    const [form, setForm] = useState({
        name: activity?.name || "", company: companyId,
    });

    const createActivityState = useFetch(createActivity, {});
    const editActivityState = useFetch(editActivity, {});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    };

    const saveActivity = () => {
        if (activity === undefined) {
            createActivityState.sendRequest({data: form}).then(() => {
                refreshActivities();
                setShow(false);
            });
        } else {
            editActivityState.sendRequest({activityId: activity?.id, data: form}).then(() => {
                refreshActivities();
                setShow(false);
            });
        }

    };

    return (<>
        <Button
            disabled={isDisabled}
            onClick={() => setShow(true)}
            style={{
                background: "#003172", border: "none", marginLeft: "5px", fontFamily: "'Inter', Arial", height: "36px"
            }}
        >
            {activity === undefined ? 'Creează' : 'Editeaza'}
        </Button>

        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton style={{border: "none"}}>
                <Modal.Title>Creează o activitate</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{border: "none"}}>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nume activitate</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nume"
                            autoFocus
                            name="name"
                            value={form.name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{background: "none", border: "none", color: "gray"}}
                    variant="secondary"
                    onClick={() => setShow(false)}
                >
                    Anulează
                </Button>
                <Button
                    style={{background: "#003172", border: "none"}}
                    variant="primary"
                    onClick={saveActivity}
                >
                    Salvează
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default NewActivity;
