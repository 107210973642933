import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {useParams} from "react-router-dom";
import {createCategory, editCategory} from "../../api/categories";
import useFetch from "../../hooks/useFetch";
import {CATEGORY_TYPE} from "../../utils/constants";

function NewCategory({isDisabled=false, refreshCategories, type = CATEGORY_TYPE.ITEM, category = undefined}) {
    const {companyId} = useParams();
    const [show, setShow] = useState(false);
    const [form, setForm] = useState({
        name: category?.name || "",
        color: category?.color || "",
        type: category?.type || type,
        company: companyId,
    });

    const createCategoryState = useFetch(createCategory, {});
    const editCategoryState = useFetch(editCategory, {});

    const saveCategory = () => {
        if (category === undefined) {
            createCategoryState.sendRequest({data: {...form, type: type}}).then(() => {
                refreshCategories();
                setShow(false);
            });
        } else {
            editCategoryState.sendRequest({categoryId: category.id, data: {...form, type: type}}).then(() => {
                refreshCategories();
                setShow(false);
            })
        }


    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    };

    return (
        <>
            <Button
                disabled={isDisabled}
                onClick={() => setShow(true)}
                style={{background: "#003172", border: "none", marginLeft: "5px", fontFamily: "'Inter', Arial", height: "36px"}}
            >
                {category === undefined ? 'Creează' : 'Editează'}
            </Button>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton style={{border: "none"}}>
                    <Modal.Title>Creează o categorie</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{border: "none"}}>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nume categorie</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nume"
                                autoFocus
                                name="name"
                                value={form.name}
                                onChange={handleInputChange}
                            />
                            <Form.Label>Culoare</Form.Label>
                            <Form.Control type="color"
                                          placeholder="Culoare"
                                          name="color"
                                          value={form.color}
                                          onChange={handleInputChange}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{background: "none", border: "none", color: "gray"}}
                        variant="secondary"
                        onClick={() => setShow(false)}
                    >
                        Anulează
                    </Button>
                    <Button
                        style={{background: "#003172", border: "none"}}
                        variant="primary"
                        onClick={saveCategory}
                    >
                        Salvează
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NewCategory;
