import {useState} from "react";
import {verifyChangePassword} from "../../../api/password";
import useFetch from "../../../hooks/useFetch";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";

const VerifyForgotPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const {token, email} = useParams();
    const verifyPass = useFetch(verifyChangePassword, {});
    const nav = useNavigate();

    const handleResetPassword = () => {
        verifyPass
            .sendRequest({email: email, token: token, password: newPassword})
            .then(() => {
                nav("/login");
            });
    };

    return (<>
            <MainNavbar/>
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title text-center">Reset Password</h1>
                                <p className="card-text text-center">
                                    Please enter your new password.
                                </p>
                                <div className="form-group">
                                    <label htmlFor="newPassword">New Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="text-center">
                                    <button
                                        onClick={handleResetPassword}
                                        className="btn btn-primary"
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>);
};

export default VerifyForgotPassword;
