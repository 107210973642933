import {
    ResponsiveContainer, BarChart, Legend, CartesianGrid, XAxis, YAxis, Bar, Tooltip,
} from "recharts";
import moment from "moment/moment";
import React from "react";
import {dealStats} from "../../../../../../../utils/functions/graphs";
import {constructInterval} from "../../../../../../../utils/functions/cashflow";

const Opportunities = ({report, data, interval}) => {
    let chartData = dealStats(interval, data);
    return (<>

        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData} margin={{bottom: 40, left: -10}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date"
                       angle={-30}
                       tickCount={7}
                       dy={30}
                       domain={[new Date(chartData[0]?.date), new Date(chartData[chartData.length - 1]?.date)]}
                       type="number"
                       tickFormatter={(str) => moment(str).format("MMM DD YYYY")}

                />
                <YAxis angle={-30} allowDecimals={false} />
                <Tooltip
                    label="da"
                    labelFormatter={(value) => {
                        return `${new Date(value).toString().substring(0, 10)}`;
                    }}
                />
                <Legend verticalAlign="top" height={36} />
                {report === 'contracts' && (<>

                    <Bar dataKey="castigat" fill="#3e8a34" name='Câştigat'/>
                    <Bar dataKey="incurs" fill="#34378a" name='În curs'/>
                    <Bar dataKey="blocat" fill="#eb4949" name='Blocat'/>
                    <Bar dataKey="pierdut" fill="#a3a0a0" name='Pierdut'/>
                </>)}
                {report === 'contacts' && (<>
                    <Bar dataKey="noi" fill="#3e8a34" name='Contacte noi'/>
                </>)}
                {report === 'companies' && (<>
                    <Bar dataKey="noi" fill="#3e8a34" name='Companii noi'/>
                </>)}
            </BarChart>

        </ResponsiveContainer>
    </>);
};

export default Opportunities;
