import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "react-bootstrap/Card";

const Payables = ({bills, filters, companies}) => {
    const filteredBills = bills
        ?.filter(bill => bill.status === 'sent'
            && new Date(bill.invoiced_at) >= new Date(filters.start)
            && new Date(bill.invoiced_at) <= new Date(filters.end)
            && (filters.company === 'all' || bill.contact === filters.company)
            && (filters.contact === 'all' || companies[bill.contact]?.contact === filters.contact)
        );
    const open_sum = filteredBills
        ?.map((bill, index) => (new Date(bill.due_at).getTime() > Date.now() ? bill?.amount : 0))
        .reduce((result, number) => result + number, 0);
    const overdue_sum = filteredBills
        ?.map((bill, index) => (new Date(bill.due_at).getTime() < Date.now() ? bill.amount : 0))
        .reduce((result, number) => result + number, 0);

    const total_unpaid = parseFloat(open_sum + overdue_sum) || 0;

    return (
        <Col xs={12} sm={6} style={{marginBottom: '20px'}}>
            <Card style={{
                borderRadius: "0px",
                border: "none",
                padding: "20px 20px 20px 20px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)"
            }}>
                <Card.Body>
                    <Row>
                        <Col>
                            <h5 style={{fontFamily: "'Inter', arial", color: "#003172"}}>
                                De plătit
                            </h5>
                            <p
                                style={{
                                    fontFamily: "'Inter', arial",
                                    color: "gray",
                                    fontSize: "12px",
                                }}
                            >
                                Suma de plătit furnizorilor
                            </p>
                        </Col>
                        <Col style={{textAlign: "right"}} sm={3}>
                            {/*<p*/}
                            {/*    style={{*/}
                            {/*        fontFamily: "'Inter', arial",*/}
                            {/*        fontSize: "15px",*/}
                            {/*        color: "#003172",*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Vezi raport*/}
                            {/*</p>*/}
                        </Col>
                        <hr></hr>
                    </Row>

                    <p
                        style={{
                            fontFamily: "'Inter', arial",
                            color: "gray",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        Total facturi neplătite: {total_unpaid?.toFixed(2)} RON
                    </p>

                    <ProgressBar style={{width: "100%"}}>
                        <ProgressBar
                            style={{backgroundColor: "gray"}}
                            now={total_unpaid === 0 ? 100 : 0}
                            key={2}
                        />
                        <ProgressBar
                            style={{backgroundColor: "#22a1e2"}}
                            now={(open_sum / total_unpaid) * 100}
                            key={1}
                        />
                        <ProgressBar
                            style={{backgroundColor: "#003172"}}
                            now={(overdue_sum / total_unpaid) * 100}
                            key={3}
                        />
                    </ProgressBar>
                    <Row style={{marginTop: "20px"}}>
                        <Col sm={5}>
                            <p
                                style={{
                                    fontFamily: "'Inter', arial",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#22a1e2",
                                }}
                            >
                                În termen
                            </p>
                            <p
                                style={{
                                    fontFamily: "'Inter', arial",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#22a1e2",
                                }}
                            >
                                {open_sum} RON
                            </p>
                        </Col>
                        <Col sm={5}>
                            <p
                                style={{
                                    fontFamily: "'Inter', arial",
                                    color: "#003172",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                            >
                                Peste termen
                            </p>
                            <p
                                style={{
                                    fontFamily: "'Inter', arial",
                                    color: "#003172",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                            >
                                {overdue_sum} RON
                            </p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default Payables;
