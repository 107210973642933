import {loggedInHeaders} from "./apiUtils";
import {put, post, get} from "./requests";
import {requestId} from "../utils/utils";
import {cache} from "../utils/cache/cache";

export const loadLicense = async (params) => {
    try {
        const url = `/api/license/owner/${params.args?.companyId}/`;
        const reqId = requestId(url);

        const cachedData = cache.get(reqId);
        if (cachedData) {
            params.setData(cachedData);
            return;
        }

        let data = await (await get(url, loggedInHeaders())).data;
        cache.set(reqId, data, 0.5);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const createLicense = async (params) => {
    try {
        const url = `/api/license/`;
        const data = await (await post(url, params.args, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const editLicense = async (params) => {
    try {
        const url = `/api/license/${params.args?.id}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getLicenseById = async (params) => {
    try {
        const url = `/api/license/${params.args?.id}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const requestNewLicense = async (params) => {
    try {
        const url = `/api/licenses/request_new/`;
        const data = await (await post(url, params.args, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const approveNewLicense = async (params) => {
    try {
        const url = `/api/licenses/approve_new/`;
        const data = await (await post(url, params.args, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const changeLicenseRole = async (params) => {
    try {
        const url = `/api/licenses/change_role/`;
        const data = await (await post(url, params.args, loggedInHeaders())).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const removeOwner = async (params) => {
    try {
        const url = `/api/licenses/remove_owner/`;
        const data = await (await post(url, params.args, loggedInHeaders())).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
}
