import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import {useParams} from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import {editContact, loadContacts} from "../../../../../../api/contacts";
import {getView, removeContacts, saveView} from "../../../../../../utils/cache/functions";

import {listToDict, sortObjects} from "../../../../../../utils/functions";
import {ORDER, SORT_BY} from "../../../../../../utils/constants";

import ContactCard from "./Components/ContactCard/ContactCard";
import ContactTableComponent from "./Components/ContactCard/ContactTableComponent";

import {filterByType} from "../../../../../../utils/functions/filters";

import "./ContactsList.css";

import {TbLayoutList, TbLayoutGrid} from "react-icons/tb";
import SearchBar from "../../../../../../components/Filters/SearchBar";
import {loadCrmCompanies} from "../../../../../../api/crm/companies";
import ExportCSV from "../../../../../../components/CSV/ExportCSV";

function CRMContacts({}) {
    const navigate = useNavigate();
    const {companyId} = useParams();

    const contactsState = useFetch(loadContacts, {companyId: companyId}, true);
    const companiesState = useFetch(loadCrmCompanies, {});

    const [contactsList, setContactsList] = useState(contactsState.data ?? []);
    const [companyDict, setCompanyDict] = useState({});

    const [contactRole, setContactRole] = useState("all");

    const [sortBy, setSortBy] = useState(SORT_BY.CREATED_AT);
    const [order, setOrder] = useState(ORDER.DESC);

    const [view, setView] = useState(getView());

    const refreshContacts = () => {
        removeContacts(companyId);
        contactsState.sendRequest();
    };

    //update contactsList when contactsState.data changes and filter by type with the filterbytype function
    useEffect(() => {
        setContactsList(filterByType(contactsState.data ?? [], contactRole));
    }, [contactsState.data, contactRole]);

    useEffect(() => {
        companiesState.sendRequest({companyId: companyId}).then((response) => {
            setCompanyDict(listToDict(response, 'contact'));
        })
    }, []);

    return (<>
        <Row>
            <Col sm={12} xs={12}>
                <Row>
                    <Col sm={6}>
                        <h1
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                            }}
                        >
                            Contacte
                        </h1>
                    </Col>
                    <Col sm={6} style={{textAlign: "right"}} xs={12}>
                        <Button
                            style={{
                                margin: "5px",
                                fontFamily: "'Inter', arial",
                                background: "#003172",
                                border: "none",
                                marginTop: "30px",
                                marginBottom: "30px",
                            }}
                            onClick={() => navigate("create")}
                        >
                            Contact nou
                        </Button>
                        <ExportCSV name={`contacte-${new Date().toLocaleDateString('en-GB')}`} data={contactsList}
                                   headers={['name', 'email', 'type']}
                                   nameHeaders={['Nume', 'Email', 'Tip']}/>
                    </Col>
                </Row>
            </Col>
        </Row>

        <div style={{
            display: "flex",
            margin: '20px 0px',
            padding: '25px',
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
            background: 'white',
            verticalAlign: "middle"
        }}>

            <SearchBar objectsArray={contactsState.data} setNewArray={setContactsList}/>

            <Button
                onClick={() => view === "Tabel" ? setView(saveView("Carduri")) : setView(saveView("Tabel"))}
                style={{
                    marginLeft: "5px",
                    fontFamily: "'Inter', arial",
                    background: "white",
                    border: "1px solid lightgray",
                    color: "#003172",
                    width: "60px",
                    height: "50px",
                }}>{view === "Tabel" ? <TbLayoutList size={30}/> : <TbLayoutGrid size={30}/>}</Button>

        </div>

        {view === "Tabel" ? (<>

            <div className="itemscontainer">
                <Table style={{color: "#003172"}} responsive hover>
                    <thead>
                    <tr>
                        <th>Nume</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Statut</th>
                        <th>Firme asociate</th>
                        <th>Creat la</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortObjects(contactsList, sortBy, order).map((contact, index) => (<ContactTableComponent
                        key={index}
                        contact={contact}
                        companies={companyDict}
                        refresh={refreshContacts}
                    />))}
                    </tbody>
                </Table>
            </div>

        </>) : (<>
            <Row style={{justifyContent: "left"}}>

                {contactsList.map((contact) => (<Col sm={3}>
                    <ContactCard
                        contact={contact}
                        refresh={refreshContacts}
                        companies={companyDict}
                    />
                </Col>))}

            </Row>
        </>)}
    </>);
}

export default CRMContacts;
