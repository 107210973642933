import ManageCompany from "../../../../../../../../components/Modals/Manage/ManageCompany";
import React from "react";
import {useNavigate} from "react-router-dom";

const CompanyRow = ({company, owner, city, category, refresh}) =>
{
    const nav = useNavigate();
    return (
        <>
            <tr>
                <td>
                    {company.company_name}
                </td>
                <td>
                    {owner}
                </td>
                <td>
                    {category ?? 'Fără categorie'}
                </td>
                <td>
                    {city}
                </td>
                <td style={{textAlign: "right"}}>
                    <button
                        onClick={() => nav(`${company.id}/view`)}
                        style={{
                            border: "none",
                            color: "white",
                            background: "#003172",
                            borderRadius: "8px",
                            padding: "2px 15px",
                            boxSizing: "border-box",
                        }}
                    >
                        Vizualizează
                    </button>
                </td>
            </tr>
        </>
    );
}

export default CompanyRow;