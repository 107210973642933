import React from 'react';
import {Page, Text, View, Document, StyleSheet, Font, Image} from '@react-pdf/renderer';
import M from "../../static/M.png";


Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

const styles = StyleSheet.create({
    header: {
        backgroundColor: "#f6f7f2",
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        padding: "20 5% 20 5%",
        marginBottom: 30,
        flexDirection: 'row'
    }, footer: {
        fontFamily: 'Roboto', padding: 30,
    }, middle: {
        fontFamily: 'Roboto', padding: '0% 5% 0% 5%', width: '100%',
    }, headerSection: {
        verticalAlign: 'middle', flexGrow: 1, width: '50%',
    }, logo: {
        alignSelf: 'right', width: 100,
    }, content: {
        width: '100%',
    }, contact_information: {
        marginBottom: 30, flexDirection: 'row'
    }, issuer_information: {
        marginBottom: 30, flexDirection: 'row'
    }, contact: {
        lineHeight: '1.4px', width: '50%',
    }, issuer: {
        lineHeight: '1.4px', width: '50%',
    },
    breakpoint: {
        margin: 10,
    },
    line: {
        borderBottom: '1px solid black',
        marginBottom: 10,
    },
});

const LicensePDF = ({companyName, userName, userEmail, license}) => {

    const active = license?.owner !== null;

    return (<Document>
        <Page size="A4">

            <View style={styles.header}>
                <View style={styles.headerSection}>
                    <Text>LICENȚĂ</Text>
                    <Text style={{fontSize: "15px"}}>{companyName}</Text>
                </View>
                <View style={{flexDirection: 'row-reverse', ...styles.headerSection}}>
                    <Image src={M} style={styles.logo}/>
                </View>
            </View>

            <View style={styles.middle}>

                <View style={styles.content}>
                    <Text>Dovada Vânzării Licenței</Text>
                    <View style={styles.line} />
                    <View style={styles.breakpoint} />

                    <View style={{marginBottom: 30}}>
                        <Text>Detalii Licență:</Text>
                        <Text>Cod Licență: {license?.code}</Text>
                        <Text>Stare Licență: {active ? 'Activă' : 'Inactivă'} </Text>
                        <Text>Data Vânzării: {license?.created_at?.slice(0, 10)}</Text>
                    </View>

                    <View style={{marginBottom: 30}}>
                        <Text>Proprietar Licență:</Text>
                        <Text>Nume: {userName}</Text>
                        <Text>Email: {userEmail}</Text>
                    </View>
                    <View style={styles.breakpoint} />
                    <Text>
                        Această licență este în prezent {active ? 'activă' : 'inactivă'}.
                        Persoana de contact pentru această licență este {userName}, având adresa de
                        email {userEmail}.
                    </Text>
                    <View style={styles.breakpoint} />
                    <Text>
                        Această dovadă servește ca confirmare a procesului de vânzare a licenței
                        către {companyName} și poate fi utilizată în scopuri administrative sau de audit.
                    </Text>
                    <View style={styles.breakpoint} />
                    <View style={styles.line} />
                    <Text>Vă mulțumim pentru utilizarea produselor noastre.</Text>
                    <Text>Cu stimă,</Text>
                    <Text>sIMMple</Text>
                </View>

            </View>
        </Page>
    </Document>);
}

export default LicensePDF;
