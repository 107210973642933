import React, {useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import useFetch from "../../hooks/useFetch";
import {getCompanyPhoto, uploadCompanyPhoto} from "../../api/photos";
import {apiEndpoint} from "../../api/apiUtils";
import {upload} from "@testing-library/user-event/dist/upload";

const UploadPhoto = ({companyId}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const uploadPhotoState = useFetch(uploadCompanyPhoto, {});
    const getImageState = useFetch(getCompanyPhoto, {});
    const [loadImage, setLoadImage] = useState(false);
    const [selectNewImage, setSelectNewImage] = useState(false);
    const [source, setSource] = useState(null);

    useEffect(() => {
        getImageState.sendRequest({companyId: companyId}).then(response => {
            // setSelectedImage(response)
            //console.log(response[response?.length - 1]?.image)
            setLoadImage(true);
            setSelectNewImage(false);
        })
    }, [companyId, uploadPhotoState.data]);

    useEffect(() => {
        setSource(() => {
            if(selectNewImage && selectedImage)
                return URL.createObjectURL(selectedImage);
            if(getImageState.data?.length)
                return `${apiEndpoint}${getImageState.data[0]?.image}`
            return null;
        });
    }, [selectNewImage, loadImage, selectedImage]);

    const uploadImg = () => {
        if (selectedImage) {
            uploadPhotoState.sendRequest({data: {company_id: companyId, image: selectedImage}}).then(res => {
                //console.log(res);
            });
        }
    };

    return (<>
        {(loadImage || selectNewImage) ? (<>
            <p style={{fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px"}}>Logo</p>
            <img
                className='rounded'
                alt="not found"
                style={{height: "122px", marginTop: "-1px", marginLeft: "-1px", border: "1px dashed gray"}}
                src={source}
            />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Button
                    style={{
                        background: "#2c2c96",
                        fontFamily: "'Inter', arial",
                        border: "none",
                        color: "white",
                        margin: "5px",
                    }}
                    onClick={uploadImg}
                >
                    Salvează
                </Button>
                <Button
                    style={{
                        background: "#2c2c96",
                        fontFamily: "'Inter', arial",
                        border: "none",
                        color: "white",
                        margin: "5px",
                    }}
                    onClick={() => {
                        setSelectedImage(null);
                        setLoadImage(false);
                    }}
                >
                    Șterge
                </Button>
            </div>
        </>) : (<>
            <p style={{fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px"}}>Siglă</p>
            <div className='rounded' style={{
                width: "120px", height: "120px", background: "white", display: "inline-block", border: "1px dashed gray"
            }}>
                <label htmlFor="file-upload"
                       style={{
                           fontFamily: "'Inter', arial",
                           fontSize: "15px",
                           color: "#003172",
                           position: "relative",
                           top: "50%",
                           left: "50%",
                           transform: "translate(-50%, -50%)"
                       }}>
                    Apasă pentru a încărca sigla
                </label>
            </div>
        </>)}
        <input
            id="file-upload"
            type="file"
            name="myImage"
            onChange={(event) => {
                setSelectedImage(event.target.files[0]);
                setSelectNewImage(true);
            }}
        ></input>

    </>);
};

export default UploadPhoto;
