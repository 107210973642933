import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import {loadCrmCompanies} from "../../../../../../api/crm/companies";
import Loading from "../../../../../MainPages/Components/Loading/Loading";
import React, {useEffect, useState} from "react";
import {getCompany} from "../../../../../../api/companies";
import useAuth from "../../../../../../hooks/useAuth";
import {loadUserData} from "../../../../../../api/user";
import {copyButExclude, formatDate, formatDateTime} from "../../../../../../utils/utils";
import {listToDict} from "../../../../../../utils/functions";
import {loadInvoices} from "../../../../../../api/invoices";
import {loadContacts} from "../../../../../../api/contacts";
import {createCrmContract, editCrmContract} from "../../../../../../api/crm/contracts";
import ErrorField, {validateForm} from "../../../../../../utils/functions/validation";
import {REGEXP} from "../../../../../../utils/constants";
import AddInvoices from "../AddInvoices";
import DealPDF from "../../../../../../components/ReactPdf/DealPDF";
import {PDFDownloadLink} from "@react-pdf/renderer";


const DealNew = ({viewPage = false}) => {
    const {companyId} = useParams();
    const auth = useAuth();
    const outletContext = useOutletContext();
    const deal = outletContext?.deal ? outletContext.deal : undefined;

    const initialState = {
        name: "",
        contractor: '',
        contractor_cui: '',
        contractor_represent: '',
        contractat: '',
        contractat_cui: '',
        contractat_represent: '',
        contractat_represent_name: '', // invoice: '',
        value: 0,
        invoices: [],
        clause: [],
        history: [],
        status: 'IN CURS',
        date: formatDate(new Date()),
        company: companyId,
        created_by: (auth.data?.user_id) ?? '',
        updated_by: (auth.data?.user_id) ?? '',

    };
    const [errors, setErrors] = useState({});

    const createContractState = useFetch(createCrmContract, {});
    const editContractState = useFetch(editCrmContract, {});

    const thisCompanyState = useFetch(getCompany, {companyId: companyId});
    const crmCompaniesState = useFetch(loadCrmCompanies, {companyId: companyId});
    const loadUserState = useFetch(loadUserData, {});
    const loadInvoicesState = useFetch(loadInvoices, {companyId: companyId});
    const contactsState = useFetch(loadContacts, {});

    const [crmCompanies, setCRMCompanies] = useState({});
    const [contacts, setContacts] = useState({});
    const [invoices, setInvoices] = useState([]);


    const [form, setForm] = useState(deal ? {
        name: deal?.name,
        contractor: deal?.contractor,
        contractat: deal?.contractat,
        value: deal?.value ?? 0,
        invoices: deal?.invoices ?? [],
        clause: deal?.clause ? JSON.parse(deal?.clause) : [],
        history: deal?.history ? JSON.parse(deal?.history) : [],
        status: deal?.status,
        date: deal?.date,
        company: companyId,
        created_by: deal?.created_by ?? '',
        updated_by: (auth.data?.user_id) ?? '',
    } : initialState);

    const [clause, setClause] = useState({
        title: '', content: '',
    });
    const [clauseArr, setClauseArr] = useState(deal?.clause ? JSON.parse(deal.clause) : []);
    const [newNote, setNewNote] = useState('');
    const [history, setHistory] = useState(deal?.history ? JSON.parse(deal?.history) : []);

    const removeNote = (index) => {
        const updated = history.filter((c, i) => i !== index);
        setHistory(updated);
    };

    useEffect(() => {
        contactsState.sendRequest({companyId: companyId}).then((response) => {
            const contactsConst = listToDict(response);
            setContacts(contactsConst);

            crmCompaniesState.sendRequest({companyId: companyId}).then((response) => {
                const crmCompaniesConst = listToDict(response);
                setCRMCompanies(crmCompaniesConst);

                //edi crm contract
                if (deal?.contractat) {
                    setForm(prevState => ({
                        ...prevState,
                        contractat: deal.contractat,
                        contractat_cui: crmCompaniesConst[deal?.contractat]?.cui,
                        contractat_represent: crmCompaniesConst[deal?.contractat]?.contact,
                        contractat_represent_name: contactsConst[crmCompaniesConst[deal?.contractat]?.contact]?.name,
                    }));
                }

            });
        });
        thisCompanyState.sendRequest({companyId: companyId}).then((response) => {
            setForm(prev => ({...prev, contractor: response?.company.domain, contractor_cui: response?.company.cui}));
        });
        loadUserState.sendRequest({userId: auth.data?.user_id}).then((response) => {
            setForm(prev => ({...prev, contractor_represent: response?.name}));
        });
        loadInvoicesState.sendRequest().then(invoiceResponse => {
            setInvoices(invoiceResponse);
        });
    }, []);

    const handleCRMCompany = (e) => {
        const company = e.target.value;
        if (company !== '') {
            setForm(prevState => ({
                ...prevState,
                contractat: company,
                contractat_cui: crmCompanies[company]?.cui,
                contractat_represent: crmCompanies[company]?.contact,
                contractat_represent_name: contacts[crmCompanies[company]?.contact]?.name,
            }));
            setInvoices(() => loadInvoicesState.data?.filter(i => i.contact === crmCompanies[company]?.id));
        } else {
            setForm(prevState => ({
                ...prevState,
                contractat: '',
                contractat_cui: '',
                contractat_represent: '',
                contractat_represent_name: '',
            }));
            setInvoices(loadInvoicesState.data);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        // if (name === 'invoice' && value === '') return;
        // if (name === 'invoice') {
        //     const val = e.target.options[e.target.selectedIndex]
        //     setForm(prev => ({
        //         ...prev, valoare_contract: val.getAttribute('valoare_contract')
        //     }));
        // }
        setForm(prev => ({...prev, [name]: value}));
    };


    const removeClause = (index) => {
        const updated = clauseArr.filter((c, i) => i !== index);
        setClauseArr(updated);
    };
    const nav = useNavigate();

    const saveContract = () => {
        const validatedData = validateForm(initialState, copyButExclude(form, ['value', 'invoices', 'company', 'created_by', 'updated_by', 'clause', 'status', 'date']), {
            contractor_cui: REGEXP.INTEGER_ABOVE_ZERO_OR_EQUAL, contractat_cui: REGEXP.INTEGER_ABOVE_ZERO_OR_EQUAL
        });
        if (Object.entries(validatedData).length > 0) {
            setErrors(validatedData);
            return;
        }
        setErrors({});

        if (deal) {
            editContractState.sendRequest({
                crmContractId: deal?.id,
                data: {...form, clause: JSON.stringify(clauseArr), history: JSON.stringify(history)}
            }).then((response) => {
                nav(-1);
            });
        } else {
            createContractState.sendRequest({
                data: {
                    ...form, clause: JSON.stringify(clauseArr), history: JSON.stringify(history)
                }
            }).then((response) => {
                nav(-1);
            })
        }
    }

    return (<>
        {(crmCompaniesState.loading || loadInvoicesState.loading || loadUserState.loading || thisCompanyState.loading || contactsState.loading) ?
            <Loading/> : <Container>
                <h2
                    style={{
                        fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                    }}
                >
                    Contract nou
                </h2>

                <Form>
                    <Row className="formsection">
                        <h3
                            style={{
                                fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Părţile contractului
                        </h3>

                        <p
                            style={{
                                fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Părţile ce urmează să încheie contractul
                        </p>
                        <hr></hr>
                        <Col sm={6}>
                            <p
                                style={{
                                    textAlign: "center", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Contractor
                            </p>

                            <Form.Group
                                style={{fontFamily: "'Inter', arial", color: "#003172"}}
                            >
                                <Form.Control
                                    style={{margin: "5px 0px 5px 0px"}}
                                    placeholder="NUME"
                                    value={form.contractor}
                                    disabled
                                ></Form.Control>
                                <Form.Control
                                    style={{margin: "5px 0px 5px 0px"}}
                                    placeholder="CUI"
                                    value={form.contractor_cui}
                                    disabled
                                ></Form.Control>
                                <Form.Control
                                    style={{margin: "5px 0px 5px 0px"}}
                                    placeholder="REPREZENTANT"
                                    value={form.contractor_represent}
                                    name="contractor_represent"
                                    onChange={handleChange}
                                    disabled={viewPage}
                                ></Form.Control>
                                <ErrorField enabled={errors['contractor_represent']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <p
                                style={{
                                    textAlign: "center", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Contractat
                            </p>

                            <Form.Group
                                style={{fontFamily: "'Inter', arial", color: "#003172"}}
                            >
                                <Form.Select
                                    disabled={viewPage} value={form.contractat} onChange={handleCRMCompany}>
                                    <option value={''}>Selecteaza...</option>
                                    {crmCompaniesState.data?.map((i, index) => {
                                        return <option key={index} value={i.id}>{i.company_name}</option>
                                    })}
                                </Form.Select>
                                <ErrorField enabled={errors['contractat']}/>
                                <Form.Control
                                    disabled={viewPage}
                                    style={{margin: "5px 0px 5px 0px"}}
                                    placeholder="CUI"
                                    value={form.contractat_cui}
                                    name="contractat_cui"
                                    onChange={handleChange}
                                ></Form.Control>
                                <ErrorField enabled={errors['contractat_cui']}/>
                                <Form.Control
                                    style={{margin: "5px 0px 5px 0px"}}
                                    placeholder="REPREZENTANT"
                                    value={form.contractat_represent_name}
                                    disabled
                                ></Form.Control>
                                <ErrorField enabled={errors['contractat_represent_name']}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="formsection">
                        <h3
                            style={{
                                fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Obiectul contractului
                        </h3>

                        <p
                            style={{
                                fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Serviciile şi produsele facturate
                        </p>
                        <hr></hr>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Numele contractului</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    style={{margin: "5px 0px 5px 0px"}}
                                    placeholder="Nume"
                                    value={form.name}
                                    name="name"
                                    onChange={handleChange}
                                ></Form.Control>
                                <ErrorField enabled={errors['name']}/>
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Data încheierii contractului</Form.Label>

                                <Form.Control
                                    disabled={(form.status !== 'CASTIGAT' && form.status !== 'PIERDUT') || viewPage}
                                    name="date"
                                    type="date"
                                    value={!(form.status !== 'CASTIGAT' && form.status !== 'PIERDUT') ? form.date : undefined}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Status contract</Form.Label>

                                <Form.Select
                                    disabled={viewPage} name='status' onChange={handleChange} value={form.status}>
                                    <option value='IN CURS'>În curs</option>
                                    <option value='CASTIGAT'>Câştigat</option>
                                    <option value='PIERDUT'>Pierdut</option>
                                    <option value='BLOCAT'>Blocat</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Valoarea contract</Form.Label>
                                <Form.Control
                                    disabled={true}
                                    style={{margin: "5px 0px 5px 0px"}}
                                    value={form.value}
                                    name="value"
                                ></Form.Control>
                            </Form.Group>
                        </Col>

                        <AddInvoices viewPage={viewPage} invoices={invoices} invoicesDict={listToDict(invoices)}
                                     setData={setForm} defaultValue={form?.invoices}/>

                    </Row>

                    <Row className="formsection">
                        <h3
                            style={{
                                fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Clauze
                        </h3>

                        <p
                            style={{
                                fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Clauzele contractului
                        </p>

                        <hr></hr>

                        <Col sm={12}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Titlu</Form.Label>
                                <Form.Control
                                    disabled={viewPage} type="text" name="title" rows={3} value={clause.title}
                                    onChange={e => setClause(prev => ({...prev, title: e.target.value}))}/>

                                <Form.Label>Conţinut</Form.Label>
                                <Form.Control
                                    disabled={viewPage} as="textarea" name="content" rows={3} value={clause.content}
                                    onChange={e => setClause(prev => ({...prev, content: e.target.value}))}/>

                                <Button
                                    disabled={viewPage} style={{
                                    marginTop: "20px",
                                    background: "#003172",
                                    height: "36px",
                                    borderRadius: '8px',
                                    border: 'none',
                                    color: 'white',
                                    padding: "0px 10px 0px 10px"
                                }} onClick={() => {
                                    if (clause.title && clause.content) {
                                        setClauseArr([...clauseArr, clause]);
                                        setClause({title: '', content: ''});
                                    }
                                }}>Adaugă clauză</Button>

                                {clauseArr.length !== 0 ? <hr></hr> : <></>}

                                {clauseArr?.map((clause, index) => <div style={{
                                    marginTop: "20px", borderLeft: "3px solid #003172", paddingLeft: "20px"
                                }}>
                                    <div>
                                        <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <span style={{color: "#003172", fontSize: "28px"}}>{clause.title}</span>
                                            <Button
                                                disabled={viewPage} style={{
                                                background: "#003172",
                                                height: "36px",
                                                borderRadius: '8px',
                                                border: 'none',
                                                color: 'white',
                                                padding: "0px 10px 0px 10px"
                                            }} onClick={() => removeClause(index)}>
                                                Şterge
                                            </Button>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {clause.content}
                                        </p>
                                    </div>
                                </div>)}
                            </Form.Group>


                        </Col>

                    </Row>

                    <Row className="formsection">
                        <h3
                            style={{
                                fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Istoric
                        </h3>

                        <p
                            style={{
                                fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Istoricul contractului
                        </p>

                        <hr></hr>

                        <Col sm={12}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Conţinut</Form.Label>
                                <Form.Control
                                    disabled={viewPage} as="textarea" name="content" rows={3} value={newNote}
                                    onChange={e => setNewNote(e.target.value)}/>

                                <Button
                                    disabled={viewPage} style={{
                                    marginTop: "20px",
                                    background: "#003172",
                                    height: "36px",
                                    borderRadius: '8px',
                                    border: 'none',
                                    color: 'white',
                                    padding: "0px 10px 0px 10px"
                                }} onClick={() => {
                                    if (newNote) {
                                        setHistory([{date: formatDateTime(new Date()), content: newNote}, ...history]);
                                        setNewNote('');
                                    }
                                }}>Adaugă</Button>

                                {history?.length !== 0 ? <hr></hr> : <></>}

                                {history.map((note, index) => {
                                    return (<div style={{
                                        marginTop: "20px", borderLeft: "3px solid #003172", paddingLeft: "20px"
                                    }}>
                                        <div>
                                            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span
                                                        style={{
                                                            color: "#003172", fontSize: "20px"
                                                        }}>[{note.date}]</span>
                                                <Button
                                                    disabled={viewPage} style={{
                                                    background: "#003172",
                                                    height: "36px",
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                    color: 'white',
                                                    padding: "0px 10px 0px 10px"
                                                }} onClick={() => removeNote(index)}>
                                                    Şterge
                                                </Button>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                {note.content}
                                            </p>
                                        </div>
                                    </div>);
                                })}
                            </Form.Group>


                        </Col>

                    </Row>

                </Form>
                <div
                    style={{
                        textAlign: "right", marginTop: "50px", height: "100px",
                    }}
                >
                    <Button
                        onClick={() => nav(-1)}
                        style={{
                            margin: "5px",
                            fontFamily: "'Inter', arial",
                            background: "none",
                            border: "none",
                            boxShadow: "none !important",
                            color: "gray",
                        }}
                    >
                        Inapoi
                    </Button>
                    <Button
                        hidden={!viewPage}
                        style={{
                            margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                        }}
                        onClick={() => nav('../edit')}
                    >Editeaza
                    </Button>

                    {/*{viewPage && <DisableObject name='contractul' disableFunction={disableCompany}/>}*/}

                    <Button
                        hidden={viewPage}
                        style={{
                            margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                        }}
                        onClick={saveContract}
                    >{deal ? 'Actualizează' : 'Salvează'}
                    </Button>
                    {<PDFDownloadLink
                        key={viewPage}
                        document={<DealPDF deal={deal} contact={contacts[deal?.contractat_represent]}
                                           company={crmCompanies[deal?.contractat]} invoices={listToDict(invoices)}
                                            invoicesList={form?.invoices}
                        />}>
                        {({blob, url, loading, error}) => (<a href={url} target="_blank" rel="noopener noreferrer">
                            <Button
                                hidden={!viewPage}
                                style={{
                                    margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                                }}
                            >{loading ? 'Loading ...' : 'Descarcă PDF'}
                            </Button>
                        </a>)}
                    </PDFDownloadLink>}

                </div>
            </Container>}
    </>);
};

export default DealNew;
