//function wich takes an array of obects and filters by type property
//if type is all it returns the array without filtering

export const filterByType = (array, type) => {
    if (type === "all") return array;
    return array.filter((item) => item.type === type);
};

//function which searches an array of objects by a pattern
export const searchObject = (list, pattern) => {
    pattern = pattern.replace(" ", "").toLowerCase();
    return list.filter((object) => Object.values(object).join("").toLowerCase().includes(pattern));
};

export const filterList = (list, filters) => {
    return list?.filter((item) => {
        for (const key in filters) {
            const value = filters[key];
            if (Array.isArray(value)) return 1;
            if (item[key] !== value && value !== 'all') {
                return 0;
            }
        }
        return 1;
    })
};

export const filterByPropsInArray = (list, propName, filterArray = []) => {
    if (!list) return [];
    if(filterArray?.length === 0) return list;

    return list?.filter(item => {
        const props = item[propName] || [];
        console.log(filterArray?.every(filter => props.includes(filter)))
        return filterArray?.every(filter => props.includes(filter));
    });
};

export const filterByTime = (list, start, end, prop = 'created_at') => list?.filter((item) => new Date(item[prop]) >= new Date(start) && new Date(item[prop]) <= new Date(end));