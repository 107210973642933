import React from 'react';

import {Link} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
    FaArrowAltCircleRight
} from "react-icons/fa";

import InvoicesButton from './InvoicesButton';

import {AiOutlinePlus, AiOutlineImport} from 'react-icons/ai';
import {INVOICE_TYPE} from "../../../../../../../../utils/constants";


function NoInvoices({type}) {

    return (<>
        <Row>
            <Col className="align-self-center">
                <h1 style={{
                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px", marginBottom: "30px"
                }}>{type === INVOICE_TYPE.INVOICE ? 'Facturi emise' : 'Facturi primite'}</h1>
                <p style={{fontFamily: "'Inter', arial", color: "#003172", marginBottom: "30px"}}>
                    Facturile pot fi o singură dată sau recurente. Le puteți trimite clienților și puteți începe să
                    acceptați plăți online.

                </p>
                <hr></hr>
            </Col>
        </Row>

        <Row>
            <InvoicesButton label="Factură nouă"
                            icon={<AiOutlinePlus size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                            path="create"/>
        </Row>
    </>);
}

export default NoInvoices;