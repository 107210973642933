import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

import Container from "react-bootstrap/Container";
import m from "../../../../static/M.png";
import "../../../../static/css/NavbarComp.css";

import Offcanvas from "react-bootstrap/Offcanvas";
import useAuth from "../../../../hooks/useAuth";

import './MainNavbar.css';
import {useEffect, useState} from "react";
import {getAuthFromStorage} from './../../../../utils/cache/cache';

const MainNavbar = ({user}) => {

    const [show, setShow] = useState(true);
    const url = useLocation();
    const nav = useNavigate();

    const controlNavbar = () => {
        if (window.scrollY > 100) {
            setShow(false);
        } else {
            setShow(true);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => window.removeEventListener("scroll", controlNavbar);
    });

    const auth = useAuth();

    return (<Navbar
        id="navbar"
        className="navigation"
        collapseOnSelect
        expand="lg"
        variant="light"
    >
        <Container>
            <NavLink to="/" style={{fontWeight: "bold"}}>
                <img
                    src={m}
                    alt="logo"
                    style={{width: "130px", padding: "none"}}
                ></img>
            </NavLink>
            <Navbar.Brand to="/" style={{fontWeight: "bold"}}></Navbar.Brand>
            <Navbar.Toggle
                className="closebutton"
                aria-controls="responsive-navbar-nav"
            />

            <Navbar.Offcanvas placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>sIMMple</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="me-auto">
                        <a onClick={() => {
                            if (url.pathname !== '/') {
                                nav('/');
                            }
                        }} className="navigationlink"
                           href={"#functii"}
                           style={{
                               color: "black",
                               fontWeight: "600",
                               margin: "20px 0px 15px 0px",
                               textDecoration: "none",
                           }}
                        >
                            Funcționalități
                        </a>

                        <a
                            onClick={() => {
                                nav('/contact_us')
                            }}
                            href={"#"}
                            style={{
                                color: "black",
                                fontWeight: "600",
                                margin: "20px 20px 15px 20px",
                                textDecoration: "none",
                            }}
                        >
                            Contact
                        </a>
                    </Nav>
                    <Nav>
                        {(auth.isLoggedIn && getAuthFromStorage()) ? (<NavDropdown
                            align="end"
                            title='Panou de control'
                            color="white"
                            id="collasible-nav-dropdown"
                            drop="down"
                            style={{
                                paddingTop: "3px",
                                marginTop: "10px",
                                fontWeight: "600",
                                marginRight: "20px",
                                borderRadius: "10px",
                            }}
                        >
                            <NavDropdown.Item>
                                <NavLink
                                    to="/dashboard"
                                    style={{textDecoration: "none", color: "black"}}
                                >
                                    Companii
                                </NavLink>
                            </NavDropdown.Item>

                            <NavDropdown.Item>
                                <NavLink
                                    to="/logout"
                                    style={{textDecoration: "none", color: "black"}}
                                >
                                    Deconectare
                                </NavLink>
                            </NavDropdown.Item>

                        </NavDropdown>) : (<NavLink
                            to="/login"
                            style={{
                                color: "#003172",
                                fontSize: "16px",
                                fontWeight: "600",
                                padding: "20px 0px 15px 0px",
                                textDecoration: "none",
                                marginRight: "20px",
                            }}
                        >
                            Autentificare
                        </NavLink>)}
                        <NavLink
                            to="/start"
                            style={{
                                backgroundColor: "#003172",
                                padding: "15px 20px 15px 20px",
                                marginTop: "5px",
                                marginRight: "0px",
                                fontWeight: "600",
                                fontSize: "16px",
                                textDecoration: "none",
                            }}
                            className="rounded text-white"
                        >
                            Iniţiere rapidă
                        </NavLink>
                    </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
    </Navbar>);
};

export default MainNavbar;
