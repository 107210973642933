import "./LicensesSettings.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import {loadLicense} from "./../../../../api/license";
import useFetch from "./../../../../hooks/useFetch";
import {useOutletContext, useParams} from "react-router-dom";
import Loading from "./../../../MainPages/Components/Loading/Loading";

import AddLicense from "../../../../components/Modals/AddLicenses/AddLicense";
import ManageLicense from "../../../../components/Modals/ManageLicense/ManageLicense";
import {removeLicenses} from "./../../../../utils/cache/functions";
import useAuth from "../../../../hooks/useAuth";
import InvoicePDF from "../../../../components/ReactPdf/InvoicePDF";
import Button from "react-bootstrap/Button";
import {PDFDownloadLink} from "@react-pdf/renderer";
import React from "react";
import LicensePDF from "../../../../components/ReactPdf/LicensePDF";
import {loadContacts} from "../../../../api/contacts";
import {getCompany} from "../../../../api/companies";

const LicensesSettings = () => {
    const {companyId} = useParams();
    const auth = useAuth();
    const outletContext = useOutletContext();
    const company = outletContext?.company ?? undefined;

    const loadLicenseState = useFetch(loadLicense, {companyId: companyId}, true);

    const refreshLic = () => {
        removeLicenses(companyId);
        loadLicenseState.sendRequest({companyId: companyId});
    };

    const userIsMe = (id) => {
        return auth.data?.user_id === id;
    }

    return (<>
        {(loadLicenseState.loading) && <Loading/>}
        <Container>
            <Row>
                <Col sm>
                    <h1
                        style={{
                            margin: "30px 0px", fontFamily: "'Inter', Arial", color: "#003172",
                        }}
                    >
                        Licenţe
                    </h1>
                </Col>
                <Col sm style={{textAlign: "right"}}>
                    <AddLicense/>
                </Col>
            </Row>

            <Row>
                <div style={{display: "flex"}}>
                    <p style={{marginRight: "30px", color: "#003172"}}>
                        Total licenţe: {loadLicenseState.data?.data?.length}
                    </p>
                    <p style={{color: "green"}}>
                        Licenţe disponibile:{" "}
                        {loadLicenseState.data?.data?.filter((e) => e.owner == null).length}
                    </p>
                </div>
            </Row>

            <Row className="itemscontainer">
                <Table style={{color: "#003172"}} responsive hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Utilizator asociat</th>
                        <th>Rol în companie</th>
                        <th>Data cumpărării</th>
                    </tr>
                    </thead>
                    <tbody>
                    {loadLicenseState.data?.data?.map((item, index) => (<tr>
                        <td>{index + 1}</td>
                        <td>{item.code}</td>
                        <td>{item.owner?.name ? item.owner.name : "-"}</td>
                        <td>
                            {item.role?.name ? item.role.name : item.owner?.name ? "MANAGER" : "-"}
                        </td>
                        <td>{item.created_at?.substring(0, 10)}</td>

                        <td style={{textAlign: "right"}}>
                            <ManageLicense disabled={userIsMe(item.owner?.id)} licenseId={item.id}
                                           companyId={companyId} owner={item.owner} refresh={refreshLic}/>
                        </td>
                        <td style={{textAlign: "right"}}>
                            <PDFDownloadLink
                                document={<LicensePDF license={item} companyName={company?.domain}
                                                      userName={item.owner?.name ? item.owner.name : "-"}
                                                      userEmail={item.owner?.email ? item.owner.email : "-"}/>}>
                                {({blob, url, loading, error}) => (
                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                        <Button
                                            style={{
                                                margin: "5px",
                                                fontFamily: "'Inter', arial",
                                                background: "#003172",
                                                border: "none",
                                            }}
                                        >{loading ? 'Loading ...' : 'Descarcă PDF'}
                                        </Button>
                                    </a>)}
                            </PDFDownloadLink>
                        </td>
                    </tr>))}
                    </tbody>
                </Table>
            </Row>
        </Container>
    </>);
};

export default LicensesSettings;
