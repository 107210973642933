import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
    useNavigate, useOutletContext, useParams,
} from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import {editCompany, editCompanyData, getCompanyDataById} from "../../../../api/companies";
import Loading from "../../../MainPages/Components/Loading/Loading";
import {removeCompanies} from "../../../../utils/cache/functions";

function LocationSettings() {
    const {companyId} = useParams();
    const navigate = useNavigate();

    const company = useOutletContext()?.company;
    const companyDataState = useFetch(getCompanyDataById, {});
    const editCompanyDataState = useFetch(editCompanyData, {});
    const editCompanyState = useFetch(editCompany, {});

    const [form, setForm] = useState({});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm((prev) => ({...prev, [name]: value}));
    };

    const saveData = () => {
        editCompanyDataState.sendRequest({companyDataId: form?.id, data: form}).then(() => {
            if (form.address !== company?.address || form.location !== company?.location) {
                editCompanyState.sendRequest({
                    companyId: companyId, data: {...company, address: form.address, location: form.location}
                }).then(() => {
                    removeCompanies();
                    navigate(`/dashboard`);
                });
            } else {
                removeCompanies();
                navigate(`/dashboard`);
            }
        });
    };

    useEffect(() => {
        companyDataState.sendRequest({companyId: companyId}).then((response) => {
            setForm(response);
            setForm((prev) => ({...prev, address: company?.address, location: company?.location}));
        })
    }, [companyId]);

    return (<>
        {(companyDataState.loading || editCompanyDataState.loading || editCompanyState.loading) && <Loading/>}
        <Container>
            <Row>
                <Col sm={12} xs={12} className="align-self-center">
                    <h2
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                        }}
                    >
                        Particularități locație
                    </h2>
                    <h3
                        style={{
                            fontSize: "20px", marginTop: "12px", fontFamily: "'Inter', arial", color: "#003172",
                        }}
                    >
                        Începutul și sfârșitul anului financiar
                    </h3>

                    <p
                        style={{
                            fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                        }}
                    >
                        Setează datele de început și sfârșit ale anului financiar.
                    </p>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col sm={12} xs={12} className="align-self-center">
                    <Row>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Început an financiar</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="start_fiscal_year"
                                    type="date"
                                    placeholder="Introdu data"
                                    value={form?.start_fiscal_year}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Sfârşit an financiar</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="end_fiscal_year"
                                    type="date"
                                    placeholder="Introdu data"
                                    value={form?.end_fiscal_year}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </Container>
        <br/><br/>
        <Container>
            <Row>
                <Col sm={12} xs={12} className="align-self-center">
                    <h3
                        style={{
                            fontSize: "20px", marginTop: "12px", fontFamily: "'Inter', arial", color: "#003172",
                        }}
                    >
                        Adresă
                    </h3>

                    <p
                        style={{
                            fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                        }}
                    >
                        Adresa va fi folosită în facturile tale şi în alte înregistrări
                    </p>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                        controlId="formBasicEmail"
                    >
                        <Form.Label>Adresa</Form.Label>
                        <Form.Control
                            className="forms"
                            required
                            name="address"
                            type="text"
                            placeholder="Introdu adresa"
                            value={form?.address}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                        controlId="formBasicEmail"
                    >
                        <Form.Label>Regiune / Judeţ</Form.Label>
                        <Form.Control
                            className="forms"
                            required
                            name="location"
                            type="text"
                            placeholder="Introdu regiunea / judeţul"
                            value={form?.location}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <div
                    style={{
                        textAlign: "right", marginTop: "50px", marginBottom: "20px",
                    }}
                >
                    <Button
                        onClick={() => navigate(-1)}
                        style={{
                            margin: "5px",
                            fontFamily: "'Inter', arial",
                            background: "none",
                            border: "none",
                            boxShadow: "none !important",
                            color: "gray",
                        }}
                    >
                        Anulează
                    </Button>
                    <Button
                        onClick={saveData}
                        style={{
                            margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                        }}
                    >
                        {"Actualizează"}
                    </Button>
                </div>
            </Row>
        </Container>
    </>);
}

export default LocationSettings;
