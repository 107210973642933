import React, {useEffect, useState} from "react";
import {CATEGORY_TYPE} from "../../../../utils/constants";
import useFetch from "../../../../hooks/useFetch";
import {loadCategories} from "../../../../api/categories";
import {useParams} from "react-router-dom";
import Loading from "../../../MainPages/Components/Loading/Loading";
import Table from "react-bootstrap/Table";
import Category from "./Category";
import {removeCategories} from "../../../../utils/cache/functions";
import NewCategory from "../../../../components/Modals/NewCategory";
import Container from "react-bootstrap/Container";
import SearchBar from "../../../../components/Filters/SearchBar";

const CategoriesPage = () => {
    const {companyId} = useParams();

    const [categoryType, setCategoryType] = useState(CATEGORY_TYPE.ITEM);
    const categoryState = useFetch(loadCategories, {});

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        categoryState.sendRequest({companyId: companyId, type: categoryType}).then((response) => {
            setCategories(response);
        });
    }, [categoryType]);

    const refreshCategories = () => {
        removeCategories(companyId);
        categoryState.sendRequest({companyId: companyId, type: categoryType}).then((response) => {
            setCategories(response);
        });
    }

    return (
        <Container>
            <div style={{display: 'flex', justifyContent: "space-between", marginTop: "30px",}}>
                <h1 style={{
                    fontFamily: "'Inter', Arial",
                    color: "#003172",
                }}>Categorii</h1>

                <div>
                    <select
                        onChange={e => setCategoryType(e.target.value)}
                        style={{
                            fontFamily: "'Inter', arial",
                            color: "#003172",
                            height: "37px",
                            padding: "0px 10px 0px 10px",
                            border: "1px solid #003172",
                            borderRadius: "8px",
                        }}
                    >
                        <option value={CATEGORY_TYPE.ITEM}>Produse</option>
                        <option value={CATEGORY_TYPE.INVOICE}>Vânzări</option>
                        <option value={CATEGORY_TYPE.BILL}>Achiziţii</option>
                        <option value={CATEGORY_TYPE.COMPANY}>Companii</option>
                    </select>

                    <NewCategory type={categoryType} refreshCategories={refreshCategories} />
                </div>
            </div>


            {categoryState.loading && <Loading/>}

                <SearchBar objectsArray={categoryState.data} setNewArray={setCategories} />


            <div className="formsection">
                <Table style={{color: "#003172"}} responsive hover>
                    <thead>
                    <tr>
                        <th width={"20%"}>Nume</th>
                        <th width={"20%"}>Tip</th>
                        <th width={"20%"}>Culoare</th>
                        <th width={"20%"}>Creat la</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories?.length > 0 ? categories?.map((c, i) =>
                        <Category key={i} category={c} refresh={refreshCategories}/>
                    ) : <></>}
                    </tbody>
                </Table>
            </div>
        </Container>
    );
}

export default CategoriesPage;
