import "reactjs-popup/dist/index.css";
import useFetch from "../../hooks/useFetch";
import {getCompany} from "../../api/companies";
import {useParams} from "react-router-dom";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {Navigate} from "react-router-dom";
import SidebarPage from "../../pages/AppPages/General/SidebarPage";
import {useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {useEffect} from "react";

const CompanyValidator = () => {
    const {companyId} = useParams();
    const companyState = useFetch(getCompany, {companyId: companyId});
    const auth = useAuth();

    useEffect(() => {
        companyState.sendRequest().then((res) => {
            if (res?.company.enabled) {
                auth.setRole(res?.role);
            }
        });
    }, [companyId]);

    return (<>
            {companyState.loading && <Loading/>}
            {companyState.error && (<Navigate
                    to={companyState.error?.status === 403 ? "/logout" : "/dashboard"}
                />)}
            {companyState.data && companyState.data?.company.enabled && (<>
                    {companyState.data?.company.active === 0 && (<Navigate to={"/activate_company"}/>)}
                    <SidebarPage company={companyState.data.company}/>
                </>)}
        </>);
};

export default CompanyValidator;
