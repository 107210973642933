import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {TbFilter} from "react-icons/tb";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import React, {useState} from 'react';
import {filterByTime, filterList} from "../../utils/functions/filters";
import {formatDate} from "../../utils/utils";

const InvoiceFilter = ({invoices, setInvoices, categories, contacts, type='INVOICE'}) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const [startTime, setStartTime] = useState(formatDate(currentDate));
    const [endTime, setEndTime] = useState(formatDate(new Date()));
    const [filters, setFilters] = useState({
        category: 'all', contact: 'all',
    });

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleFilters = (e) => {
        setFilters(prev => ({...prev, [e.target.name]: e.target.value}))
    };

    const applyFilters = () => {
        const prop = 'invoiced_at';
        setInvoices(filterList(filterByTime(invoices, startTime, endTime, prop), filters))
        handleClose();
    };



    return (<>
        <Button onClick={handleShow} style={{
            marginLeft: "5px",
            fontFamily: "'Inter', arial",
            background: "white",
            border: "1px solid lightgray",
            color: "#003172",
            height: "50px",
        }}><TbFilter size={30}/></Button>

        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Filtre</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <Card.Body style={{padding: '15px'}}>
                                <Card.Title style={{
                                    fontFamily: "'Inter', arial", color: 'black', fontSize: '15px', fontWeight: "800"
                                }}>Categorie</Card.Title>
                                <select style={{width: "100%", border: "none"}}
                                        name="category"
                                        value={filters?.category}
                                        onChange={handleFilters}
                                >
                                    <option value='all'>Toate</option>
                                    {Object.entries(categories)?.map((i, index) => <option
                                        value={i[0]}>{categories[i[0]]?.name}</option>)}
                                </select>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <Card.Body style={{padding: '15px'}}>
                                <Card.Title style={{
                                    fontFamily: "'Inter', arial", color: 'black', fontSize: '15px', fontWeight: "800"
                                }}>Contact</Card.Title>
                                <select style={{width: "100%", border: "none"}}
                                        name="contact"
                                        value={filters?.contact}
                                        onChange={handleFilters}
                                >
                                    <option value='all'>Toate</option>
                                    {Object.entries(contacts)?.map((i, index) => <option
                                        value={i[0]}>{contacts[i[0]]?.company_name}</option>)}
                                </select>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <Card.Body style={{ padding: '15px' }}>
                                <Card.Title
                                    style={{
                                        fontFamily: "'Inter', arial",
                                        color: 'black',
                                        fontSize: '15px',
                                        fontWeight: '800',
                                    }}
                                >
                                    Dată incipientă
                                </Card.Title>
                                <input
                                    type="date"
                                    value={startTime}
                                    onChange={e => setStartTime(e.target.value)}
                                    style={{ width: '100%', border: 'none' }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <Card.Body style={{ padding: '15px' }}>
                                <Card.Title
                                    style={{
                                        fontFamily: "'Inter', arial",
                                        color: 'black',
                                        fontSize: '15px',
                                        fontWeight: '800',
                                    }}
                                >
                                    Dată finală
                                </Card.Title>
                                <input
                                    type="date"
                                    value={endTime}
                                    onChange={e => setEndTime(e.target.value)}
                                    style={{ width: '100%', border: 'none' }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button style={{
                    background: "white",
                    color: "#003172",
                    borderRadius: "8px",
                    border: "none",
                    padding: "10px",
                    fontSize: "15px"
                }} onClick={handleClose}>
                    Anulează
                </button>
                <button style={{
                    background: "#003172",
                    color: "white",
                    borderRadius: "8px",
                    border: "none",
                    padding: "10px",
                    fontSize: "15px"
                }} onClick={applyFilters}>
                    Aplica filtrele
                </button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default InvoiceFilter;
