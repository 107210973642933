import React from "react";
import {useNavigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {AiOutlineEdit, AiOutlineCopy} from "react-icons/ai";
import useFetch from "../../../../../../../../hooks/useFetch";
import {editContact} from "../../../../../../../../api/contacts";
import DisableObject from "../../../../../../../../components/Modals/DisableObject";

import CustomerCard from "./CustomerCard";

const CustomerComponent = ({customer, refresh}) => {
    const navigate = useNavigate();
    const editContactState = useFetch(editContact, {
        contactId: customer.id,
        data: {enabled: 0},
    });

    const disableCustomer = () => {
        editContactState.sendRequest().then(() => {
            refresh();
        });
    };

    const updateCustomer = () => {
        navigate(`${customer.id}/edit`);
    };

    const duplicateCustomer = () => {
        //under dev
    };

    let addr = "";
    try {
        addr = JSON.parse(customer.address);
    } catch (error) {
        console.error("An error occurred while parsing the JSON string:", error);
    }

    return (
        <>
            <Col sm={3} onClick={() => navigate(`${customer.id}/view`)}>
                <CustomerCard
                    name={customer.name}
                    phone={customer.phone ? customer.phone : "N/A"}
                    address={addr?.city}
                    email={customer.email}
                    currency={customer.currency_code}
                    tax={customer.tax_number > 0 ? customer.tax_number : "N/A"}
                    disableClient={disableCustomer}
                    updateClient={updateCustomer}
                />
            </Col>
        </>
    );
};

export default CustomerComponent;
