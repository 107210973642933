import {useState} from "react";
import {Navigate} from "react-router-dom";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logo from "../../../static/M.png";
import Loading from "../Components/Loading/Loading";
import {Link} from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import {createCompany} from "../../../api/companies";
import useAuth from "../../../hooks/useAuth";
import {removeCompanies} from "../../../utils/cache/functions";
import {getFromCUI} from "../../../api/cui";

import "./StartPage.css";
import {requestActivateCompany} from "./../../../api/activate";
import {useNavigate} from "react-router-dom";
import {getAuthFromStorage} from './../../../utils/cache/cache';
import ErrorField, {validateForm} from "../../../utils/functions/validation";
import {REGEXP} from "../../../utils/constants";

const StartPage = () => {
    const auth = useAuth();
    const nav = useNavigate();

    const initialStateCompany = {
        domain: "", address: "", cui: "", location: "",
    };
    const initialStateCompanyData = {
        activity: "", activity_time: "", members: "", inmatriculare: "",
    };
    const [errors, setErrors] = useState({});

    const [company, setCompany] = useState(initialStateCompany);
    const [companyData, setCompanyData] = useState(initialStateCompanyData);

    const createCompanyState = useFetch(createCompany, {});
    const searchByCUIState = useFetch(getFromCUI, {});
    const requestActivateCompanyState = useFetch(requestActivateCompany, {});

    const searchByCUI = (cui) => {
        searchByCUIState.sendRequest({CUI: cui}).then((data) => {
            data = data?.data;
            setCompany((prev) => ({
                ...prev,
                domain: data?.company_name ? data?.company_name : "",
                address: data?.address ? data?.address : "",
                location: data?.judet ? data?.judet : "",
            }));
            setCompanyData((prev) => ({
                ...prev,
                activity: data?.activity ? data?.activity : "",
                activity_time: data?.year ? data?.year : "",
                inmatriculare: data?.nr_reg_comertului ? data?.nr_reg_comertului : "",
            }));
        });
    };

    const handleSearch = () => {
        searchByCUI(company.cui);
    };

    const handleCompany = (event) => {
        const {name, value} = event.target;
        setCompany((prev) => ({...prev, [name]: value}));
    };

    const handleCompanyData = (event) => {
        const {name, value} = event.target;
        setCompanyData((prev) => ({...prev, [name]: value}));
    };

    const start = () => {
        const companyErr = validateForm(initialStateCompany, company, {cui: REGEXP.INTEGER_ABOVE_ZERO_OR_EQUAL});
        const companyDataErr = validateForm(initialStateCompanyData, companyData, {
            activity_time: REGEXP.INTEGER_ABOVE_ZERO, members: REGEXP.INTEGER_ABOVE_ZERO
        });
        if (Object.entries(companyErr).length > 0 || Object.entries(companyDataErr).length > 0) {
            setErrors({...companyErr, ...companyDataErr});
            return;
        }
        setErrors({});
        removeCompanies();
        createCompanyState
            .sendRequest({
                ...{company: company, companyData: companyData}, userId: auth.data?.user_id,
            })
            .then((data) => {
                requestActivateCompanyState
                    .sendRequest({companyId: data?.id})
                    .then(() => {
                        nav("/dashboard");
                    });
            });
    };
    console.log(errors)

    return (<>
        {!(getAuthFromStorage()) && <Navigate to="/login"/>}
        {createCompanyState.loading ? (<Loading/>) : (<Row style={{}}>
            <Col
                style={{
                    fontFamily: "'Inter', arial", backgroundColor: "", margin: "auto", paddingTop: "20px",
                }}
                sm={8}
                xs={12}
            >
                <Container style={{background: ""}}>
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: "center"}}>
                            <Link to="/">
                                <img
                                    src={logo}
                                    style={{width: "150px", marginBottom: "30px"}}
                                    alt="im"
                                ></img>
                            </Link>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row style={{marginTop: "40px"}}>
                        <Col sm>
                            <Row>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Introdu CUI-ul
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="cui"
                                            type="number"
                                            placeholder="Introdu CUI-ul"
                                            onChange={handleCompany}
                                            value={company.cui}
                                        />
                                        <ErrorField enabled={errors['cui']}/>
                                    </Form.Group>
                                </Form>
                            </Row>
                        </Col>
                    </Row>

                    <div style={{display: "flex", marginTop: "40px"}}>
                        <p>
                            Completează datele cu ajutorul CUI-ului
                            <button onClick={handleSearch} className="CUISearchButton">
                                Caută după CUI
                            </button>
                        </p>
                    </div>

                    <Row style={{marginTop: "40px"}}>
                        <Form>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Cum se numește compania ta?
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="domain"
                                            type="text"
                                            placeholder="Nume"
                                            onChange={handleCompany}
                                            value={company.domain}
                                        />
                                        <ErrorField enabled={errors['domain']}/>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Cu ce se ocupă compania ta?
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="activity"
                                            type="text"
                                            onChange={handleCompanyData}
                                            value={companyData.activity}
                                        />
                                        <ErrorField enabled={errors['activity']}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Din ce an este activă firma?
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="activity_time"
                                            type="number"
                                            onChange={handleCompanyData}
                                            value={companyData.activity_time}
                                        />
                                        <ErrorField enabled={errors['activity_time']}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Număr registrul comerţului
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="inmatriculare"
                                            type="text"
                                            onChange={handleCompanyData}
                                            value={companyData.inmatriculare}
                                        />
                                        <ErrorField enabled={errors['inmatriculare']}/>
                                    </Form.Group>
                                </Col>

                                <Col sm>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Câți membri aveți în echipă?
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="members"
                                            type="number"
                                            onChange={handleCompanyData}
                                        />
                                        <ErrorField enabled={errors['members']}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                    <Row style={{marginTop: "0px"}}>
                        <Form>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Unde este localizată compania ta?
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="location"
                                            type="text"
                                            placeholder="Locaţie"
                                            onChange={handleCompany}
                                            value={company.location}
                                        />
                                        <ErrorField enabled={errors['location']}/>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{fontWeight: "bold"}}>
                                            Introdu adresa
                                            <span style={{color: "red"}}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            style={{boxShadow: "1px 1px 1px grey"}}
                                            name="address"
                                            type="text"
                                            placeholder="Adresă"
                                            onChange={handleCompany}
                                            value={company.address}
                                        />
                                        <ErrorField enabled={errors['address']}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row style={{marginTop: "40px", marginBottom: "0px"}}>
                                <Col sm></Col>
                                <Col sm>
                                    <div>
                                        <Button
                                            className="mb-3"
                                            variant="primary"
                                            style={{
                                                backgroundImage: "linear-gradient(to bottom right, #003172, #003172)",
                                                border: "none",
                                                width: "100%",
                                                fontWeight: "",
                                            }}
                                            onClick={start}
                                        >
                                            Creează compania
                                        </Button>
                                    </div>
                                </Col>
                                <Col sm></Col>
                            </Row>
                        </Form>
                    </Row>

                    {createCompanyState.error && "Oops! Ceva nu a decurs cum trebuie..."}
                    {requestActivateCompany.data && <Navigate to="/dashboard"/>}
                </Container>
            </Col>
        </Row>)}
    </>);
};

export default StartPage;
