import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import ItemsButton from './ItemsButton';

import { AiOutlinePlus, AiOutlineImport } from 'react-icons/ai';

function NoItems({ setLocale }) {

  return (
    <>
    <Col>
    <div style={{background: ""}}>
        <Container>
          <Row>
            <Col sm={12} xs={12} className="align-self-center">
              <h1 style={{fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px", marginBottom: "30px"}}>Produse</h1>

              <p style={{fontFamily: "'Inter', arial", color: "#003172", marginBottom: "30px"}}> 
              Produsele pot să fie produse fizice sau servicii. Produsele pot fi folosite la crearea de facturi, emise sau primite
              pentru a completa câmpurile referitoare la preț, taxe, etc.
              </p>
              <hr></hr>
            </Col>
          </Row>

          <Row>
            <ItemsButton label="Produs nou"
                          icon={<AiOutlinePlus size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>} 
                          path="create"/>

            {/*<ItemsButton label="Importă produse"*/}
            {/*              icon={<AiOutlineImport size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>} */}
            {/*              path="import"/>*/}
          </Row>

        </Container>
    </div>
    </Col>
  </>
  );
}

export default NoItems;