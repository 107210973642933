import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Receivables from "./Components/Receivables/Receivables";
import Payables from "./Components/Payables/Payables";
import ProfitLossRatio from "./Components/ProfitLossRatio/ProfitLossRatio";
import Expenses from "./Components/Expenses/Expenses"
import CashFlow from "./Components/CashFlow/CashFlow";

import {Navigate, useParams} from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import {loadInvoices} from "../../../api/invoices";
import {removeInvoices} from "../../../utils/cache/functions";
import Loading from "../../MainPages/Components/Loading/Loading";
import {loadCategories} from "../../../api/categories";
import {formatDate} from "../../../utils/utils";
import {loadContacts} from "../../../api/contacts";
import {loadCrmCompanies} from "../../../api/crm/companies";
import {CATEGORY_TYPE, INVOICE_TYPE} from "../../../utils/constants";
import {loadCrmContracts} from "../../../api/crm/contracts";
import HR from "./Components/HR/HR";
import {loadHR} from "../../../api/hr";
import {listToDict} from "../../../utils/functions";

function CompanyDashboardPage({setLocale}) {
    const {companyId} = useParams();
    const contactsState = useFetch(loadContacts, {companyId: companyId});
    const crmCompaniesState = useFetch(loadCrmCompanies, {companyId: companyId});
    const invoicesState = useFetch(loadInvoices, {companyId: companyId});
    const billsCategoriesState = useFetch(loadCategories, {companyId: companyId, type: CATEGORY_TYPE.BILL}, true);
    const hrState = useFetch(loadHR, {companyId: companyId}, true);

    const [invoices, setInvoices] = useState([]);
    const [bills, setBills] = useState([]);
    const [dicts, setDicts] = useState({
        contacts: {},
        companies: {},
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 14);
    const [filters, setFilters] = React.useState({
        start: formatDate(currentDate), end: formatDate(new Date()), contact: 'all', company: 'all',
    });

    useEffect(() => {
        invoicesState.sendRequest().then(res => {
            setInvoices(res.filter(i => i.type === INVOICE_TYPE.INVOICE));
            setBills(res.filter(i => i.type === INVOICE_TYPE.BILL));
        });
        contactsState.sendRequest().then(contactRes => {
            crmCompaniesState.sendRequest().then(companyRes => {
                setDicts({
                    contacts: listToDict(contactRes),
                    companies: listToDict(companyRes),
                })
            })
        })
    }, [companyId]);

    const refresh = () => {
        removeInvoices(companyId);
        invoicesState.sendRequest().then(res => {
            setInvoices(res.filter(i => i.type === INVOICE_TYPE.INVOICE));
            setBills(res.filter(i => i.type === INVOICE_TYPE.BILL));
        });
        contactsState.sendRequest();
        crmCompaniesState.sendRequest();
    };

    return (<>
        {contactsState.loading || crmCompaniesState.loading || invoicesState.loading || hrState.loading ? (
            <Loading/>) : invoicesState.error ? (<Navigate to="/dashboard"/>) : (<div>
            <Container>
                <h1
                    style={{
                        marginTop: "30px", marginBottom: "20px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Panou de control
                </h1>

                <Row style={{marginBottom: "20px"}}>
                    <Col>
                        <Card
                            style={{
                                border: "none",
                                borderRadius: "0px",
                                padding: "25px",
                                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                                background: "white",
                            }}
                        >
                            <Card.Body>
                                <p
                                    style={{fontFamily: "'Inter', Arial", color: "#003172"}}
                                >
                                    Filtrează statisticile
                                </p>
                                <Row>
                                    <Col sm={3}>
                                        <Card>
                                            <Card.Body style={{padding: "15px"}}>
                                                <Card.Title
                                                    style={{
                                                        fontFamily: "'Inter', arial",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "800",
                                                    }}
                                                >
                                                    Dată incipientă
                                                </Card.Title>
                                                <input
                                                    type="date"
                                                    onChange={(e) => {
                                                        setFilters(prev => ({
                                                            ...prev, start: new Date(e.target.value)
                                                                .toISOString()
                                                                .slice(0, 10)
                                                        }))
                                                    }}
                                                    style={{width: "100%", border: "none"}}
                                                    value={new Date(filters.start)
                                                        .toISOString()
                                                        .slice(0, 10)}
                                                ></input>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3}>
                                        <Card>
                                            <Card.Body style={{padding: "15px"}}>
                                                <Card.Title
                                                    style={{
                                                        fontFamily: "'Inter', arial",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "800",
                                                    }}
                                                >
                                                    Dată finală
                                                </Card.Title>
                                                <input
                                                    type="date"
                                                    onChange={(e) => {
                                                        setFilters(prev => ({
                                                            ...prev, end: new Date(e.target.value)
                                                                .toISOString()
                                                                .slice(0, 10)
                                                        }))
                                                    }}
                                                    style={{width: "100%", border: "none"}}
                                                    value={new Date(filters.end)
                                                        .toISOString()
                                                        .slice(0, 10)}
                                                ></input>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3}>
                                        <Card>
                                            <Card.Body style={{padding: "15px"}}>
                                                <Card.Title
                                                    style={{
                                                        fontFamily: "'Inter', arial",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "800",
                                                    }}
                                                >
                                                    Corespondenți
                                                </Card.Title>
                                                <select
                                                    onChange={e => {
                                                        setFilters(prev => ({
                                                            ...prev, contact: e.target.value, company: 'all',
                                                        }));

                                                    }}
                                                    value={filters.contact}
                                                    style={{width: "100%", border: "none"}}
                                                >
                                                    <option value="all">Selectează</option>
                                                    {contactsState.data?.map((contact, index) => {
                                                        return <option key={index}
                                                                       value={contact.id}>{contact.name}</option>
                                                    })}
                                                </select>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3}>
                                        <Card>
                                            <Card.Body style={{padding: "15px"}}>
                                                <Card.Title
                                                    style={{
                                                        fontFamily: "'Inter', arial",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "800",
                                                    }}
                                                >
                                                    Companii
                                                </Card.Title>
                                                <select
                                                    onChange={e => {
                                                        setFilters(prev => {
                                                            return {
                                                                ...prev, company: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    value={filters.company}
                                                    style={{width: "100%", border: "none"}}
                                                >
                                                    <option value="all">Selectează</option>
                                                    {crmCompaniesState.data
                                                        ?.filter(c => filters.contact === 'all' || c.contact === filters.contact)
                                                        ?.map((c, index) => {
                                                            return <option key={index}
                                                                           value={c.id}>{c.company_name}</option>
                                                        })}
                                                </select>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Receivables invoices={invoices} filters={filters} refresh={refresh} companies={dicts.companies}/>
                    <Payables bills={bills} filters={filters} refresh={refresh} companies={dicts.companies}/>
                </Row>

                <Row>
                    <ProfitLossRatio
                        invoices={invoices}
                        bills={bills}
                        filters={filters}
                        refresh={refresh}
                        companies={dicts.companies}/>
                    <Expenses
                        bills={bills}
                        categories={billsCategoriesState.data}
                        filters={filters}
                        refresh={refresh}
                        companies={dicts.companies}
                    />
                    <HR hr={hrState.data}
                        invoices={invoices}
                        bills={bills}
                        filters={filters}
                        companies={dicts.companies}
                    />
                </Row>

                <div>
                    <CashFlow
                        bills={bills}
                        invoices={invoices}
                        filters={filters}
                        companies={dicts.companies}
                    />
                </div>
            </Container>
        </div>)}
    </>);
}

export default CompanyDashboardPage;
