import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {TbLayoutList, TbLayoutGrid} from "react-icons/tb";
import {sortObjects} from "../../../../../../../utils/functions";
import {ORDER, SORT_BY} from "../../../../../../../utils/constants";

import ItemTableComponent from "../ItemCard/ItemTableComponent";
import ItemComponent from "../ItemCard/Item";

import Table from "react-bootstrap/Table";
import "./HasItems.css";
import SearchBar from "../../../../../../../components/Filters/SearchBar";
import ItemFilter from "../../../../../../../components/Filters/ItemFilter";
import {getView, saveView} from "../../../../../../../utils/cache/functions";

function HasItems({itemsParam, refresh, categories}) {
    const navigate = useNavigate();

    const [items, setItems] = useState(itemsParam ? itemsParam : []);

    const [view, setView] = useState(getView());
    const [order, setOrder] = useState(ORDER.DESC);
    const [sortBy, setSortBy] = useState(SORT_BY.CREATED_AT);

    return (<>
        <Col>
            <div style={{background: ""}}>
                <Container>

                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h1
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                            }}
                        >
                            Produse
                        </h1>
                        <Button
                            style={{
                                height: "40px",
                                marginTop: "30px",
                                fontFamily: "'Inter', arial",
                                background: "#003172",
                                border: "none",
                            }}
                            onClick={() => navigate("create")}
                        >
                            Produs nou
                        </Button>
                    </div>

                    <div className="itemscontainer" style={{
                        display: "flex",
                        margin: '20px 0px',
                        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
                        background: 'white',
                        verticalAlign: "middle"
                    }}>

                        <SearchBar objectsArray={itemsParam} setNewArray={setItems}/>

                        <Button
                            onClick={() => view === "Tabel" ? setView(saveView("Carduri")) : setView(saveView("Tabel"))}
                            style={{
                                marginLeft: "5px",
                                fontFamily: "'Inter', arial",
                                background: "white",
                                border: "1px solid lightgray",
                                color: "#003172",
                                width: "60px",
                                height: "50px",
                            }}>{view === "Tabel" ? <TbLayoutList size={30}/> : <TbLayoutGrid size={30}/>}</Button>

                        <ItemFilter items={itemsParam} setItems={setItems} categories={categories}/>
                    </div>

                    {view === "Tabel" ? (<>
                        <div className="itemscontainer">
                            <Table style={{color: "#003172"}} responsive hover>
                                <thead>
                                <tr>
                                    <th>Nume</th>
                                    <th>Categorie</th>
                                    <th>Descriere</th>
                                    <th>Preţ de vânzare</th>
                                    <th>Preţ de cumpărare</th>
                                    <th>Creat la</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sortObjects(items, sortBy, order).map((item, index) => (<ItemTableComponent
                                    key={index}
                                    item={item}
                                    category={categories[item.category]}
                                    refresh={refresh}
                                />))}
                                </tbody>
                            </Table>
                        </div>
                    </>) : (<>
                        <Row>
                            <Col>
                                <Row>
                                    {sortObjects(items, sortBy, order).map((item, index) => (<ItemComponent
                                        key={index}
                                        item={item}
                                        category={categories[item.category]}
                                        refresh={refresh}
                                    />))}
                                </Row>
                            </Col>
                        </Row>
                    </>)}

                </Container>
            </div>
        </Col>
    </>);
}

export default HasItems;
