import React from "react";
import useFetch from "../../../../hooks/useFetch";
import {editCategory} from "../../../../api/categories";
import DisableObject from "../../../../components/Modals/DisableObject";
import NewCategory from "../../../../components/Modals/NewCategory";

const Category = ({category, refresh}) => {

    const editCategoryState = useFetch(editCategory, {});

    const disableCategory = () => {
        editCategoryState.sendRequest({categoryId: category.id, data: {...category, enabled: 0}}).then(() => {
            refresh();
        });
    };

    return (<tr>
        <td width={"20%"}>{category?.name}</td>
        <td width={"20%"}>{category?.type}</td>
        <td width={"20%"} style={{color: category?.color}}>{category?.color}</td>
        <td width={"20%"}>{category?.created_at.substring(0, 10)}</td>
        <td width={"20%"} style={{textAlign: 'right'}}>
            <div style={{display: 'flex', justifyContent: "right"}}>
                <NewCategory category={category} type={category.type} refreshCategories={refresh} />
                <DisableObject disableFunction={disableCategory} name={'categoria'}/>
            </div>
        </td>
    </tr>);
};

export default Category;
