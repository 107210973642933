import React from "react";
import {Navigate, useParams} from "react-router-dom";
import Loading from "../../../../../MainPages/Components/Loading/Loading";
import {getCustomers} from "../../../../../../utils/functions";
import HasCustomers from "./Components/HasCustomers/HasCustomers";
import NoCustomers from "./Components/NoCustomers/NoCustomers";
import useFetch from "../../../../../../hooks/useFetch";
import {loadContacts} from "../../../../../../api/contacts";
import {removeContacts} from "../../../../../../utils/cache/functions";

function CustomersList({setLocale}) {
    const {companyId} = useParams();
    const contactsState = useFetch(loadContacts, {companyId: companyId}, true);

    const refreshCustomers = () => {
        removeContacts(companyId);
        contactsState.sendRequest();
    };

    return (
        <>
            {contactsState.loading && <Loading/>}
            {contactsState.error && <Navigate to="/dashboard"/>}
            {contactsState.data &&
                (getCustomers(contactsState.data).length ? (
                    <HasCustomers
                        customersParam={getCustomers(contactsState.data)}
                        refresh={refreshCustomers}
                    />
                ) : (
                    <NoCustomers/>
                ))}
        </>
    );
}

export default CustomersList;
