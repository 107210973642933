import "./App.css";
import {Routes, Route} from "react-router-dom";
import {LoginRequired} from "./utils/components/LoginRequired";

import Dashboard from "./pages/MainPages/Dashboard/Dashboard";
import Login from "./pages/MainPages/Login/Login";
import Home from "./pages/MainPages/Home/Home";
import LogoutPage from "./pages/MainPages/Logout/LogoutPage";
import StartPage from "./pages/MainPages/Start/StartPage";
import RegisterPage from "./pages/MainPages/Register/RegisterPage";

import "./styles/Sidebar.scss";

import {useState} from "react";
import CompanyDashboardPage from "./pages/AppPages/Dashboard/CompanyDashboardPage";
import ListItems from "./pages/AppPages/List/Items/ListItems/ListItems";
import ListEmployees from "./pages/AppPages/List/HR/ListEmployees";
import InvoicesList from "./pages/AppPages/List/Sales/Invoices/InvoicesList/InvoicesList";
import CustomersList from "./pages/AppPages/List/Sales/Customers/CustomersList/CustomersList";
// import InvoiceNew from "./pages/AppPages/New/Sales/Invoices/InvoiceNew/InvoiceNew";
import InvoiceNew from "./pages/AppPages/New/Invoices/InvoiceNew";
import BillNew from "./pages/AppPages/New/Purchases/Bills/BillNew/BillNew";

import Error404Page from "./pages/ErrorPages/Error404Page";
import MenuSidebar from "./components/Others/MenuSidebar";
import ChangePassword from "./pages/MainPages/ChangePassword/ChangePassword";
import NewItem from "./pages/AppPages/New/Items/NewItem/NewItem";
import ViewItem from "./pages/AppPages/View/Items/ViewItem/ViewItem";
import CustomerNew from "./pages/AppPages/New/Sales/Customers/CustomerNew/CustomerNew";
import CustomerView from "./pages/AppPages/View/Sales/Customers/CustomerView/CustomerView";
import {CONTACT_TYPE, INVOICE_TYPE} from "./utils/constants";
import VendorNew from "./pages/AppPages/New/Purchases/Vendors/VendorNew/VendorNew";
import VendorsList from "./pages/AppPages/List/Purchases/Vendors/VendorsList/VendorsList";
import VendorView from "./pages/AppPages/View/Purchases/Vendors/VendorView/VendorView";
import useAuth from "./hooks/useAuth";
import CompanyValidator from "./components/validators/Company";
import ItemValidator from "./components/validators/Item";
import ContactValidator from "./components/validators/Contact";
import InvoiceValidator from "./components/validators/Invoice";
import BillValidator from "./components/validators/Bill";

import CRMHome from "./pages/AppPages/CRM/Home/CRMHome";

import CRMContacts from "./pages/AppPages/List/CRM/Contacts/ContactsList/ContactsList";
import CRMCompanies from "./pages/AppPages/List/CRM/Companies/CompaniesList/CompaniesList";
import CRMDeals from "./pages/AppPages/List/CRM/Deals/DealsList/DealsList";

import CRMNewContact from "./pages/AppPages/New/CRM/Contacts/ContactNew/ContactNew";
import InvitationPage from "./pages/AppPages/Join/InvitationPage";
import ActivateAccount from "./pages/ErrorPages/ActivateAccount";
import ConfirmRegistration from "./pages/AppPages/Join/ConfirmRegistration";

import CompanySettings from "./pages/AppPages/Settings/Company/CompanySettings";
import LocationSettings from "./pages/AppPages/Settings/Location/LocationSettings";

import SettingsMenu from "./pages/AppPages/Settings/Menu/SettingsMenu";
import SalesMenu from "./pages/AppPages/List/Sales/SalesMenu/SalesMenu";
import PurchasesMenu from "./pages/AppPages/List/Purchases/PurchasesMenu/PurchasesMenu";
import ForgotPassword from "./pages/MainPages/ChangePassword/ForgotPassword";
import VerifyForgotPassword from "./pages/MainPages/ChangePassword/VerifyForgotPassword";
import LicensePage from "./pages/MainPages/License/LicensePage";

import ActivateCompany from "./pages/AppPages/Settings/Activate/ActivateCompany";
import LicensesSettings from "./pages/AppPages/Settings/Licenses/LicensesSettings";
import CompanyNotActive from "./pages/ErrorPages/ActivateCompany";
import ApproveNewLicense from "./pages/AppPages/Settings/Licenses/ApproveNewLicense";
import CompanyNew from "./pages/AppPages/New/CRM/Companies/CompanyNew/CompanyNew";
import DealNew from "./pages/AppPages/New/CRM/Deals/DealNew/DealNew";

import HomeStats from "./pages/AppPages/CRM/Home/HomeStats";
import DealValidator from "./components/validators/Deal";
import ProfilePage from "./pages/MainPages/Profile/ProfilePage";
import CrmCompanyValidator from "./components/validators/CrmCompany";
import ContactUsPage from "./pages/MainPages/Features/ContactUsPage";
import CategoriesPage from "./pages/AppPages/Settings/Category/CategoriesPage";
import TaxesPage from "./pages/AppPages/Settings/Tax/TaxesPage";
import ServersDown from "./pages/ErrorPages/ServersDown";
import CookiesPopup from "./components/Others/Cookies";
import ActivitiesPage from "./pages/AppPages/Settings/Activity/ActivitiesPage";

import TermsAndConditions from "./pages/MainPages/TermsAndConditions/TermsAndConditions";
import SecurityPolicy from "./pages/MainPages/SecurityPolicy/SecurityPolicy";
import NewHR from "./pages/AppPages/New/HR/NewHR";
import HRValidator from "./components/validators/HR";

function App() {
    const auth = useAuth();
    const [locale, setLocale] = useState("en");
    document.title = "sIMMple";

    return (<div className="App">
        <Routes>
            <Route
                element={<ActivateCompany/>}
                path="/activate/:companyId/:userId"
            />
            <Route
                element={<ApproveNewLicense/>}
                path="/license/approve/:companyId/:userId/:licenseNumber"
            />

            <Route path="invite">
                <Route element={<InvitationPage/>} path=":token"/>
            </Route>

            <Route path={"terms_and_conditions"} element={<TermsAndConditions/>}/>

            <Route path={"security_policy"} element={<SecurityPolicy/>}/>

            <Route element={<LoginRequired/>}>
                <Route element={<MenuSidebar/>}>
                    <Route element={<Dashboard/>} path="/dashboard"/>
                    <Route element={<ProfilePage/>} path="/profile"/>
                    <Route element={<ChangePassword/>} path="/password"/>
                    <Route element={<LicensePage/>} path="/license"/>
                </Route>

                <Route element={<StartPage/>} path="/start"/>

                <Route element={<CompanyValidator/>} path="/:companyId">
                    <Route
                        element={<CompanyDashboardPage setLocale={setLocale}/>}
                        path=""
                    />

                    <Route path="hr">
                        <Route element={<ListEmployees/>} path=""/>
                        <Route element={<NewHR/>} path="create"/>
                        <Route element={<HRValidator/>} path=":hrId">
                            <Route element={<NewHR/>} path="edit"/>
                            <Route element={<NewHR viewPage={true}/>} path="view"/>
                        </Route>
                    </Route>

                    <Route path="items">
                        <Route element={<ListItems setLocale={setLocale}/>} path=""/>
                        <Route element={<NewItem/>} path="create"/>
                        <Route element={<ItemValidator/>} path=":itemId">
                            <Route element={<NewItem/>} path="edit"/>
                            <Route element={<NewItem viewPage={true}/>} path="view"/>
                        </Route>
                    </Route>

                    <Route path="settings">
                        <Route element={<SettingsMenu/>} path=""/>
                        <Route element={<CompanySettings/>} path="company"/>
                        <Route element={<LocationSettings/>} path="location"/>
                        <Route element={<LicensesSettings/>} path="licenses"/>
                        <Route element={<CategoriesPage/>} path="categories"/>
                        <Route element={<ActivitiesPage/>} path="activities"/>
                        <Route element={<TaxesPage/>} path="taxes"/>
                    </Route>

                    <Route path="sales" element={<SalesMenu/>}>
                        <Route path="customers">
                            <Route
                                element={<CustomersList setLocale={setLocale}/>} path=""/>
                            <Route element={<CustomerNew/>} path="create"/>
                            <Route
                                element={<ContactValidator contactType={CONTACT_TYPE.CUSTOMER}/>}
                                path=":contactId"
                            >
                                <Route element={<CustomerNew/>} path="edit"/>
                                <Route element={<CustomerNew viewPage={true}/>} path="view"/>
                            </Route>

                        </Route>

                        <Route path="invoices">
                            <Route
                                element={<InvoicesList type={INVOICE_TYPE.INVOICE} setLocale={setLocale}/>}
                                path=""
                            />
                            <Route
                                element={<InvoiceNew type={INVOICE_TYPE.INVOICE} setLocale={setLocale}/>}
                                path="create"
                            />
                            <Route element={<InvoiceValidator type={INVOICE_TYPE.INVOICE}/>} path=":invoiceId">
                                <Route element={<InvoiceNew type={INVOICE_TYPE.INVOICE}/>} path="edit"/>
                                <Route element={<InvoiceNew type={INVOICE_TYPE.INVOICE} viewPage={true}/>} path="view"/>
                            </Route>
                        </Route>
                    </Route>

                    <Route path="purchases" element={<PurchasesMenu/>}>
                        <Route path="vendors">
                            <Route
                                element={<VendorsList setLocale={setLocale}/>}
                                path=""
                            />
                            <Route element={<VendorNew/>} path="create"/>
                            <Route
                                element={<ContactValidator contactType={CONTACT_TYPE.VENDOR}/>}
                                path=":contactId"
                            >
                                <Route element={<VendorNew/>} path="edit"/>
                                <Route element={<VendorNew viewPage={true}/>} path="view"/>
                            </Route>

                        </Route>

                        <Route path="bills">
                            <Route element={<InvoicesList type={INVOICE_TYPE.BILL} setLocale={setLocale}/>} path=""/>
                            <Route
                                element={<InvoiceNew type={INVOICE_TYPE.BILL} setLocale={setLocale}/>}
                                path="create"
                            />
                            <Route element={<InvoiceValidator type={INVOICE_TYPE.BILL}/>} path=":invoiceId">
                                <Route element={<InvoiceNew type={INVOICE_TYPE.BILL}/>} path="edit"/>
                                <Route element={<InvoiceNew type={INVOICE_TYPE.BILL} viewPage={true}/>} path="view"/>
                            </Route>
                        </Route>
                    </Route>

                    <Route path="crm">
                        <Route element={<CRMHome/>}>
                            <Route path="" element={<HomeStats/>}/>

                            <Route path="contacts">
                                <Route element={<CRMContacts/>} path=""/>

                                <Route element={<CRMNewContact/>} path="create"/>
                                <Route element={<ContactValidator/>} path=":contactId">
                                    <Route element={<CRMNewContact/>} path="edit"/>
                                    <Route element={<CRMNewContact viewPage={true}/>} path="view"/>
                                </Route>
                            </Route>

                            <Route path="companies">
                                <Route element={<CRMCompanies/>} path=""/>
                                <Route element={<CompanyNew/>} path="create"/>
                                <Route element={<CrmCompanyValidator/>} path=":crmCompanyId">
                                    <Route element={<CompanyNew/>} path="edit"/>
                                    <Route element={<CompanyNew viewPage={true}/>} path="view"/>
                                </Route>
                            </Route>

                            <Route path="deals">
                                <Route element={<CRMDeals/>} path=""/>
                                <Route element={<DealNew/>} path="create"/>
                                <Route element={<DealValidator/>} path=":dealId">
                                    <Route element={<DealNew/>} path="edit"/>
                                    <Route element={<DealNew viewPage={true}/>} path="view"/>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>

            <Route element={<Home/>} path="/"/>
            <Route element={<Login/>} path="/login"/>
            <Route element={<LogoutPage/>} path="/logout"/>
            <Route element={<RegisterPage/>} path="/register"/>
            <Route element={<ContactUsPage/>} path="/contact_us"/>
            <Route element={<ActivateAccount/>} path="/activate_account"/>
            <Route element={<CompanyNotActive/>} path="/activate_company"/>
            <Route element={<ForgotPassword/>} path="/forgot_password"/>
            <Route
                element={<VerifyForgotPassword/>}
                path="/reset_password/:token/:email"
            />
            <Route
                element={<ConfirmRegistration/>}
                path="/confirm-registration/:token/:email"
            />
            <Route element={<ServersDown/>} path="/servers_down"/>
            <Route element={<Error404Page/>} path="*"/>
        </Routes>
        <CookiesPopup/>
    </div>);
}

export default App;
