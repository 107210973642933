import {downloadCSV} from "../../utils/csv";
import Button from "react-bootstrap/Button";

const ExportCSV = ({name='data', data, headers, nameHeaders}) => {

    return (<>

        <Button
            onClick={() => downloadCSV(name, data, headers, nameHeaders)}
            style={{
                margin: "5px",
                fontFamily: "'Inter', arial",
                background: "#003172",
                border: "none",
                marginTop: "30px",
                marginBottom: "30px",
            }}
        >
            Exportă CSV
        </Button>
    </>);

};

export default ExportCSV;
