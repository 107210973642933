import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { MdOutlineBusiness } from "react-icons/md";

import SettingsButton from "./SettingsButton";
import "./SettingsMenu.css";

import { RiProfileLine } from "react-icons/ri";

import {
  AiOutlineLogout,
  AiOutlineFlag,
  AiOutlinePercentage,
  AiOutlineFolder,
} from "react-icons/ai";


import {
  BsPatchCheck,
  BsMailbox,
} from "react-icons/bs";
import {BiListCheck} from "react-icons/bi";


const SettingsMenu = () => {

    const  navigate = useNavigate();

    return (
        <Container>
            <Row>
              <Col sm={6}>
                <h1
                  style={{
                    fontFamily: "'Inter', arial",
                    color: "#003172",
                    marginTop: "30px",
                  }}
                >
                  Setări
                </h1>
              </Col>
            </Row>

            <Row style={{textAlign: "center"}}>
                <h2 style={{
                        fontFamily: "'Inter', arial",
                        color: "#003172",
                        marginTop: "30px",
                      }}>Setări companie</h2>
                <hr style={{width: "100%"}}></hr>
            </Row>

            <Row style={{marginTop: "30px"}}>

                <SettingsButton label="Companie"
                                icon={<MdOutlineBusiness size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="company"/>

                <SettingsButton label="Localizare"
                                icon={<AiOutlineFlag size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="location"/>

                <SettingsButton label="Categorii"
                                icon={<AiOutlineFolder size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="categories"/>

                <SettingsButton label="Taxe"
                                icon={<AiOutlinePercentage size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="taxes"/>

                <SettingsButton label="Activitați"
                                icon={<BiListCheck size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="activities"/>

            </Row>

            <Row style={{textAlign: "center"}}>
                <h2 style={{
                        fontFamily: "'Inter', arial",
                        color: "#003172",
                        marginTop: "30px",
                        textAlign: "center",
                      }}>Setări cont</h2>
                <hr style={{width: "100%"}}></hr>
            </Row>

            <Row style={{margin: "30px 0px 0px 0px"}}>
                <SettingsButton label="Profil"
                                icon={<RiProfileLine size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="/profile"/>

                <SettingsButton label="Licenţe"
                                icon={<BsPatchCheck size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="licenses"/>

                <SettingsButton label="Tichete"
                                icon={<BsMailbox size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="/contact_us"/>

                <SettingsButton label="Deconectare"
                                icon={<AiOutlineLogout size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>}
                                path="/logout"/>
            </Row>

        </Container>
    );
};

export default SettingsMenu;