export const sumTaxes = (list) => list.reduce((prev, current) => prev + current.rate, 0);

export const createCheck = (list) => {
    const arr = {};
    list.forEach((element) => {
        arr[element.id] = {
            isChecked: false, quantity: 1, object: element,
        };
    });
    return arr;
};

export const defaultTaxes = (item) => item ? item.taxes
    .map((tax, i) => tax.enabled && {
        value: tax.id, label: `${tax.name}-${tax.rate}%`,
    })
    .filter((t) => t) : [];

export const defaultCategory = (state, list, item) => {
    //selected but not saved
    if (state.category) {
        const cat = list.find((c) => c.id === state.category && c.enabled);
        return cat ? {value: cat.id, label: cat.name} : undefined;
    }

    if (item) {
        const cat = list.find((c) => c.id === item.category && c.enabled);
        return cat ? {value: cat.id, label: cat.name} : undefined;
    }
    return undefined;
};

export const optionsCategories = (list) => list
    .map((c, i) => c.enabled && {
        value: c.id, label: c.name,
    })
    .filter((c) => c);

export const optionsTaxes = (list) => list
    .map((tax, i) => tax.enabled && {
        value: tax.id, label: `${tax.name}-${tax.rate}%`,
    })
    .filter((t) => t);

export const handleCategories = (setState, e) => {
    setState((prev) => ({...prev, category: e.value}));
};

export const handleTaxes = (state, options) => {
    state.taxes = [];
    for (let i = 0; i < options.length; i++) {
        state.taxes.push(options[i].value);
    }
};
