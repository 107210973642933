import { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loading from "../Components/Loading/Loading";
import { Navigate } from "react-router-dom";

import useFetch from "../../../hooks/useFetch";
import { changePassword } from "../../../api/password";

const ChangePassword = () => {
  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    repeat_new_password: "",
  });
  const [msg, setMsg] = useState("");

  const resetPaswdState = useFetch(changePassword, {});

  const handleForm = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const updatePassword = () => {
    if(!form.old_password || !form.new_password)
      return;
    if (form.new_password !== form.repeat_new_password)
      setMsg("The passwords do not match");
    else
      resetPaswdState.sendRequest({
        old_password: form.old_password,
        new_password: form.new_password,
      });
  };

  return (
    <>
      {resetPaswdState.data && <Navigate to={"/logout"} />}
      {resetPaswdState.loading ? (
        <Loading />
      ) : (
        <Col style={{}}>
          <Form.Group className="mb-3" controlId="formBasicPassword1">
            <Form.Label>Parola curentă</Form.Label>
            <Form.Control
              name="old_password"
              type="password"
              placeholder="Introdu parola"
              onChange={handleForm}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword2">
            <Form.Label>Parola nouă</Form.Label>
            <Form.Control
              name="new_password"
              type="password"
              placeholder="Introdu parola"
              onChange={handleForm}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword3">
            <Form.Label>Confirmă parola nouă</Form.Label>
            <Form.Control
              name="repeat_new_password"
              type="password"
              placeholder="Confirmă parola"
              onChange={handleForm}
            />
          </Form.Group>
          <Button
            onClick={updatePassword}
            style={{
              float: "right",
              backgroundImage:
                "linear-gradient(to bottom right, #003172, #003172)",
              border: "none",
            }}
          >
            Actualizează
          </Button>
          {resetPaswdState.error
            ? "Parola curentă este greşită"
            : msg && msg}
        </Col>
      )}
    </>
  );
};

export default ChangePassword;
