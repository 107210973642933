import React from "react";

import AppNavbar from "../AppNavbar/AppNavbar";

const Sidebar = ({company}) => {

  return (
    <>
      <AppNavbar company={company} />
    </>
  );
};

export default Sidebar;
