import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ErrorField from "../../../../utils/functions/validation";
import AddCrmCompany from "../../../../components/Modals/AddCrmCompany/AddCrmCompany";
import {findById} from "../../../../utils/functions";
import {AiOutlineEdit} from "react-icons/ai";
import ItemInShoppingList from "../../List/Items/ListItems/Components/ItemInShoppingList/ItemInShoppingList";
import AddItem from "../../../../components/Modals/AddItem/AddItem";
import NewCategory from "../../../../components/Modals/NewCategory";
import {CATEGORY_TYPE} from "../../../../utils/constants";
import React from "react";

const InputFields = ({
                         invoiceForm,
                         handleInvoiceChange,
                         errors,
                         viewPage,
                         COMPANY_NAME,
                         USER_EMAIL,
                         companiesParam,
                         invoice,
                         returnCompany,
                         categories,
                         refreshCategories
                     }) => {

    return (<>
        <Row className="formsection">
            <Col sm={12} className="align-self-center">
                <h3
                    style={{
                        fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Companie
                </h3>

                <p
                    style={{
                        fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Schimbă adresa sau alte informații despre
                    compania ta
                </p>
                <hr></hr>
            </Col>
            <Col sm={4}>
                <Col sm={12}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                        controlId="formBasicEmail"
                    >
                        <Form.Label>Titlu</Form.Label>
                        <Form.Control
                            disabled={viewPage}
                            className="forms"
                            required
                            name="title"
                            type="text"
                            placeholder="Introdu titlul"
                            value={invoiceForm.title}
                            onChange={handleInvoiceChange}
                        />
                        <ErrorField enabled={errors['title']}/>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                        controlId="formBasicEmail"
                    >
                        <Form.Label>Subtitlu</Form.Label>
                        <Form.Control
                            disabled={viewPage}
                            className="forms"
                            required
                            name="subheading"
                            type="text"
                            placeholder="Introdu subtitlul"
                            value={invoiceForm.subheading}
                            onChange={handleInvoiceChange}
                        />
                        <ErrorField enabled={errors['subheading']}/>
                    </Form.Group>
                </Col>
            </Col>
            <Col sm={5} style={{textAlign: "center"}}>
                {/*<UploadImage />*/}
            </Col>
            <Col
                sm={3}
                style={{textAlign: "right", marginTop: "20px"}}
            >
                <p
                    style={{
                        fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    {COMPANY_NAME}
                </p>
                <p
                    style={{
                        fontFamily: "'Inter', arial", fontSize: "15px", color: "#003172", position: "relative",
                    }}
                >
                    {USER_EMAIL}
                </p>
            </Col>
        </Row>

        <Row className="formsection">
            <Col sm={12}
                 className="align-self-center"
            >
                <h3
                    style={{
                        fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Date de facturare
                </h3>

                <p
                    style={{
                        fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Datele de facturare apar în factură. Data emiterii este
                    folosită în panoul de control și în rapoarte.
                </p>
                <hr></hr>
            </Col>

            <Col sm={6} style={{textAlign: "center"}}>
                <p
                    style={{
                        fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                    }}
                >
                    Client
                </p>
                <AddCrmCompany
                    isDisabled={viewPage}
                    defaultValue={invoice && findById(invoice.contact, companiesParam)}
                    companiesParam={companiesParam}
                    returnCompany={returnCompany}
                />
                <br></br>
                <ErrorField enabled={errors['contact']}/>
            </Col>

            <Col sm={6} className="align-self-center">
                <Row>
                    <Col sm={6}>
                        <Form.Group
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                            }}
                            controlId="formBasicEmail"
                        >
                            <Form.Label>Data emiterii</Form.Label>
                            <Form.Control
                                disabled={viewPage}
                                className="forms"
                                required
                                name="invoiced_at"
                                onChange={handleInvoiceChange}
                                value={invoiceForm.invoiced_at}
                                type="date"
                                placeholder="Data emiterii"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                            }}
                            className="sm-auto"
                            controlId="formBasicPassword"
                        >
                            <Form.Label>Numărul facturii</Form.Label>
                            <Form.Control
                                disabled={viewPage}
                                className="forms"
                                required
                                name="invoice_number"
                                onChange={handleInvoiceChange}
                                value={invoiceForm.invoice_number}
                                type="text"
                                placeholder="Numărul facturii"
                            />
                            <ErrorField enabled={errors['invoice_number']}/>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                            }}
                            className="sm-auto"
                            controlId="formBasicPassword"
                        >
                            <Form.Label>Data scadentă</Form.Label>
                            <Form.Control
                                disabled={viewPage}
                                className="forms"
                                required
                                name="due_at"
                                type="date"
                                onChange={handleInvoiceChange}
                                value={invoiceForm.due_at}
                                placeholder="Data scadentă"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                            }}
                            className="sm-auto"
                            controlId="formBasicPassword"
                        >
                            <Form.Label>Numărul comenzii</Form.Label>
                            <Form.Control
                                disabled={viewPage}
                                className="forms"
                                required
                                name="order_number"
                                type="number"
                                onChange={handleInvoiceChange}
                                value={invoiceForm.order_number}
                                placeholder="Numărul comenzii"
                            />
                            <ErrorField enabled={errors['order_number']}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </Row>


        <Row className="formsection">
            <Col sm={12}
                 className="align-self-center"
            >
                <h3
                    style={{
                        fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Avansat
                </h3>

                <p
                    style={{
                        fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Selectează categoria si adaugă sau editează subsolul
                    facturii{" "}
                </p>
                <hr></hr>
            </Col>
            <Col sm={6}>
                <Form.Group
                    style={{
                        fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                    }}
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                >
                    <Form.Label>Subsol factură</Form.Label>
                    <Form.Control
                        disabled={viewPage}
                        style={{height: "11.9rem", fontSize: "12px"}}
                        className="forms"
                        as="textarea"
                        rows={3}
                        placeholder="Adaugă subsol"
                        name="footer"
                        value={invoiceForm.footer}
                        onChange={handleInvoiceChange}
                    />
                    <ErrorField enabled={errors['footer']}/>
                </Form.Group>
            </Col>
            <Col sm={6}>
                <Row>
                    <Col sm={12}>
                        <Form.Group
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                            }}
                            className="sm-auto"
                            controlId="formBasicPassword"
                        >
                            <Form.Label>Categorie</Form.Label>
                            <div style={{display: 'flex'}}>
                                <Form.Select
                                    disabled={viewPage}
                                    name={'category'}
                                    value={invoiceForm.category}
                                    onChange={handleInvoiceChange}
                                >
                                    <option value="null">Fără categorie</option>
                                    {categories?.map((c, index) => {
                                        return <option value={c.id}
                                                       key={index}>{c.name}</option>
                                    })}
                                </Form.Select>
                                <NewCategory refreshCategories={refreshCategories}
                                             type={CATEGORY_TYPE.INVOICE}/>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                            }}
                            className="sm-auto"
                            controlId="formBasicPassword"
                        >
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </Row>

    </>);
};

export default InputFields;