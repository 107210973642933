import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";
import ContactUs from "../../../components/Others/ContactUs";
const ContactUsPage = () => {

    return (
        <>
            <MainNavbar/>
            <ContactUs />
            <Footer/>
        </>
    );

};

export default ContactUsPage;