import ManageDeal from "../../../../../../../components/Modals/Manage/ManageDeal";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {getDealStatus} from "../../../../../../../utils/functions/deals";

const DealRow = ({deal, salesperson, business, value, invoices}) => {
    const nav = useNavigate();
    return (<>
        <tr>
            <td width={"14.2%"}>{deal?.name}</td>
            <td width={"14.2%"}>{salesperson?.name}</td>
            <td width={"14.2%"}>{business?.company_name}</td>
            <td width={"14.2%"}>{getDealStatus(deal?.status)}</td>
            <td width={"14.2%"}>{value}</td>
            <td width={"14.2%"}>{invoices}</td>

            <td style={{textAlign: "right"}}>
                <button
                    onClick={() => nav(`${deal.id}/view`)}
                    style={{
                        border: "none",
                        color: "white",
                        background: "#003172",
                        borderRadius: "8px",
                        padding: "2px 15px",
                        boxSizing: "border-box",
                    }}
                >
                    Vizualizează
                </button>
            </td>
        </tr>
    </>);
}

export default DealRow;