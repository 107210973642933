import React from "react";
import ManageContact from "../../../../../../../../components/Modals/Manage/ManageContact";

import './ContactCard.css';
import {CONTACT_DICT} from "../../../../../../../../utils/constants";
import {useNavigate} from "react-router-dom";

const ContactRow = ({ contact, company, refresh }) => {
    const nav = useNavigate();
  return (
    <tr>
        <td>
            {contact.name}
        </td>

        <td>
            {contact.phone}
        </td>
        <td>
            {contact.email}
        </td>

        <td>
            {CONTACT_DICT[contact.type]}
        </td>

        <td>
            {company}
        </td>

        <td>
            {contact.created_at.slice(0, 10)}
        </td>

        <td style={{textAlign: "right"}}>
            <button
                onClick={() => nav(`${contact.id}/view`)}
                style={{
                    border: "none",
                    color: "white",
                    background: "#003172",
                    borderRadius: "8px",
                    padding: "2px 15px",
                    boxSizing: "border-box",
                }}
            >
                Vizualizează
            </button>
        </td>

    </tr>
  );
};

export default ContactRow;
