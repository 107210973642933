import "reactjs-popup/dist/index.css";
import useFetch from "../../hooks/useFetch";
import {Outlet, useParams} from "react-router-dom";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {Navigate} from "react-router-dom";
import {getItem} from "../../api/items";

const ItemValidator = () => {
    const {itemId} = useParams();
    const itemState = useFetch(getItem, {itemId: itemId}, true);

    return (<>
            {itemState.loading && <Loading/>}
            {itemState.error && (<Navigate
                    to={itemState.error?.status === 403 ? "/logout" : "/dashboard"}
                />)}
            {itemState.data && itemState.data?.enabled && (<>
                    <Outlet context={{item: itemState.data}}/>
                </>)}
        </>);
};

export default ItemValidator;
