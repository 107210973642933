import React from "react";
import EmployeeRow from "./EmployeeRow";

const EmployeeTableComponent = ({employee, refresh}) => {

    return (<>
            <EmployeeRow
                employee={employee}
                refresh={refresh}
                name={employee.name}
            />
        </>);
};

export default EmployeeTableComponent;
