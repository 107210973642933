import React from "react";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';

import './ContactCard.css';
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../../../../../../../utils/utils";
import useFetch from "../../../../../../../../hooks/useFetch";
import {editContact} from "../../../../../../../../api/contacts";
import DisableObject from "../../../../../../../../components/Modals/DisableObject";
import {CONTACT_DICT} from "../../../../../../../../utils/constants";

const ContactCard = ({contact, refresh, companies}) => {

    const nav = useNavigate();

    const editContactState = useFetch(editContact, {});

    const disableContact = () => {
        editContactState.sendRequest({contactId: contact.id, data: {...contact, enabled: 0}})
            .then(() => {
                refresh();
            })
    };

    return (
        <Card onClick={() => nav(`${contact.id}/view`)} style={{margin: "20px auto", fontFamily: "'Inter', arial"}} className="contactcard">
            <Card.Header as="h5" className="contactcard-header">{contact.name}</Card.Header>
            <ListGroup className="list-group-flush">
                <ListGroupItem>
                    <i className="fa fa-phone mr-2" aria-hidden="true"></i>
                    {contact.phone}
                </ListGroupItem>
                <ListGroupItem>
                    <i className="fa fa-envelope mr-2" aria-hidden="true"></i>
                    {contact.email}
                </ListGroupItem>
                <ListGroupItem>
                    <i className="fa fa-address-card-o mr-2" aria-hidden="true"></i>
                    {CONTACT_DICT[contact?.type]}
                </ListGroupItem>
                <ListGroupItem>
                    <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                    {formatDate(new Date(contact.created_at))}
                </ListGroupItem>
                <ListGroupItem>
                    <i className="fa fa-briefcase mr-2" aria-hidden="true"></i>
                    {companies[contact.id]?.company_name}
                </ListGroupItem>
            </ListGroup>
            
        </Card>
    );
};

export default ContactCard;
