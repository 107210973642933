import jwtDecode from "jwt-decode";
import {createContext, useContext} from "react";
import {useEffect, useState} from "react";
import {getAuthFromStorage} from "../utils/cache/cache";

const authContext = createContext();

export const AuthProvider = ({children}) => {
    const [isLoggedIn, setAuthStatus] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [companyRole, setCompanyRole] = useState(undefined);

    const userIsLoggedIn = () => {
        const authData = getAuthFromStorage();
        if (authData && authData?.exp * 1000 > Date.now()) {
            setAuthStatus(authData?.token);
            setData(jwtDecode(authData?.token));
        }
    };

    const setRole = (role) => {
        setCompanyRole(role);
    };

    useEffect(() => {
        userIsLoggedIn();
    }, []);

    return (<authContext.Provider value={{isLoggedIn, setAuthStatus, data, setData, setRole, companyRole}}>
            {children}
        </authContext.Provider>);
};

const useAuth = () => {
    return useContext(authContext);
};

export default useAuth;
