import jwtDecode from "jwt-decode";
import { getMillisecondsFromMinutes } from "../functions";

export const getAuthFromStorage = () => {
  const auth = localStorage.getItem("auth");
  const data = auth ? JSON.parse(auth) : undefined;
  return data;
};

export const setAuthToStorage = (token, sessionid) => {
  const tokenData = jwtDecode(token);
  const data = JSON.stringify({
    token,
    sessionid,
    userId: tokenData?.user_id,
    is_active: tokenData?.is_active,
    exp: tokenData?.exp,
  });
  localStorage.setItem("auth", data);
};

export const cache = {
  get: (name) => {
    try {
      const savedData = JSON.parse(localStorage.getItem("cache"));
      if (!savedData[name]) return undefined;
      return savedData[name].exp > Date.now()
        ? savedData[name].value
        : undefined;
    } catch (e) {
      //console.log("cache get error", e);
      return undefined;
    }
  },
  set: (name, value, lifetime = 5) => {
    try {
      let savedData = JSON.parse(localStorage.getItem("cache"));
      if (!savedData) savedData = {};
      savedData[name] = {
        value: value,
        exp: Date.now() + getMillisecondsFromMinutes(lifetime),
      };
      localStorage.setItem("cache", JSON.stringify(savedData));
    } catch (e) {
      //console.log("cache set error", e);
      localStorage.setItem("cache", JSON.stringify({}));
    }
  },
  remove: (name) => {
    try {
      let cache = JSON.parse(localStorage.getItem("cache"));
      if (!cache) cache = {};
      cache[name] = undefined;
      localStorage.setItem("cache", JSON.stringify(cache));
    } catch (e) {
      localStorage.removeItem("cache");
    }
  },
};
