export const handleActivities = (state, options) => {
    state.activities = [];
    for (let i = 0; i < options.length; i++) {
        state.activities.push(options[i].value);
    }
};

export const defaultActivities = (actIds = [], activities = []) => {
    const arr = [];

    actIds?.forEach(id => {
        activities?.every(activity => {
            if (activity.enabled === 0) return false;
            if (activity.id === id) {
                arr.push({
                    value: activity.id, label: `${activity.name}`,
                });
                return false;
            }
            return true;
        });
    });

    return arr;
};

export const optionsActivities = (list) => list
    .map((act, i) => act.enabled && {
        value: act.id, label: `${act.name}`,
    })
    .filter((t) => t);