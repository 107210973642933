import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
    Navigate, useNavigate, useOutletContext, useParams,
} from "react-router-dom";
import Loading from "../../../../MainPages/Components/Loading/Loading";

import Select from "react-select";
import NewCategory from "../../../../../components/Modals/NewCategory";
import NewTax from "../../../../../components/Modals/NewTax";
import {
    defaultCategory, defaultTaxes, handleCategories, handleTaxes, optionsCategories, optionsTaxes,
} from "../../../../../utils/functions/items";
import useFetch from "../../../../../hooks/useFetch";
import {createItem, editItem} from "../../../../../api/items";
import {loadCategories} from "../../../../../api/categories";
import {loadTaxes} from "../../../../../api/taxes";
import {
    removeCategories, removeItems, removeTaxes,
} from "../../../../../utils/cache/functions";

import "./NewItem.css";
import ErrorField, {validateForm} from "../../../../../utils/functions/validation";
import {copyButExclude} from "../../../../../utils/utils";
import {REGEXP} from "../../../../../utils/constants";
import DisableObject from "../../../../../components/Modals/DisableObject";

function NewItem({viewPage = false}) {
    const {companyId} = useParams();
    const navigate = useNavigate();

    const outletContext = useOutletContext();
    const item = outletContext?.item ? outletContext?.item : undefined;

    const initialState = {
        name: "", description: "", sale_price: "", purchase_price: "", category: "", taxes: [], company: companyId,
    }

    const [form, setForm] = useState(item ? {
        name: item.name,
        description: item.description,
        sale_price: item.sale_price,
        purchase_price: item.purchase_price,
        category: item.category,
        taxes: item.taxes.map((tax, i) => tax.id),
        company: companyId,
    } : initialState);
    const [errors, setErrors] = useState({});

    const editItemState = useFetch(editItem, {});
    const createItemState = useFetch(createItem, {});
    const categoriesState = useFetch(loadCategories, {companyId: companyId}, true);
    const taxesState = useFetch(loadTaxes, {companyId: companyId}, true);

    const saveItem = () => {
        const validatedData = validateForm(initialState, copyButExclude(form, ['category', 'taxes', 'company']), {
            sale_price: REGEXP.FLOAT_ABOVE_ZERO_OR_EQUAL, purchase_price: REGEXP.FLOAT_ABOVE_ZERO_OR_EQUAL
        });
        if (Object.entries(validatedData).length > 0) {
            setErrors(validatedData);
            return;
        }
        setErrors({});

        removeItems(companyId);
        if (item) editItemState.sendRequest({
            itemId: item.id, data: form
        }).then(() => {
            navigate(-1);
        }); else createItemState.sendRequest({data: form}).then(() => {
            navigate(-1);
        });
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    };

    const refreshCategories = () => {
        removeCategories(companyId);
        categoriesState.sendRequest();
    };

    const refreshTaxes = () => {
        removeTaxes(companyId);
        taxesState.sendRequest();
    };

    const selectStyles = {
        option: (styles, {data, isFocused}) => {
            return {
                ...styles, backgroundColor: isFocused ? "#d4d0cf" : null,
            };
        }, control: (styles) => {
            return {
                ...styles,
            };
        }
    };

    const disableItem = () => {
        editItemState.sendRequest({itemId: item.id, data: {...item, enabled: 0}})
            .then(() => {
                navigate('../../');
            });
    };

    return (<>
        {createItemState.loading || taxesState.loading || categoriesState.loading || editItemState.loading ? (
            <Loading/>) : (<Col>
            <div>
                <Container>
                    <h2
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                        }}
                    >
                        Produs Nou
                    </h2>

                    <Row className="formsection">
                        <Col sm={12}>
                            <h3
                                style={{
                                    fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                General
                            </h3>

                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Selectează o categorie pentru a primi rapoarte mai
                                detaliate. Descrierea poate fi completată și când produsul
                                va fi selectat într-o factură.
                            </p>
                            <hr></hr>
                        </Col>

                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Nume</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="name"
                                    type="text"
                                    placeholder="Introdu numele"
                                    value={form.name}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['name']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Categorie</Form.Label>
                                {categoriesState.data && (<div style={{display: "flex"}}>
                                    <Select
                                        isDisabled={viewPage}
                                        className="categoryselect"
                                        onChange={(e) => handleCategories(setForm, e)}
                                        defaultValue={defaultCategory(form, categoriesState.data, item)}
                                        placeholder={"Selectează o categorie"}
                                        options={optionsCategories(categoriesState.data)}
                                        styles={selectStyles}

                                    />
                                    <NewCategory
                                        refreshCategories={refreshCategories}
                                    />
                                </div>)}
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Descriere</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    name="description"
                                    as="textarea"
                                    rows={3}
                                    value={form.description}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['description']}/>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row className="formsection">
                        <Col sm={12}>
                            <h3
                                style={{
                                    fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Date de vânzare
                            </h3>

                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Datele de vânzare sunt folosite în facturi, emise sau
                                primite. Taxele vor fi aplicate în ambele.
                            </p>
                            <hr></hr>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Preț de vânzare</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="sale_price"
                                    type="number"
                                    placeholder="Preţ de vânzare"
                                    value={form.sale_price}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['sale_price']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Preț de cumpărare</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="purchase_price"
                                    type="number"
                                    placeholder="Preţ de cumpărare"
                                    value={form.purchase_price}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['purchase_price']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Taxe</Form.Label>
                                {taxesState.data && (<div style={{display: "flex"}}>
                                    <Select
                                        isDisabled={viewPage}
                                        className="taxselect"
                                        onChange={(e) => handleTaxes(form, e)}
                                        defaultValue={defaultTaxes(item)}
                                        placeholder={"Selectează taxe"}
                                        isMulti
                                        styles={selectStyles}
                                        options={optionsTaxes(taxesState.data)}
                                    />
                                    <NewTax refreshTaxes={refreshTaxes}/>
                                </div>)}
                            </Form.Group>
                        </Col>
                    </Row>

                    {/*<div*/}
                    {/*    style={{*/}
                    {/*        textAlign: "right", marginTop: "50px", height: "100px",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Button*/}
                    {/*        onClick={() => navigate(-1)}*/}
                    {/*        style={{*/}
                    {/*            margin: "5px",*/}
                    {/*            fontFamily: "'Inter', arial",*/}
                    {/*            background: "none",*/}
                    {/*            border: "none",*/}
                    {/*            boxShadow: "none !important",*/}
                    {/*            color: "gray",*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Anulează*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*        style={{*/}
                    {/*            margin: "5px",*/}
                    {/*            fontFamily: "'Inter', arial",*/}
                    {/*            background: "#003172",*/}
                    {/*            border: "none",*/}
                    {/*        }}*/}
                    {/*        onClick={saveItem}*/}
                    {/*    >*/}
                    {/*        {item === undefined ? "Salvează" : "Actualizează"}*/}
                    {/*    </Button>*/}
                    {/*</div>*/}

                    <div
                        style={{
                            textAlign: "right", marginTop: "50px", height: "100px",
                        }}
                    >
                        <Button
                            onClick={() => navigate(-1)}
                            style={{
                                margin: "5px",
                                fontFamily: "'Inter', arial",
                                background: "none",
                                border: "none",
                                boxShadow: "none !important",
                                color: "gray",
                            }}
                        >
                            Inapoi
                        </Button>
                        <Button
                            hidden={!viewPage}
                            style={{
                                margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                            }}
                            onClick={() => navigate('../edit')}
                        >Editeaza
                        </Button>

                        {viewPage && <DisableObject name='produsul' disableFunction={disableItem}/>}

                        <Button
                            hidden={viewPage}
                            style={{
                                margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                            }}
                            onClick={saveItem}
                        >{item ? 'Actualizează' : 'Salvează'}
                        </Button>
                    </div>

                </Container>
            </div>
        </Col>)}
        {(createItemState.error || editItemState.error) && (<Navigate to={`/${companyId}/items`}/>)}
    </>);
}

export default NewItem;
