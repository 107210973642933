import "reactjs-popup/dist/index.css";
import useFetch from "../../hooks/useFetch";
import {Outlet, useParams} from "react-router-dom";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {Navigate} from "react-router-dom";
import {getContact} from "../../api/contacts";

const ContactValidator = ({contactType}) => {
    const {contactId} = useParams();
    const contactState = useFetch(getContact, {contactId: contactId}, true);

    return (<>
            {contactState.loading && <Loading/>}
            {contactState.error && (<Navigate
                    to={contactState.error?.status === 403 ? "/logout" : "/dashboard"}
                />)}
            {contactState.data && contactState.data?.enabled && (<>
                    <Outlet context={{contact: contactState.data}}/>
                </>)}
        </>);
};

export default ContactValidator;
