import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AiOutlinePlus, AiOutlineImport } from 'react-icons/ai';

import CustomersButton from './CustomersButton';

function NoCustomers({setLocale}) {

    return (
        <>
            <Row>
                <Col className="align-self-center">
                    <h1 style={{
                        fontFamily: "'Inter', arial",
                        color: "#003172",
                        marginTop: "30px",
                        marginBottom: "30px"
                    }}>Clienți</h1>
                    <p style={{fontFamily: "'Inter', arial", color: "#003172", marginBottom: "30px"}}>
                        Clienții sunt o necesitate dacă dorești să creezi facturi. Aceștia se pot conecta
                        prin portalul pentru clienți, pentru a-și vedea soldul.
                    </p>
                    <hr></hr>
                </Col>
            </Row>

            <Row>
                <CustomersButton label="Client nou"
                icon={<AiOutlinePlus size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>} 
                path="create"/>

                {/*<CustomersButton label="Importă clienţi"*/}
                {/*            icon={<AiOutlineImport size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>} */}
                {/*            path="import"/>*/}

            </Row>
        </>
    );
}

export default NoCustomers;