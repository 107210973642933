import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useFetch from "../../../../hooks/useFetch";
import {removeTaxes} from "../../../../utils/cache/functions";
import Loading from "../../../MainPages/Components/Loading/Loading";
import Table from "react-bootstrap/Table";
import {loadTaxes} from "../../../../api/taxes";
import Tax from "./Tax";
import SearchBar from "../../../../components/Filters/SearchBar";
import Container from "react-bootstrap/Container";
import NewTax from "../../../../components/Modals/NewTax";

const TaxesPage = () => {
    const {companyId} = useParams();
    const taxState = useFetch(loadTaxes, {companyId: companyId});
    const [taxes, setTaxes] = useState([]);

    const refreshTaxes = () => {
        removeTaxes(companyId);
        taxState.sendRequest().then((response) => {
            setTaxes(response);
        });
    }

    useEffect(() => {
        taxState.sendRequest().then((response) => {
            setTaxes(response);
        });
    }, [companyId]);


    return (<Container>
        <div style={{display: 'flex', justifyContent: "space-between", marginTop: "30px",}}>
            <h1 style={{
                fontFamily: "'Inter', Arial", color: "#003172",
            }}>Taxe</h1>
            <NewTax refreshTaxes={refreshTaxes}/>
        </div>


        {taxState.loading && <Loading/>}

        <SearchBar objectsArray={taxState.data} setNewArray={setTaxes}/>


        <div className="formsection">
            <Table style={{color: "#003172"}} responsive hover>
                <thead>
                <tr>
                    <th width={"20%"}>Nume</th>
                    <th width={"20%"}>Cotă de impozitare</th>
                    <th width={"20%"}>Creat la</th>
                </tr>
                </thead>
                <tbody>
                {taxes?.length > 0 ? taxes?.map((c, i) => <Tax key={i} tax={c} refresh={refreshTaxes}/>) : <></>}
                </tbody>
            </Table>
        </div>
    </Container>);
};

export default TaxesPage;
