import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AiOutlineUserAdd } from 'react-icons/ai';

import { BsReceiptCutoff } from 'react-icons/bs';

import CustomersButton from '../Customers/CustomersList/Components/NoCustomers/CustomersButton';
import { useParams, useNavigate, Outlet } from 'react-router-dom';

function SalesMenu({setLocale}) {

    const nav = useNavigate();
    const { companyId } = useParams();

    return (
        <Container>
            <Row>
                <Col className="align-self-center">
                    <h1 style={{
                        fontFamily: "'Inter', arial",
                        color: "#003172",
                        marginTop: "30px",
                    }}>Vânzări</h1>
                </Col>
                <div style={{display: "flex"}} className="crmsections">
                    {/*<button onClick={() => nav(`/${companyId}/sales/`)} className="crmsection">Acasă</button>*/}
                    <button onClick={() => nav(`/${companyId}/sales/customers`)} className="crmsection">Clienţi</button>
                    <button onClick={() => nav(`/${companyId}/sales/invoices`)} className="crmsection">Facturi</button>
                </div>
            </Row>

            <Outlet />
            
        </Container>
    );
}

export default SalesMenu;