import DealRow from "./DealRow";

const DealTableComponent = ({deal, salesperson, business, value, invoices}) => {
    return (<DealRow
        deal={deal}
        salesperson={salesperson}
        business={business}
        value={value}
        invoices={invoices}
    />);
}

export default DealTableComponent;