import "reactjs-popup/dist/index.css";
import useFetch from "../../hooks/useFetch";
import {Outlet, useParams} from "react-router-dom";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {Navigate} from "react-router-dom";
import {getInvoice} from "../../api/invoices";

const InvoiceValidator = ({type}) => {
    const {invoiceId} = useParams();
    const invoiceState = useFetch(getInvoice, {invoiceId: invoiceId}, true);

    return (<>
        {invoiceState.loading && <Loading/>}
        {invoiceState.error && (<Navigate
            to={invoiceState.error?.status === 403 ? "/logout" : "/dashboard"}
        />)}
        {invoiceState.data && invoiceState.data?.enabled && invoiceState.data?.type === type && (<>
            <Outlet context={{invoice: invoiceState.data}}/>
        </>)}
    </>);
};

export default InvoiceValidator;
