// src/components/InstallButton.js

import React, {useEffect, useState} from 'react';

const InstallButton = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        });
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the PWA installation');
                }
                setDeferredPrompt(null);
            });
        }
    };

    return (<div style={{ display: deferredPrompt ? 'block' : 'none' }} className="container footer-section">
            <div className="row">
                <div className="text-center text-lg-left">
                    <button
                        onClick={handleInstallClick}
                        className="single-cta"
                    >
                        <i className="fa fa-download"></i>
                        <h4>Instalează aplicația</h4>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InstallButton;
