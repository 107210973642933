import React, {useEffect} from "react";
import {searchObject} from "../../utils/functions";
import {AiOutlineSearch} from "react-icons/ai";

const SearchBar = ({objectsArray, setNewArray}) => {
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        if (!search) {
            setNewArray(objectsArray);
        } else {
            setNewArray(searchObject(objectsArray, search));
        }
    }, [search, objectsArray]);

    return (
        <div style={{
            width: "100%",
            border: "1px solid lightgray",
            height: "50px",
            borderRadius: "3px",
            padding: "10px",
            display: "flex"
        }}>
            <AiOutlineSearch size={20} style={{marginRight: "5px", marginTop: "4px"}}/>
            <input
                style={{
                    fontSize: "12px",
                    fontFamily: "'Inter', arial",
                    color: "#003172",
                    background: "none",
                    width: "90%",
                    border: "none",
                    outline: "none",
                }}
                name="search"
                type="text"
                placeholder="Caută.."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
        </div>
    );

};

export default SearchBar;
