import MainNavbar from "../MainPages/Components/MainNavbar/MainNavbar";
import {Link, Navigate} from "react-router-dom";
import Footer from "../MainPages/Components/Footer/Footer";

const ActivateAccount = () => {
    return (<>
            <MainNavbar/>

            <div className="container">
                <div className="row" style={{minHeight: "100vh"}}>
                    <div className="col-12">
                        <div className="card" style={{border: "none"}}>
                            <div className="card-body">
                                <h1 className="text-center">Activate Account</h1>
                                <p className="text-center">
                                    Your account needs to been activated.
                                </p>
                                <p className="text-center">
                                    Please check your email for the activation link.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>);
};

export default ActivateAccount;
