import ManageInvoice from "../../../../../../../../../components/Modals/Manage/ManageInvoice";
import {INVOICE_STATUS} from "../../../../../../../../../utils/constants";
import React from "react";
import {useNavigate} from "react-router-dom";

const InvoiceRow = ({
                        name, total, status, due_at, invoiced_at, invoice_number, client, id
                    }) => {

    const nav = useNavigate();
    return (<>
        <tr>
            <td>
                {name}
            </td>

            <td>
                {client}
            </td>

            <td>
                {total}{" "}RON
            </td>

            <td>
                {status === INVOICE_STATUS.PAID ? 'Platită' : 'Trimisă'}
            </td>

            <td>
                {due_at}
            </td>

            <td>
                {invoiced_at}
            </td>

            <td>
                {invoice_number}
            </td>

            <td style={{textAlign: "right"}}>
                <button
                    onClick={() => nav(`${id}/view`)}
                    style={{
                        border: "none",
                        color: "white",
                        background: "#003172",
                        borderRadius: "8px",
                        padding: "2px 15px",
                        boxSizing: "border-box",
                    }}
                >
                    Vizualizează
                </button>
            </td>
        </tr>
    </>);
}

export default InvoiceRow;