import React from "react";
import {Button} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {INVOICE_TYPE} from "../../../../../../../../../utils/constants";

const HeaderSection = ({type, navigate}) => {

    return (<>
        <Row>
            <Col sm={6}>
                <h1
                    style={{
                        fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                    }}
                >
                    {type === INVOICE_TYPE.INVOICE ? 'Facturi emise' : 'Facturi primite'}
                </h1>
            </Col>

            <Col sm={6} style={{textAlign: "right"}} xs={12}>
                <Button
                    style={{
                        margin: "5px",
                        fontFamily: "'Inter', arial",
                        background: "#003172",
                        border: "none",
                        marginTop: "30px",
                        marginBottom: "30px",
                    }}
                    onClick={() => navigate("create")}
                >
                    Factură nouă
                </Button>
            </Col>
        </Row>
    </>);
};

export default HeaderSection;
