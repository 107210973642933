import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const TermsAndConditions = () =>
{
    return (
        <>
            <MainNavbar />
            <Container>

                <div style={{minHeight: "100vh", color: "#003172", fontFamily: "'Inter', Arial"}}>
                    <h1 style={{textAlign: 'center', marginTop: '100px'}}>Termeni şi condiţii</h1>

                    <Row style={{marginTop: "100px"}}>
                        <Col sm={2}></Col>
                        <Col sm={8}>
                            <p>Termenii și condițiile pentru utilizarea aplicației sIMMple.ro sunt prezentate mai jos. Prin accesarea și utilizarea aplicației, sunteți de acord să respectați acești termeni și condiții.</p>

                            <p>1. Utilizarea aplicației: Aplicația sIMMple.ro este destinată utilizării pentru scopuri legale și comerciale. Nu puteți utiliza aplicația pentru orice scop ilegal sau care ar putea prejudicia alți utilizatori sau a terților.</p>

                            <p>2. Proprietatea intelectuală: Toate drepturile de proprietate intelectuală asupra aplicației, inclusiv drepturile de autor, mărci comerciale și altele, sunt rezervate de către ZENTIC SRL (CUI 37940774). Nu puteți reproduce, modifica sau distribui aplicația fără permisiunea prealabilă scrisă a ZENTIC SRL.</p>

                            <p>3. Confidențialitatea: sIMMple.ro respectă confidențialitatea utilizatorilor și se angajează să protejeze informațiile personale furnizate de către utilizatori în conformitate cu politica noastră de confidențialitate. Utilizatorii sunt responsabili pentru menținerea confidențialității informațiilor lor de acces, inclusiv numele de utilizator și parola.</p>

                            <p>4. Modificări ale termenilor și condițiilor: ZENTIC SRL își rezervă dreptul de a modifica termenii și condițiile sIMMple.ro în orice moment. Utilizatorii sunt responsabili pentru a verifica periodic acești termeni și condiții pentru eventualele modificări. Utilizarea continuată a aplicației după modificări constituie acceptarea acestor modificări.</p>

                            <p>5. Limitarea răspunderii: ZENTIC SRL nu poate fi considerat responsabil pentru orice pierdere sau daune indirecte sau ca și consecință rezultate din utilizarea aplicației. Utilizatorii sunt responsabili pentru verificarea preciziei și completitudinii informațiilor furnizate prin intermediul aplicației.</p>

                            <p>6. Legile aplicabile: Acești termeni și condiții sunt guvernați și interpretați în conformitate cu legile din România. Orice litigiu care rezultă din sau este legat de utilizarea aplicației va fi rezolvat exclusiv în instanțele din România.</p>

                            <p>7. Suspendarea sau încetarea utilizării: ZENTIC SRL își rezervă dreptul de a suspenda or înceta accesul utilizatorilor la aplicația sIMMple.ro în cazul în care se constată că utilizatorii încalcă acești termeni și condiții sau dacă există motive întemeiate să creadă că utilizatorii au încălcat sau vor încălca legile aplicabile.</p>

                            <p>8. Întreținerea și actualizarea aplicației: ZENTIC SRL se angajează să întrețină și să actualizeze aplicația sIMMple.ro în mod regulat pentru a asigura funcționarea corectă și pentru a adăuga noi caracteristici. Totuși, ZENTIC SRL nu poate garanta disponibilitatea continuă a aplicației sau absența erorilor sau defectelor.</p>

                            <p>9. Suport tehnic: ZENTIC SRL oferă suport tehnic pentru utilizatorii aplicației sIMMple.ro prin intermediul unui formular de contact sau e-mail.</p>

                            <p>10. Accesul și colaborarea: Utilizatorii pot invita alți membri din echipa lor să colaboreze și să acceseze aplicația cu diferite niveluri de acces și permisiuni, în conformitate cu politica și regulamentele sIMMple.ro și conform licenței achiziționate.</p>

                            <p>11. Garanții și limitări de responsabilitate: Utilizatorii înțeleg că aplicația sIMMple.ro este furnizată "așa cum este" și "după disponibilitate", și că utilizarea aplicației este la propriul risc. ZENTIC SRL nu oferă nicio garanție, expresă sau implicită, cu privire la aplicație, inclusiv, dar fără a se limita la garanțiile de vandabilitate, potrivire pentru un anumit scop sau neîncălcare. ZENTIC SRL nu va fi responsabil pentru nicio pierdere sau daune, inclusiv, dar fără a se limita la pierderea de profit sau întreruperea activității, rezultate din utilizarea sau incapacitatea de a utiliza aplicația.</p>

                            <p>12. Termenii și condiții suplimentare: Utilizatorii sunt de acord să respecte orice termeni și condiții suplimentare care pot fi aplicabile pentru anumite caracteristici sau servicii oferite prin intermediul aplicației sIMMple.ro. Aceste termeni și condiții suplimentare vor fi disponibile prin intermediul aplicației sau prin intermediul unui link separat și vor fi considerate parte a acestor termeni și condiții generale.</p>

                            <p>13. Contact: Dacă aveți întrebări sau nelămuriri cu privire la acești termeni și condiții, vă rugăm să contactați echipa noastră prin intermediul formularului de contact sau prin email la adresa office@simmple.ro</p>

                            <p>14. Răspunderea pentru utilizarea aplicației: Utilizatorii sunt responsabili pentru toate acțiunile efectuate prin intermediul aplicației sIMMple.ro, inclusiv pentru respectarea legilor și regulamentelor aplicabile. Utilizatorii sunt de acord să nu folosească aplicația pentru orice activitate ilegală sau pentru a prejudicia alți utilizatori sau terți.</p>

                            <p>15. Răspunderea pentru informațiile furnizate: Utilizatorii sunt responsabili pentru precizia și completitudinea informațiilor furnizate prin intermediul aplicațieisIMMple.ro. ZENTIC SRL nu poate fi considerat responsabil pentru orice pierdere sau prejudiciu rezultat din informațiile furnizate de utilizatori.</p>

                            <p>16. Renunțarea la drepturi: Prin utilizarea aplicației sIMMple.ro, utilizatorii renunță la orice drept sau acțiune împotriva sIMMple.ro sau a afiliaților săi, rezultat din utilizarea aplicației sau din nerespectarea acestor termeni și condiții.</p>

                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </div>

            </Container>
            <Footer />
        </>
    );
}

export default TermsAndConditions;