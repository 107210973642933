import React from "react";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';

import './EmployeeCard.css';
import {useNavigate} from "react-router-dom";

const EmployeeCard = ({employee, editEmployee, viewEmployee, disableEmployee}) => {
    const nav = useNavigate();
    return (<Card
        onClick={() => nav(`${employee.id}/view`)}
        style={{margin: "20px auto", fontFamily: "'Inter', Arial"}} className="employeecard">
        <Card.Header as="h5" className="employeecard-header">
            {`${employee.last_name} ${employee.first_name}`}
        </Card.Header>
        <ListGroup className="list-group-flush">
            <ListGroupItem>
                <i className="fa fa-user mr-2" aria-hidden="true"></i>
                {employee.pozitie}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-dollar mr-2" aria-hidden="true"></i>
                {employee.salariu_brut}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                {employee.data_incepere.slice(0, 10)}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                {employee.data_plecare ?? '-'}
            </ListGroupItem>
        </ListGroup>
    </Card>);
};

export default EmployeeCard;
