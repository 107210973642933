import "reactjs-popup/dist/index.css";
import useFetch from "../../hooks/useFetch";
import {Outlet, useParams} from "react-router-dom";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {Navigate} from "react-router-dom";
import {getCrmContract} from "../../api/crm/contracts";

const DealValidator = () => {
    const {dealId} = useParams();
    const dealState = useFetch(getCrmContract, {crmContractId: dealId}, true);

    return (<>
            {dealState.loading && <Loading/>}
            {dealState.error && (<Navigate
                    to={dealState.error?.status === 403 ? "/logout" : "/dashboard"}
                />)}
            {dealState.data && dealState.data?.enabled && (<>
                    <Outlet context={{deal: dealState.data}}/>
                </>)}
        </>);
};

export default DealValidator;
