import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
    useNavigate, useOutletContext, useParams,
} from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import {editCompany, editCompanyData, getCompanyDataById} from "../../../../api/companies";
import Loading from "../../../MainPages/Components/Loading/Loading";
import {removeCompanies} from "../../../../utils/cache/functions";
import UploadPhoto from "../../../../components/UploadPhoto/UploadPhoto";
import UploadImage from "../../List/Sales/Invoices/InvoicesList/Components/UploadImage/UploadImage";

function CompanySettings() {
    const {companyId} = useParams();
    const navigate = useNavigate();

    const company = useOutletContext()?.company;
    const companyDataState = useFetch(getCompanyDataById, {});
    const editCompanyDataState = useFetch(editCompanyData, {});
    const editCompanyState = useFetch(editCompany, {});

    const [form, setForm] = useState({});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm((prev) => ({...prev, [name]: value}));
    };

    const saveData = () => {
        editCompanyDataState.sendRequest({companyDataId: form?.id, data: form}).then(() => {
            if (form.name !== company?.domain) {
                editCompanyState.sendRequest({companyId: companyId, data: {...company, domain: form.name}}).then(() => {
                    removeCompanies();
                    navigate(`/dashboard`);
                });
            } else {
                removeCompanies();
                navigate(`/dashboard`);
            }
        });
    };

    useEffect(() => {
        companyDataState.sendRequest({companyId: companyId}).then((response) => {
            setForm(response);
            setForm((prev) => ({...prev, name: company?.domain, cui: company?.cui}));
        })
    }, [companyId]);


    return (<>
        {(companyDataState.loading || editCompanyDataState.loading || editCompanyState.loading) && <Loading/>}
        <Container>
            <Row>
                <Col sm={12} xs={12} className="align-self-center">
                    <h2
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                        }}
                    >
                        Companie
                    </h2>
                    <h3
                        style={{
                            fontSize: "20px", marginTop: "12px", fontFamily: "'Inter', arial", color: "#003172",
                        }}
                    >
                        General
                    </h3>

                    <p
                        style={{
                            fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                        }}
                    >
                        Aceste informații vor fi afișate pe facturile emise de tine.
                    </p>
                    <hr></hr>
                </Col>
            </Row>

            <Row>
                <Col sm={12} xs={12} className="align-self-center">
                    <Col sm={5} style={{textAlign: "center"}}>
                        <UploadPhoto companyId={companyId}/>
                    </Col>
                    {/*<UploadImage/>*/}
                    <Row>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Nume</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="name"
                                    type="text"
                                    placeholder="Introdu numele"
                                    value={form?.name}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="email"
                                    type="text"
                                    placeholder="Introdu email"
                                    value={form?.email}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Telefon</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="phone"
                                    type="number"
                                    placeholder="Introdu numărul de telefon"
                                    value={form?.phone}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>CUI</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="cui"
                                    type="number"
                                    placeholder="Introdu CUI-ul"
                                    value={form?.cui}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Activitate</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="activity"
                                    type="text"
                                    placeholder="Introdu activitatea"
                                    value={form?.activity}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Anul de când este activă</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="activity_time"
                                    type="number"
                                    placeholder="Introdu anul"
                                    value={form?.activity_time}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Număr registrul comerţului </Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="inmatriculare"
                                    type="text"
                                    placeholder="Introdu numar registrul comert"
                                    value={form?.inmatriculare}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Număr de membri</Form.Label>
                                <Form.Control
                                    className="forms"
                                    required
                                    name="members"
                                    type="number"
                                    placeholder="Introdu numar de membri"
                                    value={form?.members}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                </Col>
            </Row>

            <Row>
                <Col sm={12} xs={12} className="align-self-center">
                    <div
                        style={{
                            textAlign: "right", marginTop: "50px", marginBottom: "20px",
                        }}
                    >
                        <Button
                            onClick={() => navigate(-1)}
                            style={{
                                margin: "5px",
                                fontFamily: "'Inter', arial",
                                background: "none",
                                border: "none",
                                boxShadow: "none !important",
                                color: "gray",
                            }}
                        >
                            Anulează
                        </Button>
                        <Button
                            onClick={saveData}
                            style={{
                                margin: "5px", fontFamily: "'Inter', arial", background: "#2c2c96", border: "none",
                            }}
                        >
                            {"Actualizează"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    </>);
}

export default CompanySettings;
