import React, { useState } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Col from "react-bootstrap/Col";
import { Outlet } from "react-router-dom";

import Fab from "../Components/AppNavbar/FAB";

import { useNavigate } from "react-router-dom";

import {
  AiOutlineShopping,
  AiOutlineUserAdd,
  AiOutlineFileText,
  AiOutlineFile,
} from "react-icons/ai";

function SidebarPage({ company }) {

  const navigate = useNavigate();

  const actions = [
    { label: "Factură emisă", icon: <AiOutlineFileText style={{color: "#003172"}} />, onclick: `/${company.id}/sales/invoices/create/` },
    { label: "Factură primită", icon: <AiOutlineFile style={{color: "#003172"}} />, onclick: `/${company.id}/purchases/bills/create/` },
    { label: "Produs", icon: <AiOutlineShopping style={{color: "#003172"}} />, onclick: `/${company.id}/items/create/` },
    { label: "Contact", icon: <AiOutlineUserAdd style={{color: "#003172"}} />, onclick: `/${company.id}/crm/contacts/create/` },
  ];

  return (
    <>
      <Col>
        <div
        >
          <Sidebar
            company={company}
          />
          {/*<Fab actions={actions} />*/}
          <Outlet context={{ company: company }} />
        </div>
      </Col>
    </>
  );
}

export default SidebarPage;
