export const convertToCSV = (data, headers, nameHeaders) => {
    if (!data || data.length === 0) {
        return '';
    }
    nameHeaders = nameHeaders || headers;
    const csv = [nameHeaders.join(',')];

    data.forEach((row) => {
        const rowValues = headers?.map((header) => {
            if (header === 'type') {
                if (row[header] === 'vendor') return 'Furnizor';
                else if (row[header] === 'customer') return 'Client';
                else if (row[header] === 'multiple') return 'Multiplu';
            }
            return row[header];
        });
        csv.push(rowValues.join(','));
    });

    return csv.join('\n');
};

export const downloadCSV = (name, data = [], headers = [], nameHeaders = []) => {
    const csvContent = convertToCSV(data, headers, nameHeaders);

    const blob = new Blob([csvContent], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
};



