import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {Link} from "react-router-dom";
import "./CreateCompany.css";

const CreateCompany = () => {

    return (
        <Col sm={12} xs={12} lg={6}>

            <Link to="/start" className="text-decoration-none">

                <Card className="p-2 rounded CreateCard" style={{height: "160px"}}>
                    <Card.Body className="CreateBody">
                        <Card.Subtitle className="mb-2 text-muted Subtitle"><i style={{}} className="fa fa-solid fa-plus"></i></Card.Subtitle>
                        <Card.Title>Creează o companie</Card.Title>
                    </Card.Body>
                </Card>

            </Link>

        </Col>
    );
};

export default CreateCompany;