import {post} from "./requests";

export const login = async (params) => {
    try {
        const url = "/api/users/login/";
        //reset errors
        params.setError(undefined);

        const data = await (await post(url, params.args)).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err);
    } finally {
        params.setLoading(false);
    }
};
