import Opportunities from "./Graphs/Opportunities";
import ReturnRate from "./Graphs/ReturnRate";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {constructInterval} from "../../../../../../utils/functions/cashflow";
import useFetch from "../../../../../../hooks/useFetch";
import {loadContacts} from "../../../../../../api/contacts";
import {loadCrmContracts} from "../../../../../../api/crm/contracts";
import {loadCrmCompanies} from "../../../../../../api/crm/companies";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const Stats = ({report, start, end}) => {
    const {companyId} = useParams();

    const contactsState = useFetch(loadContacts, {});
    const contractsState = useFetch(loadCrmContracts, {});
    const companiesState = useFetch(loadCrmCompanies, {});

    const [data, setData] = useState([]);

    useEffect(() => {
        if (report === 'contacts') {
            contactsState.sendRequest({companyId: companyId}).then(response => {
                setData(response);
            });
        } else if (report === 'contracts') {
            contractsState.sendRequest({companyId: companyId}).then(response => {
                setData(response);
            });
        } else if (report === 'companies') {
            companiesState.sendRequest({companyId: companyId}).then(response => {
                setData(response);
            });
        }
    }, [report]);

    return (<Row>
            <Col sm={8}>
                <Card style={{
                    borderRadius: "0px",
                    border: "none",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                    padding: "20px 20px",
                    marginBottom: "20px"
                }}>
                    <Card.Body>
                        <p>Oportunitate nouă</p>
                        <Opportunities report={report} data={data} interval={constructInterval(start, end)}/>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={4}>
                <Card style={{
                    borderRadius: "0px",
                    border: "none",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                    padding: "20px 20px",
                    marginBottom: "20px"
                }}>
                    <Card.Body>
                        <p>Rată închidere</p>
                        <ReturnRate data={data} start={start} end={end}/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>);
}

export default Stats;