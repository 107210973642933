import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
    AiOutlineMenu,
} from "react-icons/ai";

function ItemInShoppingList({item}) {
    return (
        <>
        <Row style={{paddingTop: "20px"}}>
            <Col sm={2}>
                <p style={{fontFamily: "'Inter', arial", fontSize: "12px", color: "#003172"}}><AiOutlineMenu size={17} style={{fontFamily: "'Inter', arial", marginRight: "5px"}} />{item.name}</p>
            </Col>

            <Col sm={4}>
                <p style={{fontFamily: "'Inter', arial", fontSize: "12px", color: "#003172"}}>{item?.description}</p>
            </Col>

            <Col sm={2}>
                <p style={{fontFamily: "'Inter', arial", fontSize: "12px", color: "#003172"}}>{item?.quantity}</p>
            </Col>

            <Col sm={2}>
                <p style={{fontFamily: "'Inter', arial", fontSize: "12px", color: "#003172"}}>{item?.price} RON</p>
            </Col>

            <Col sm={2} style={{textAlign: "right"}}>
                <p style={{fontFamily: "'Inter', arial", fontSize: "12px", color: "#003172"}}>{item?.total.toFixed(2)} RON</p>
            </Col>
        </Row>
        <Row>
            <Col sm={8}>
            </Col>

            <Col sm={4} style={{textAlign: "right"}}>
                <p style={{fontFamily: "'Inter', arial", fontSize: "12px", color: "#003172"}}>Taxe {item?.tax}%</p>
            </Col>
        </Row>
        </>
    );
}

export default ItemInShoppingList;