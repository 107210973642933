import {post} from "./requests";
import {loggedInHeaders} from "./apiUtils";

export const register = async (params) => {
    try {
        const url = "/api/register/";

        //reset errors
        params.setError(undefined);
        const data = await (await post(url, params.args)).data;

        //send confirmation email
        await post("/api/register/send-confirmation-email/", {
            'email': params.args.email,
        });

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const confirmEmail = async (params) => {
    try {
        const url = "/api/register/confirm-registration/";
        //reset errors
        params.setError(undefined);

        const data = await (await post(url, params.args), loggedInHeaders()).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
