import React from "react";
import useFetch from "../../../../hooks/useFetch";
import {editCategory} from "../../../../api/categories";
import DisableObject from "../../../../components/Modals/DisableObject";
import NewCategory from "../../../../components/Modals/NewCategory";
import {editTax} from "../../../../api/taxes";
import NewTax from "../../../../components/Modals/NewTax";

const Tax = ({tax, refresh}) => {

    const editTaxState = useFetch(editTax, {});

    const disableTax = () => {
        editTaxState.sendRequest({taxId: tax.id, data: {...tax, enabled: 0}}).then(() => {
            refresh();
        });
    };

    return (<tr>
        <td>{tax?.name}</td>
        <td>{tax?.rate}%</td>
        <td>{tax?.created_at.substring(0, 10)}</td>
        <td>
            <div style={{display: 'flex', justifyContent: "right"}}>
                <NewTax refreshTaxes={refresh} tax={tax}/>
                <DisableObject disableFunction={disableTax} name={'taxa'}/>
            </div>
        </td>
    </tr>);
};

export default Tax;
