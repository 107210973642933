import CompanyRow from "./CompanyRow";
import React from "react";

const CompanyTableComponent = ({company, contact, refresh, category}) => {
    let addr = "";
    try {
        addr = JSON.parse(company.address);
    } catch (error) {
        console.error("An error occurred while parsing the JSON string:", error);
    }
    return (
        <>
            <CompanyRow
                company={company}
                owner={contact}
                city={addr?.city}
                category={category}
                refresh={refresh}
            />
        </>
    );
};

export default CompanyTableComponent;
