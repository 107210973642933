import React from "react";

import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import InvoiceComponent from "../../InvoiceCard/Invoice";
import InvoiceTableComponent from "../InvoiceTableComponent.js/InvoiceTableComponent";
import {INVOICE_TYPE} from "../../../../../../../../../utils/constants";

const HasInvoices = ({type, view, invoices, customers, refresh}) => {

    return (
        <>
            {view === "Tabel" ?
                <>
                    <div className="itemscontainer">
                        <Table style={{color: "#003172"}} responsive hover>
                            <thead>
                            <tr>
                                <th>Nume</th>
                                <th>{type === INVOICE_TYPE.INVOICE ? 'Client' : 'Furnizor'}</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Data scadentă</th>
                                <th>Data emiterii</th>
                                <th>Număr</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoices.map((invoice, index) => (
                                <InvoiceTableComponent
                                    key={index}
                                    invoice={invoice}
                                    refresh={refresh}
                                    customer={customers[invoice.contact]}
                                />
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </>
                :
                <>
                    <Row>
                        {invoices.map((invoice, index) => (
                            <InvoiceComponent
                                key={index}
                                invoice={invoice}
                                refresh={refresh}
                                customer={customers[invoice.contact]}
                            />
                        ))}
                    </Row>
                </>
            }
        </>
    );
};

export default HasInvoices;
