import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRef, useState } from "react";
import { login } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import useAuth from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";
import { setAuthToStorage } from "../../utils/cache/cache";
import jwtDecode from "jwt-decode";

function InviteLoginForm({ join }) {
  const [show, setShow] = useState(false);

  const nav = useNavigate();
  const emailRef = useRef("");
  const passwordRef = useRef("");

  const auth = useAuth();
  const loginState = useFetch(login, {});

  const handleLogin = () => {
    loginState
      .sendRequest({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      })
      .then((res) => {
        auth.setAuthStatus(res?.token);
        auth.setData(jwtDecode(res?.token));
        setAuthToStorage(res?.token, res?.sessionid);
        join();
        setShow(false);
        nav("/");
      });
  };

  const [rememberMe, setRememberMe] = useState(false);

  const handleCheck = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button onClick={handleShow}>Autentificare</Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Autentifică-te pe sIMMple</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Adresa Email</Form.Label>
              <Form.Control
                required
                name="email"
                type="email"
                placeholder="Enter email"
                ref={emailRef}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label>Parola</Form.Label>
              <Form.Control
                required
                name="password"
                type="password"
                placeholder="Password"
                ref={passwordRef}
              />
            </Form.Group>

            <Form.Group
              style={{ marginLeft: "10px" }}
              className="mb-4"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                className="text-muted"
                name="checkbox"
                type="checkbox"
                label="Ține-mă minte"
                onChange={handleCheck}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Închide
          </Button>
          <Button variant="primary" onClick={handleLogin}>
            Autentificare
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InviteLoginForm;
