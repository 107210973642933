import React from "react";
import {Card, Image, ListGroup, ListGroupItem} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';
import DisableObject from "../../../../../../../../components/Modals/DisableObject";

import './InvoiceCard.css';
import {useNavigate} from "react-router-dom";
import {INVOICE_STATUS} from "../../../../../../../../utils/constants";

const InvoiceCard = ({
                         customer,
                         invoiceId,
                         name,
                         total,
                         status,
                         created_at,
                         due_at,
                         invoiced_at,
                         address,
                         invoice_number,
                         disable
                     }) => {
    const nav = useNavigate();

    return (<Card style={{margin: "20px auto", fontFamily: "'Inter', arial"}} className="invoicecard">
        <Card.Header as="h5" className="invoicecard-header">{name}</Card.Header>
        <ListGroup className="list-group-flush">
            <ListGroupItem>
                <i className="fa fa-briefcase mr-2" aria-hidden="true"></i>
                {customer?.company_name}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-dollar mr-2" aria-hidden="true"></i>
                {total}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-handshake-o mr-2" aria-hidden="true"></i>
                {status === INVOICE_STATUS.PAID ? 'Platită' : 'Trimisă'}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-files-o  mr-2" aria-hidden="true"></i>
                {invoice_number}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-calendar-times-o mr-2" aria-hidden="true"></i>
                {due_at}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-calendar-plus-o mr-2" aria-hidden="true"></i>
                {invoiced_at}
            </ListGroupItem>
        </ListGroup>
    </Card>);
};

export default InvoiceCard;
