import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import MainNavbar from "../../pages/MainPages/Components/MainNavbar/MainNavbar";
import { Link, Navigate, Outlet } from "react-router-dom";
import Footer from "../../pages/MainPages/Components/Footer/Footer";
import Container from "react-bootstrap/Container";
import { loadUserData } from "../../api/user";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import useFetch from "../../hooks/useFetch";
import { getAuthFromStorage } from "../../utils/cache/cache";

import "./MenuSidebar.css";

const MenuSidebar = () => {
  const user = useFetch(
    loadUserData,
    { userId: getAuthFromStorage()?.userId },
    true
  );

  return (
    <>
      {user.loading && <Loading />}
      {user.error && <Navigate to={user.error?.code === 'ERR_NETWORK' ? '/servers_down' : '/logout'} />}
      {user.data && (
        <>
          <MainNavbar user={user.data} />
          <div style={{ minHeight: "92vh" }}>
            <Container>
              <Row>
                <Col sm={4}>
                  <div className="menusidebartopcontainer" style={{ marginTop: "100px", marginBottom: "100px", justifyContent: "space-between" }}>
                    <div>
                      <h1 style={{ fontSize: "3.5em", fontWeight: "bold" }}>
                        {user.data?.name}
                      </h1>
                      <p style={{}} className="text-muted">
                        Panoul de control al contului tău
                      </p>
                    </div>
                  </div>
                  <ListGroup as="ul">
                    <ListGroup.Item
                      as="li"
                      style={{ border: "none" }}
                      className="rounded"
                    >
                      <Link
                        to="/dashboard"
                        className="text-decoration-none text-dark"
                      >
                        Panou de control
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                    <Link
                        to="/profile"
                        className="text-decoration-none text-dark"
                      >
                        Profil
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                      <Link
                        to="/password"
                        className="text-decoration-none text-dark"
                      >
                        Parolă
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      style={{ border: "none" }}
                      className="rounded"
                    >
                      <Link
                        to="/logout"
                        className="text-decoration-none"
                        style={{color: "red"}}
                      >
                        Deconectare
                      </Link>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col sm={8}>
                  <div style={{ marginTop: "100px", width: "100%" }}>
                    <Outlet />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default MenuSidebar;
