import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {AiOutlineDelete} from "react-icons/ai";

function DisableObject({disableFunction, name = 'factura'}) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        disableFunction();
        setShow(false);
    };
    return (<>
        <Button
            onClick={() => setShow(true)}
            style={{background: "#c70b0b", border: "none", marginLeft: "5px"}}
        >
            Dezactivează
        </Button>

        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Dezactivează {name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Ești pe cale de a dezactiva {name}, ești sigur?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Închide
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Dezactivează
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default DisableObject;