import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {useParams} from "react-router-dom";
import {TAX_TYPE} from "../../utils/constants";
import useFetch from "../../hooks/useFetch";
import {createTax, editTax} from "../../api/taxes";

function NewTax({refreshTaxes, tax = undefined}) {
    const {companyId} = useParams();

    const [show, setShow] = useState(false);
    const [form, setForm] = useState({
        name: tax?.name || "", rate: tax?.rate || 0, type: tax?.type || TAX_TYPE.ITEM, company: companyId,
    });

    const createTaxState = useFetch(createTax, {});
    const editTaxState = useFetch(editTax, {});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    };

    const saveTax = () => {
        if (tax === undefined) {
            createTaxState.sendRequest({data: form}).then(() => {
                refreshTaxes();
                setShow(false);
            });
        } else {
            editTaxState.sendRequest({taxId: tax?.id, data: form}).then(() => {
                refreshTaxes();
                setShow(false);
            });
        }

    };

    return (<>
        <Button
            onClick={() => setShow(true)}
            style={{
                background: "#003172", border: "none", marginLeft: "5px", fontFamily: "'Inter', Arial", height: "36px"
            }}
        >
            {tax === undefined ? 'Creează' : 'Editeaza'}
        </Button>

        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton style={{border: "none"}}>
                <Modal.Title>Creează o taxă</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{border: "none"}}>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nume taxă</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nume"
                            autoFocus
                            name="name"
                            value={form.name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Cotă de impozitare</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder=".%"
                            name="rate"
                            value={form.rate}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{background: "none", border: "none", color: "gray"}}
                    variant="secondary"
                    onClick={() => setShow(false)}
                >
                    Anulează
                </Button>
                <Button
                    style={{background: "#003172", border: "none"}}
                    variant="primary"
                    onClick={saveTax}
                >
                    Salvează
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default NewTax;
