import MainNavbar from "../../MainPages/Components/MainNavbar/MainNavbar";
import Footer from "../../MainPages/Components/Footer/Footer";
import InviteLoginForm from "../../../components/Modals/InviteLoginForm";
import InviteRegisterForm from "../../../components/Modals/InviteRegisterForm";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import Button from "react-bootstrap/Button";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { acceptCompanyInvite } from "../../../api/invites";
import useAuth from "../../../hooks/useAuth";
import { removeCompanies } from "../../../utils/cache/functions";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

function InvitationPage() {
  const auth = useAuth();
  const { token } = useParams();
  const joinCompanyState = useFetch(acceptCompanyInvite, {});
  const nav = useNavigate();

  const handleJoin = () => {
    joinCompanyState.sendRequest({
      inviteToken: token,
    });

    //refresh companies
    removeCompanies();
    nav('/');
  };

  return (
    <>
      <div
        id="noitemstri1"
        style={{
          borderRadius: "10px",
          width: "200rem",
          height: "30rem",
          zIndex: "-1",
          marginLeft: "-1600px",
          rotate: "120deg",
          top: "35rem",
        }}
      ></div>
      <MainNavbar />
      <div style={{ minHeight: "92vh" }}>
        <Container>
          <Row style={{ textAlign: "center", margin: "200px 0px 150px 0px" }}>
            <h1 style={{ color: "#003172", fontFamily: "Inter" }}>
              Ai fost invitat să faci parte din compania {jwtDecode(token)?.company_name}
            </h1>
            <p style={{ color: "#003172", fontFamily: "Inter", fontSize: 22 }}>
              Alege opțiunea potrivită pentru tine și alătură-te echipei
            </p>
          </Row>
          {auth.isLoggedIn ? (
            <Row>
              <Col>
                <Button onClick={handleJoin}>Alatura-te</Button>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={6}>
                <Card
                  style={{
                    border: "none",
                    borderRadius: "8px",
                    padding: "25px",
                    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
                    background: "white",
                  }}
                >
                  <Card.Title style={{ textAlign: "center" }}>
                    <p style={{ color: "#003172", fontFamily: "Inter" }}>
                      Am cont de utilizator pe sIMMple.ro
                    </p>
                  </Card.Title>

                  <Card.Body style={{ textAlign: "center" }}>
                    <InviteLoginForm join={handleJoin} />
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={6}>
                <Card
                  style={{
                    border: "none",
                    borderRadius: "8px",
                    padding: "25px",
                    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
                    background: "white",
                  }}
                >
                  <Card.Title style={{ textAlign: "center" }}>
                    <p style={{ color: "#003172", fontFamily: "Inter" }}>
                      Nu am cont de utilizator pe sIMMple.ro
                    </p>
                  </Card.Title>

                  <Card.Body style={{ textAlign: "center" }}>
                    <InviteRegisterForm join={handleJoin} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default InvitationPage;
