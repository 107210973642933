import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useFetch from "./../../hooks/useFetch";
import {getCompanyDataById} from "./../../api/companies";

function ViewCompany({companyId}) {
    const [show, setShow] = useState(false);

    const companyDataState = useFetch(getCompanyDataById, {
        companyId: companyId,
    });

    const handleClose = () => setShow(false);
    const handleShow = () => {
        companyDataState.sendRequest();
        setShow(true);
    };

    const companyData = companyDataState.data || {};

    const companyDetailsStyle = {
        marginTop: "20px", padding: "20px", border: "1px solid #ccc", borderRadius: "8px", backgroundColor: "#f9f9f9",
    };

    const labelStyle = {
        fontWeight: "bold",
    };

    return (<>
        <i
            className="fa fa-info-circle"
            aria-hidden="true"
            style={{float: "right", fontSize: "20px", cursor: "pointer"}}
            onClick={handleShow}
        ></i>

        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Detalii companie</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={companyDetailsStyle}>
                    <p><span style={labelStyle}>Activitate:</span> {companyData.activity}</p>
                    <p><span style={labelStyle}>An înființare:</span> {companyData.activity_time}</p>
                    <p><span style={labelStyle}>Membri:</span> {companyData.members}</p>
                    <p><span style={labelStyle}>Număr înmatriculare:</span> {companyData.inmatriculare}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Închide
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default ViewCompany;
