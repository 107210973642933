import React from "react";
import useFetch from "../../../../hooks/useFetch";
import DisableObject from "../../../../components/Modals/DisableObject";
import {editActivity} from "../../../../api/activities";
import NewActivity from "../../../../components/Modals/NewActivity";

const Activity = ({activity, refresh}) => {

    const editActivityState = useFetch(editActivity, {});

    const disableActivity = () => {
        editActivityState.sendRequest({activityId: activity.id, data: {...activity, enabled: 0}}).then(() => {
            refresh();
        });
    };

    return (<tr>
        <td>{activity?.name}</td>
        <td>{activity?.created_at.substring(0, 10)}</td>
        <td>
            <div style={{display: 'flex', justifyContent: "right"}}>
                <NewActivity refreshActivities={refresh} activity={activity}/>
                <DisableObject disableFunction={disableActivity} name={'activitatea'}/>
            </div>
        </td>
    </tr>);
};

export default Activity;
