import "./ActivateCompany.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import { useParams } from "react-router-dom";
import { getCompany } from "../../../../api/companies";
import useFetch from "../../../../hooks/useFetch";
import { useEffect } from "react";
import { activateCompany } from "../../../../api/activate";
import { useNavigate } from "react-router-dom";
import {createLicense} from "../../../../api/license";
import MainNavbar from "../../../MainPages/Components/MainNavbar/MainNavbar";
import Footer from "../../../MainPages/Components/Footer/Footer";


const ActivateCompany = () => {
  const nav = useNavigate();
  const { companyId, userId } = useParams();
  const companyState = useFetch(getCompany, { companyId: companyId }, true);
  const act = useFetch(activateCompany, { companyId: companyId });
  const createLicenseState = useFetch(createLicense, {
    companyId: companyId,
    userId: userId,
  });

  const nume = companyState.data?.company.domain;

  const actCompany = () => {
    act.sendRequest().then((data) => {
      createLicenseState.sendRequest().then((data) => {
        nav("/dashboard");
      });
    });
  };

  return (
    <Container >
      <MainNavbar />
      {nume ? (
        <>
          <Row className="headercontainer">
            <h1 className="headertext">{nume}</h1>
          </Row>
          <Row className="maintextcontainer" style={{minHeight: "100vh"}}>
            <p className="maintext">
              Activezi compania {nume} ?
              <button
                style={{ marginLeft: "20px", width: "200px" }}
                onClick={actCompany}
              >
                DA
              </button>
              <button
                style={{ marginLeft: "20px", width: "200px" }}
                onClick={() => nav("/dashboard")}
              >
                NU
              </button>
            </p>
          </Row>
        </>
      ) : (
        <Row>
          <p>ID-ul companiei nu este inregistrat</p>
        </Row>
      )}
      <Footer/>
    </Container>
  );
};

export default ActivateCompany;
