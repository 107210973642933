import React from "react";
import {useNavigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {sortObjects} from "../../../../../../../../utils/functions";
import {useState} from "react";
import {ORDER, SORT_BY} from "../../../../../../../../utils/constants";
import {TbLayoutGrid, TbLayoutList} from "react-icons/tb";
import SearchBar from "../../../../../../../../components/Filters/SearchBar";
import Table from "react-bootstrap/Table";
import CustomerTableComponent
    from "../../../../../Sales/Customers/CustomersList/Components/CustomerTableComponent/CustomerTableComponent";
import CustomerComponent from "../../../../../Sales/Customers/CustomersList/Components/CustomerCard/Customer";
import {getView, saveView} from "../../../../../../../../utils/cache/functions";
import ExportCSV from "../../../../../../../../components/CSV/ExportCSV";

function HasVendors({vendorsParam, refresh}) {
    const navigate = useNavigate();

    const [vendors, setVendors] = useState(vendorsParam || []);
    const [order, setOrder] = useState(ORDER.DESC);
    const [sortBy, setSortBy] = useState(SORT_BY.CREATED_AT);

    const [view, setView] = useState(getView());

    return (<>
            <Row>
                <Col sm={12} xs={12}>
                    <Row>
                        <Col sm={6}>
                            <h1
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                                }}
                            >
                                Furnizori
                            </h1>
                        </Col>
                        <Col sm={6} style={{textAlign: "right"}} xs={12}>
                            <Button
                                style={{
                                    margin: "5px",
                                    fontFamily: "'Inter', arial",
                                    background: "#003172",
                                    border: "none",
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                }}
                                onClick={() => navigate("create")}
                            >
                                Furnizor nou
                            </Button>
                            <ExportCSV name={`furnizori-${new Date().toLocaleDateString('en-GB')}`} data={vendors}
                                       headers={['name', 'email', 'type']}
                                       nameHeaders={['Nume', 'Email', 'Tip']}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div style={{
                display: "flex",
                margin: '20px 0px',
                padding: '25px',
                boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
                background: 'white',
                verticalAlign: "middle"
            }}>
                <SearchBar objectsArray={vendorsParam} setNewArray={setVendors}/>
                <Button
                    onClick={() => view === "Tabel" ? setView(saveView("Carduri")) : setView(saveView("Tabel"))}
                    style={{
                        marginLeft: "5px",
                        fontFamily: "'Inter', arial",
                        background: "white",
                        border: "1px solid lightgray",
                        color: "#003172",
                        width: "60px",
                        height: "50px",
                    }}>{view === "Tabel" ? <TbLayoutList size={30}/> : <TbLayoutGrid size={30}/>}</Button>
            </div>

            {view === "Tabel" ? (<div className="itemscontainer">
                <Table style={{color: "#003172"}} responsive hover>
                    <thead>
                    <tr>
                        <th>Nume</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Adresă</th>
                        <th>Ultima editare</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortObjects(vendors, sortBy, order).map((customer, index) => (<CustomerTableComponent
                            key={index}
                            customer={customer}
                            refresh={refresh}
                        />))}
                    </tbody>
                </Table>
            </div>) : (<>
                <Row>
                    {sortObjects(vendors, sortBy, order).map((customer, index) => (<CustomerComponent
                            key={index}
                            customer={customer}
                            refresh={refresh}
                        />))}
                </Row>
            </>)}
        </>);
}

export default HasVendors;
