import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import m from '../../static/M.png'

function ServersDown() {
    return (
        <div>
            <Container>
                <Row style={{ marginTop: "30px", marginBottom: "20px" }}>
                    <Col sm={12}>
                        <h1
                            style={{
                                fontFamily: "'Inter', Arial",
                                color: "#003172",
                                float: "left",
                            }}
                        >
                            Servers are Down
                        </h1>
                    </Col>
                </Row>

                <Row style={{ marginBottom: "20px" }} zIndex={-1}>
                    <Col>
                        <Card
                            style={{
                                border: "none",
                                borderRadius: "0px",
                                padding: "25px",
                                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                                background: "white",
                            }}
                        >
                            <Card.Body>
                                <div>
                                    <img
                                        src={m}
                                        alt="Logo"
                                        style={{
                                            maxWidth: "200px", // Adjust the maximum width as needed
                                            marginBottom: "20px",
                                            display: "block", // Center the image
                                            margin: "0 auto", // Center the image
                                        }}
                                    />
                                </div>
                                <p
                                    style={{
                                        textAlign: "center",
                                        fontFamily: "'Inter', Arial",
                                        color: "#003172",
                                    }}
                                >
                                    We apologize for the inconvenience, but our servers are
                                    currently down.
                                </p>
                                <p
                                    style={{
                                        textAlign: "center",
                                        fontFamily: "'Inter', Arial",
                                        color: "#003172",
                                    }}
                                >
                                    Please try again later or contact our support team for
                                    assistance.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ServersDown;
