import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Pagination from 'react-bootstrap/Pagination';

import "./AddItem.css";


import
{
    AiOutlinePlus,
} from "react-icons/ai";
import {createCheck, searchObject} from '../../../utils/functions';

function AddItem({isDisabled = false, defaultValue=[], itemsParam = [], returnItems}) {
    const [itemsDict, setItemsDict] = useState(createCheck(itemsParam));
    const [itemsArr, setItemsArr] = useState(itemsParam);
    const [search, setSearch] = useState('');

    const [activePage, setActivePage] = useState(1);
    const [Pages, setPages] = useState([]);
    const itemsPerPage = 6;
    const paginationLength = !(itemsArr.length % itemsPerPage) ? itemsArr.length / itemsPerPage : Math.floor(itemsArr.length / itemsPerPage) + 1;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if(isDisabled)
            return;
        setShow(true);
    };

    const handleCheck = e => {
        const {id, checked} = e.target;
        itemsDict[id].isChecked = checked;
        setItemsDict(itemsDict);

        //force the html to render on the spot
        setItemsArr(searchObject(itemsParam, search));
    };

    const handleQuantity = (value, id) => {
        itemsDict[id].quantity = value;
        setItemsDict(itemsDict);
        setItemsArr(searchObject(itemsParam, search));
    };

    const saveSelectedItems = () => {
        returnItems(itemsArr.filter(item => itemsDict[item.id].isChecked).map(item => ({...item, quantity: itemsDict[item.id].quantity})));
        setShow(false);
    }

    useEffect(() => {
        setItemsArr(searchObject(itemsParam, search));
        setPages(Array.from(Array(paginationLength)).map((e, i) => (i+1)));
    }, [itemsParam, paginationLength, search]);

    //set default value
    useEffect(() => {
        defaultValue.forEach(item => {
            setItemsDict(itemsDict => {
                if(itemsDict[item.id] === undefined){
                    console.log(itemsDict)
                    return itemsDict;
                }
                itemsDict[item.id].isChecked = true;
                itemsDict[item.id].quantity = item.quantity;
                return itemsDict;
            });
        });
    }, [defaultValue]);

    return (
        <>
            <div id="AddItemModal" onClick={handleShow} style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}>
                <p style={{fontSize: "13px", fontWeight: "bold", fontFamily: "'Inter', arial", paddingTop: "20px"}}>
                    <AiOutlinePlus size={20} style={{marginRight: "5px"}}/>Adaugă un produs</p>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{border: "none"}}>
                    <Modal.Title>Adaugă un produs</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{border: "none", height: "500px"}}>
                    <Tabs className="text-muted" justify>
                        <Tab eventKey="existing" title="Produse existente" className="text-muted">
                            <Row>
                                <Col sm={1}></Col>
                                <Col sm={10}>
                                    <input placeholder='Caută produse..' style={{
                                        paddingTop: "20px",
                                        marginBottom: "20px",
                                        fontSize: "20px",
                                        fontFamily: "'Inter', arial",
                                        color: "#003172",
                                        background: "none",
                                        width: "100%",
                                        border: "none",
                                        outline: "none",
                                        borderBottom: "1px solid gray"
                                    }} type="text" onChange={e => setSearch(e.target.value)}/>

                                    {itemsArr.map((item, index) =>
                                        index >= itemsPerPage * (activePage - 1) && index < itemsPerPage * (activePage) ?
                                            <div key={index}>
                                                <Row style={{paddingTop: "15px"}}>
                                                    <Col sm={6}>
                                                        <label>
                                                            <input type="checkbox" id={item.id}
                                                                   checked={itemsDict[item.id].isChecked}
                                                                   onChange={handleCheck} style={{marginRight: "5px"}}/>
                                                            <span className="checkmark"></span>
                                                            <p style={{
                                                                fontFamily: "'Inter', arial",
                                                                color: "#003172",
                                                                fontSize: "20px",
                                                                display: "inline"
                                                            }}>{item.name}</p>
                                                        </label>
                                                    </Col>
                                                    <Col sm={6} style={{textAlign: "right"}}>
                                                        <input style={{
                                                            textAlign: "right",
                                                            fontSize: "12px",
                                                            fontFamily: "'Inter', arial",
                                                            color: "#003172",
                                                            background: "none",
                                                            border: "none",
                                                            outline: "none",
                                                            borderBottom: "1px solid gray",
                                                            width: "80px"
                                                        }} type='number' value={itemsDict[item.id].quantity !== 1 ? itemsDict[item.id].quantity : ''}
                                                               onChange={event => handleQuantity(event.target.value, item.id)}
                                                               placeholder='Cantitate'/>
                                                    </Col>
                                                </Row>
                                            </div> : <div key={index}></div>
                                    )}

                                    <Pagination style={{
                                        outline: "none",
                                        boxShadow: "none",
                                        position: "absolute",
                                        bottom: "10px",
                                        left: "50%",
                                        transform: "translate(-50%, 0%)"
                                    }}>
                                        <Pagination.Prev onClick={() => setActivePage(Math.max(activePage - 1, 1))}/>
                                        {Pages.map((page, index) => page === activePage ?
                                            <Pagination.Item onClick={() => setActivePage(page)} key={index}
                                                             active> {page} </Pagination.Item>
                                            :
                                            <Pagination.Item onClick={() => setActivePage(page)}
                                                             key={index}> {page} </Pagination.Item>
                                        )}
                                        <Pagination.Next onClick={() => setActivePage(Math.min(activePage + 1, paginationLength))}/>
                                    </Pagination>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{background: "none", border: "none", color: "gray"}} variant="secondary"
                            onClick={handleClose}>
                        Anulează
                    </Button>
                    <Button style={{background: "#003172", border: "none"}} variant="primary" onClick={saveSelectedItems}>
                        Salvează
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddItem;