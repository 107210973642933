import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {TbLayoutList, TbLayoutGrid} from "react-icons/tb";
import useFetch from "../../../../../../hooks/useFetch";

import {loadContacts} from "../../../../../../api/contacts";
import {useParams} from "react-router-dom";

import CompanyCard from "./Components/CompanyCard/CompanyCard";
import {loadCrmCompanies} from "../../../../../../api/crm/companies";

import CompanyTableComponent from "./Components/CompanyTableComponent/CompanyTableComponent";
import SearchBar from "../../../../../../components/Filters/SearchBar";
import {listToDict} from "../../../../../../utils/functions";
import CompanyFilter from "../../../../../../components/Filters/CompanyFilter";
import {loadCategories} from "../../../../../../api/categories";
import {CATEGORY_TYPE} from "../../../../../../utils/constants";
import {getView, saveView} from "../../../../../../utils/cache/functions";
import {loadActivities} from "../../../../../../api/activities";

function CRMCompanies() {
    const navigate = useNavigate();
    const {companyId} = useParams();

    const crmCompanyState = useFetch(loadCrmCompanies, {companyId: companyId}, true);
    const contactsState = useFetch(loadContacts, {});
    const categoriesState = useFetch(loadCategories, {});
    const activitiesState = useFetch(loadActivities, {companyId: companyId}, true);

    const [companyList, setCompanyList] = useState(crmCompanyState.data ?? []);
    const [contactsList, setContactsList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const [view, setView] = useState(getView());

    //update companyList when contactsState.data changes and filter by type with the filterbytype function
    useEffect(() => {
        setCompanyList(crmCompanyState.data ?? []);
    }, [crmCompanyState.data]);

    useEffect(() => {
        contactsState.sendRequest({companyId: companyId}).then((response) => {
            setContactsList(listToDict(response));
        });
        categoriesState.sendRequest({companyId: companyId, type: CATEGORY_TYPE.COMPANY}).then((response) => {
            setCategoryList(listToDict(response));
        });
    }, []);

    const refresh = () => {
        crmCompanyState.sendRequest({companyId: companyId});
    }

    return (<>
        <Row>
            <Col sm={12} xs={12}>
                <Row>
                    <Col sm={6}>
                        <h1
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                            }}
                        >
                            Companii
                        </h1>
                    </Col>
                    <Col sm={6} style={{textAlign: "right"}} xs={12}>
                        <Button
                            style={{
                                margin: "5px",
                                fontFamily: "'Inter', arial",
                                background: "#003172",
                                border: "none",
                                marginTop: "30px",
                                marginBottom: "30px",
                            }}
                            onClick={() => navigate("create")}
                        >
                            Companie nouă
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>

        <div style={{
            display: "flex",
            margin: '20px 0px',
            padding: '25px',
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
            background: 'white',
            verticalAlign: "middle"
        }}>
            <SearchBar objectsArray={crmCompanyState.data} setNewArray={setCompanyList}/>
            <Button onClick={() => view === "Tabel" ? setView(saveView("Carduri")) : setView(saveView("Tabel"))}
                    style={{
                        marginLeft: "5px",
                        fontFamily: "'Inter', arial",
                        background: "white",
                        border: "1px solid lightgray",
                        color: "#003172",
                        width: "60px",
                        height: "50px",
                    }}>{view === "Tabel" ? <TbLayoutList size={30}/> : <TbLayoutGrid size={30}/>}</Button>

            <CompanyFilter companies={crmCompanyState.data} setCompanies={setCompanyList} contacts={contactsList}
                           activities={activitiesState.data}/>
        </div>

        {view === "Tabel" ? <>
            <div className="itemscontainer">
                <Table style={{color: "#003172"}} responsive hover>
                    <thead>
                    <tr>
                        <th>Nume</th>
                        <th>Reprezentant</th>
                        <th>Categorie</th>
                        <th>Oraş</th>
                    </tr>
                    </thead>
                    <tbody>
                    {companyList.map((item, index) => (<CompanyTableComponent
                        key={index}
                        company={item}
                        contact={contactsList[item.contact]?.name}
                        category={categoryList[item.category]?.name}
                        refresh={refresh}
                    />))}
                    </tbody>
                </Table>
            </div>
        </> : <>
            <Row style={{justifyContent: 'left'}}>

                {companyList.map((item, index) => (<Col sm={3}>
                    <CompanyCard
                        key={index}
                        company={item}
                        contact={contactsList[item.contact]}
                        category={categoryList[item.category]?.name}
                        refresh={refresh}
                    />
                </Col>))}

            </Row>
        </>}
    </>);
}

export default CRMCompanies;
