import React, {useEffect} from "react";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from "recharts";
import {ResponsiveContainer} from "recharts";
import {dailyCashflow} from "../../../../../utils/functions/cashflow";
import {useState} from "react";
import {constructInterval} from "../../../../../utils/functions/cashflow";
import moment from "moment";

const ProfitGraph = ({invoices, bills, filters, companies}) => {

    const [graphData, setGraphData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [billData, setBillData] = useState([]);

    useEffect(() => {
        setInvoiceData(invoices?.filter(invoice => (filters.company === 'all' || invoice.contact === filters.company)
            && (filters.contact === 'all' || companies[invoice.contact]?.contact === filters.contact)));
        setBillData(bills?.filter(invoice => (filters.company === 'all' || invoice.contact === filters.company)
            && (filters.contact === 'all' || companies[invoice.contact]?.contact === filters.contact)));
    }, [filters.contact, filters.company, invoices, bills]);

    useEffect(() => {
        setGraphData(dailyCashflow(invoiceData, billData, constructInterval(filters.start, filters.end)));
    }, [invoiceData, billData, filters.start, filters.end]);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart
                width={500}
                height={270}
                data={graphData}
                margin={{top: 5, right: 30, left: -20, bottom: 30}}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                    dataKey="date"
                    domain={[new Date(filters.start), new Date(filters.end)]}
                    type="number"
                    tickFormatter={(str) => moment(str).format("MMM DD YYYY")}
                    tickCount={7}
                    angle={-30}
                    dx={0}
                    dy={20}
                    minTickGap={-200}
                ></XAxis>
                <YAxis angle={-40}/>
                <Tooltip
                    labelFormatter={(value) => {
                        return `${new Date(value).toString().substring(0, 10)}`;
                    }}
                />
                <Legend verticalAlign={"top"}/>
                <Line
                    type="monotone"
                    dataKey="totalintrari"
                    name="intrari"
                    stroke="#8884d8"
                    activeDot={{r: 8}}
                />
                <Line
                    name={"iesiri"}
                    type="monotone"
                    dataKey="totaliesiri"
                    stroke="#82ca9d"
                />
            </LineChart>
        </ResponsiveContainer>);
};

export default ProfitGraph;
