import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {TbLayoutList, TbLayoutGrid} from "react-icons/tb";
import useFetch from "../../../../hooks/useFetch";
import {useParams} from "react-router-dom";
import {getView, saveView} from "../../../../utils/cache/functions";
import {loadHR} from "../../../../api/hr";
import EmployeeTableComponent from "./Components/EmployeeCard/EmployeeTableComponent";
import SearchBar from "../../../../components/Filters/SearchBar";
import "./ListEmployees.css"
import EmployeeCard from "./Components/EmployeeCard/EmployeeCard";
import HRFilter from "../../../../components/Filters/HRFilter";
import ExportCSV from "../../../../components/CSV/ExportCSV";

function ListEmployees() {
    const navigate = useNavigate();
    const {companyId} = useParams();

    const hr = useFetch(loadHR, {companyId: companyId}, true);
    const [hrList, setHRList] = useState(hr.data ?? []);

    const [view, setView] = useState(getView());

    useEffect(() => {
        setHRList(hr.data ?? []);
    }, [hr.data]);


    const refresh = () => {
        hr.sendRequest({companyId: companyId});
    }

    return (<>
        <Row>
            <Col sm={12} xs={12}>
                <Row>
                    <Col sm={6}>
                        <h1
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                            }}
                        >
                            Angajați
                        </h1>
                    </Col>
                    <Col sm={6} style={{textAlign: "right"}} xs={12}>
                        <Button
                            style={{
                                margin: "5px",
                                fontFamily: "'Inter', arial",
                                background: "#003172",
                                border: "none",
                                marginTop: "30px",
                                marginBottom: "30px",
                            }}
                            onClick={() => navigate("create")}
                        >
                            Angajat nouă
                        </Button>
                        <ExportCSV name={`angajati-${new Date().toLocaleDateString('en-GB')}`} data={hrList}
                                   headers={['first_name', 'last_name', 'email', 'pozitie', 'salariu_brut']}
                                   nameHeaders={['Nume', 'Prenume', 'Email', 'Pozitie', 'Salariu brut']}/>
                    </Col>

                </Row>
            </Col>
        </Row>

        <div style={{
            display: "flex",
            margin: '20px 0px',
            padding: '25px',
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
            background: 'white',
            verticalAlign: "middle",
            className: "centered-container"
        }}>
            <SearchBar objectsArray={hr?.data} setNewArray={setHRList}/>
            <Button onClick={() => view === "Tabel" ? setView(saveView("Carduri")) : setView(saveView("Tabel"))}
                    style={{
                        marginLeft: "5px",
                        fontFamily: "'Inter', arial",
                        background: "white",
                        border: "1px solid lightgray",
                        color: "#003172",
                        width: "60px",
                        height: "50px",
                    }}>{view === "Tabel" ? <TbLayoutList size={30}/> : <TbLayoutGrid size={30}/>}</Button>
            <HRFilter hr={hr?.data} setHR={setHRList} />
        </div>

        {view === "Tabel" ? <>
            <div className="itemscontainer">
                <Table style={{color: "#003172"}} responsive hover>
                    <thead>
                    <tr>
                        <th>Nume</th>
                        <th>Prenume</th>
                        <th>Poziţie</th>
                        <th>Salariu Brut</th>
                        <th>Data începerii</th>
                        <th>Data plecării</th>
                    </tr>
                    </thead>
                    <tbody>
                    {hrList.map((item, index) => (<EmployeeTableComponent
                        key={index}
                        employee={item}
                        refresh={refresh}
                    />))}
                    </tbody>
                </Table>
            </div>
        </> : <>
            <Row style={{justifyContent: 'left'}}>

                {hrList.map((item, index) => (<Col sm={3}>
                    <EmployeeCard
                        key={index}
                        employee={item}
                    />
                </Col>))}

            </Row>
        </>}
    </>);
}

export default ListEmployees;
