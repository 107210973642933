import {cache} from "../utils/cache/cache";
import {requestId} from "../utils/utils";
import {loggedInHeaders} from "./apiUtils";
import {get, post, put} from "./requests";

export const loadCompanies = async (params) => {
    try {
        const url = `/api/companies/owner/`;
        const reqId = requestId(url);

        const cachedData = cache.get(reqId);
        if (cachedData) {
            params.setData(cachedData);
            return;
        }

        let data = await (await get(url, loggedInHeaders())).data;
        data = data?.filter((c) => c.enabled);
        cache.set(reqId, data, 1);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const createCompany = async (params) => {
    try {
        const args = params.args;
        const headers = loggedInHeaders();

        if (!args) return;

        const companyResponse = await (await post("/api/companies/", args?.company, headers)).data;

        // const userCompanyData = {
        //   user: args?.userId,
        //   company: companyResponse?.id,
        //   user_type: args?.company.userType,
        // };

        // const userCompanyResponse = await (
        //   await post("/api/user_companies/", userCompanyData, headers)
        // ).data;

        //new
        const companyDataResponse = await (await post("/api/company_data/", {
            ...args?.companyData, company: companyResponse?.id
        }, headers)).data;

        params.setData({companyResponse});
        return companyResponse;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const editCompany = async (params) => {
    try {
        const url = `/api/companies/${params.args?.companyId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getCompany = async (params) => {
    try {
        const url = `/api/companies/${params.args?.companyId}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        //save the role in cache
        cache.set(requestId(`/api/get_role/${params.args?.companyId}/`), data?.role, 20);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getCompanyDataById = async (params) => {
    try {
        const url = `/api/company_data_by_id/${params.args?.companyId}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const editCompanyData = async (params) => {
    try {
        const url = `/api/company_data/${params.args?.companyDataId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const loadCompaniesData = async (params) => {
    try {
        const url = `/api/company_data/owner/`;
        const reqId = requestId(url);

        const cachedData = cache.get(reqId);
        if (cachedData) {
            params.setData(cachedData);
            return;
        }

        let data = await (await get(url, loggedInHeaders())).data;
        data = data?.filter((c) => c.enabled);
        cache.set(reqId, data, 10);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const sendCompanyInvite = async (params) => {
    try {
        const url = `/api/company/invite/`;
        const data = await (await post(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
