import {loggedInHeaders} from './apiUtils';
import {get, post, put} from './requests';

export const loadHR = async (params) => {
    try {
        const url = '/api/hr/owner/';

        let data = await (await get(url, loggedInHeaders())).data;
        data = data?.filter((i) => i.enabled && params?.args.companyId === i.company);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const createHR = async (params) => {
    try {
        const data = params.args?.data;
        const headers = loggedInHeaders();

        if (!data) return;
        const res = await (await post('/api/hr/', data, headers)).data;

        params.setData(res);
        return res;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const editHR = async (params) => {
    try {
        const url = `/api/hr/${params.args?.hrId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getHR = async (params) => {
    try {
        const url = `/api/hr/${params.args?.hrId}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};


