import {loggedInHeaders} from "./apiUtils";
import {post} from "./requests";

export const requestActivateCompany = async (params) => {
    try {
        const url = "/api/request_activate/";
        //reset errors
        params.setError(undefined);

        const data = await post(url, params.args, loggedInHeaders()).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err);
    } finally {
        params.setLoading(false);
    }
};

export const activateCompany = async (params) => {
    try {
        const url = "/api/activate/";
        //reset errors
        params.setError(undefined);

        const data = await (await post(url, params.args, loggedInHeaders())).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
