import {get} from "./requests";

export const getFromCUI = async (params) => {
    try {
        const url = `/api/get_company_info/${params.args?.CUI}/`;
        const data = await (await get(url)).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};