import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import { approveNewLicense } from "../../../../api/license";
import Row from "react-bootstrap/Row";
import MainNavbar from "../../../MainPages/Components/MainNavbar/MainNavbar";
import Footer from "../../../MainPages/Components/Footer/Footer";

const ApproveNewLicense = () => {
  const nav = useNavigate();
  const { companyId, userId, licenseNumber } = useParams();

  const approveNewLicenseState = useFetch(approveNewLicense, {});

  const handleApprove = () => {
    approveNewLicenseState
        .sendRequest({
          companyId: companyId,
          userId: userId,
          licenseNumber: licenseNumber,
        })
        .then((data) => {
          nav("/dashboard");
        });
  };

  return (
      <div className="container">
        <MainNavbar />
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-12">
            <div className="card">
              <div className="card-body text-center">
                <h1>Aprobă Licența Nouă</h1>
                <div className="d-flex justify-content-center">
                  <button
                      style={{ width: "200px" }}
                      className="btn btn-primary"
                      onClick={handleApprove}
                  >
                    Da
                  </button>
                  <button
                      style={{ marginLeft: "20px", width: "200px" }}
                      className="btn btn-secondary"
                      onClick={() => nav("/dashboard")}
                  >
                    Nu
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  );
};

export default ApproveNewLicense;
