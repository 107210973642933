import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {useNavigate} from "react-router-dom";

import {useParams, useOutletContext} from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import {
    createCrmCompany, editCrmCompany,
} from "../../../../../../api/crm/companies";
import useAuth from "../../../../../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import {loadContacts} from "../../../../../../api/contacts";
import {getFromCUI} from "../../../../../../api/cui";
import ErrorField, {validateForm} from "../../../../../../utils/functions/validation";
import {copyButExclude} from "../../../../../../utils/utils";
import {CATEGORY_TYPE, CONTACT_TYPE, REGEXP} from "../../../../../../utils/constants";
import {loadActivities} from "../../../../../../api/activities";
import Select from "react-select";
import NewActivity from "../../../../../../components/Modals/NewActivity";
import {defaultActivities, handleActivities, optionsActivities} from "../../../../../../utils/functions/activities";
import Loading from "../../../../../MainPages/Components/Loading/Loading";
import {loadCategories} from "../../../../../../api/categories";
import NewCategory from "../../../../../../components/Modals/NewCategory";
import {removeCategories, removeContacts} from "../../../../../../utils/cache/functions";
import DisableObject from "../../../../../../components/Modals/DisableObject";
import AddContact from "../../../../../../components/Modals/AddContact/AddContact";
import {findById, listToDict} from "../../../../../../utils/functions";

const CompanyNew = ({viewPage = false}) => {
    const {companyId} = useParams();
    const auth = useAuth();

    const outletContext = useOutletContext();
    const crmCompany = outletContext?.crmCompany ? outletContext.crmCompany : undefined;

    const initialStateCompany = {
        company: companyId,
        company_name: "",
        cui: "",
        activities: [],
        contact: "",
        email: "",
        phone: "",
        created_by: auth.data?.user_id,
        dimensiune: "",
        category: ""
    };

    const initialStateAddress = {
        city: "", county: "", postal_code: "", country: "",
    };

    const createCompanyState = useFetch(createCrmCompany, {data: {}});
    const editCompanyState = useFetch(editCrmCompany, {data: {}});
    const categoriesState = useFetch(loadCategories, {companyId: companyId, type: CATEGORY_TYPE.COMPANY}, true);
    const contactState = useFetch(loadContacts, {companyId: companyId}, true);
    const searchCUI = useFetch(getFromCUI, {});
    const activitiesState = useFetch(loadActivities, {companyId: companyId}, true);

    const [contactsDict, setContactsDict] = useState({});
    useEffect(() => {
        setContactsDict(listToDict(contactState?.data));
    }, [contactState.data]);

    const [form, setForm] = useState(crmCompany ? {
        company: companyId,
        company_name: crmCompany?.company_name,
        cui: crmCompany?.cui,
        activities: crmCompany?.activities ? JSON.parse(crmCompany?.activities) : [],
        contact: crmCompany?.contact,
        email: crmCompany?.email,
        phone: crmCompany?.phone,
        dimensiune: crmCompany?.dimensiune,
        category: crmCompany?.category,
        created_by: auth.data?.user_id,
    } : initialStateCompany);

    const addr = crmCompany ? JSON.parse(crmCompany.address) : {};
    const [address, setAddress] = useState({
        city: addr.city ? addr.city : "",
        county: addr.county ? addr.county : "",
        postal_code: addr.postal_code ? addr.postal_code : "",
        country: addr.country ? addr.country : "",
    });

    const nav = useNavigate();
    const [errors, setErrors] = useState({});

    const handleAddressChange = (e) => {
        const {name, value} = e.target;
        setAddress((prev) => ({
            ...prev, [name]: value,
        }));
    };

    const handleFormChange = (e) => {
        const {name, value} = e.target;
        setForm((prev) => ({
            ...prev, [name]: value,
        }));
    };

    const searchByCUI = () => {
        searchCUI.sendRequest({CUI: form.cui}).then(response => {
            setForm(prev => ({
                ...prev, company_name: response?.data.company_name,
            }));
            setAddress(prev => ({...prev, county: response?.data.judet}));
        });
    };

    const refreshActivities = () => {
        activitiesState.sendRequest();
    };

    const refreshCategories = () => {
        removeCategories(companyId);
        categoriesState.sendRequest();
    };

    const selectStyles = {
        option: (styles, {data, isFocused}) => {
            return {
                ...styles, backgroundColor: isFocused ? "#d4d0cf" : null,
            };
        }, control: (styles) => {
            return {
                ...styles,
            };
        }
    };
    const handleClick = () => {
        const validatedCompany = validateForm(initialStateCompany, copyButExclude(form, ['company', 'category', 'created_by']), {
            cui: REGEXP.INTEGER_ABOVE_ZERO_OR_EQUAL, phone: REGEXP.PHONE
        });
        const validatedAddress = validateForm(initialStateAddress, copyButExclude(address, ['postal_code']), {});

        if (Object.entries(validatedCompany).length > 0 || Object.entries(validatedAddress).length > 0) {
            setErrors({...validatedCompany, ...validatedAddress});
            return;
        }
        setErrors({});

        if (!crmCompany) {
            createCompanyState.sendRequest({
                data: {...form, address: JSON.stringify(address), activities: JSON.stringify(form.activities)},
            }).then(() => {
                nav(-1);
            });
        } else {
            editCompanyState.sendRequest({
                crmCompanyId: crmCompany.id,
                data: {...form, address: JSON.stringify(address), activities: JSON.stringify(form.activities)},
            }).then(() => {
                nav(-1);
            });
        }
    };

    const disableCompany = () => {
        editCompanyState.sendRequest({crmCompanyId: crmCompany.id, data: {...crmCompany, enabled: 0}})
            .then(() => {
                nav('../../');
            });
    };

    const returnContact = (contact) => {
        setForm(prev => ({...prev, contact: contact?.id, email: contact?.email, phone: contact?.phone}));
    };

    const refreshContacts = () => {
        removeContacts(companyId);
        contactState.sendRequest();
    };

    return (<Container>
        {activitiesState.loading && <Loading/>}
        <h2
            style={{
                fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
            }}
        >
            Companie nouă
        </h2>

        <Form>
            <Row className="formsection">
                <h3
                    style={{
                        fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    General
                </h3>

                <p
                    style={{
                        fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Informaţiile companiei vor apărea în facturi şi în profilul
                    acestora. Puteţi adăuga şi logo-ul acesteia pentru a fi folosite în
                    facturi.
                </p>
                <hr></hr>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Cum se numește compania?</Form.Label>

                        <Form.Control
                            disabled={viewPage}
                            name="company_name"
                            type="text"
                            placeholder="Nume"
                            value={form.company_name}
                            onChange={handleFormChange}
                        />
                        <ErrorField enabled={errors['company_name']}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Care este CUI-ul companiei?</Form.Label>

                        <div style={{display: 'flex'}}>
                            <Form.Control
                                disabled={viewPage}
                                name="cui"
                                type="text"
                                placeholder="CUI"
                                value={form.cui}
                                onChange={handleFormChange}
                                style={{marginRight: "10px"}}
                            />
                            <ErrorField enabled={errors['cui']}/>
                            <Button disabled={viewPage} style={{border: "none", color: "white", background: "#003172"}}
                                    onClick={searchByCUI}>Caută</Button>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Email companie</Form.Label>

                        <Form.Control
                            disabled={viewPage}
                            name="email"
                            type="text"
                            placeholder="Email"
                            value={form.email}
                            onChange={handleFormChange}
                        />
                        <ErrorField enabled={errors['email']}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Telefon companie</Form.Label>

                        <Form.Control
                            disabled={viewPage}
                            name="phone"
                            type="text"
                            placeholder="Telefon"
                            value={form.phone}
                            onChange={handleFormChange}
                        />
                        <ErrorField enabled={errors['phone']}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                        className="sm-auto"
                        controlId="formBasicPassword"
                    >
                        <Form.Label>Activitați</Form.Label>
                        {activitiesState.data && (<div style={{display: "flex"}}>
                            <Select
                                isDisabled={viewPage}
                                className="activityselect"
                                onChange={(e) => handleActivities(form, e)}
                                defaultValue={defaultActivities(form.activities, activitiesState.data)}
                                placeholder={"Selectează activitați"}
                                isMulti
                                styles={selectStyles}
                                options={optionsActivities(activitiesState.data)}
                            />
                            <NewActivity isDisabled={viewPage} refreshActivities={refreshActivities}/>
                        </div>)}
                    </Form.Group>
                </Col>
                <AddContact
                    isDisabled={viewPage}
                    defaultValue={contactsDict[crmCompany?.contact]}
                    contactsParam={contactState?.data}
                    returnContact={returnContact}
                    contactType={CONTACT_TYPE.MULTIPLE}
                    refreshContacts={refreshContacts}
                />
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Dimensiunea companiei</Form.Label>

                        <Form.Select
                            disabled={viewPage} name={'dimensiune'} value={form.dimensiune} onChange={handleFormChange}>
                            <option value="">Selectează</option>
                            <option value='microintreprindere'>Microîntreprindere</option>
                            <option value='mica'>Mică</option>
                            <option value='mijlocie'>Mijlocie</option>
                            <option value='mare'>Mare</option>

                        </Form.Select>
                        <ErrorField enabled={errors['dimensiune']}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                        className="sm-auto"
                        controlId="formBasicPassword"
                    >
                        <Form.Label>Categorie</Form.Label>
                        <div style={{display: "flex"}}>
                            <Form.Select
                                disabled={viewPage}
                                name={'category'}
                                value={form.category}
                                onChange={handleFormChange}
                            >
                                <option value="null">Fara categorie</option>
                                {categoriesState.data?.map((c, index) => {
                                    return <option value={c.id}
                                                   key={index}>{c.name}</option>
                                })}
                            </Form.Select>
                            <NewCategory isDisabled={viewPage} refreshCategories={refreshCategories}
                                         type={CATEGORY_TYPE.COMPANY}/>
                        </div>

                    </Form.Group>
                </Col>
            </Row>

            <Row className="formsection">
                <h3
                    style={{
                        fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Adresă
                </h3>

                <p
                    style={{
                        fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                    }}
                >
                    Adresa este necesară pentru facturi.
                </p>
                <hr></hr>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Oraş</Form.Label>

                        <Form.Control
                            disabled={viewPage}
                            name="city"
                            type="text"
                            placeholder="Oraş"
                            value={address.city}
                            onChange={handleAddressChange}
                        />
                        <ErrorField enabled={errors['city']}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Judeţ / Provincie</Form.Label>

                        <Form.Control
                            disabled={viewPage}
                            name="county"
                            type="text"
                            placeholder="Judeţ / Provincie"
                            value={address.county}
                            onChange={handleAddressChange}
                        />
                        <ErrorField enabled={errors['county']}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Cod Poştal</Form.Label>

                        <Form.Control
                            disabled={viewPage}
                            name="postal_code"
                            type="text"
                            placeholder="Cod Poştal"
                            value={address.postal_code}
                            onChange={handleAddressChange}
                        />
                        <ErrorField enabled={errors['postal_code']}/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                        }}
                    >
                        <Form.Label>Ţară</Form.Label>

                        <Form.Control
                            disabled={viewPage}
                            name="country"
                            type="text"
                            placeholder="Ţară"
                            value={address.country}
                            onChange={handleAddressChange}
                        />
                        <ErrorField enabled={errors['country']}/>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
        <div
            style={{
                textAlign: "right", marginTop: "50px", height: "100px",
            }}
        >
            <Button
                onClick={() => nav(-1)}
                style={{
                    margin: "5px",
                    fontFamily: "'Inter', arial",
                    background: "none",
                    border: "none",
                    boxShadow: "none !important",
                    color: "gray",
                }}
            >
                Inapoi
            </Button>
            <Button
                hidden={!viewPage}
                style={{
                    margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                }}
                onClick={() => nav('../edit')}
            >Editeaza
            </Button>

            {viewPage && <DisableObject name='compania' disableFunction={disableCompany}/>}

            <Button
                hidden={viewPage}
                style={{
                    margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                }}
                onClick={handleClick}
            >{crmCompany ? 'Actualizează' : 'Salvează'}
            </Button>
        </div>

    </Container>);
};

export default CompanyNew;
