import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import useFetch from './../../hooks/useFetch';
import { loadLicense } from './../../api/license';

function Invite({ invite, companyId }) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("MANAGER");
  const licenseState = useFetch(loadLicense, {companyId: companyId}, true);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const send = () => {
    const arr = licenseState.data?.data ? licenseState.data?.data : [];
    for (const element of arr){
      if(element.owner === null){
        invite(email, role, element.id);
        break;
      }
    }
    setShow(false);
  };

  const options = [
    { label: "Manager", value: "MANAGER" },
    { label: "Contabil", value: "CONTABIL" },
    { label: "Client", value: "CLIENT" },
  ];

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  return (
    <>
      <i
        className="fa fa-plus-circle"
        aria-hidden="true"
        style={{ float: "right", marginRight: "5px", fontSize: "20px" }}
        onClick={handleShow}
      ></i>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Invită în companie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="clientEmailId">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
          <select value={role} onChange={handleChangeRole}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <p style={{ color: "green" }}>Licenţe disponibile: {licenseState.data?.data?.filter(e => e.owner == null).length}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Închide
          </Button>
          <Button variant="primary" onClick={send} disabled={licenseState.data?.data?.filter(e => e.owner == null).length < 0}>
            Trimite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Invite;
