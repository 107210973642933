import React from "react";
import { Navigate, useParams } from "react-router-dom";
import HasItems from "./Components/HasItems/HasItems";
import NoItems from "./Components/NoItems/NoItems";
import Loading from "../../../../MainPages/Components/Loading/Loading";
import { listToDict } from "../../../../../utils/functions";
import useFetch from "../../../../../hooks/useFetch";
import { loadItems } from "../../../../../api/items";
import { loadCategories } from "../../../../../api/categories";
import { removeItems } from "../../../../../utils/cache/functions";

function ListItems({ setLocale }) {
  const { companyId } = useParams();
  const itemsState = useFetch(loadItems, { companyId: companyId }, true);
  const categoriesState = useFetch(
    loadCategories,
    { companyId: companyId },
    true
  );

  const refreshItems = () => {
    removeItems(companyId);
    itemsState.sendRequest();
  };

  return (
    <>
      {(itemsState.loading || categoriesState.loading) && <Loading />}
      {(itemsState.error || categoriesState.error) && (
        <Navigate to="/dashboard" />
      )}
      {itemsState.data &&
        categoriesState.data &&
        (itemsState.data?.length ? (
          <HasItems
            itemsParam={itemsState.data}
            categories={listToDict(categoriesState.data)}
            refresh={refreshItems}
          />
        ) : (
          <NoItems />
        ))}
    </>
  );
}

export default ListItems;
