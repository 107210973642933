import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import SwitchCompanies from "../../../../components/Modals/SwitchCompanies/SwitchCompanies";

import {
  BsCashStack,
} from "react-icons/bs";

import { MdBusiness } from "react-icons/md";

import {FiUsers} from "react-icons/fi";

import {
    AiOutlineSetting,
    AiOutlineUsergroupAdd,
    AiOutlineBell,
    AiOutlineDashboard,
    AiOutlineBank,
    AiOutlineShoppingCart,
    AiOutlineShopping,
    Ai, AiOutlineSolution
} from "react-icons/ai";

import {
  CgArrowsV
} from "react-icons/cg";

import Container from "react-bootstrap/Container";
import m from "../../../../static/M.png";
import "../../../../static/css/NavbarComp.css";

import Offcanvas from "react-bootstrap/Offcanvas";

import './AppNavbar.css';

const AppNavbar = ({ company }) => {

  const domain = company.domain.substr(0, 12);

  return (
    <>
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      bg="white"
      variant="light"
      style={{borderBottom: "1px solid lightgray", height: "100px"}}
    >
      <Container>
        <Navbar.Toggle
          className="closebutton"
          aria-controls="responsive-navbar-nav"
        />

        <Navbar.Offcanvas placement="start">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{fontFamily: "'Inter', Arial"}}>sIMMple</Offcanvas.Title>
            <img></img>
          </Offcanvas.Header>
          <Offcanvas.Body>

            <NavLink className="navigationlink"
              to={`/${company?.id}/`}
              style={{
                color: "#003172",
                fontWeight: "600",
                margin: "20px 30px 15px 0px",
                textDecoration: "none",
                fontFamily: "'Inter', Arial"
              }} disabled
            >
              <MdBusiness size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                <SwitchCompanies domain={domain == company.domain ? domain : domain.concat("...")} />
              <CgArrowsV size={18} style={{marginRight: "3px", paddingBottom: "3px"}}/>
            </NavLink>

            <Nav className="me-auto">

              <NavLink className="navigationlink"
                to={`/${company?.id}/`}
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "20px 30px 15px 0px",
                  textDecoration: "none",
                  fontFamily: "'Inter', Arial"
                }}
              >
                <AiOutlineDashboard size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                Panou de control
              </NavLink>

              <NavLink className="navigationlink"
                to={`/${company?.id}/crm`}
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "20px 30px 15px 0px",
                  textDecoration: "none",
                  fontFamily: "'Inter', Arial"
                }}
              >
                <AiOutlineUsergroupAdd size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                CRM
              </NavLink>

                <NavLink className="navigationlink"
                         to={`/${company?.id}/hr`}
                         style={{
                             color: "#003172",
                             fontWeight: "600",
                             margin: "20px 30px 15px 0px",
                             textDecoration: "none",
                             fontFamily: "'Inter', Arial"
                         }}
                >
                    <AiOutlineSolution size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                    HR
                </NavLink>

              <NavLink className="navigationlink"
                to={`/${company?.id}/items`}
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "20px 30px 15px 0px",
                  textDecoration: "none",
                  fontFamily: "'Inter', Arial"
                }}
              >
                <AiOutlineShopping size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                Produse
              </NavLink>
                
              <NavLink className="navigationlink"
                to={`/${company?.id}/sales/invoices`}
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "20px 30px 15px 0px",
                  textDecoration: "none",
                  fontFamily: "'Inter', Arial"
                }}
              >
                <BsCashStack size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                Vânzări
              </NavLink>

              <NavLink className="navigationlink"
                to={`/${company?.id}/purchases/bills`}
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "20px 30px 15px 0px",
                  textDecoration: "none",
                  fontFamily: "'Inter', Arial"
                }}
              >
                <AiOutlineShoppingCart size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                Achiziţii
              </NavLink>
              <NavLink className="navigationlink"
                to={`/${company?.id}/banking`}
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "20px 30px 15px 0px",
                  textDecoration: "none",
                  display: "none",
                  fontFamily: "'Inter', Arial"
                }}
              >
                <AiOutlineBank size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                Banking
              </NavLink>

              <NavLink className="navigationlink"
                to={`/${company?.id}/settings`}
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "20px 30px 15px 0px",
                  textDecoration: "none",
                  fontFamily: "'Inter', Arial"
                }}
              >
                <AiOutlineSetting size={24} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                Setări
              </NavLink>
            </Nav>

          </Offcanvas.Body>
        </Navbar.Offcanvas>

        <div className="navigationlink"
                style={{
                  color: "#003172",
                  fontWeight: "600",
                  margin: "25px 30px 15px 0px",
                  textDecoration: "none",
                  position: "relative",
                  display: "none"
                }}
              >
                <AiOutlineBell size={34} style={{marginRight: "3px", paddingBottom: "3px"}}/>
                <div style={{display: "none", background: "red", width: "10px", height: "10px", borderRadius: "10px", position: "absolute", top: "6px", right: "8px"}}></div>
        </div>

        <NavLink to="/" style={{ fontWeight: "bold" }}>
          <img
            src={m}
            alt="logo"
            style={{ width: "130px", padding: "none" }}
          ></img>
        </NavLink>
      </Container>
    </Navbar>

    </>
  );
};

export default AppNavbar;
