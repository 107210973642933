import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {login} from "../../api/auth";
import {register} from "../../api/register";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
import { setAuthToStorage } from "../../utils/cache/cache";

function InviteRegisterForm({ join }) {
  const [show, setShow] = useState(false);

  const nav = useNavigate();
  const auth = useAuth();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const registerState = useFetch(register, formData);
  const loginState = useFetch(login, {});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = () => {
    registerState.sendRequest().then((res) => {
      //now login
      loginState
        .sendRequest({
          email: formData.email,
          password: formData.password,
        })
        .then((res) => {
          auth.setAuthStatus(res?.token);
          auth.setData(jwtDecode(res?.token));
          setAuthToStorage(res?.token, res?.sessionid);
          join();
          setShow(false);
          nav("/");
        });
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow}>Înregistrare</Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Autentifică-te pe sIMMple</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4" controlId="formBasicName">
              <Form.Label>Nume</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder="Name"
                onChange={handleInputChange}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Adresa Email</Form.Label>
              <Form.Control
                required
                name="email"
                type="email"
                placeholder="Enter email"
                onChange={handleInputChange}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label>Parola</Form.Label>
              <Form.Control
                required
                name="password"
                type="password"
                placeholder="Password"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group
              style={{ marginLeft: "10px" }}
              className="mb-4"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                required
                className="text-muted"
                type="checkbox"
                label="Accept Termenii și Condițiile de utilizare și Politica de Condifențialitate."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Închide
          </Button>
          <Button variant="primary" onClick={handleRegister}>
            Înregistrare
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InviteRegisterForm;
