import React, {PureComponent, useEffect} from "react";
import {SORT_BY, ORDER, INVOICE_STATUS} from "../../../../../utils/constants";
import {useState} from "react";
import {
    constructInterval, dailyCashflow,
} from "../../../../../utils/functions/cashflow";

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart, Line,
} from "recharts";

import moment from "moment";

const ITEMS = ["Facturi emise", "Facturi primite"];

const MainGraph = ({bills, invoices, filters, companies}) => {
    const [graphData, setGraphData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [billData, setBillData] = useState([]);

    useEffect(() => {
        setInvoiceData(invoices?.filter(invoice => (filters.company === 'all' || invoice.contact === filters.company)
            && (filters.contact === 'all' || companies[invoice.contact]?.contact === filters.contact)));
        setBillData(bills?.filter(invoice => (filters.company === 'all' || invoice.contact === filters.company)
            && (filters.contact === 'all' || companies[invoice.contact]?.contact === filters.contact)));
    }, [filters.contact, filters.company, invoices, bills]);

    useEffect(() => {
        setGraphData(dailyCashflow(invoiceData, billData, constructInterval(filters.start, filters.end), INVOICE_STATUS.PAID));
    }, [invoiceData, billData, filters.start, filters.end]);

    return (<>
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                    top: 0, right: 0, left: 0, bottom: 40,
                }}
            >
                <CartesianGrid strokeDasharray="4 4"/>
                <XAxis
                    dataKey="date" // Change "data" to "date"
                    domain={[new Date(filters.start), new Date(filters.end)]}
                    type="number"
                    tickFormatter={(str) => moment(str).format("MMM DD YYYY")}
                    tickCount={7}
                    angle={-30}
                    dx={0}
                    dy={25}
                    minTickGap={-200}
                ></XAxis>
                <YAxis angle={-40} tickFormatter={(str) => `${str}`}/>
                <Tooltip
                    label="da"
                    labelFormatter={(value) => {
                        return `${new Date(value).toString().substring(0, 10)}`;
                    }}
                />
                <Legend verticalAlign="top" height={36}/>
                <Bar
                    dataKey="totalintrari"
                    stackId="a"
                    fill="#22a1e2"
                    name="Total intrări"
                />
                <Bar
                    dataKey="totaliesiri"
                    stackId="b"
                    fill="#003172"
                    name="Total ieșiri"
                />
                <Line // Change dataKey to "profit" from "totalintrari"
                    type="monotone"
                    dataKey="profit"
                    stroke="#ff7300"
                    name="Profit"
                />
            </ComposedChart>
        </ResponsiveContainer>
    </>);
};

export default MainGraph;
