import React from "react";
import "./Footer.css";

import logo from "../../../../static/M.png";
import InstallButton from "../../../../components/Others/PWA";

const Footer = () => {
  return (
    <div
      style={{ fontFamily: "'Inter', arial", width: "100%", color: "black" }}
    >
      <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fa fa-map-marker"></i>
                            <div className="cta-text">
                                <h4>Adresă</h4>
                                <span>Strada Vilcele 15 Focsani VN</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fa fa-phone"></i>
                            <div className="cta-text">
                                <h4>Telefon</h4>
                                <span>+40 767 100 000</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fa fa-envelope-open"></i>
                            <div className="cta-text">
                                <h4>Email</h4>
                                <span>office@simmple.ro</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <a href="index.html"><img src={logo} className="img-fluid" alt="logo" /></a>
                            </div>
                            <div className="footer-social-icon">
                                <span>Social media</span>
                                <a href="#"><i className="fa fa-instagram" style={{background: "pink"}}></i></a>
                                <a href="#"><i className="fa fa-linkedin" style={{background: "#003172"}}></i></a>
                                <a href="#"><i className="fa fa-facebook-f facebook-bg"></i></a>
                                <a href="#"><i className="fa fa-twitter twitter-bg"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Link-uri utile</h3>
                            </div>
                            <ul>
                                <li><a href="/">Acasă</a></li>
                                <li><a href="/terms_and_conditions">Termeni şi condiţii</a></li>
                                <li><a href="https://www.anpc.ro">ANPC</a></li>
                                <li><a href="/security_policy">Politică de confidenţialitate</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                          <div className="mapouter" style={{position: "relative", textAlign: "left", height: "300px", width: "300px"}}>
                            <div className="gmap_canvas" style={{overflow: "hidden", background: "none!important", height: "300px", width: "300px"}}>
                              <iframe src="https://maps.google.com/maps?q=strada%20valcele%2015%20focsani%20vrancea&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed" id="gmap_canvas" style={{width: "300px", height: "300px"}}></iframe>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
            <InstallButton />
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="text-center text-lg-left">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2023, <a href="https://www.simmple.ro">sIMMple.ro este proprietatea ZENTIC SRL</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
  );
};

export default Footer;
