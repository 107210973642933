import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";
import appscreenshot from "../../../assets/appscreenshot.png";
import leadsss from "../../../assets/leadsss.png";
import contactess from "../../../assets/contactess.png";
import vanzariss from "../../../assets/vanzariss.png";
import React, {useEffect} from "react";
import Loading from "../Components/Loading/Loading";
import useAuth from "../../../hooks/useAuth";
import useFetch from "../../../hooks/useFetch";
import {loadUserData} from "../../../api/user";
import {getAuthFromStorage} from "../../../utils/cache/cache";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import "./Home.css";
import {Navigate} from "react-router-dom";

const Home = () => {

    const auth = useAuth();
    const user = useFetch(loadUserData, {userId: getAuthFromStorage()?.userId});


    useEffect(() => {
        if (auth.isLoggedIn && !user.data) {
            user.sendRequest();
        }
    }, [auth]);

    return (<>
        {user.error?.code === 'ERR_NETWORK' && <Navigate to='/servers_down'/>}
        {user.loading ? (<Loading/>) : ((!user.data || auth.isLoggedIn) && (<>
            <MainNavbar user={user.data}/>

            <Container>
                <Row style={{marginTop: "50px"}}>

                    <Col sm={12} style={{textAlign: "center"}}>
                        <h1 style={{fontFamily: "'Inter', Arial", fontSize: "50px", color: "black"}}>
                            Descoperă transformarea digitală
                        </h1>
                        <p style={{fontFamily: "'Inter', Arial", fontSize: "20px"}}>
                            sIMMple este soluția modernă pentru a-ți revoluționa afacerea
                        </p>
                    </Col>
                    <Col sm={12} style={{textAlign: "center", marginTop: "50px"}}>
                        <div>
                            <img alt={"photo"} src={appscreenshot}
                                 style={{width: "85%", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.6)"}}/>
                        </div>
                    </Col>

                </Row>

                <Row style={{marginTop: "50px", marginBottom: "100px"}} id="functii">

                    <Col sm={12} style={{textAlign: "center"}}>
                        <h1 style={{fontFamily: "'Inter', Arial", fontSize: "50px", color: "black"}}>
                            Explorează suita de funcționalități
                        </h1>
                        <p style={{fontFamily: "'Inter', Arial", fontSize: "20px"}}>
                            Ţine evidenţa clienţilor, a furnizorilor, a produselor şi a comenzilor tale
                            dintr-un singur loc.
                        </p>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial", color: "white"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            background: "#003172",
                            opacity: "100%",
                            borderRadius: "8px 8px 0px 0px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Vânzări</h3>
                        </div>
                        <div style={{textAlign: "center", background: "#003172"}}>
                            <img alt={"photo"} src={vanzariss}
                                 style={{width: "95%", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.6)"}}/>
                        </div>
                        <div style={{
                            width: "100%",
                            height: "250px",
                            padding: "35px",
                            background: "#003172",
                            opacity: "100%",
                            borderRadius: "0px 0px 8px 8px"
                        }}>
                            <p style={{textAlign: "justify"}}>Automatizează procesul de vânzare al firmei şi
                                maximizează veniturile prin aceeaşi echipă</p>
                            <ul style={{listStyleType: "square"}}>
                                <li>da</li>
                                <li></li>
                            </ul>
                        </div>
                    </Col>

                    <Col sm={4} style={{marginTop: "100px", fontFamily: "'Inter', arial", color: "white"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            background: "#003172",
                            opacity: "100%",
                            borderRadius: "8px 8px 0px 0px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Lead-uri</h3>
                        </div>
                        <div style={{textAlign: "center", background: "#003172"}}>
                            <img alt={"photo"} src={leadsss}
                                 style={{width: "95%", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.6)"}}/>
                        </div>
                        <div style={{
                            height: "250px",
                            width: "100%",
                            padding: "35px",
                            background: "#003172",
                            opacity: "100%",
                            borderRadius: "0px 0px 8px 8px"
                        }}>
                            <p style={{textAlign: "justify"}}>Gestionează procesul de transformare a unui
                                lead într-un deal de succes</p>
                            <ul style={{listStyleType: "square"}}>
                                <li>Construieşte contractele după bunul plac</li>
                                <li>Ataşează factură fiscală unui contract</li>
                                <li>Filtrează rezultatele căutării şi generează rapoarte</li>
                            </ul>
                        </div>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial", color: "white"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            background: "#003172",
                            opacity: "100%",
                            borderRadius: "8px 8px 0px 0px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Contacte</h3>
                        </div>
                        <div style={{textAlign: "center", background: "#003172"}}>
                            <img alt={"photo"} src={contactess}
                                 style={{width: "95%", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.6)"}}/>
                        </div>
                        <div style={{
                            height: "250px",
                            width: "100%",
                            padding: "35px",
                            background: "#003172",
                            opacity: "100%",
                            borderRadius: "0px 0px 8px 8px"
                        }}>
                            <p style={{textAlign: "justify"}}>Automatizează procesul de vânzare şi
                                maximizează veniturile prin aceeaşi echipă</p>
                            <ul style={{listStyleType: "square"}}>
                                <li>Gestionează toate companiile unui contact</li>
                                <li></li>
                            </ul>
                        </div>
                    </Col>

                    <div style={{textAlign: "center", marginTop: "40px"}}>
                        <button className="morebutton">Mai multe</button>
                    </div>

                </Row>

                <Row style={{
                    marginTop: "50px", marginBottom: "100px", color: "#003172", fontFamily: "'Inter', arial"
                }}>

                    <Col sm={12} style={{textAlign: "center"}}>
                        <h1 style={{fontFamily: "'Inter', Arial", fontSize: "50px", color: "black"}}>
                            De ce sIMMple?
                        </h1>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            opacity: "100%",
                            border: "2px solid #003172",
                            borderRadius: "8px 8px 8px 8px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Gestionarea relaţiilor cu clienţii</h3>
                        </div>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            opacity: "100%",
                            border: "2px solid #003172",
                            borderRadius: "8px 8px 8px 8px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Analiza Detaliată a Datelor</h3>
                        </div>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            opacity: "100%",
                            border: "2px solid #003172",
                            borderRadius: "8px 8px 8px 8px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Alocarea resurselor materiale şi umane</h3>
                        </div>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            opacity: "100%",
                            border: "2px solid #003172",
                            borderRadius: "8px 8px 8px 8px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Rapoarte personalizate</h3>
                        </div>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            opacity: "100%",
                            border: "2px solid #003172",
                            borderRadius: "8px 8px 8px 8px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Facturare şi contractare</h3>
                        </div>
                    </Col>

                    <Col sm={4} style={{marginTop: "50px", fontFamily: "'Inter', arial"}}>
                        <div style={{
                            width: "100%",
                            padding: "35px",
                            opacity: "100%",
                            border: "2px solid #003172",
                            borderRadius: "8px 8px 8px 8px"
                        }}>
                            <h3 style={{textAlign: "center"}}>Gestionarea încasărilor şi a plăţilor</h3>
                        </div>
                    </Col>

                </Row>

            </Container>

            <Footer/>
        </>))}
    </>);
};

export default Home;
