import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const SecurityPolicy = () =>
{
    return (
        <>
            <MainNavbar />
                <Container>

                    <div style={{minHeight: "100vh", color: "#003172", fontFamily: "'Inter', Arial"}}>
                        <h1 style={{textAlign: 'center', marginTop: '100px'}}>Politica de confidenţialitate</h1>

                        <Row style={{marginTop: "100px"}}>
                            <Col sm={2}></Col>
                            <Col sm={8}>
                                <p>Prelucrarea și confidențialitatea datelor (GDPR)</p>
                                
                                <p>In cele ce urmează vom descrie practicile de colectare a datelor personale pentru
                                    site-ul sIMMple.ro care este deținut și operat de către ZENTIC SRL și colectează
                                    date personale în conformitate cu această politică. Acest document are ca scop
                                    informarea dumneavoastră cu privire la felul în care colectăm datele personale de la
                                    dumneavoastră, cum și unde putem să le utilizăm, cum înțelegem să le protejăm și
                                    cine are acces la ele.</p>

                                <p>Cine suntem?</p>
                                <p>ZENTIC SRL, în calitate de administrator al aplicației sIMMple.ro se angajează să
                                    respecte confidențialitatea și securitatea informațiilor furnizate de către
                                    utilizatori, inclusiv datele personale. ZENTIC SRL este un operator de date cu
                                    caracter personal în sensul Regulamentului UE 2016/679 (GDPR), cu sediul social în
                                    Strada Corp C1, Bloc C1, Ap. Camera 2 Cod Postal 627326, Muncelu, Județ Vrancea, cod
                                    fiscal: 37940774, înregistrat la Registrul Comerțului sub nr. J39/724/2017, telefon
                                    +40 767100000, e-mail office@sIMMple.ro, numit în prezentul document SIMMple.ro.
                                    Detaliile de contact ale operatorului pentru protecția datelor sunt: Stanciu
                                    Leonard, dpo@sIMMple.ro. SIMMple.ro respectă legislația română și europeană în
                                    vigoare în domeniul protecției datelor cu caracter personal și colectează date cu
                                    caracter personal numai în scopuri specifice. Scopul colectării datelor cu caracter
                                    personal este de a vinde serviciile SIMMple.ro (cu toate activitățile aferente, cum
                                    ar fi plată, facturare, etc.), servicii de relații cu clienții și activități de
                                    marketing pentru nevoile proprii ale SIMMple.ro. Datele cu caracter personal sunt
                                    utilizate exclusiv de către SIMMple.ro în scopurile de mai sus și nu pot fi vândute
                                    sau închiriate către terțe părți.</p>

                                <p>Care sunt drepturile legale pentru prelucrarea datelor?</p>
                                <p>
                                    • executarea contractului și etapele anterioare acestui contract (art. 6 (1) b GDPR)
                                    - pentru datele colectate prin formularul de contact / comandă sau datele primite ca
                                    rezultat al serviciilor de relații cu clienții;
                                    • obligații legale (art. 6 (1) c GDPR) - pentru datele necesare pentru emiterea
                                    facturii;
                                    • interesul legitim (art. 6 (1) f GDPR și Legea 506/2004, art. 12 (2)) - pentru
                                    datele utilizate pentru marketing pentru clienții curenți, securitatea propriului
                                    site și datele utilizate pentru optimizarea propriului site;
                                    • consimțământul (art. 6 (1) GDPR) - pentru datele utilizate in scopuri de marketing
                                    pentru vizitatorii aplicatiei.
                                </p>

                                <p>Care sunt datele personale pe care le colectăm și cine are acces la datele dvs.?</p>

                                <p>SIMMple.ro colectează informații de la utilizatorii săi în trei moduri: direct de la
                                    utilizator, din rapoartele de trafic înregistrate de serverele care găzduiesc
                                    site-urile noastre și prin intermediul cookie-urilor.</p>

                                <p>Date personale colectate direct</p>
                                <p>Când accesați SIMMple.ro sau când creați un cont sau plasați o comandă, vă putem
                                    solicita numele de familie, prenumele și / sau adresa de e-mail, precum și alte date
                                    necesare pentru contractare sau facturare. Aceste date sunt păstrate pentru o
                                    perioadă de cel puțin 3 ani de la încheierea contractului (aceasta fiind perioada
                                    generală de prescripție) sau cel puțin 5 ani în cazul facturilor (când există o
                                    obligație fiscală legală). Puteți să ne furnizați, de asemenea, o adresă de e-mail
                                    sau un număr de telefon atunci când accesați suportul. Avem dreptul de a înregistra
                                    orice comunicare făcută pentru serviciile de suport ale serviciilor SIMMple.ro
                                    pentru a îmbunătăți serviciile noastre. Aceste date sunt utilizate, de exemplu,
                                    pentru a vă contacta, pentru a stabili datele necesare pentru contract sau pentru
                                    facturare, pentru a stabili persoana de contact și / sau pentru a rezolva o plângere
                                    / o cerere din partea dumneavoastră. Putem, de asemenea, să solicităm alte date care
                                    pot constitui date personale numai în măsura în care acestea sunt necesare pentru
                                    astfel de scopuri. De asemenea, atunci când vă abonați la newsletter-ul nostru, vă
                                    rugăm să ne furnizați o adresă de e-mail, precum și un nume și alte informații
                                    necesare pentru a personaliza comunicarea dumneavoastră. Aceste date sunt păstrate
                                    până când dumneavoastră sau reprezentantul legal al dumneavoastră revocați
                                    consimțământul sau indicați faptul că nu mai sunteți interesat de comunicarea
                                    noastră. Politicile de prelucrare a datelor personale ale partenerilor noștri sunt
                                    disponibile pe site-urile lor oficiale.</p>

                                <p>Informațiile furnizate automat din raportul de trafic al serverului</p>
                                <p>Când vizitați un site web, indiferent de dispozitivul folosit, dezvăluiți anumite
                                    informații despre dumneavoastră, cum ar fi adresa IP, timpul vizitei, locul în care
                                    ați intrat pe site-urile noastre, pagina pe care ați vizitat-o. SIMMple.ro, ca și
                                    alți operatori, înregistrează aceste informații pentru un anumit interval de timp.
                                    SIMMple.ro utilizează servicii de analiză a traficului extern, cum ar fi Google
                                    Analytics. Aceste date sunt utilizate exclusiv de către SIMMple.ro pentru a
                                    îmbunătăți site-ul și serviciile noastre, dar și pentru a asigura securitatea
                                    aplicatiei noastre. În niciun caz SIMMple.ro nu va utiliza datele de trafic pentru a
                                    identifica persoanele din spatele adreselor IP.</p>

                                <p>Cookie-uri</p>
                                <p>SIMMple.ro poate utiliza cookie-uri pentru a facilita stocarea și urmărirea
                                    preferințelor dumneavoastră, dar și pentru a optimiza site-urile noastre sau pentru
                                    marketing. Mai multe detalii despre politica de utilizare a cookie-urilor gasiți pe
                                    pagina Politică Cookies.</p>

                                <p>Cum stocam datele dvs.?</p>
                                <p>Informațiile, inclusiv datele personale, primite pe serverele noastre vor fi
                                    înregistrate, utilizate, stocate și organizate pe serverele noastre protejate, unde
                                    este găzduit site-ul în sine, precum și pe orice alt mediu care permite astfel de
                                    operațiuni, în condiții de siguranță, optim și prin mijloacele adecvate. Pentru
                                    găzduirea sigură a datelor personale, folosim serviciile unor companii specializate
                                    din Uniunea Europeană, care acționează ca persoane autorizate de către
                                    SIMMple.ro.</p>

                                <p>Cum protejăm datele pe care le colectăm?</p>
                                <p>Confidențialitatea și protecția informațiilor pe care le colectăm de la tine sunt de
                                    mare importanță pentru noi. Accesul tău la serviciile și produsele SIMMple.ro este
                                    protejat de parola contului tău. Recomandăm să nu divulgi acest parolă nimănui.
                                    SIMMple.ro nu te va întreba niciodată pentru parola contului tău în mesajele
                                    nesolicitate sau în apelurile telefonice. De asemenea, te sfătuim să închizi
                                    fereastra browser-ului în care ai lucrat la finalul navigării pe site-urile sau
                                    serviciile oferite de SIMMple.ro. Aceste sfaturi au ca scop înlăturarea accesului
                                    neautorizat la informațiile personale despre tine atunci când alții pot avea acces
                                    la dispozitivul tău. Când primim informații de la tine, vom face tot posibilul
                                    pentru a asigura securitatea acestora în sistemele noastre prin implementarea de
                                    măsuri pentru prevenirea accesului neautorizat, abuzului, alterării, distrugerii
                                    ilegale sau accidentale.</p>

                                <p>Cum putem corecta erorile din datele pe care le colectăm de la tine?</p>
                                <p>Pentru orice erori legate de datele pe care le introduceți, vă rugăm să ne contactați
                                    la dpo@sIMMple.ro.</p>

                                <p>Drepturile tale</p>
                                <p>Potrivit reglementărilor GDPR, ai dreptul de a accesa, rectifica, șterge,
                                    restricționa prelucrarea sau dreptul de a te opune prelucrarii, precum și dreptul la
                                    portabilitatea datelor personale. Pentru datele colectate prin consimțământ, ai
                                    dreptul de a-ți retrage consimțământul oricând. Pentru a exercita aceste drepturi,
                                    poți trimite o cerere prin e-mail la dpo@sIMMple.ro.</p>

                                <p>Cui îi este permis să acceseze informațiile tale?</p>
                                <p>SIMMple.ro poate transfera aceste date către terțe părți pentru scopurile menționate
                                    mai sus, cum ar fi:
                                •	procesator de plăți
                                •	serviciul de livrare
                                •	societatea de contabilitate
                                •	analiza traficului / marketing (Google Analytics).
                                Aceste companii au obligația de a utiliza aceste date în condiții de siguranță și exclusiv pentru scopurile specificate de SIMMple.ro. În cazul unor circumstanțe speciale, SIMMple.ro poate divulga datele personale atunci când este expres prevăzut de lege, când este solicitat de o autoritate competentă sau când acest lucru este necesar pentru a apăra drepturile și interesele SIMMple.ro.
                                </p>

                                <p>Politica privind prelucrarea datelor personale trebuie interpretată împreună cu
                                    Termenii și condițiile de utilizare SIMMple.ro și sunt supuși legilor în vigoare din
                                    România. Această versiune a Politicii de prelucrare a datelor personale este
                                    disponibilă la https://sIMMple.ro/security_policy.</p>

                                <p>Cum ne poți contacta?</p>
                                <p>Dacă ai întrebări cu privire la această politică sau cu privire la informațiile pe
                                    care le colectăm, te rugăm să ne contactezi la adresa de email: dpo@sIMMple.ro sau
                                    la numarul de telefon: +40767100000</p>

                                Ultima modificare efectuată in 01.01.2023

                            </Col>
                            <Col sm={2}></Col>
                        </Row>
                    </div>

                </Container>
            <Footer />
        </>
    );
}

export default SecurityPolicy;