import {loggedInHeaders} from "../apiUtils";
import {get, post, put} from "../requests";

export const loadCrmCompanies = async (params) => {
    try {
        const url = `/api/crm_companies/owner/`;
        let data = await (await get(url, loggedInHeaders())).data;

        data = data?.filter((i) => i.enabled && params?.args.companyId === i.company);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const createCrmCompany = async (params) => {
    try {
        const data = params.args?.data;
        const headers = loggedInHeaders();

        if (!data) return;

        const res = await (await post("/api/crm_companies/", data, headers)).data;

        params.setData({res});
        return res;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const editCrmCompany = async (params) => {
    try {
        const url = `/api/crm_companies/${params.args?.crmCompanyId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getCrmCompany = async (params) => {
    try {
        const url = `/api/crm_companies/${params.args?.crmCompanyId}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
