import React, {useMemo} from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const StatsSection = ({overdue, sent, draft}) => {

    return (<>
        <Row style={{justifyContent: 'left', margin: '20px 0px'}}>
            <Col>
                <Row style={{
                    borderRadius: '0px',
                    padding: '25px',
                    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
                    background: 'white'
                }}>

                    <Col sm={4} style={{textAlign: "center"}}>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", fontSize: "40px", color: "#003172", marginBottom: "0px",
                            }}
                        >
                            {overdue.toFixed(2)}{" "}RON
                        </p>
                        <p
                            style={{
                                fontFamily: "'Inter', arial",
                                fontSize: "25px",
                                color: "#003172",
                                borderTop: "2px dashed gray",
                                paddingTop: "15px",
                            }}
                        >
                            Peste termen
                        </p>
                    </Col>
                    <Col sm={4} style={{textAlign: "center"}}>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", fontSize: "40px", color: "#003172", marginBottom: "0px",
                            }}
                        >
                            {sent.toFixed(2)}{" "}RON
                        </p>
                        <p
                            style={{
                                fontFamily: "'Inter', arial",
                                fontSize: "25px",
                                color: "#003172",
                                borderTop: "2px dashed gray",
                                paddingTop: "15px",
                            }}
                        >
                            În termen
                        </p>
                    </Col>
                    <Col sm={4} style={{textAlign: "center"}}>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", fontSize: "40px", color: "#003172", marginBottom: "0px",
                            }}
                        >
                            {draft.toFixed(2)}{" "}RON
                        </p>
                        <p
                            style={{
                                fontFamily: "'Inter', arial",
                                fontSize: "25px",
                                color: "#003172",
                                borderTop: "2px dashed gray",
                                paddingTop: "15px",
                            }}
                        >
                            Încasate
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>);
};

export default StatsSection;
