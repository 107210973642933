//income outcome
export const dailyCashflow = (
  incomeData,
  outcomeData,
  interval = [],
  status = undefined
) => {
  const incomeDict = {},
    outcomeDict = {};
  const cashflowArray = [];

  if (interval === [] || incomeData === undefined || outcomeData === undefined)
    return cashflowArray;

  //filter data
  const income = incomeData.filter((i) => {
    if (status === undefined) return true;
    return i.status === status;
  });

  const outcome = outcomeData.filter((i) => {
    if (status === undefined) return true;
    return i.status === status;
  });

  income.forEach((i) => {
    const index = i.invoiced_at.substr(0, 10);
    if (!incomeDict[index]) {
      incomeDict[index] = {
        amount: i?.amount,
        counter: 1,
      };
    } else {
      incomeDict[index].amount += i?.amount;
      incomeDict[index].counter++;
    }
  });
  outcome.forEach((i) => {
    const index = i.invoiced_at.substr(0, 10);
    //console.log(index);
    if (!outcomeDict[index]) {
      outcomeDict[index] = {
        amount: i?.amount,
        counter: 1,
      };
    } else {
      outcomeDict[index].amount += i?.amount;
      outcomeDict[index].counter++;
    }
  });

  interval.forEach((intervalDate) => {
    const date = intervalDate.substr(0, 10);
    //console.log(date, incomeDict[date], outcomeDict[date]);
    if (!incomeDict[date]) incomeDict[date] = { amount: 0, counter: 0 };
    if (!outcomeDict[date]) outcomeDict[date] = { amount: 0, counter: 0 };

    cashflowArray.push({
      date: new Date(date).getTime(),
      totalintrari: incomeDict[date].amount,
      totaliesiri: outcomeDict[date].amount,
      profit: incomeDict[date].amount - outcomeDict[date].amount,
    });
  });

  return cashflowArray;
};

export const dailyCashflow2 = (
    incomeData,
    outcomeData,
    interval = [],
    status = undefined
) => {
  const incomeDict = {},
      outcomeDict = {};
  const cashflowArray = [];

  if (interval === [] || incomeData === undefined || outcomeData === undefined)
    return cashflowArray;

  //filter data
  const income = incomeData.filter((i) => {
    if (status === undefined) return true;
    return i.status === status;
  });

  const outcome = outcomeData.filter((i) => {
    if (status === undefined) return true;
    return i.status === status;
  });

  income.forEach((i) => {
    const index = i.invoiced_at.substr(0, 10);
    if (!incomeDict[index]) {
      incomeDict[index] = {
        amount: i?.amount,
        counter: 1,
      };
    } else {
      incomeDict[index].amount += i?.amount;
      incomeDict[index].counter++;
    }
  });
  outcome.forEach((i) => {
    const index = i.billed_at.substr(0, 10);
    //console.log(index);
    if (!outcomeDict[index]) {
      outcomeDict[index] = {
        amount: i?.amount * (-1),
        counter: 1,
      };
    } else {
      outcomeDict[index].amount -= i?.amount;
      outcomeDict[index].counter++;
    }
  });

  interval.forEach((intervalDate) => {
    const date = intervalDate.substr(0, 10);
    //console.log(date, incomeDict[date], outcomeDict[date]);
    if (!incomeDict[date]) incomeDict[date] = { amount: 0, counter: 0 };
    if (!outcomeDict[date]) outcomeDict[date] = { amount: 0, counter: 0 };

    cashflowArray.push({
      date: new Date(date).getTime(),
      totalintrari: incomeDict[date].amount,
      totaliesiri: outcomeDict[date].amount,
      profit: incomeDict[date].amount - outcomeDict[date].amount,
    });
  });

  return cashflowArray;
};

export const constructInterval = (start, end) => {
  const interval = [];
  const startDate = new Date(start);
  const endDate = new Date(end);

  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    interval.push(currentDate.toISOString().substr(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return interval;
};
