import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
    useNavigate, useOutletContext, useParams,
} from "react-router-dom";
import Loading from "../../../MainPages/Components/Loading/Loading";
import useFetch from "../../../../hooks/useFetch";
import ErrorField, {validateForm} from "../../../../utils/functions/validation";
import {createHR, editHR} from "../../../../api/hr";
import {copyButExclude, formatDateTime} from "../../../../utils/utils";
import {REGEXP} from "../../../../utils/constants";
import DisableObject from "../../../../components/Modals/DisableObject";

function NewHR({viewPage = false}) {
    const {companyId} = useParams();
    const navigate = useNavigate();

    const outletContext = useOutletContext();
    const hr = outletContext?.hr ? outletContext?.hr : undefined;

    const initialState = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        pozitie: "",
        salariu_brut: "",
        data_incepere: "",
        data_plecare: null,
        enabled: 1,
        notes: [],
        company: companyId,
    };

    const [form, setForm] = useState(hr ? {
        first_name: hr.first_name,
        last_name: hr.last_name,
        phone: hr.phone,
        email: hr.email,
        pozitie: hr.pozitie,
        salariu_brut: hr.salariu_brut,
        data_incepere: hr.data_incepere,
        data_plecare: hr.data_plecare,
        enabled: 1,
        notes: JSON.parse(hr?.notes === '' ? '[]' : hr.notes),
        company: companyId,
    } : initialState);

    const createHRState = useFetch(createHR, {});
    const editHRState = useFetch(editHR, {});
    const [errors, setErrors] = useState({});

    const [newNote, setNewNote] = useState('');
    const [notes, setNotes] = useState(hr?.notes ? JSON.parse(hr?.notes) : []);

    const removeNote = (index) => {
        const updated = notes.filter((c, i) => i !== index);
        setNotes(updated);
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    };

    const saveEmployee = () => {
        const validatedData = validateForm(initialState, copyButExclude(form, ['notes', 'company', 'data_incepere', 'data_plecare', 'enabled']), {
            email: REGEXP.EMAIL, phone: REGEXP.PHONE, salariu_brut: REGEXP.INTEGER_ABOVE_ZERO_OR_EQUAL
        });
        if (Object.entries(validatedData).length > 0) {
            setErrors(validatedData);
            return;
        }
        setErrors({});

        if (hr) {
            editHRState.sendRequest({hrId: hr.id, data: {...form, notes: JSON.stringify(notes)}}).then(() => {
                navigate(-1);
            })
        } else {
            createHRState.sendRequest({data: {...form, notes: JSON.stringify(notes)}}).then(() => {
                navigate(-1);
            })
        }
    };

    const disableHr = () => {
        editHRState.sendRequest({hrId: hr.id, data: {...hr, enabled: 0}})
            .then(() => {
                navigate('../../');
            });
    };

    return (<>
        {createHRState.loading || editHRState.loading ? (<Loading/>) : (<Col>
            <div>
                <Container>
                    <h2
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                        }}
                    >
                        Angajat Nou
                    </h2>

                    <Row className="formsection">
                        <Col sm={6}>
                            <Form.Group

                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Nume</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="first_name"
                                    type="text"
                                    placeholder="Introdu numele"
                                    value={form.first_name}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['first_name']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Prenume</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="last_name"
                                    type="text"
                                    placeholder="Introdu prenumele"
                                    value={form.last_name}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['last_name']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    value={form.email}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['email']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Telefon</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="phone"
                                    type="text"
                                    placeholder="Telefon"
                                    value={form.phone}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['phone']}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Pozitie</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="pozitie"
                                    type="text"
                                    placeholder="Introdu pozitia"
                                    value={form.pozitie}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['pozitie']}/>
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Salariu brut</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="salariu_brut"
                                    type="number"
                                    placeholder="Salariu brut"
                                    value={form.salariu_brut}
                                    onChange={handleInputChange}
                                />
                                <ErrorField enabled={errors['salariu_brut']}/>
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Data incepere</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="data_incepere"
                                    type="date"
                                    value={form.data_incepere}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Data plecare</Form.Label>
                                <Form.Control
                                    disabled={viewPage}
                                    className="forms"
                                    required
                                    name="data_plecare"
                                    type="date"
                                    value={form.data_plecare}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row className="formsection">
                        <h3
                            style={{
                                fontSize: "20px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Notiţe
                        </h3>

                        <p
                            style={{
                                fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                            }}
                        >
                            Istoricul interacţiunilor cu angajatul
                        </p>

                        <hr></hr>

                        <Col sm={12}>
                            <Form.Group
                                style={{
                                    fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
                                }}
                            >
                                <Form.Label>Conţinut</Form.Label>
                                <Form.Control disabled={viewPage}
                                              as="textarea" name="content" rows={3} value={newNote}
                                              onChange={e => setNewNote(e.target.value)}/>

                                <Button disabled={viewPage}
                                        style={{
                                            marginTop: "20px",
                                            background: "#003172",
                                            height: "36px",
                                            borderRadius: '8px',
                                            border: 'none',
                                            color: 'white',
                                            padding: "0px 10px 0px 10px"
                                        }} onClick={() => {
                                    if (newNote) {
                                        setNotes([{date: formatDateTime(new Date()), content: newNote}, ...notes]);
                                        setNewNote('');
                                    }
                                }}>Adaugă notiţa</Button>

                                {notes?.length !== 0 ? <hr></hr> : <></>}

                                {notes.map((note, index) => {
                                    return (<div style={{
                                        marginTop: "20px", borderLeft: "3px solid #003172", paddingLeft: "20px"
                                    }}>
                                        <div>
                                            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span
                                                        style={{
                                                            color: "#003172", fontSize: "20px"
                                                        }}>[{note.date}]</span>
                                                <Button style={{
                                                    background: "#003172",
                                                    height: "36px",
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                    color: 'white',
                                                    padding: "0px 10px 0px 10px"
                                                }} onClick={() => removeNote(index)}>
                                                    Şterge
                                                </Button>
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                {note.content}
                                            </p>
                                        </div>
                                    </div>);
                                })}
                            </Form.Group>
                        </Col>
                    </Row>

                    <div
                        style={{
                            textAlign: "right", marginTop: "50px", height: "100px",
                        }}
                    >
                        <Button
                            onClick={() => navigate(-1)}
                            style={{
                                margin: "5px",
                                fontFamily: "'Inter', arial",
                                background: "none",
                                border: "none",
                                boxShadow: "none !important",
                                color: "gray",
                            }}
                        >
                            Inapoi
                        </Button>
                        <Button
                            hidden={!viewPage}
                            style={{
                                margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                            }}
                            onClick={() => navigate('../edit')}
                        >Editeaza
                        </Button>

                        {viewPage && <DisableObject name='angajatul' disableFunction={disableHr}/>}

                        <Button
                            hidden={viewPage}
                            style={{
                                margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                            }}
                            onClick={saveEmployee}
                        >{hr ? 'Actualizează' : 'Salvează'}
                        </Button>
                    </div>

                </Container>
            </div>
        </Col>)}
    </>);
}

export default NewHR;
