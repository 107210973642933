import React, {useEffect, useState} from 'react';
import {Page, Text, View, Document, StyleSheet, Font, Image} from '@react-pdf/renderer';

import M from "../../static/M.png";
import {useOutletContext, useParams} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {getCompanyDataById} from "../../api/companies";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {getInvoice, loadInvoiceItems} from "../../api/invoices";
import {getBill} from "../../api/bills";

Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

const styles = StyleSheet.create({
    header: {
        backgroundColor: "#f6f7f2",
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        padding: "20 5% 20 5%",
        marginBottom: 30,
        flexDirection: 'row'
    }, footer: {
        fontFamily: 'Roboto', padding: 30,
    }, middle: {
        fontFamily: 'Roboto', padding: '0% 5% 0% 5%', width: '100%',
    }, headerSection: {
        verticalAlign: 'middle', flexGrow: 1, width: '50%',
    }, logo: {
        alignSelf: 'right', width: 100,
    }, content: {
        width: '100%',
    }, contact_information: {
        marginBottom: 30, flexDirection: 'row'
    }, issuer_information: {
        marginBottom: 30, flexDirection: 'row'
    }, contact: {
        lineHeight: '1.4px', width: '50%',
    }, issuer: {
        lineHeight: '1.4px', width: '50%',
    }, invoice_data: {
        lineHeight: '1.4px', width: '50%', flexGrow: 1, textAlign: 'right',
    }, table_header: {
        borderBottom: '1px solid gray', backgroundColor: "#f6f7f2", flexDirection: 'row', fontSize: '12px',
    }, table_content: {
        fontSize: '12px', backgroundColor: "#fff",
    }, table_row: {
        flexDirection: 'row',
    }, table_cell1: {
        padding: '10px 5px 10px 5px', height: '20px', width: '20%',
    }, table_cell2: {
        textAlign: 'center', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell3: {
        textAlign: 'center', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell4: {
        textAlign: 'center', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell5: {
        textAlign: 'right', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell6: {
        textAlign: 'right', padding: '10px 5px 10px 5px', width: '20%',
    }, table_footer: {
        borderTop: '1px solid gray',
        padding: '10px 5px 10px 5px',
        flexDirection: 'row',
        fontSize: '12px',
        backgroundColor: "#f6f7f2",
    }
});

const BillPDF = ({id, name, client, total, due_at, billed_at, company}) => {
    const companyData = useFetch(getCompanyDataById, {});
    const billData = useFetch(getBill, {billId: id});

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (company?.id === undefined) return;
        companyData.sendRequest({companyId: company.id});
    }, [company]);

    useEffect(() => {
        if (id === undefined) return;
        billData.sendRequest().then((response) => {
            setItems(response?.billItemsList);
        });
    }, [id]);

    return (<Document>
        {(companyData?.loading || billData?.loading) && <Loading/>}
        <Page size="A4">

            <View style={styles.header}>
                <View style={styles.headerSection}>
                    <Text>FACTURĂ FISCALĂ</Text>
                    <Text style={{fontSize: "15px"}}>{name}</Text>
                </View>
                <View style={{flexDirection: 'row-reverse', ...styles.headerSection}}>
                    <Image src={M} style={styles.logo}/>
                </View>
            </View>

            <View style={styles.middle}>

                <View style={styles.contact_information}>

                    <View style={styles.contact}>
                        <Text>EMISĂ CĂTRE</Text>
                        <Text style={{fontSize: "15"}}>NUME: {company?.domain}</Text>
                        <Text style={{fontSize: "15"}}>EMAIL: {companyData.data?.email}</Text>
                        <Text
                            style={{fontSize: "15"}}>ADRESĂ: {company?.address}</Text></View>

                    <View style={styles.invoice_data}>
                        <Text>Data emiterii: {billed_at}</Text>
                        <Text>Data scadentă: {due_at}</Text>
                    </View>

                </View>

                <View style={styles.issuer_information}>

                    <View style={styles.issuer}>
                        <Text>EMISĂ DE</Text>
                        <Text style={{fontSize: "15"}}>NUME: {client?.company_name}</Text>
                        <Text style={{fontSize: "15"}}>EMAIL: {client?.email}</Text>
                        <Text
                            style={{fontSize: "15"}}>ADRESĂ: {JSON.parse(client?.address ?? '{}')?.city !== undefined ? (JSON.parse(client?.address ?? '{}')?.city + ", " + JSON.parse(client?.address ?? '{}')?.country) : 'N/A'}</Text>
                    </View>

                </View>

                <View style={styles.content}>

                    <View style={styles.table_header}>
                        <Text style={styles.table_cell1}>NUME</Text>
                        <Text style={styles.table_cell2}>DESCRIERE</Text>
                        <Text style={styles.table_cell3}>COST</Text>
                        <Text style={styles.table_cell4}>CANTITATE</Text>
                        <Text style={styles.table_cell4}>TAXE</Text>
                        <Text style={styles.table_cell6}>TOTAL</Text>
                    </View>

                    <View style={styles.table_content}>
                        {items?.map((item, key) => <View break style={styles.table_row} key={key}>
                            <Text style={styles.table_cell1}>{item?.name}</Text>
                            <Text style={styles.table_cell2}>{item?.description}</Text>
                            <Text style={styles.table_cell3}>{item?.price} RON</Text>
                            <Text style={styles.table_cell4}>{item?.quantity}</Text>
                            <Text style={styles.table_cell4}>{item?.tax_rate}%</Text>
                            <Text style={styles.table_cell6}>{item?.total} RON</Text>
                        </View>)}
                    </View>

                    <View style={styles.table_footer}>
                        <Text style={{width: '50%'}}>Total cumulat:</Text>
                        <Text style={{textAlign: 'right', fontSize: '12px', width: '50%'}}>{total} RON</Text>
                    </View>

                </View>

            </View>
        </Page>
    </Document>);
}

export default BillPDF;