import "reactjs-popup/dist/index.css";
import useFetch from "../../hooks/useFetch";
import {Outlet, useParams} from "react-router-dom";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {Navigate} from "react-router-dom";
import {getHR} from "../../api/hr";

const HRValidator = () => {
    const {hrId} = useParams();
    const hrState = useFetch(getHR, {hrId: hrId}, true);

    return (<>
        {hrState.loading && <Loading/>}
        {hrState.error && (<Navigate
            to={hrState.error?.status === 403 ? "/logout" : "/dashboard"}
        />)}
        {hrState.data && hrState.data?.enabled && (<>
            <Outlet context={{hr: hrState.data}}/>
        </>)}
    </>);
};

export default HRValidator;
