import React, {useEffect, useState} from 'react';
import {Page, Text, View, Document, StyleSheet, Font, Image, Link} from '@react-pdf/renderer';

import M from "../../static/M.png";
import useFetch from "../../hooks/useFetch";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {loadUserData} from "../../api/user";
import {getAuthFromStorage} from "../../utils/cache/cache";
import jwtDecode from "jwt-decode";

Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

const styles = StyleSheet.create({
    header: {
        backgroundColor: "#f6f7f2",
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        padding: "20 5% 20 5%",
        marginBottom: 30,
        flexDirection: 'row'
    }, footer: {
        fontFamily: 'Roboto', padding: 30,
    }, middle: {
        fontFamily: 'Roboto', padding: '0% 5% 0% 5%', width: '100%',
    }, headerSection: {
        verticalAlign: 'middle', flexGrow: 1, width: '50%',
    }, logo: {
        alignSelf: 'right', width: 100,
    }, content: {
        width: '100%',
    }, contact_information: {
        marginBottom: 30, flexDirection: 'row'
    }, issuer_information: {
        marginBottom: 30, flexDirection: 'row'
    }, contact: {
        lineHeight: '1.4px', width: '50%',
    }, issuer: {
        lineHeight: '1.4px', width: '50%',
    }, invoice_data: {
        lineHeight: '1.4px', width: '50%', flexGrow: 1, textAlign: 'right',
    }, table_header: {
        borderBottom: '1px solid gray', backgroundColor: "#f6f7f2", flexDirection: 'row', fontSize: '12px',
    }, table_content: {
        fontSize: '12px', backgroundColor: "#fff",
    }, table_row: {
        flexDirection: 'row',
    }, table_cell1: {
        padding: '10px 5px 10px 5px', height: '20px', width: '20%',
    }, table_cell2: {
        textAlign: 'center', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell3: {
        textAlign: 'center', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell4: {
        textAlign: 'center', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell5: {
        textAlign: 'right', padding: '10px 5px 10px 5px', width: '20%',
    }, table_cell6: {
        textAlign: 'right', padding: '10px 5px 10px 5px', width: '20%',
    }, table_footer: {
        borderTop: '1px solid gray',
        padding: '10px 5px 10px 5px',
        flexDirection: 'row',
        fontSize: '12px',
        backgroundColor: "#f6f7f2",
    }
});

const DealPDF = ({deal, contact, company, invoices, invoicesList}) => {
    const userId = jwtDecode(getAuthFromStorage()?.token).user_id;

    const loadUserState = useFetch(loadUserData, {});

    const [clauses, setClauses] = useState(deal?.clause ? JSON.parse(deal?.clause) : []);

    useEffect(() => {
        loadUserState.sendRequest({userId: userId});
    }, [userId]);

    return (<>
        <Document>
            {(loadUserState?.loading) && <Loading/>}
            <Page size="A4">
                <View style={styles.header}>
                    <View style={styles.headerSection}>
                        <Text>CONTRACT</Text>
                        <Text style={{fontSize: "15px"}}>{deal?.name}</Text>
                    </View>
                    <View style={{flexDirection: 'row-reverse', ...styles.headerSection}}>
                        <Image src={M} style={styles.logo}/>
                    </View>
                </View>

                <View style={styles.middle}>

                    <View style={styles.contact_information}>

                        <View style={styles.contact}>
                            <Text>CONTRACTOR:</Text>
                            <Text style={{fontSize: "15"}}>NUME: {deal?.contractor}</Text>
                            <Text style={{fontSize: "15"}}>REPREZENTAT DE: {loadUserState.data?.name}</Text>
                        </View>
                        <View style={styles.invoice_data}>
                            <Text>Status: {deal?.status}</Text>
                            {(deal?.status === 'CASTIGAT' || deal?.status === 'PIERDUT') ?
                                <Text>Data inchieierii: {deal?.date}</Text> :
                                <Text>Ultima actualizare: {deal?.updated_at?.substring(0, 10)}</Text>}
                            <Text>Valoare: {deal?.value ?? '0'} RON</Text>
                        </View>

                    </View>

                    <View style={styles.issuer_information}>
                        <View style={styles.issuer}>
                            <Text>CONTRACTAT:</Text>
                            <Text style={{fontSize: "15"}}>NUME: {company?.company_name}</Text>
                            <Text style={{fontSize: "15"}}>REPREZENTAT DE: {contact?.name}</Text>
                        </View>
                    </View>

                    <View style={styles.issuer_information}>
                        <View style={styles.issuer}>
                            <Text>FACTURI ATAȘATE:</Text>
                        </View>
                    </View>

                    <View style={styles.content}>

                        <View style={styles.table_header}>
                            <Text style={styles.table_cell1}>NUMAR</Text>
                            <Text style={styles.table_cell2}>TITLU</Text>
                            <Text style={styles.table_cell3}>VALOARE</Text>
                            <Text style={styles.table_cell4}>STATUS</Text>
                        </View>
                        <View>
                            <View style={styles.table_content}>

                                {invoicesList?.map((invoice, index) => <View style={styles.table_row} key={index}>
                                    <Text style={styles.table_cell1}>{invoices[invoice?.id]?.invoice_number}</Text>
                                    <Text style={styles.table_cell2}>{invoices[invoice?.id]?.title}</Text>
                                    <Text
                                        style={styles.table_cell3}>{invoice?.invoiceValue} RON</Text>
                                    <Text style={styles.table_cell4}>{invoice?.status=== 'waiting' ? 'În aşteptare' : 'Achitată'}</Text>
                                </View>)}
                            </View>
                        </View>
                    </View>

                    <View>
                        <Text>CLAUZE:</Text>
                        {clauses?.map((c, i) => (<div key={i}>
                            <Text style={{fontSize: "20", fontWeight: 'bold'}}>{c.title}</Text>
                            <Text style={{fontSize: "15"}}>{c.content}</Text>
                        </div>))}
                    </View>


                </View>
            </Page>
        </Document>
    </>);
}

export default DealPDF;