import {loggedInHeaders, MediaHeaders} from "./apiUtils";
import {get, post, put} from "./requests";

export const uploadCompanyPhoto = async (params) => {
    try {
        const data = params.args?.data;
        const headers = MediaHeaders();

        if (!data) return;

        const res = await (await post("/api/photos_company/", data, headers)).data;

        params.setData({res});
        return res;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getCompanyPhoto = async (params) => {
    try {
        const url = `/api/photos_company/${params.args?.companyId}/`;
        const data = await (await get(url, MediaHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};