import React from "react";

import {useState} from "react";

import {TbLayoutList, TbLayoutGrid} from "react-icons/tb";
import {ORDER, SORT_BY} from "../../../../../../../../../utils/constants";

import {Button} from "react-bootstrap";

import SearchBar from "../../../../../../../../../components/Filters/SearchBar";
import InvoiceFilter from "../../../../../../../../../components/Filters/InvoiceFilter";
import {saveView} from "../../../../../../../../../utils/cache/functions";


const FilterSection = ({type, view, set, objectsParam, setObjects, contacts, categories}) => {

    const [order, setOrder] = useState(ORDER.DESC);
    const [sortBy, setSortBy] = useState(SORT_BY.CREATED_AT);

    return (<>

        <div style={{
            display: "flex",
            margin: '20px 0px',
            padding: '25px',
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
            background: 'white',
            verticalAlign: "middle"
        }}>
            <SearchBar objectsArray={objectsParam} setNewArray={setObjects}/>
            <Button onClick={() => view === "Tabel" ? set(saveView("Carduri")) : set(saveView("Tabel"))} style={{
                marginLeft: "5px",
                fontFamily: "'Inter', arial",
                background: "white",
                border: "1px solid lightgray",
                color: "#003172",
                width: "60px",
                height: "50px",
            }}>{view === "Tabel" ? <TbLayoutList size={30}/> : <TbLayoutGrid size={30}/>}</Button>

            <InvoiceFilter type={type} invoices={objectsParam} setInvoices={setObjects} categories={categories}
                           contacts={contacts}/>
        </div>

    </>);
};

export default FilterSection;
