import React from "react";
import {
    PieChart, Pie, Cell, Tooltip, Label, LabelList, Legend,
} from "recharts";

import {ResponsiveContainer} from "recharts";
import {
    generateAndPushUniqueColors, mapByCategory,
} from "../../../../../utils/functions/graphs";
import {listToDict} from "../../../../../utils/functions";

const ExpensesGraph = ({bills, categories}) => {
    const mapByCat = () => {
        const dict = {};
        bills?.forEach(b => {
            if (dict[b.category]) dict[b.category] += b.amount; else dict[b.category] = b.amount;
        });
        return dict;
    };

    const values = mapByCat();
    const data = [];

    categories?.forEach((cat) => {
        if (values[cat.id] > 0) {
            data.push({name: cat.name, color: cat?.color, value: values[cat.id]});
        }
    });
    if (values[null] > 0) data.push({name: 'Fara categorie', value: values['null']});

    const COLORS = ["#FF5733", "#FFC300", "#36A2EB", "#33FF99", "#FF3366", "#FF9900", "#9933FF", "#66FF33", "#FFCC00", "#00CCFF", "#FF66B2", "#99FF33", "#FF9933", "#CC33FF", "#FF3300",];

    return (<div style={{textAlign: "center"}}>
            <ResponsiveContainer width="100%" height={400}>
                {data.length === 0 ? (<div>Nu sunt cheltuieli de afişat</div>) : (
                    <PieChart style={{background: "none"}}>
                        <Pie
                            data={data}
                            innerRadius={0}
                            outerRadius="100%"
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                        >
                            {data.map((entry, index) => (<Cell
                                    key={`cell-${index}`}
                                    fill={entry.color === '' ? COLORS[index % COLORS.length] : entry.color}
                                />))}
                            <LabelList dataKey="name" position="inside"/>
                        </Pie>
                        <Tooltip/>
                        <Label value="da"/>
                        <Legend margin={{right: 40}} verticalAlign={"top"} align={"right"} layout={"vertical"}/>
                    </PieChart>)}
            </ResponsiveContainer>
        </div>);
};

export default ExpensesGraph;
