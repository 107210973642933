import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AiOutlineUserAdd } from 'react-icons/ai';

import { BsReceiptCutoff } from 'react-icons/bs';

import CustomersButton from '../../Sales/Customers/CustomersList/Components/NoCustomers/CustomersButton';

import "./PurchasesMenu.css";
import { useParams, Outlet, useNavigate } from 'react-router-dom';

function PurchasesMenu() {

    const nav = useNavigate();
    const { companyId } = useParams();

    return (
        <Container>
            <Row>
                <Col className="align-self-center">
                    <h1 style={{
                        fontFamily: "'Inter', arial",
                        color: "#003172",
                        marginTop: "30px",
                    }}>Achiziţii</h1>
                </Col>
                <div style={{display: "flex"}} className="crmsections">
                    {/*<button onClick={() => nav(`/${companyId}/purchases/`)} className="crmsection">Acasă</button>*/}
                    <button onClick={() => nav(`/${companyId}/purchases/vendors`)} className="crmsection">Furnizori</button>
                    <button onClick={() => nav(`/${companyId}/purchases/bills`)} className="crmsection">Facturi</button>
                </div>
            </Row>

            <Outlet />

        </Container>
    );
}

export default PurchasesMenu;