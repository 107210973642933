import React from "react";
import {Link, useNavigate} from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {AiOutlineEdit, AiOutlineCopy} from "react-icons/ai";
import DisableObject from "../../../../../../../../components/Modals/DisableObject";
import useFetch from "../../../../../../../../hooks/useFetch";
import {editInvoice} from "../../../../../../../../api/invoices";

import InvoiceCard from "./InvoiceCard";

const InvoiceComponent = ({invoice, customer, refresh}) => {
    const nav = useNavigate();
    const editInvoiceStatus = useFetch(editInvoice, {});

    const disableInvoice = () => {
        editInvoiceStatus
            .sendRequest({
                invoice: {...invoice, enabled: 0},
                oldInvoiceItems: [],
                currentInvoiceItems: [],
                invoiceItemsId: undefined,
            })
            .then(() => {
                refresh();
            });
    };

    return (<>
        <Col sm={3} onClick={() => nav(`${invoice.id}/view`)}>
            <InvoiceCard
                customer={customer}
                invoiceId={invoice.id}
                name={invoice.title || invoice.invoice_number}
                invoiced_at={invoice.invoiced_at}
                due_at={invoice.due_at}
                total={invoice.amount}
                invoice_number={invoice.invoice_number}
                status={invoice.status}
                disable={disableInvoice}
            />
        </Col>
    </>);
};

export default InvoiceComponent;
