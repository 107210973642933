import React, {useRef, useState} from "react";
import useFetch from "../../../hooks/useFetch";
import { sendChangePasswordEmail } from "../../../api/password";
import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";

const ForgotPassword = () => {
  const emailRef = useRef();
  const sendResetEmailRequest = useFetch(sendChangePasswordEmail, {});

  const [reset, setReset] = useState('');

  const handleResetPassword = () => {
    const email = emailRef.current.value;
    sendResetEmailRequest.sendRequest({ email }).then(() => {
      setReset('Acum accesati linkul trimis pe email.');
    });
  };

  return (
      <div className="container">
        <MainNavbar />
        <div className="row justify-content-center mt-5 " style={{minHeight: "100vh"}}>
          <div className="col-md-6">
            <div className="card" style={{textAlign: "center"}}>
              <div className="card-body">
                <h1 className="card-title text-center">Resetare Parolă</h1>
                <p className="card-text text-center">
                  Introduceți adresa de email pentru a vă reseta parola.
                </p>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                      type="email"
                      className="form-control"
                      id="email"
                      ref={emailRef}
                  />
                </div>
                <div className="text-center">
                  <button
                      onClick={handleResetPassword}
                      className="btn btn-primary"
                  >
                    Resetează Parola
                  </button>
                </div>
              </div>
              {reset}
            </div>

          </div>
        </div>
        <Footer />
      </div>
  );
};

export default ForgotPassword;
