import { useEffect, useState } from "react";

const useFetch = (request, args, onStart = false) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const sendRequest = async (params = undefined) => {
    setData(undefined);
    setError(undefined);
    setLoading(true);
    return await request({
      setData: setData,
      setLoading: setLoading,
      setError: setError,
      args: params ? params : args,
    });
  };

  useEffect(() => {
    if (onStart) sendRequest();
  }, []);

  return { data, loading, error, sendRequest };
};

export default useFetch;
