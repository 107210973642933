import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import InvoicesButton from "../../../../../Sales/Invoices/InvoicesList/Components/NoInvoices/InvoicesButton";

import { AiOutlinePlus, AiOutlineImport } from "react-icons/ai";

function NoVendors() {
  return (
    <>
      <Row>
        <Col className="align-self-center">
          <h1
            style={{
              fontFamily: "'Inter', arial",
              color: "#003172",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            Furnizori
          </h1>
          <p
            style={{
              fontFamily: "'Inter', arial",
              color: "#003172",
              marginBottom: "30px",
            }}
          >
            Furnizorii sunt necesari dacă doriți să creați facturi. Poti sa vezi
            soldul pe care îl datorați și filtrați rapoartele de către furnizor.
          </p>
          <hr></hr>
        </Col>
      </Row>

      <Row>
          <InvoicesButton label="Furnizor nou"
                          icon={<AiOutlinePlus size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>} 
                          path="create"/>

          {/*<InvoicesButton label="Importă furnizori"*/}
          {/*            icon={<AiOutlineImport size={28} style={{marginBottom: "6px", marginRight: "10px"}}/>} */}
          {/*            path="import"/>*/}
      </Row>
    </>
  );
}

export default NoVendors;
