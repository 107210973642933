import "./NoItems.css";
import Col from "react-bootstrap/Col";

import { useNavigate } from "react-router-dom";

const ItemsButton = ({label, icon, path}) => {

    const navigate = useNavigate();


    return (
        <>
            <Col sm={6} className="itemscol">
                    <button onClick={() => navigate(path)} className="itemsbutton">
                    {icon}
                    {label}</button>
            </Col>
        </>
    );
}

export default ItemsButton;