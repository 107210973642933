export const getDealStatus = (status) => {
    if (status === 'IN CURS') return 'În curs';
    if (status === 'CASTIGAT') return 'Câştigat';
    if (status === 'PIERDUT') return 'Pierdut';
    return 'Blocat';
};

export const countDealInvoices = (invoices) => {
    const invoicesNumberTotal = invoices?.length;
    const invoicesNumberPaid = invoices?.filter(invoice => invoice.status === 'paid').length;
    return `${invoicesNumberPaid}/${invoicesNumberTotal}`;
};


