import React, {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import {getInvoiceStatus} from "../../../../../../../../utils/functions/invoices";

import HeaderSection from "./SubComponents/HeaderSection";
import FilterSection from "./SubComponents/FilterSection";
import StatsSection from "./SubComponents/StatsSection";
import TabsSection from "./SubComponents/TabsSection";
import {getView} from "../../../../../../../../utils/cache/functions";

const HasInvoices = ({type, invoicesParam, refresh, customers, categories}) => {
    const [invoices, setInvoices] = useState(invoicesParam || []);
    const {overdue, sent, draft} = useMemo(() => getInvoiceStatus(invoices), [invoices]);

    const navigate = useNavigate();
    const [view, setView] = useState(getView());
    return (<>
        <HeaderSection type={type} navigate={navigate}/>

        <StatsSection overdue={overdue} sent={sent} draft={draft}/>

        <FilterSection type={type} view={view} set={setView} objectsParam={invoicesParam} setObjects={setInvoices}
                       contacts={customers}
                       categories={categories}
        />

        <TabsSection type={type} view={view} invoices={invoices} customers={customers} refresh={refresh}/>
    </>);
};

export default HasInvoices;
