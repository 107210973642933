import React, {useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import Loading from "../../../../../MainPages/Components/Loading/Loading";
import {getVendors} from "../../../../../../utils/functions";
import NoVendors from "./Components/NoVendors/NoVendors";
import HasVendors from "./Components/HasVendors/HasVendors";
import useFetch from "../../../../../../hooks/useFetch";
import {loadContacts} from "../../../../../../api/contacts";
import {removeContacts} from "../../../../../../utils/cache/functions";

function VendorsList({setLocale}) {
    const {companyId} = useParams();
    const contactsState = useFetch(loadContacts, {companyId: companyId}, true);

    const [vendors, setVendors] = useState([]);

    useEffect(() => {
        setVendors(getVendors(contactsState.data));
    }, [contactsState.data]);

    const refreshVendors = () => {
        removeContacts(companyId);
        contactsState.sendRequest();
    };

    return (
        <>
            {contactsState.loading && <Loading/>}
            {contactsState.error && <Navigate to="/dashboard"/>}
            {contactsState.data &&
                (vendors?.length ? (
                    <HasVendors vendorsParam={vendors} refresh={refreshVendors}/>
                ) : (
                    <NoVendors/>
                ))}
        </>
    );
}

export default VendorsList;
