import React from "react";
import { Card, Image, ListGroup, ListGroupItem } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';
import DisableObject from "../../../../../../../../components/Modals/DisableObject";

import './CustomerCard.css';

const CustomerCard = ({ name, phone, email, createdAt, address, currency, tax, disableCustomer, updateClient }) => {
  return (
    <Card style={{ margin: "20px auto",  fontFamily: "'Inter', arial" }} className="clientcard">
      <Card.Header as="h5" className="clientcard-header">{name}</Card.Header>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
          <i className="fa fa-phone mr-2" aria-hidden="true"></i>
          {phone}
        </ListGroupItem>
        <ListGroupItem>
          <i className="fa fa-envelope mr-2" aria-hidden="true"></i>
          {email}
        </ListGroupItem>
        <ListGroupItem>
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
          {address}
        </ListGroupItem>
        <ListGroupItem>
          <i className="fa fa-dollar mr-2" aria-hidden="true"></i>
          {currency}
        </ListGroupItem>
        <ListGroupItem>
          <p style={{fontWeight: "2000", color: "gray"}}>CUI <span style={{fontWeight: "400"}}> {tax}</span></p>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default CustomerCard;
