import MainNavbar from "../MainPages/Components/MainNavbar/MainNavbar";
import Footer from "../MainPages/Components/Footer/Footer";
import React from "react";
import {Link} from "react-router-dom";

//function that returns the page when the company is not active

/**
 *
 * @param a
 * @param b
 * @param c
 * @returns {JSX.Element}
 * @constructor
 */

const CompanyNotActive = ({a, b, c}) => {
    return (<>
        <MainNavbar/>
        <div className="container">
            <div className="row" style={{minHeight: "100vh"}}>
                <div className="col-12">
                    <div className="card" style={{border: "none"}}>
                        <div className="card-body">
                            <h1 className="text-center">Activeaza compania</h1>
                            <p className="text-center">
                                Va rugam asteptati pana administratorul va confirma compania.
                            </p>
                            <p className="text-center">
                                Telefon: +40 767 100 000
                            </p>
                            <p className="text-center">
                                Email: office@simmple.ro
                            </p>

                            <div className="text-center">
                                <button>
                                    Resolicită activarea
                                </button>
                            </div>
                            
                            <p className="text-center">
                                <Link to='/dashboard'>Înapoi</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </>);
};

export default CompanyNotActive;
