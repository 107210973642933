import {loggedInHeaders} from "./apiUtils";
import {put, post} from "./requests";

export const changePassword = async (params) => {
    try {
        const url = "/api/password/change/";
        //reset errors
        params.setError(undefined);

        const data = await (await put(url, params.args, loggedInHeaders())).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const sendChangePasswordEmail = async (params) => {
    try {
        const url = "/api/password/reset/";
        //reset errors
        params.setError(undefined);

        const data = await await post(url, params.args).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const verifyChangePassword = async (params) => {
    try {
        const url = "/api/password/reset/verify/";
        //reset errors
        params.setError(undefined);

        const data = await (await post(url, params.args)).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
