import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Card from "react-bootstrap/Card";

import HRGraph from "../HRGraph/HRGRaph";
import {employeeIsFired, salaryCalendar} from "../../../../../utils/functions";
import {constructInterval} from "../../../../../utils/functions/cashflow";

const HR = ({hr, invoices, bills, filters, companies}) => {

    return (<Col xs={12} sm={6} style={{marginBottom: "20px"}}>
        <Card
            style={{
                borderRadius: "0px",
                border: "none",
                padding: "20px 20px 20px 20px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
            }}
        >
            <Card.Body>
                <Row>
                    <Col>
                        <h5 style={{fontFamily: "'Inter', arial", color: "#003172"}}>
                            Venit raportat la angajaţi
                        </h5>
                    </Col>
                    <Col style={{textAlign: "right"}} sm={3}>

                    </Col>
                    <hr></hr>
                </Row>

                <HRGraph
                    salaryExpenses={salaryCalendar(hr, constructInterval(filters.start, filters.end))}
                    invoices={invoices} bills={bills}
                    companies={companies} filters={filters}/>
            </Card.Body>
        </Card>
    </Col>);
};

export default HR;
