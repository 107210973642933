import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {CATEGORY_TYPE, INVOICE_STATUS, INVOICE_TYPE, REGEXP} from "../../../../utils/constants";
import React, {useEffect, useState} from "react";
import useFetch from "../../../../hooks/useFetch";
import {getCompany} from "../../../../api/companies";
import {loadItems} from "../../../../api/items";
import {loadCrmCompanies} from "../../../../api/crm/companies";
import {loadUserData} from "../../../../api/user";
import {getAuthFromStorage} from "../../../../utils/cache/cache";
import {countInvoices, createInvoice, editInvoice, loadInvoices} from "../../../../api/invoices";
import {loadCategories} from "../../../../api/categories";
import {listToDict} from "../../../../utils/functions";
import {removeCategories, removeInvoices} from "../../../../utils/cache/functions";
import {validateForm} from "../../../../utils/functions/validation";
import {copyButExclude} from "../../../../utils/utils";
import Loading from "../../../MainPages/Components/Loading/Loading";
import InputFields from "./InputFields";
import Footer from "./Footer";
import AddItem from "../../../../components/Modals/AddItem/AddItem";
import AddItems from "./AddItems";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import {getNextInvoiceNumber} from "../../../../utils/functions/invoices";

const InvoiceNew = ({type, viewPage = false}) => {
    const {companyId} = useParams();
    const nav = useNavigate();
    const outletContext = useOutletContext();
    const invoice = outletContext?.invoice ?? undefined;

    const initialState = {
        title: '',
        subheading: '',
        invoice_number: '',
        order_number: '',
        status: INVOICE_STATUS.SENT,
        type: type,
        invoice_items: [],
        invoiced_at: new Date().toISOString().slice(0, 10),
        due_at: new Date().toISOString().slice(0, 10),
        amount: 0,
        category: '',
        contact: '',
        footer: '',
        company: companyId,
    };
    const [errors, setErrors] = useState({});

    const companyState = useFetch(getCompany, {companyId: companyId}, true);
    const itemsState = useFetch(loadItems, {companyId: companyId}, true);
    const crmCompaniesState = useFetch(loadCrmCompanies, {companyId: companyId}, true);
    const userState = useFetch(loadUserData, {userId: getAuthFromStorage()?.userId}, true);

    const createInvoiceState = useFetch(createInvoice, {});
    const editInvoiceState = useFetch(editInvoice, {});
    const categoryState = useFetch(loadCategories, {companyId: companyId, type: type}, true);
    const countInvoiceState = useFetch(countInvoices, {companyId: companyId});

    const [invoiceForm, setInvoiceForm] = useState(invoice ? {
        title: invoice?.title,
        subheading: invoice?.subheading,
        invoice_number: invoice?.invoice_number,
        order_number: invoice?.order_number,
        status: invoice?.status,
        type: invoice?.type,
        invoice_items: invoice?.invoice_items,
        invoiced_at: invoice?.invoiced_at,
        due_at: invoice?.due_at,
        amount: invoice?.amount,
        category: invoice?.category,
        contact: invoice?.contact,
        footer: invoice?.footer,
        company: companyId,
    } : initialState);
    const [contacts, setContacts] = useState([]);

    const returnCompany = (company) => {
        setInvoiceForm({
            ...invoiceForm, currency_code: 'RON', contact: company?.id,
        });
    };

    const refreshCategories = () => {
        removeCategories(companyId);
        categoryState.sendRequest();
    };

    useEffect(() => {
        setContacts(listToDict(crmCompaniesState.data));
    }, [crmCompaniesState.data]);

    useEffect(() => {
        countInvoiceState.sendRequest().then((data) => {
            setInvoiceForm({...invoiceForm, invoice_number: getNextInvoiceNumber(data)});
        });
    }, []);

    const handleInvoiceChange = (e) => {
        const {name, value} = e.target;
        setInvoiceForm({...invoiceForm, [name]: value});
    };

    const saveInvoice = () => {
        const validatedData = validateForm(initialState, copyButExclude(invoiceForm, ['invoiced_at', 'due_at', 'status', 'amount', 'company', 'category', 'type', 'invoice_items']), {order_number: REGEXP.INTEGER_ABOVE_ZERO_OR_EQUAL});
        if (Object.entries(validatedData).length > 0) {
            setErrors(validatedData);
            return;
        }
        setErrors({});
        removeInvoices(companyId);

        if (invoice) {
            editInvoiceState.sendRequest({
                invoiceId: invoice.id, data: invoiceForm,
            }).then(() => {
                nav(-1);
            });
        } else {
            createInvoiceState.sendRequest({
                data: invoiceForm,
            }).then(() => {
                nav(-1);
            });
        }
    };

    const disableInvoice = () => {
        editInvoiceState
            .sendRequest({
                invoiceId: invoice.id, data: {...invoiceForm, enabled: 0}
            })
            .then(() => {
                nav('../../');
            });
    };

    return (<>
        {(itemsState.loading || crmCompaniesState.loading || createInvoiceState.loading) ? <Loading/> : (<>
            <Col>
                <div style={{background: ""}}>
                    <Container>
                        <h2
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                            }}
                        >
                            Factură nouă
                        </h2>
                        <InputFields invoice={invoice} invoiceForm={invoiceForm} returnCompany={returnCompany}
                                     viewPage={viewPage}
                                     companiesParam={crmCompaniesState?.data} categories={categoryState?.data}
                                     refreshCategories={refreshCategories} errors={errors}
                                     handleInvoiceChange={handleInvoiceChange}
                                     COMPANY_NAME={companyState.data?.company.domain}
                                     USER_EMAIL={userState.data?.email}/>

                        <AddItems type={type} invoiceForm={invoiceForm} setForm={setInvoiceForm} viewPage={viewPage}
                                  itemsParam={itemsState?.data}/>

                        <Footer invoice={invoice} type={type} company={companyState.data?.company} viewPage={viewPage}
                                client_data={listToDict(crmCompaniesState?.data)[invoice?.contact]}
                                saveInvoice={saveInvoice}
                                disableInvoice={disableInvoice}/>
                    </Container>
                </div>
            </Col>
        </>)}


    </>);


};

export default InvoiceNew;