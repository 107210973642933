import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ProgressBar from "react-bootstrap/ProgressBar";
import ProfitGraph from "../ProfitGraph/ProfitGraph";
import Card from "react-bootstrap/Card";

const ProfitLossRatio = ({invoices, bills, filters, companies}) => {
    return (<Col xs={12} sm={12} style={{marginBottom: '20px'}}>
            <Card style={{
                borderRadius: "0px",
                border: "none",
                padding: "20px 20px 20px 20px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)"
            }}>
                <Card.Body>
                    <Row>
                        <Col>
                            <h5 style={{fontFamily: "'Inter', arial", color: "#003172"}}>
                                Profit & Pierderi
                            </h5>
                            <p
                                style={{
                                    fontFamily: "'Inter', arial", color: "gray", fontSize: "12px",
                                }}
                            >
                                Intrări și ieșiri, include facturi neplătite
                            </p>
                        </Col>
                        <Col style={{ textAlign: "right" }} sm={3}>
                        </Col>
                        <hr></hr>
                    </Row>

                    <ProfitGraph invoices={invoices} bills={bills} filters={filters} companies={companies}/>

                </Card.Body>
            </Card>
        </Col>);
};

export default ProfitLossRatio;
