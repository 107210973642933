import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {AiOutlineEdit} from "react-icons/ai";
import ItemInShoppingList from "../../List/Items/ListItems/Components/ItemInShoppingList/ItemInShoppingList";
import AddItem from "../../../../components/Modals/AddItem/AddItem";
import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {INVOICE_TYPE} from "../../../../utils/constants";
import {calcInvoiceTotal, sumTaxes} from "../../../../utils/functions";

const AddItems = ({invoiceForm, setForm, viewPage, itemsParam, type}) => {
    const [itemsList, setItemsList] = useState(invoiceForm?.invoice_items || []);

    const returnItems = (items) => {
        let amount = 0;
        const invoice_items = items?.map((item, index) => {
            const total = calcInvoiceTotal(type === INVOICE_TYPE.INVOICE ? item?.sale_price : item?.purchase_price, item?.quantity, sumTaxes(item?.taxes));
            amount = amount + total;
            return {
                id: item?.id,
                name: item?.name,
                description: item?.description,
                price: type === INVOICE_TYPE.INVOICE ? item?.sale_price : item?.purchase_price,
                quantity: item?.quantity,
                tax: sumTaxes(item?.taxes),
                total: total,
            };
        });
        setItemsList(invoice_items);
        setForm(prev => ({...prev, invoice_items: invoice_items, amount: amount}));
    };

    return (<>

        <Row className="formsection">
            <Col
                className="align-self-center"
                style={{marginTop: "50px"}}
            >
                <Row>
                    <Col sm={2} xs={2}>
                        <div
                            style={{
                                position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                <AiOutlineEdit
                                    size={17}
                                    style={{marginRight: "5px"}}
                                />
                                Produse
                            </p>
                        </div>
                    </Col>
                    <Col sm={4} xs={4}>
                        <div
                            style={{
                                position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Descriere
                            </p>
                        </div>
                    </Col>
                    <Col sm={2} xs={2}>
                        <div
                            style={{
                                position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Cantitate
                            </p>
                        </div>
                    </Col>
                    <Col sm={2} xs={2}>
                        <div
                            style={{
                                position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Preț unitar
                            </p>
                        </div>
                    </Col>
                    <Col sm={2} xs={2}>
                        <div
                            style={{
                                position: "relative",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "right",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                Preț
                            </p>
                        </div>
                    </Col>
                </Row>
                <hr></hr>
            </Col>

            <Col sm={12} className="align-self-center">
                <Row>
                    <Col sm={12}>
                        {itemsList?.map((item, index) => (<ItemInShoppingList key={index} item={item}/>))}
                    </Col>
                </Row>
                <hr></hr>
            </Col>

            <Col sm={12} className="align-self-center">
                <Row>
                    <Col sm={12}>
                        <AddItem
                            isDisabled={viewPage}
                            defaultValue={itemsList}
                            itemsParam={itemsParam}
                            returnItems={returnItems}
                        />
                    </Col>
                </Row>
                <hr></hr>
            </Col>

            <Col className="align-self-center">
                <Row style={{marginTop: "20px"}}>
                    <Col sm={8}></Col>
                    <Col sm={2}>
                        <div
                            style={{
                                position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    fontFamily: "'Inter', arial",
                                    color: "#003172",
                                }}
                            >
                                Subtotal
                            </p>
                        </div>
                    </Col>
                    <Col sm={2}>
                        <div
                            style={{
                                position: "relative",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "right",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                {invoiceForm?.amount} RON
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: "20px"}}>
                    <Col sm={8}></Col>
                    <Col sm={2} xs={4}>
                        <div
                            style={{
                                position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            }}
                        >
                            <Form.Group
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    fontFamily: "'Inter', arial",
                                    color: "#003172",
                                }}
                                className="sm-auto"
                                controlId="formBasicPassword"
                            >
                                <Form.Label style={{marginBottom: "-2px"}}>
                                    Total
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </Col>
                    <Col sm={2}>
                        <div
                            style={{
                                position: "relative",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "right",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px", fontFamily: "'Inter', arial", color: "#003172",
                                }}
                            >
                                {invoiceForm?.amount} RON
                            </p>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>

    </>);
};

export default AddItems;