import {loggedInHeaders} from "./apiUtils";
import {get, put} from "./requests";
import {requestId} from "../utils/utils";
import {cache} from "../utils/cache/cache";

export const loadUserData = async (params) => {
    try {
        const url = `/api/users/${params.args?.userId}/`;
        const reqId = requestId(url, params.args);

        const cachedData = cache.get(reqId);
        if (cachedData) {
            params.setData(cachedData);
            return cachedData;
        }

        const data = await (await get(url, loggedInHeaders())).data;
        cache.set(reqId, data, 10);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err);
    } finally {
        params.setLoading(false);
    }
};

export const editUser = async (params) => {
    try {
        const url = `/api/users/${params.args?.userId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;
        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
