import {loggedInHeaders} from "./apiUtils";
import {post} from "./requests";

export const sendCompanyInvite = async (params) => {
    try {
        const url = `/api/invite/`;

        const data = await (await post(url, params.args, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const acceptCompanyInvite = async (params) => {
    try {
        const url = `/api/join/`;
        const data = await (await post(url, params.args, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
