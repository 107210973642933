import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";

const CookiesPopup = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const hasAcceptedCookies = localStorage.getItem('acceptedCookies');
        if (!hasAcceptedCookies || hasAcceptedCookies === false) {
            setShow(true);
        }
    }, []);

    const handleClosePopup = () => {
        setShow(false);
        localStorage.setItem('acceptedCookies', 'true');
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Politica de Cookie-uri</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Acest site folosește cookie-uri pentru a vă asigura că obțineți cea mai bună experiență pe
                        site-ul nostru.
                    </p>
                    <p>
                        Prin continuarea utilizării site-ului nostru, sunteți de acord cu utilizarea cookie-urilor
                        conform{' '}
                        <Link to="/security_policy">Politicii de Confidențialitate</Link>.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClosePopup}>
                        Acceptă
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CookiesPopup;
