import {ResponsiveContainer, PieChart, Pie, Legend, Cell, LabelList, Tooltip, Label} from "recharts";
import {returnRateDeals} from "../../../../../../../utils/functions/graphs";
import React from "react";


const COLORS = ["#3e8a34", "#34378a", "#eb4949", "#a3a0a0"];

const ReturnRate = ({data, start, end}) => {
    const chartData = returnRateDeals(data, start, end);

    return (<>
        <ResponsiveContainer width="100%" height={400}>

            <PieChart>
                <Legend/>

                <Pie data={chartData} dataKey="value" cx="50%" cy="50%" innerRadius={80} outerRadius={100}
                     fill="#82ca9d"
                     label>
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}

                </Pie>
                <Tooltip/>
                <Label value="da"/>
            </PieChart>

        </ResponsiveContainer>
    </>);
}

export default ReturnRate;