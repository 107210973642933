import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MainGraph from "../MainGraph/MainGraph";
import Card from "react-bootstrap/Card";

const CashFlow = ({ bills, invoices, filters, companies }) => {
  return (
      <Card style={{ borderRadius: "0px", border: "none", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)", padding: "20px 20px", marginBottom: "20px"}}>
        <Card.Body>

        <div style={{display: "flex", justifyContent: "space-between"}}>

            <div style={{width: "70%"}}>
              <h5 style={{ fontFamily: "'Inter', arial", color: "#003172" }}>
                Cash flow
              </h5>
              <p
                style={{
                  fontFamily: "'Inter', arial",
                  color: "gray",
                  fontSize: "12px",
                }}
              >
                Încasările şi ieşirile din business
              </p>
            </div>

            {/*<div style={{width: "30%", textAlign: "right"}}>*/}
            {/*  <p style={{ fontFamily: "'Inter', arial", fontSize: "15px", color: "#003172" }}>*/}
            {/*      Vezi raport*/}
            {/*    </p>*/}
            {/*</div>*/}

            <hr></hr>
          </div>

          <div>
            <MainGraph bills={bills} invoices={invoices} filters={filters} companies={companies} />
          </div>

        </Card.Body>
      </Card>
  );
};

export default CashFlow;
