import "./NoCustomers.css";
import Col from "react-bootstrap/Col";

import { useNavigate } from "react-router-dom";

const CustomersButton = ({label, icon, path}) => {

    const navigate = useNavigate();


    return (
        <>
            <Col sm={6} className="customerscol">
                    <button onClick={() => navigate(path)} className="customersbutton">
                    {icon}
                    {label}</button>
            </Col>
        </>
    );
}

export default CustomersButton;