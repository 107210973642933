import React from "react";
import {useNavigate} from "react-router-dom";

const EmployeeRow = ({employee, refresh}) => {
    const nav = useNavigate();

    return (<tr>
            <td>{employee?.first_name}</td>
            <td>{employee?.last_name}</td>
            <td>{employee?.pozitie}</td>
            <td>{employee?.salariu_brut}</td>
            <td>{employee?.data_incepere.slice(0, 10)}</td>
            <td>{employee?.data_plecare ?? '-'}</td>
            <td style={{textAlign: "right"}}>
                <button
                    onClick={() => nav(`${employee.id}/view`)}
                    style={{
                        border: "none",
                        color: "white",
                        background: "#003172",
                        borderRadius: "8px",
                        padding: "2px 15px",
                        boxSizing: "border-box",
                    }}
                >
                    Vizualizează
                </button>
            </td>
        </tr>);
};

export default EmployeeRow;