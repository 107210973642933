import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {TbFilter} from "react-icons/tb";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import React, {useState} from 'react';
import {filterList} from "../../utils/functions/filters";
import {employeeIsFired} from "../../utils/functions";

const ItemFilter = ({hr, setHR}) => {

    const [show, setShow] = useState(false);
    const [filters, setFilters] = useState({
        status: 'all',
    });
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleFilters = (e) => {
        setFilters(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const applyFilters = () => {
        if (filters.status !== 'all') {
            if (filters.status === 'concediat') setHR(hr.filter(employee => employeeIsFired(employee))); else setHR(hr.filter(employee => !employeeIsFired(employee)));
        } else setHR(hr);
        handleClose();
    }

    return (<>
        <Button onClick={handleShow} style={{
            marginLeft: "5px",
            fontFamily: "'Inter', arial",
            background: "white",
            border: "1px solid lightgray",
            color: "#003172",
            height: "50px",
        }}><TbFilter size={30}/></Button>

        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Filtre</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <Card.Body style={{padding: '15px'}}>
                                <Card.Title style={{
                                    fontFamily: "'Inter', arial", color: 'black', fontSize: '15px', fontWeight: "800"
                                }}>Statut </Card.Title>
                                <select style={{width: "100%", border: "none"}}
                                        name="status"
                                        value={filters?.status}
                                        onChange={handleFilters}
                                >
                                    <option value='all'>Toate</option>
                                    <option value='concediat'>Concediat</option>
                                    <option value='angajat'>Angajat curent</option>
                                </select>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button style={{
                    background: "white",
                    color: "#003172",
                    borderRadius: "8px",
                    border: "none",
                    padding: "10px",
                    fontSize: "15px"
                }} onClick={handleClose}>
                    Anulează
                </button>
                <button style={{
                    background: "#003172",
                    color: "white",
                    borderRadius: "8px",
                    border: "none",
                    padding: "10px",
                    fontSize: "15px"
                }} onClick={applyFilters}>
                    Aplică filtrele
                </button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default ItemFilter;
