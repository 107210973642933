import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import DealCard from "../Components/DealCard/DealCard";
import DealTableComponent from "../Components/DealCard/DealTableComponent";
import Table from "react-bootstrap/Table";

import {TbLayoutList, TbLayoutGrid} from "react-icons/tb";
import useFetch from './../../../../../../hooks/useFetch';
import {loadCrmContracts} from '../../../../../../api/crm/contracts';
import {useParams} from 'react-router-dom'
import {loadCrmCompanies} from "../../../../../../api/crm/companies";
import {loadContacts} from "../../../../../../api/contacts";
import {loadInvoices} from "../../../../../../api/invoices";
import {listToDict} from "../../../../../../utils/functions";
import SearchBar from "../../../../../../components/Filters/SearchBar";
import DealFilter from "../../../../../../components/Filters/DealFilter";
import {getView, saveView} from "../../../../../../utils/cache/functions";
import Loading from "../../../../../MainPages/Components/Loading/Loading";
import {countDealInvoices, getDealStatus} from "../../../../../../utils/functions/deals";

function CRMDeals() {
    const navigate = useNavigate();
    const [view, setView] = useState(getView());

    const {companyId} = useParams();

    const contractsState = useFetch(loadCrmContracts, {});
    const crmCompaniesState = useFetch(loadCrmCompanies, {});
    const contactsState = useFetch(loadContacts, {});
    const invoicesState = useFetch(loadInvoices, {});

    const [contractsList, setContractsList] = useState([]);
    const [crmCompaniesList, setCRMCompaniesList] = useState([]);
    const [contactsList, setContactsList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);

    useEffect(() => {
        contractsState.sendRequest({companyId: companyId}).then((response) => {
            setContractsList(response);
        });
        crmCompaniesState.sendRequest({companyId: companyId}).then((response) => {
            setCRMCompaniesList(listToDict(response));
        });
        contactsState.sendRequest({companyId: companyId}).then((response) => {
            setContactsList(listToDict(response));
        });
        invoicesState.sendRequest({companyId: companyId}).then((response) => {
            setInvoiceList(response);
        });
    }, []);

    return ((invoicesState.loading || crmCompaniesState.loading || contractsState.loading || contactsState.loading) ?
        <Loading/> : (<><Row>
            <Col sm={6}>
                <h1
                    style={{
                        fontFamily: "'Inter', arial", color: "#003172", marginTop: "30px",
                    }}
                >
                    Contracte
                </h1>
            </Col>
            <Col sm={6} style={{textAlign: "right"}} xs={12}>
                <Button
                    style={{
                        margin: "5px",
                        fontFamily: "'Inter', arial",
                        background: "#003172",
                        border: "none",
                        marginTop: "30px",
                        marginBottom: "30px",
                    }}
                    onClick={() => navigate("create")}
                >
                    Contract nou
                </Button>
            </Col>
        </Row>

            <div style={{
                display: "flex",
                margin: '20px 0px',
                padding: '25px',
                boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
                background: 'white',
                verticalAlign: "middle"
            }}>

                <SearchBar objectsArray={contractsState.data} setNewArray={setContractsList}/>

                <Button
                    onClick={() => view === "Tabel" ? setView(saveView("Carduri")) : setView(saveView("Tabel"))}
                    style={{
                        marginLeft: "5px",
                        fontFamily: "'Inter', arial",
                        background: "white",
                        border: "1px solid lightgray",
                        color: "#003172",
                        width: "60px",
                        height: "50px",
                    }}>{view === "Tabel" ? <TbLayoutList size={30}/> : <TbLayoutGrid size={30}/>}</Button>

                <DealFilter deals={contractsState.data} setDeals={setContractsList} companies={crmCompaniesList}/>
            </div>

            {view === "Tabel" ? <div className={"formsection"}>
                <Table style={{color: "#003172"}}>
                    <thead>
                    <tr>
                        <th width={"14.2%"}>Nume</th>
                        <th width={"17.2%"}>Reprezentant</th>
                        <th width={"18.2%"}>Companie</th>
                        <th width={"14.2%"}>Status</th>
                        <th width={"14.2%"}>Valoare</th>
                        <th width={"12.2%"}>Factura asociată</th>
                    </tr>
                    </thead>
                    <tbody>
                    {contractsList?.map((c, index) => (<DealTableComponent
                        deal={c}
                        salesperson={contactsList[c.contractat_represent]}
                        business={crmCompaniesList[c.contractat]}
                        value={`${c?.value} RON`}
                        invoices={countDealInvoices(c?.invoices)}
                        // invoice={invoiceList[c.invoice]}
                        // invoice_link={`/${companyId}/${invoiceList[c.invoice]?.invoiced_at ? 'sales/invoices' : 'purchases/bills'}/${c.invoice}/view`}
                    />))}
                    </tbody>
                </Table>
            </div> : <>
                <Row style={{justifyContent: 'left'}}>
                    {contractsList?.map((c, index) => (<Col key={index} sm={4}><DealCard
                        id={c.id}
                        name={c?.name}
                        salesperson={contactsList[c.contractat_represent]?.name}
                        business={crmCompaniesList[c.contractat]?.company_name}
                        date={c?.status === 'CASTIGAT' || c?.status === 'PIERDUT' ? c?.date : 'Fără dată'}
                        status={getDealStatus(c?.status)}
                        value={`${c?.value} RON`}
                        invoices={countDealInvoices(c?.invoices)}
                        // invoice={invoiceList[c.invoice]?.title}
                        // invoice_link={`/${companyId}/${invoiceList[c.invoice]?.invoiced_at ? 'sales/invoices' : 'purchases/bills'}/${c.invoice}/view`}
                    />
                    </Col>))}
                </Row>
            </>}</>));
}

export default CRMDeals;
