import React from "react";

import {Navigate, useParams} from "react-router-dom";
import Loading from "../../../../../MainPages/Components/Loading/Loading";
import HasInvoices from "./Components/HasInvoices/HasInvoices";
import NoInvoices from "./Components/NoInvoices/NoInvoices";
import {getCustomers, listToDict} from "../../../../../../utils/functions";
import {loadInvoices} from "../../../../../../api/invoices";
import useFetch from "../../../../../../hooks/useFetch";
import {loadContacts} from "../../../../../../api/contacts";
import {removeInvoices} from "../../../../../../utils/cache/functions";
import {loadCategories} from "../../../../../../api/categories";
import {CATEGORY_TYPE, INVOICE_TYPE} from "../../../../../../utils/constants";
import {loadCrmCompanies} from "../../../../../../api/crm/companies";

function InvoicesList({type = INVOICE_TYPE.INVOICE}) {
    const {companyId} = useParams();
    const invoicesState = useFetch(loadInvoices, {companyId: companyId}, true);
    // const invoiceItemsState = useFetch(loadInvoiceItems, {companyId: companyId}, true);
    const crmCompaniesState = useFetch(loadCrmCompanies, {companyId: companyId}, true);
    const categoriesState = useFetch(loadCategories, {companyId: companyId, type: type}, true);

    const refreshInvoices = () => {
        removeInvoices(companyId);
        invoicesState.sendRequest();
    };

    return (<>
        {(invoicesState.error || crmCompaniesState.error || categoriesState.error) && <Navigate to="/dashboard"/>}
        {(invoicesState.loading || crmCompaniesState.loading || categoriesState.loading || categoriesState.loading) &&
            <Loading/>}
        {invoicesState.data && crmCompaniesState.data && categoriesState.data && categoriesState.data && (invoicesState.data?.length > 0 ? (
            <HasInvoices
                type={type}
                invoicesParam={invoicesState.data?.filter(i => i.type === type)}
                refresh={refreshInvoices}
                customers={listToDict(crmCompaniesState.data)}
                categories={listToDict(categoriesState.data)}
            />) : (<NoInvoices type={type}/>))}
    </>);
}

export default InvoicesList;
