import {CONTACT_TYPE} from "./constants";
import CashFlow from "../pages/AppPages/Dashboard/Components/CashFlow/CashFlow";

//contacts
export const getCustomers = (list) => list?.filter((contact) => contact.type === CONTACT_TYPE.CUSTOMER || contact.type === CONTACT_TYPE.MULTIPLE);

export const getVendors = (list) => list?.filter((contact) => contact.type === CONTACT_TYPE.VENDOR || contact.type === CONTACT_TYPE.MULTIPLE);

//taxes
export const sumTaxes = (list) => list?.filter(i => i.enabled)?.reduce((prev, current) => prev + current.rate, 0);

//general
export const listToDict = (list, identifier = "id") => {
    const dict = {};
    list?.forEach((element) => {
        dict[element[identifier]] = element;
    });
    return dict;
};

export const findById = (id, list) => list?.find((element) => element.id === id);

export const sortObjects = (list, SORT_BY, ORDER) => {
    const arr = [...list];
    return arr.sort((a, b) => ORDER * (new Date(a[SORT_BY]) - new Date(b[SORT_BY])));
};

export const searchObject = (list, pattern) => {
    pattern = pattern.replace(" ", "").toLowerCase();
    return list?.filter((object) => Object.values(object).join("").toLowerCase().includes(pattern));
};

export const createCheck = (list) => {
    const arr = {};
    list.forEach((element) => {
        arr[element.id] = {
            isChecked: false, quantity: 1, object: element,
        };
    });
    return arr;
};

//invoices
export const getCurrentInvoiceNumber = (invoices) => {
    const numb = invoices.toString();
    return "INV_" + "0".repeat(5 - numb.length) + numb;
};

export const getCurrentBillNumber = (bills) => {
    const numb = bills.toString();
    return "BILL_" + "0".repeat(5 - numb.length) + numb;
};

export const calcInvoiceTotal = (p, q, t) => {
    const value = p * q;
    return value + (t / 100) * value;
};

export const getMillisecondsFromMinutes = (minutes) => minutes * 60000;

export const employeeIsFired = (employee) => employee.data_plecare !== null;

export const wasHiredThatDay = (date, employee) => new Date(employee.data_incepere) <= new Date(date) && (employee.data_plecare === null || new Date(employee.data_plecare) >= new Date(date));

export const salaryCalendar = (hr, interval) => {
    const arr = [];
    interval.forEach(intervalDate => {
        const salaryThatDay = ((hr?.filter(employee => wasHiredThatDay(intervalDate, employee))
            ?.reduce((acc, employee) => acc + parseFloat(employee.salariu_brut), 0)) / 30).toFixed(2);
        arr.push({date: new Date(intervalDate).getTime(), salaryThatDay: salaryThatDay});
    });
    return arr;
};
