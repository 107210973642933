import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import useFetch from "../../hooks/useFetch";
import {sendContactUsEmail} from "../../api/contact_us";


const ContactUs = () => {

    const [form, setForm] = useState({
        name: '', phone: '', email: '', message: '',
    });
    const [addr, setAddr] = useState({
        city: '', county: '', postal_code: '', details: '',
    });

    const contactUsState = useFetch(sendContactUsEmail, {});

    const handleForm = (e) => {
        const {name, value} = e.target;
        setForm(prev => ({...prev, [name]: value}));
    };

    const handleAddr = (e) => {
        const {name, value} = e.target;
        setAddr(prev => ({...prev, [name]: value}));
    };

    const sendEmail = () => {
        contactUsState.sendRequest({...form, address: JSON.stringify(addr)}).then(() => {
            window.location.reload();
        });
    };

    return (<>
        <Col sm={12} style={{textAlign: "center"}}>
            <h1 style={{fontFamily: "'Inter', Arial", fontSize: "50px", color: "black"}}>
                Contact
            </h1>
        </Col>

        <div className="formbold-main-wrapper">
            <div className="formbold-form-wrapper">
                <div className="formbold-mb-5">
                    <label htmlFor="name" className="formbold-form-label"> Nume complet </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nume complet"
                        className="formbold-form-input"
                        value={form.name}
                        onChange={handleForm}
                    />
                </div>
                <div className="formbold-mb-5">
                    <label htmlFor="phone" className="formbold-form-label"> Telefon </label>
                    <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Telefon"
                        className="formbold-form-input"
                        value={form.phone}
                        onChange={handleForm}
                    />
                </div>
                <div className="formbold-mb-5">
                    <label htmlFor="email" className="formbold-form-label"> Email </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        className="formbold-form-input"
                        value={form.email}
                        onChange={handleForm}
                    />
                </div>

                <div className="formbold-mb-5 formbold-pt-3">
                    <label className="formbold-form-label formbold-form-label-2">
                        Adresă
                    </label>
                    <div className="flex flex-wrap formbold--mx-3">
                        <div className="w-full sm:w-half formbold-px-3">
                            <div className="formbold-mb-5">
                                <input
                                    type="text"
                                    name="details"
                                    id="area"
                                    placeholder="Detalii adresă"
                                    className="formbold-form-input"
                                    value={addr.details}
                                    onChange={handleAddr}
                                />
                            </div>
                        </div>
                        <div className="w-full sm:w-half formbold-px-3">
                            <div className="formbold-mb-5">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="Oraş"
                                    className="formbold-form-input"
                                    value={addr.city}
                                    onChange={handleAddr}
                                />
                            </div>
                        </div>
                        <div className="w-full sm:w-half formbold-px-3">
                            <div className="formbold-mb-5">
                                <input
                                    type="text"
                                    name="county"
                                    id="state"
                                    placeholder="Judeţ"
                                    className="formbold-form-input"
                                    value={addr.county}
                                    onChange={handleAddr}
                                />
                            </div>
                        </div>
                        <div className="w-full sm:w-half formbold-px-3">
                            <div className="formbold-mb-5">
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="post-code"
                                    placeholder="Cod Poştal"
                                    className="formbold-form-input"
                                    value={addr.postal_code}
                                    onChange={handleAddr}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="formbold-mb-5">
                    <label htmlFor="name" className="formbold-form-label"> Mesaj </label>
                    <textarea value={form.message} onChange={handleForm} className="formbold-form-input"
                              name="message" id="message">

                        </textarea>
                </div>

                <div>
                    <button onClick={sendEmail} className="formbold-btn">Trimite mesaj</button>
                </div>
            </div>
        </div>


    </>)

};

export default ContactUs;
