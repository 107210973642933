import ManageContact from "../../../../../../../../components/Modals/Manage/ManageContact";
import React from "react";
import {useNavigate} from "react-router-dom";

const CustomerRow = ({customer, refresh}) => {
    const nav = useNavigate();
    let addr = "";
    try {
        addr = JSON.parse(customer.address);
    } catch (error) {
        console.error("An error occurred while parsing the JSON string:", error);
    }
    return (
        <>
            <tr>
                <td>
                    {customer?.name}
                </td>

                <td>
                    {customer?.phone}
                </td>

                <td>
                    {customer?.email}
                </td>

                <td>
                    {addr?.city}
                </td>

                <td>
                    {customer?.updated_at.slice(0, 10)}
                </td>

                <td style={{textAlign: "right"}}>
                    <button
                        onClick={() => nav(`${customer.id}/view`)}
                        style={{
                            border: "none",
                            color: "white",
                            background: "#003172",
                            borderRadius: "8px",
                            padding: "2px 15px",
                            boxSizing: "border-box",
                        }}
                    >
                        Vizualizează
                    </button>
                </td>
            </tr>
        </>
    );
}

export default CustomerRow;