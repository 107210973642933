import {cache} from "../utils/cache/cache";
import {requestId} from "../utils/utils";
import {loggedInHeaders} from "./apiUtils";
import {get, post, put} from "./requests";

export const loadTaxes = async (params) => {
    try {
        const url = `/api/taxes/owner/`;

        let data = await (await get(url, loggedInHeaders())).data;
        data = data?.filter((i) => i.enabled && params?.args.companyId === i.company);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const createTax = async (params) => {
    try {
        const data = params.args?.data;
        const headers = loggedInHeaders();

        if (!data) return;

        const res = await (await post("/api/taxes/", data, headers)).data;

        params.setData({res});
        return res;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const editTax = async (params) => {
    try {
        const url = `/api/taxes/${params.args?.taxId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getTax = async (params) => {
    try {
        const url = `/api/taxes/${params.args?.taxId}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
