import {Navigate} from "react-router-dom";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import "reactjs-popup/dist/index.css";
import useFetch from "../../../../../hooks/useFetch";
import {editCompany} from "../../../../../api/companies";
import {sendCompanyInvite} from "../../../../../api/invites";
import ViewCompany from "../../../../../components/Modals/ViewCompany";
import Invite from "../../../../../components/Modals/Invite";

import "./Company.css";

const Company = ({company, refresh}) => {
    const updateCompanyState = useFetch(editCompany, {
        companyId: company.id, data: {enabled: 0},
    });

    const sendCompanyInviteState = useFetch(sendCompanyInvite, {});

    const disableCompany = () => {
        updateCompanyState.sendRequest().then(() => {
            refresh();
        });
    };

    const handleCompanyInvite = (email, role, licenseId) => {
        sendCompanyInviteState.sendRequest({companyId: company.id, email: email, role: role, licenseId: licenseId});
    };

    return (<>
        <Col sm={12} xs={12} lg={6}>
            <Card
                style={{margin: "10px 10px 10px 10px", height: "160px"}}
                className="p-2 rounded companies"
            >
                <Card.Body>
                    <Card.Title>{company.domain}</Card.Title>
                    <Card.Subtitle
                        className="mb-2 text-muted"
                        style={{fontSize: "12px"}}
                    >
                        {company.location}
                    </Card.Subtitle>
                    <Card.Text></Card.Text>
                    <Link
                        to={`/${company.id}`}
                        className="mb-2 text-white text-decoration-none rounded"
                        style={{
                            backgroundImage: "linear-gradient(to bottom right, #003172, #003172)",
                            fontSize: "13px",
                            fontWeight: "bold",
                            padding: "5px 15px 5px 15px",
                        }}
                    >
                        Conectare
                    </Link>
                    <p color="red">
                        {updateCompanyState.error && <Navigate to="/logout"/>}
                    </p>

                    <div>
                        {/*<DeleteCompany style={{marginRight: "10px", display: "none"}} disableCompany={disableCompany} />*/}

                        <ViewCompany companyId={company.id}/>

                        <Invite invite={handleCompanyInvite} companyId={company.id}/>
                    </div>

                </Card.Body>
            </Card>
        </Col>
    </>);
};

export default Company;
