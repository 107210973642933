import React from "react";
import {Card, Image, ListGroup, ListGroupItem} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';

import './CompanyCard.css';
import {limitWords} from "../../../../../../../../utils/utils";
import DisableObject from "../../../../../../../../components/Modals/DisableObject";
import useFetch from "../../../../../../../../hooks/useFetch";
import {editCrmCompany} from "../../../../../../../../api/crm/companies";
import {useNavigate} from "react-router-dom";

const CompanyCard = ({company, contact, category, refresh}) => {
    let addr = "";
    const editCompanyState = useFetch(editCrmCompany, {});
    const nav = useNavigate();

    try {
        addr = JSON.parse(company.address);
    } catch (error) {
        console.error("An error occurred while parsing the JSON string:", error);
    }

    const disableCompany = () => {
        editCompanyState.sendRequest({crmCompanyId: company.id, data: {...company, enabled: 0}})
            .then(() => {
                refresh();
            })
    };

    return (
        <Card onClick={() => nav(`${company.id}/view`)} style={{margin: "20px auto", fontFamily: "'Inter', arial"}} className="companycard">
            <Card.Header as="h5" className="companycard-header">{company.company_name}</Card.Header>
            <ListGroup className="list-group-flush">
                <ListGroupItem>
                    <i className="fa fa-address-card" aria-hidden="true"></i>
                    {contact?.name}
                </ListGroupItem>
                <ListGroupItem>
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                    {category ?? 'Fără categorie'}
                </ListGroupItem>
                <ListGroupItem>
                    <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
                    {addr.city}
                </ListGroupItem>
                {/*<ListGroupItem>*/}
                {/*    <i className="fa fa-dollar mr-2" aria-hidden="true"></i>*/}
                {/*    {contact?.currency_code || 'RON'}*/}
                {/*</ListGroupItem>*/}
                <ListGroupItem>
                    <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                    {new Date(company.created_at).toISOString().slice(0, 10)}
                </ListGroupItem>
            </ListGroup>

        </Card>
    );
};

export default CompanyCard;
