import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import {confirmEmail} from "../../../api/register";
import {Navigate} from "react-router-dom"
import MainNavbar from "../../MainPages/Components/MainNavbar/MainNavbar";
import Footer from "../../MainPages/Components/Footer/Footer";

const ConfirmRegistration = () => {
    //get token and email from url
    const {token, email} = useParams();
    const confirmRegistrationState = useFetch(confirmEmail, {token: token, email: email}, true);

    return (<>
            <MainNavbar/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="text-center">Confirm Registration</h1>
                                {confirmRegistrationState.error ? (<>
                                    <p className="text-center">Something went wrong</p>
                                    <Navigate to={"/login"}/></>) : (!confirmRegistrationState.loading && (<div>
                                    <p className="text-center">Your account was activated</p>
                                    <p className="text-center">
                                        <Link to={"/login"}>Please login</Link>
                                    </p>
                                </div>))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>

    );
};

export default ConfirmRegistration;
