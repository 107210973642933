import React from "react";
import './ItemRow.css';
import ManageItem from "../../../../../../../components/Modals/Manage/ManageItem";
import {useNavigate} from "react-router-dom";

const ItemRow = ({ item, refresh, category }) => {
    const nav = useNavigate();
    return (
        <tr>
            <td>{item?.name}</td>
            <td>{category?.name}</td>
            <td>{item?.description}</td>
            <td>{item?.sale_price}</td>
            <td>{item?.purchase_price}</td>
            <td>{item?.created_at.slice(0, 10)}</td>
            <td style={{ textAlign: "right" }}>
                <button
                    onClick={() => nav(`${item.id}/view`)}
                    style={{
                        border: "none",
                        color: "white",
                        background: "#003172",
                        borderRadius: "8px",
                        padding: "2px 15px",
                        boxSizing: "border-box",
                    }}
                >
                    Vizualizează
                </button>
            </td>
        </tr>
    );
};

export default ItemRow;
