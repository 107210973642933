import React from "react";

import ContactRow from "./ContactRow";

const ContactTableComponent = ({contact, companies, refresh}) => {

    return (
        <>
            <ContactRow
                contact={contact}
                company={companies[contact.id]?.company_name}
                refresh={refresh}
            />
        </>
    );
};

export default ContactTableComponent;
