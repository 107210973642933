import Form from "react-bootstrap/Form";
import ErrorField from "../../../../../utils/functions/validation";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {useStore} from "react-redux";

const AddInvoices = ({viewPage, invoices, invoicesDict, setData, defaultValue}) => {
    const [selectedInvoices, setSelectedInvoices] = useState(defaultValue || []);

    const addInvoice = () => {
        setSelectedInvoices(prev => [...prev, {status: 'waiting', id: '', invoiceValue: 0}]);
    };

    const handleSelect = (e) => {
        const index = e.target.getAttribute('index');
        const id = e.target.options[e.target.selectedIndex].getAttribute('value');

        if (!index) return;
        if (selectedInvoices[index] === undefined) return;

        setSelectedInvoices(prev => {
            const newArray = [...prev];
            newArray[index] = {...newArray[index], id: id, invoiceValue: invoicesDict[id].amount};
            return newArray;
        });
    };

    const handleStatus = (e) => {
        const index = e.target.getAttribute('index');

        if (!index) return;
        if (selectedInvoices[index] === undefined) return;

        setSelectedInvoices(prev => {
            const newArray = [...prev];
            newArray[index] = {
                ...newArray[index], status: e.target.options[e.target.selectedIndex].getAttribute('value')
            };
            return newArray;
        });
    };

    const removeInvoice = (index) => {
        const remaining = selectedInvoices?.filter((inv, i) => i !== index);
        setSelectedInvoices(remaining);
    };

    useEffect(() => {
        setData(prev => ({
            ...prev,
            invoices: selectedInvoices,
            value: selectedInvoices.reduce((sum, current) => sum + current.invoiceValue, 0)
        }));
    }, [selectedInvoices]);

    return (<div>

        <hr style={{marginTop: "30px"}}></hr>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <p style={{
                fontFamily: "'Inter', arial", color: "#003172", marginTop: "20px",
            }}>
                Facturi ataşate
            </p>

            <Button
                onClick={addInvoice}
                disabled={viewPage} style={{height: "40px", background: "#003172", border: 'none'}}>
                Adaugă factură
            </Button>
        </div>
        {selectedInvoices?.map((item, itemIndex) => {
            return <>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Form.Select
                        value={item.id}
                        index={itemIndex}
                        disabled={viewPage} onChange={handleSelect}>
                        <option value={''}>Selecteaza...</option>
                        {invoices?.map((i, index) => {
                            return <option
                                value={i.id}>{i.title} - {i?.invoice_number || i?.bill_number} - {i?.amount} RON</option>
                        })}
                    </Form.Select>

                    <Form.Select
                        value={item.status}
                        onChange={handleStatus}
                        index={itemIndex}
                        disabled={viewPage} style={{width: "400px"}} name='invoice_status'>
                        <option value='paid'>Achitată</option>
                        <option value='waiting'>În aşteptare</option>
                    </Form.Select>

                    {/*<Button*/}
                    {/*    onClick={() => setView(prev => !prev)}*/}
                    {/*    disabled={viewPage}*/}
                    {/*    style={{color: "#003172", background: 'none', border: 'none'}}>Modifică</Button>*/}

                    <Button
                        onClick={() => removeInvoice(itemIndex)}
                        disabled={viewPage}
                        style={{color: "red", background: 'none', border: 'none'}}>Elimină</Button>

                </div>
                <br></br>
            </>
        })}
    </div>);
};

export default AddInvoices;
