import Company from "./Components/Company/Company";
import {Navigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CreateCompany from "./Components/CreateCompany/CreateCompany";
import useFetch from "../../../hooks/useFetch";
import {loadCompanies} from "../../../api/companies";
import {removeCompanies} from "../../../utils/cache/functions";
const Dashboard = () => {

    const companies = useFetch(loadCompanies, {}, true);

    const refreshCompanies = () => {
        removeCompanies();
        companies.sendRequest();
    };

    return (
        <>
            <Col style={{}} sm={12}>
                <div style={{marginLeft: "0px"}}>
                    <Tabs className="text-muted" justify>
                        <Tab eventKey="companies" title="Companii" className="text-muted">
                            <Row>
                                {companies.error && <Navigate to="/logout"/>}
                                {companies.data?.length ?
                                    companies.data?.map((company, index) => (
                                        <Company
                                            key={index}
                                            company={company}
                                            refresh={refreshCompanies}
                                        />
                                    ))
                                    :
                                    ''
                                }
                                <CreateCompany/>
                            </Row>
                            <p style={{marginLeft: "10px", marginBottom: "100px"}}>
                                Afişează companiile de la {companies.data?.length ? 1 : 0} la {" "}
                                {companies.data?.length} din {companies.data?.length}
                            </p>
                        </Tab>
                    </Tabs>
                </div>
            </Col>
        </>
    );
};

export default Dashboard;
