import "reactjs-popup/dist/index.css";
import useFetch from "../../hooks/useFetch";
import {Outlet, useParams} from "react-router-dom";
import Loading from "../../pages/MainPages/Components/Loading/Loading";
import {Navigate} from "react-router-dom";
import {getCrmCompany} from "../../api/crm/companies";

const CrmCompanyValidator = () => {
    const {crmCompanyId} = useParams();
    const crmCompanyState = useFetch(getCrmCompany, {crmCompanyId: crmCompanyId}, true);

    return (<>
            {crmCompanyState.loading && <Loading/>}
            {crmCompanyState.error && (<Navigate
                    to={crmCompanyState.error?.status === 403 ? "/logout" : "/dashboard"}
                />)}
            {crmCompanyState.data && crmCompanyState.data?.enabled && (<>
                    <Outlet context={{crmCompany: crmCompanyState.data}}/>
                </>)}
        </>);
};

export default CrmCompanyValidator;
