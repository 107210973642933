import {getAuthFromStorage} from "../utils/cache/cache";

export const apiEndpoint = "https://www.zentic.ro";

export const loggedInHeaders = () => {
    const authData = getAuthFromStorage();

    return {
        "Content-Type": "application/json", token: authData?.token, sessionid: authData?.sessionid,
    };
};

export const MediaHeaders = () => {
    const authData = getAuthFromStorage();

    return {
        "Content-Type": "multipart/form-data", token: authData?.token, sessionid: authData?.sessionid,
    };
};

export const defaultHeaders = {
    "Content-Type": "application/json",
};
