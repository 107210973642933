import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Card from "react-bootstrap/Card";


import ProgressBar from "react-bootstrap/ProgressBar";

const Receivables = ({invoices, filters, companies}) => {
    const filteredInvoices = invoices
        ?.filter(invoice => invoice.status === 'sent' && new Date(invoice.invoiced_at) > new Date(filters.start)
            && new Date(invoice.invoiced_at) < new Date(filters.end)
            && (filters.company === 'all' || invoice.contact === filters.company)
            && (filters.contact === 'all' || companies[invoice.contact]?.contact === filters.contact));
    const open_sum = filteredInvoices
        ?.map((invoice, index) => (new Date(invoice.due_at).getTime() > Date.now() ? invoice.amount : 0))
        .reduce((result, number) => result + number, 0);
    const overdue_sum = filteredInvoices
        ?.map((invoice, index) => (new Date(invoice.due_at).getTime() <= Date.now() ? invoice.amount : 0))
        .reduce((result, number) => result + number, 0);

    const total_unpaid = parseFloat(open_sum + overdue_sum) || 0;

    return (<Col xs={12} sm={6} style={{marginBottom: '20px'}}>
        <Card style={{
            borderRadius: "0px",
            border: "none",
            padding: "20px 20px 20px 20px",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)"
        }}>
            <Card.Body>
                <Row>
                    <Col>
                        <h5 style={{fontFamily: "'Inter', arial", color: "#003172"}}>
                            De primit
                        </h5>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", color: "gray", fontSize: "12px",
                            }}
                        >
                            Suma de primit de la clienți
                        </p>
                    </Col>
                    <Col style={{textAlign: "right"}} sm={3}>
                    </Col>
                    <hr></hr>
                </Row>

                <p
                    style={{
                        fontFamily: "'Inter', arial", color: "gray", fontSize: "12px", fontWeight: "bold",
                    }}
                >
                    Total facturi neplătite: {total_unpaid.toFixed(2)} RON
                </p>

                <ProgressBar>
                    <ProgressBar
                        style={{backgroundColor: "gray"}}
                        now={total_unpaid === 0 ? 100 : 0}
                        key={2}
                    />
                    <ProgressBar
                        style={{backgroundColor: "#22a1e2"}}
                        now={(open_sum / total_unpaid) * 100}
                        key={1}
                    />
                    <ProgressBar
                        style={{backgroundColor: "#003172"}}
                        now={(overdue_sum / total_unpaid) * 100}
                        key={3}
                    />
                </ProgressBar>
                <Row style={{marginTop: "20px"}}>
                    <Col sm={5}>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", fontSize: "12px", fontWeight: "bold", color: "#22a1e2",
                            }}
                        >
                            În termen
                        </p>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", fontSize: "12px", fontWeight: "bold", color: "#22a1e2",
                            }}
                        >
                            {open_sum} RON
                        </p>
                    </Col>
                    <Col sm={5}>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", fontSize: "12px", fontWeight: "bold",
                            }}
                        >
                            Peste termen
                        </p>
                        <p
                            style={{
                                fontFamily: "'Inter', arial", color: "#003172", fontSize: "12px", fontWeight: "bold",
                            }}
                        >
                            {overdue_sum} RON
                        </p>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Col>);
};

export default Receivables;
