import CustomerRow from "./CustomerRow";

const CustomerTableComponent = ({customer, refresh}) => {
    return (
        <>
            <CustomerRow
                customer={customer}
                refresh={refresh}
            />
        </>
    );
}

export default CustomerTableComponent;