import React, {useState} from "react";
import MainNavbar from "../Components/MainNavbar/MainNavbar";
import Footer from "../Components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import useFetch from "../../../hooks/useFetch";
import {register} from "../../../api/register";
import Loading from "../Components/Loading/Loading";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import ErrorField, {validateForm} from "../../../utils/functions/validation";
import {REGEXP} from "../../../utils/constants";

const RegisterPage = () => {
    const initialForm = {
        name: "", email: "", password: "",
    };

    const [formData, setFormData] = useState(initialForm);
    const [isChecked, setIsChecked] = useState(false);

    const nav = useNavigate();
    const [errors, setErrors] = useState({});
    const registerState = useFetch(register, {});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleRegister = () => {
        const validatedData = validateForm(initialForm, formData, {password: REGEXP.PASSWORD, email: REGEXP.EMAIL});
        if (Object.entries(validatedData).length > 0) {
            setErrors(validatedData);
            return;
        }
        setErrors({});
        registerState.sendRequest(formData).then((res) => {
            if (res) nav("/activate_account");
        });
    };

    return (<>
        {registerState.loading ? (<Loading/>) : (<>
            <MainNavbar/>
            <div style={{minHeight: "92vh"}}>
                <Container>
                    <Row>
                        <Col style={{marginTop: "150px", marginBottom: "150px"}}>
                            <h1
                                style={{
                                    fontSize: "75px", fontWeight: "bold", color: "#003172",
                                }}
                            >
                                Înregistrează-te, e mai sIMMple ca niciodată.
                            </h1>
                            <h2
                                style={{
                                    fontSize: "18px", fontWeight: "bold", color: "#003172",
                                }}
                            >
                                sIMMple este un software online construit să ajute afaceri
                                mici <br></br>să țină evidența finanțelor cu ușurință.
                            </h2>
                        </Col>
                        <Col>

                            <Card
                                style={{border: "none", margin: "150px 0px 150px 0px"}}
                                className="shadow-lg p-3 mb-5 bg-white rounded"
                            >
                                <Card.Body>
                                    <Form.Group className="mb-4" controlId="formBasicName">
                                        <Form.Label>Nume</Form.Label>
                                        <Form.Control
                                            value={formData.name}
                                            name="name"
                                            type="text"
                                            placeholder="Name"
                                            onChange={handleInputChange}
                                        />
                                        <ErrorField enabled={errors['name']}/>
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>Adresa Email</Form.Label>
                                        <Form.Control
                                            value={formData.email}
                                            name="email"
                                            type="email"
                                            placeholder="Enter email"
                                            onChange={handleInputChange}
                                        />
                                        <ErrorField enabled={errors['email']} error={'Email-ul este invalid.'}
                                                    specialCase={{
                                                        enabled: registerState.error?.data.result === "Email already in use",
                                                        error: 'Acest email este deja utilizat'
                                                    }}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label>Parola</Form.Label>
                                        <Form.Control
                                            value={formData.password}
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            onChange={handleInputChange}/>
                                        <ErrorField enabled={errors['password']}
                                                    error={'Parola trebuie să conțină minim 8 caractere, o literă mare, o literă mică, un număr și un caracter special.'}/>
                                    </Form.Group>

                                    <Form.Group
                                        style={{marginLeft: "10px"}}
                                        className="mb-4"
                                        controlId="formBasicCheckbox"
                                    >
                                        <Form.Check
                                            checked={isChecked}
                                            onChange={() => setIsChecked(!isChecked)}
                                            className="text-muted"
                                            type="checkbox"
                                            label="Accept Termenii și Condițiile de utilizare și Politica de Condifențialitate."
                                        />
                                    </Form.Group>

                                    <Button
                                        disabled={!isChecked}
                                        style={{background: "#003172", border: "none"}}
                                        onClick={handleRegister}
                                    >
                                        Înregistrare
                                    </Button>

                                    <div style={{marginTop: "10px"}}>
                                        <Link
                                            to={"/login"}
                                            className="text-decoration-none text-muted"
                                        >
                                            Ai deja un cont?
                                        </Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer/>
        </>)}
    </>);
};

export default RegisterPage;
