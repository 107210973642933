import "./SettingsMenu.css";
import Col from "react-bootstrap/Col";

import {useNavigate} from "react-router-dom";

const SettingsButton = ({label, icon, path}) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(path);
    }

    return (
        <>
            <Col sm={4} className="settingscol">
                <button onClick={handleClick} className="settingsbutton">
                    {icon}
                    {label}</button>
            </Col>
        </>
    );
}

export default SettingsButton;