import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {TbFilter} from "react-icons/tb";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import React, {useState} from 'react';
import {filterByPropsInArray, filterList} from "../../utils/functions/filters";
import {defaultActivities, handleActivities, optionsActivities} from "../../utils/functions/activities";
import Select from "react-select";

const CompanyFilter = ({companies, setCompanies, contacts, activities=[]}) => {

    const [show, setShow] = useState(false);
    const [filters, setFilters] = useState({
        contact: 'all', activities: [],
    });
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const selectStyles = {
        option: (styles, {data, isFocused}) => {
            return {
                ...styles, backgroundColor: isFocused ? "#d4d0cf" : null,
            };
        }, control: (styles) => {
            return {
                ...styles,
            };
        }
    };

    const handleFilters = (e) => {
        setFilters(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const applyFilters = () => {
        console.log(filters)
        setCompanies(filterByPropsInArray(filterList(companies, filters), 'activities', filters.activities));
        handleClose();
    }

    return (<>
        <Button onClick={handleShow} style={{
            marginLeft: "5px",
            fontFamily: "'Inter', arial",
            background: "white",
            border: "1px solid lightgray",
            color: "#003172",
            height: "50px",
        }}><TbFilter size={30}/></Button>

        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Filtre</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <Card.Body style={{padding: '15px'}}>
                                <Card.Title style={{
                                    fontFamily: "'Inter', arial", color: 'black', fontSize: '15px', fontWeight: "800"
                                }}>Reprezentant </Card.Title>
                                <select style={{width: "100%", border: "none"}}
                                        name="contact"
                                        value={filters?.contact}
                                        onChange={handleFilters}
                                >
                                    <option value='all'>Toate</option>
                                    {Object.entries(contacts)?.map((c, index) => <option
                                        value={c[0]}>{contacts[c[0]]?.name}</option>)}
                                </select>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <Card.Body style={{padding: '15px'}}>
                                <Card.Title style={{
                                    fontFamily: "'Inter', arial", color: 'black', fontSize: '15px', fontWeight: "800"
                                }}>Activitați </Card.Title>
                                <Select
                                    className="activityselect"
                                    onChange={(e) => handleActivities(filters, e)}
                                    placeholder={"Selectează activitați"}
                                    defaultValue={defaultActivities(filters.activities, activities)}
                                    isMulti
                                    styles={selectStyles}
                                    options={optionsActivities(activities)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button style={{
                    background: "white",
                    color: "#003172",
                    borderRadius: "8px",
                    border: "none",
                    padding: "10px",
                    fontSize: "15px"
                }} onClick={handleClose}>
                    Anulează
                </button>
                <button style={{
                    background: "#003172",
                    color: "white",
                    borderRadius: "8px",
                    border: "none",
                    padding: "10px",
                    fontSize: "15px"
                }} onClick={applyFilters}>
                    Aplica filtrele
                </button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default CompanyFilter;
