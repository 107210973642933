import { INVOICE_STATUS } from "../constants";

export const getInvoiceStatus = (invoices) => {
  let overdue = 0,
    sent = 0,
    draft = 0;
  invoices.forEach((i) => {
    if (i.status === INVOICE_STATUS.PAID) draft += i.amount;
    else if (i.status === INVOICE_STATUS.SENT) {
      if (i.due_at < new Date().toISOString().slice(0, 10)) overdue += i.amount;
      else sent += i.amount;
    }
  });
  return { overdue, sent, draft };
};

export const getNextInvoiceNumber = (invoicesNumber) => {
    const numb = invoicesNumber.toString();
    return "INV_" + "0".repeat(5 - numb.length) + numb;
}
