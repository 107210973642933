import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import {requestId} from "./../../../utils/utils";
import {
    changeRole, removeOwner, changeLicenseRole,
} from "./../../../api/license";
import useFetch from "./../../../hooks/useFetch";
import {sendCompanyInvite} from './../../../api/invites';
import Button from "react-bootstrap/Button";

const ManageLicense = ({licenseId, companyId, owner, refresh, disabled}) => {
    const options = [{label: "Manager", value: "MANAGER"}, {label: "Contabil", value: "CONTABIL"}, {
        label: "Client",
        value: "CLIENT"
    },];

    const [role, setRole] = useState("MANAGER");
    const [email, setEmail] = useState("");

    const sendLicenseState = useFetch(sendCompanyInvite, {});
    const changeRoleState = useFetch(changeLicenseRole, {});
    const removeOwnerState = useFetch(removeOwner, {});

    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const sendInvite = () => {
        sendLicenseState.sendRequest({
            companyId: companyId, email: email, role: role, licenseId: licenseId,
        });
        setShow(false);
    };

    const sendRole = () => {
        changeRoleState
            .sendRequest({
                companyId: companyId, licenseId: licenseId, role: role,
            })
            .then(() => {
                refresh();
                setShow(false);
            });
    };

    const handleDeleteOwner = () => {
        //set owner to null
        removeOwnerState
            .sendRequest({
                companyId: companyId, licenseId: licenseId, ownerId: owner?.id,
            })
            .then(() => {
                refresh();
                setShow(false);
            });
    };

    return (<>
            <Button
                disabled={disabled}
                onClick={handleShow}
                style={{
                    margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                }}
            >
                Administrează
            </Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <h2>Administrează licenţă</h2>
                </Modal.Header>
                <Modal.Body>
                    {owner !== null ? (<div>
                            <p
                                style={{
                                    color: "#003172", fontFamily: "'Inter', Arial", fontSize: "20px",
                                }}
                            >
                                Invită alt utilizator
                            </p>

                            <div style={{display: "flex", marginBottom: "30px"}}>
                                <input
                                    onChange={handleEmailChange}
                                    value={email}
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: "'Inter', arial",
                                        color: "#003172",
                                        background: "none",
                                        width: "100%",
                                        border: "1px solid gray",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        outline: "none",
                                        marginRight: "10px",
                                    }}
                                    name="search"
                                    type="text"
                                    placeholder="Email"
                                ></input>
                                <button
                                    disabled={email === ""}
                                    onClick={sendInvite}
                                    style={{
                                        width: "100px",
                                        color: "white",
                                        background: "#003172",
                                        padding: "10px",
                                        border: "none",
                                        borderRadius: "8px",
                                    }}
                                >
                                    Invită
                                </button>
                            </div>

                            <p
                                style={{
                                    color: "#003172", fontFamily: "'Inter', Arial", fontSize: "20px",
                                }}
                            >
                                Schimbă rol
                            </p>

                            <div style={{display: "flex"}}>
                                <select
                                    value={role}
                                    onChange={handleRoleChange}
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: "'Inter', arial",
                                        color: "#003172",
                                        background: "none",
                                        border: "1px solid gray",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        outline: "none",
                                        width: "100%",
                                        marginRight: "10px",
                                    }}
                                >
                                    {options.map((option) => (<option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>))}
                                </select>
                                <button
                                    onClick={sendRole}
                                    style={{
                                        width: "100px",
                                        color: "white",
                                        background: "#003172",
                                        padding: "10px",
                                        border: "none",
                                        borderRadius: "8px",
                                    }}
                                >
                                    Schimbă
                                </button>
                            </div>

                            <div style={{display: "flex", marginTop: "30px"}}>
                                <p
                                    style={{
                                        color: "#003172", fontFamily: "'Inter', Arial", fontSize: "20px",
                                    }}
                                >
                  <span style={{marginRight: "10px"}}>
                    Elimină utilizator
                  </span>
                                    <button
                                        onClick={handleDeleteOwner}
                                        style={{
                                            color: "white",
                                            background: "#003172",
                                            padding: "10px",
                                            border: "none",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        Elimină
                                    </button>
                                </p>
                            </div>
                        </div>) : (<div>
                            <p
                                style={{
                                    color: "#003172", fontFamily: "'Inter', Arial", fontSize: "20px",
                                }}
                            >
                                Invită utilizator
                            </p>

                            <div style={{display: "flex"}}>
                                <input
                                    onChange={handleEmailChange}
                                    value={email}
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: "'Inter', arial",
                                        color: "#003172",
                                        background: "none",
                                        width: "100%",
                                        border: "1px solid gray",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        outline: "none",
                                        marginRight: "10px",
                                    }}
                                    name="search"
                                    type="text"
                                    placeholder="Email"
                                ></input>
                                <button
                                    disabled={email === ""}
                                    onClick={sendInvite}
                                    style={{
                                        width: "100px",
                                        color: "white",
                                        background: "#003172",
                                        padding: "10px",
                                        border: "none",
                                        borderRadius: "8px",
                                    }}
                                >
                                    Invită
                                </button>
                            </div>
                        </div>)}
                </Modal.Body>
            </Modal>
        </>);
};

export default ManageLicense;
