import React from "react";
import {useState} from "react";
import {formatDate} from "../../../../utils/utils";
import Stats from "./Components/Stats/Stats";

function HomeStats() {
    const [report, setReport] = useState("contracts");

    const [start, setStart] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return formatDate(date);
    });
    const [end, setEnd] = useState(formatDate(new Date()));

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "30px 0px 30px 0px",
                }}
            >
                <h2 style={{fontFamily: "'Inter', arial", color: "#003172"}}>
                    Rapoarte
                </h2>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: "20px"}}>
                <select
                    name="rapoarte"
                    style={{
                        padding: "0px 20px 0px 20px",
                        height: "50px",
                        marginRight: '10px',
                        border: "1px solid #003172",
                        color: "#003172",
                    }}
                    onChange={(e) => setReport(e.target.value)}
                    value={report}
                >
                    <option value={"contracts"}>Contracte</option>
                    {/*<option value={"contacts"}>Contacte</option>*/}
                    {/*<option value={"companies"}>Companii</option>*/}
                </select>
                <input style={{
                    padding: "0px 20px 0px 20px",
                    marginRight: '10px',
                    height: "50px",
                    border: "1px solid #003172",
                    color: "#003172",
                }}
                    type="date"
                    value={new Date(start).toISOString().slice(0, 10)}
                    onChange={(e) => setStart(e.target.value)}
                />
                <input style={{
                    padding: "0px 20px 0px 20px",
                    height: "50px",
                    border: "1px solid #003172",
                    color: "#003172",
                }}
                        type="date"
                        placeholder={"Început"}
                        value={new Date(end).toISOString().slice(0, 10)}
                        onChange={(e) => setEnd(e.target.value)}
                />
            </div>

            {/*<Filters*/}
            {/*  start={start}*/}
            {/*  end={end}*/}
            {/*  report={report}*/}
            {/*  setStart={setStart}*/}
            {/*  setEnd={setEnd}*/}
            {/*  setReport={setReport}*/}
            {/*/>*/}

            <Stats report={report} start={start} end={end}/>
        </>
    );
}

export default HomeStats;
