import InvoiceRow from "./InvoiceRow";

const InvoiceTableComponent = ({invoice, customer, refresh}) => {
    return (<>
        <InvoiceRow
            name={invoice.title || invoice.invoice_number}
            total={invoice.amount}
            client={customer?.company_name}
            due_at={invoice.due_at}
            invoiced_at={invoice.invoiced_at}
            invoice_number={invoice.invoice_number}
            status={invoice.status}
            id={invoice.id}
            refresh={refresh}
            object={invoice}
        />
    </>);
}

export default InvoiceTableComponent;