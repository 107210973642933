import Button from "react-bootstrap/Button";
import DisableObject from "../../../../components/Modals/DisableObject";
import {PDFDownloadLink} from "@react-pdf/renderer";
import InvoicePDF from "../../../../components/ReactPdf/InvoicePDF";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import {getCompanyPhoto} from "../../../../api/photos";
import {apiEndpoint} from "../../../../api/apiUtils";
import {INVOICE_STATUS} from "../../../../utils/constants";
import {editInvoice} from "../../../../api/invoices";

const Footer = ({invoice, viewPage, saveInvoice, disableInvoice, client_data, type, company}) => {
    const nav = useNavigate();
    const {companyId} = useParams();
    const getImageState = useFetch(getCompanyPhoto, {companyId: companyId}, true);
    const editInvoiceState = useFetch(editInvoice, {});

    const changeStatus = () => {
        let newStatus = '';
        if (invoice.status === INVOICE_STATUS.PAID) newStatus = INVOICE_STATUS.SENT; else newStatus = INVOICE_STATUS.PAID;

        editInvoiceState
            .sendRequest({
                invoiceId: invoice.id, data: {...invoice, status: newStatus}
            })
            .then(() => {
                nav('../../');
            });

    };

    return (<>
        <div
            style={{
                textAlign: "right", marginTop: "50px", height: "100px",
            }}
        >
            <Button
                onClick={() => nav(-1)}
                style={{
                    margin: "5px",
                    fontFamily: "'Inter', arial",
                    background: "none",
                    border: "none",
                    boxShadow: "none !important",
                    color: "gray",
                }}
            >
                Inapoi
            </Button>
            <Button
                hidden={!viewPage}
                style={{
                    margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                }}
                onClick={changeStatus}
            >Plăteşte / Trimite
            </Button>
            <Button
                hidden={!viewPage}
                style={{
                    margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                }}
                onClick={() => nav('../edit')}
            >Editeaza
            </Button>

            {viewPage && <DisableObject name='factura' disableFunction={disableInvoice}/>}

            <Button
                hidden={viewPage}
                style={{
                    margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                }}
                onClick={saveInvoice}
            >{invoice ? 'Actualizează' : 'Salvează'}
            </Button>

            {invoice && <PDFDownloadLink
                hidden={!viewPage}
                document={<InvoicePDF
                    id={invoice?.id}
                    name={invoice?.title || invoice?.invoice_number}
                    status={invoice?.status}
                    due_at={invoice?.due_at}
                    invoiced_at={invoice?.invoiced_at}
                    total={invoice?.amount}
                    client_data={client_data}
                    company={company}
                    type={type}
                    invoice_items={invoice?.invoice_items}
                    logo={getImageState.data}
                />}>
                {({blob, url, loading, error}) => (<a href={url} target="_blank" rel="noopener noreferrer">
                    <Button
                        hidden={!viewPage}
                        style={{
                            margin: "5px", fontFamily: "'Inter', arial", background: "#003172", border: "none",
                        }}
                    >{loading ? 'Loading ...' : 'Descarcă PDF'}
                    </Button>
                </a>)}
            </PDFDownloadLink>}
        </div>
    </>);
};

export default Footer;