import {cache} from "../utils/cache/cache";
import {requestId} from "../utils/utils";
import {loggedInHeaders} from "./apiUtils";
import {get, post, put} from "./requests";

import {sumTaxes} from "../utils/functions";

export const loadInvoices = async (params) => {
    try {
        const url = `/api/invoices/owner/`;

        let data = await (await get(url, loggedInHeaders())).data;
        data = data?.filter((i) => i.enabled && params?.args.companyId === i.company);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const countInvoices = async (params) => {
    try {
        const url = `/api/invoices/owner/`;
        const reqId = requestId(url, params.args);

        let data = await (await get(url, loggedInHeaders())).data;
        data = data?.filter((i) => params?.args.companyId === i.company);

        params.setData(data.length ?? 0);
        return data.length ?? 0;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

// export const loadInvoiceItems = async (params) => {
//     try {
//         const url = `/api/invoice_items/owner/`;
//         const reqId = requestId(url, params.args);
//
//         const cachedData = cache.get(reqId);
//         if (cachedData) {
//             params.setData(cachedData);
//             return;
//         }
//
//         let data = await (await get(url, loggedInHeaders())).data;
//         data = data?.filter((i) => i.enabled && params?.args.companyId === i.company);
//         cache.set(reqId, data, 0.1);
//
//         params.setData(data);
//         return data;
//     } catch (err) {
//         params.setError(err.response);
//     } finally {
//         params.setLoading(false);
//     }
// };

export const createInvoice = async (params) => {
    try {
        const data = params.args?.data;
        const headers = loggedInHeaders();

        if (!data) return;
        const res = await (await post('/api/invoices/', data, headers)).data;

        params.setData(res);
        return res;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
//
// export const createInvoice = async (params) => {
//     try {
//         const {invoice, invoiceItems} = params.args;
//         const headers = loggedInHeaders();
//
//         if (!invoice || !invoiceItems) return;
//         const invoiceResponse = await (await post("/api/invoices/", invoice, headers)).data;
//
//         const invoiceItemsObject = {
//             invoice: invoiceResponse?.id, items: [], quantities: [], totals: [],
//         };
//         invoiceItems.forEach((ii) => {
//             invoiceItemsObject.items.push(ii.item);
//             invoiceItemsObject.totals.push(ii.total);
//             invoiceItemsObject.quantities.push(ii.quantity);
//         });
//         invoiceItemsObject.quantities = JSON.stringify(invoiceItemsObject.quantities);
//         invoiceItemsObject.totals = JSON.stringify(invoiceItemsObject.totals);
//
//         const invoiceItemsResponse = await (await post("/api/invoice_items/", invoiceItemsObject, headers)).data;
//
//         params.setData({invoiceResponse, invoiceItemsResponse});
//         return {invoiceResponse, invoiceItemsResponse};
//     } catch (err) {
//         params.setError(err.response);
//     } finally {
//         params.setLoading(false);
//     }
// };


export const editInvoice = async (params) => {
    try {
        const url = `/api/invoices/${params.args?.invoiceId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
//
// export const editInvoice = async (params) => {
//     try {
//         const args = params.args;
//         const headers = loggedInHeaders();
//
//         if (!args) return;
//
//         const invoiceResponse = await (await put(`/api/invoices/${args?.invoice.id}/`, args?.invoice, headers)).data;
//
//         if (JSON.stringify(args?.oldInvoiceItems) !== JSON.stringify(args?.currentInvoiceItems)) {
//             const invoiceItemsObject = {
//                 invoice: invoiceResponse?.id, items: [], quantities: [], totals: [],
//             };
//
//             args?.currentInvoiceItems.forEach((ii) => {
//                 invoiceItemsObject.items.push(ii.item);
//                 invoiceItemsObject.quantities.push(ii.quantity);
//                 invoiceItemsObject.totals.push(ii.total);
//             });
//
//             invoiceItemsObject.quantities = JSON.stringify(invoiceItemsObject.quantities);
//             invoiceItemsObject.totals = JSON.stringify(invoiceItemsObject.totals);
//
//             const invoiceItemsResponse = await (await put(`/api/invoice_items/${args?.invoiceItemsId}/`, invoiceItemsObject, headers)).data;
//         }
//
//         params.setData({invoiceResponse});
//         return invoiceResponse;
//     } catch (err) {
//         params.setError(err.response);
//     } finally {
//         params.setLoading(false);
//     }
// };



export const getInvoice = async (params) => {
    try {
        const url = `/api/invoices/${params.args?.invoiceId}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
//
// export const getInvoice = async (params) => {
//     try {
//         const headers = loggedInHeaders();
//         const invoiceItemsList = [];
//
//         const invoiceResponse = await (await get(`/api/invoices/${params.args?.invoiceId}/`, loggedInHeaders())).data;
//         let invoiceItemsResponse = await (await get(`/api/invoice_items/owner/`, headers)).data;
//         invoiceItemsResponse = invoiceItemsResponse?.find((ii) => ii.invoice === params.args?.invoiceId);
//         if (invoiceItemsResponse === undefined || invoiceItemsResponse === []) return undefined;
//
//         invoiceItemsResponse.quantities = JSON.parse(invoiceItemsResponse.quantities);
//         invoiceItemsResponse.totals = JSON.parse(invoiceItemsResponse.totals);
//         invoiceItemsResponse.items.forEach((ii, index) => {
//             invoiceItemsList[index] = {
//                 item: ii?.id,
//                 quantity: invoiceItemsResponse.quantities[index],
//                 price: ii?.sale_price,
//                 tax_rate: sumTaxes(ii?.taxes),
//                 total: invoiceItemsResponse.totals[index],
//
//                 name: ii?.name,
//                 description: ii?.description,
//             };
//         });
//         const iiId = invoiceItemsResponse?.id;
//         params.setData({
//             invoice: invoiceResponse, invoiceItems: invoiceItemsList, iiId,
//         });
//         return {invoiceResponse, invoiceItemsList, iiId};
//     } catch (err) {
//         params.setError(err.response);
//     } finally {
//         params.setLoading(false);
//     }
// };
