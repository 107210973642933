import React, {useEffect, useState} from 'react';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {constructInterval, dailyCashflow} from "../../../../../utils/functions/cashflow";
import moment from "moment/moment";


const gradientOffset = (data) => {
    const dataMax = Math.max(...data.map((i) => i.uv));
    const dataMin = Math.min(...data.map((i) => i.uv));

    if (dataMax <= 1) {
        return 0;
    }
    if (dataMin >= 1) {
        return 1;
    }

    return dataMax / (dataMax - dataMin);
};

const HRGRaph = ({salaryExpenses, invoices, bills, filters, companies}) => {
    const [hrData, setHrData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [billData, setBillData] = useState([]);
    const [offsetData, setOffsetData] = useState(0);
    const [interval, setInterval] = useState([]);

    useEffect(() => {
        setInterval(constructInterval(filters.start, filters.end));
    }, [filters.start, filters.end]);

    useEffect(() => {
        if (invoices && bills) {
            setInvoiceData(invoices?.filter(invoice => (filters.company === 'all' || invoice.contact === filters.company) && (filters.contact === 'all' || companies[invoice.contact]?.contact === filters.contact)));
            setBillData(bills?.filter(invoice => (filters.company === 'all' || invoice.contact === filters.company) && (filters.contact === 'all' || companies[invoice.contact]?.contact === filters.contact)));
        }

    }, [filters.contact, filters.company, invoices, bills]);

    useEffect(() => {
        const arr = [];
        dailyCashflow(invoiceData, billData, interval)?.forEach((d, index) => {
            arr.push({date: d.date, uv: parseInt(d.profit) - salaryExpenses[index]?.salaryThatDay})
        });
        console.log(arr)
        setHrData(arr);
        setOffsetData(gradientOffset(arr));
    }, [invoiceData, billData, interval, salaryExpenses]);


    return (<div style={{textAlign: "center"}}>
        <ResponsiveContainer width="100%" height={400}>
            <AreaChart
                width={500}
                height={400}
                data={hrData}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 30,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                    dataKey="date"
                    domain={[new Date(filters.start), new Date(filters.end)]}
                    type="number"
                    tickFormatter={(str) => moment(str).format("MMM DD YYYY")}
                    tickCount={7}
                    angle={-30}
                    dx={0}
                    dy={20}
                    minTickGap={-200}
                ></XAxis>
                <YAxis angle={-30} tickFormatter={(str) => `${str} RON`}/>
                <Tooltip
                    labelFormatter={(value) => {
                        return `${new Date(value).toString().substring(0, 10)}`;
                    }}
                />
                <defs>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={offsetData} stopColor="green" stopOpacity={1}/>
                        <stop offset={offsetData} stopColor="red" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                <Area type="monotone" dataKey="uv" name={'Bilanț'} stroke="#000" fill="url(#splitColor)"/>
            </AreaChart>
        </ResponsiveContainer>
    </div>);
}

export default HRGRaph;