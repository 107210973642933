import { createContext } from "react";
import { AuthProvider } from "./hooks/useAuth";

const globalContext = createContext();

const GlobalProvider = ({ children }) => {
  return (
    <globalContext.Provider value={{}}>
      <AuthProvider>{children}</AuthProvider>
    </globalContext.Provider>
  );
};

export default GlobalProvider;
