import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {TbFilter} from "react-icons/tb";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import React, {useEffect, useState} from "react";
import {requestNewLicense} from "./../../../api/license";
import useFetch from "./../../../hooks/useFetch";
import {useParams} from "react-router-dom";

const AddLicense = () => {
    const {companyId} = useParams();
    const requestNewLicenseState = useFetch(requestNewLicense, {});

    const [licenseNumber, setLicenseNumber] = useState(0);

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleLicenseNumber = (e) => {
        setLicenseNumber(e.target.value);
    };

    const handleNewLicense = () => {
        if (licenseNumber < 1) return;

        requestNewLicenseState.sendRequest({
            companyId: companyId, licenseNumber: licenseNumber,
        });
        handleClose();
    };

    return (<>
            <button
                onClick={handleShow}
                style={{
                    margin: "30px 0px",
                    fontFamily: "'Inter', Arial",
                    border: "none",
                    color: "white",
                    background: "#003172",
                    borderRadius: "8px",
                    padding: "10px",
                    boxSizing: "border-box",
                }}
            >
                Adaugă licenţe
            </button>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Adauga licente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm>
                            <input
                                type="number"
                                placeholder="Numar Licente"
                                style={{
                                    width: "100%", borderRadius: "8px", height: "50px", border: "1px solid grey",
                                }}
                                onChange={handleLicenseNumber}
                            ></input>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        style={{
                            background: "white",
                            color: "#003172",
                            borderRadius: "8px",
                            border: "none",
                            padding: "10px",
                            fontSize: "15px",
                        }}
                        onClick={handleClose}
                    >
                        Anulează
                    </button>
                    <button
                        style={{
                            background: "#003172",
                            color: "white",
                            borderRadius: "8px",
                            border: "none",
                            padding: "10px",
                            fontSize: "15px",
                        }}
                        onClick={handleNewLicense}
                    >
                        Trimite cererea
                    </button>
                </Modal.Footer>
            </Modal>
        </>);
};

export default AddLicense;
