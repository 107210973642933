import {loggedInHeaders} from "./apiUtils";
import {get, post, put} from "./requests";

export const loadCategories = async (params) => {
    try {
        const url = `/api/categories/owner/`;
        const type = params?.args.type || 'item';

        let data = await (await get(url, loggedInHeaders())).data;
        data = data?.filter((i) => i.enabled && params?.args.companyId === i.company && type === i.type);

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const createCategory = async (params) => {
    try {
        const data = params.args?.data;
        const headers = loggedInHeaders();

        if (!data) return;

        const res = await (await post("/api/categories/", data, headers)).data;

        params.setData({res});
        return res;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const editCategory = async (params) => {
    try {
        const url = `/api/categories/${params.args?.categoryId}/`;
        const data = await (await put(url, params.args?.data, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};

export const getCategory = async (params) => {
    try {
        const url = `/api/categories/${params.args?.categoryId}/`;
        const data = await (await get(url, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
