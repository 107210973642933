import React from "react";
import {Card, Image, ListGroup, ListGroupItem} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';
import DisableObject from "../../../../../../../components/Modals/DisableObject";

import './ItemCard.css';

const ItemCard = ({name, category, taxes, created_at, description, sale_price, purchase_price}) => {
    return (<Card
        style={{margin: "20px auto", fontFamily: "'Inter', arial"}} className="contactcard">
        <Card.Header as="h5" className="contactcard-header">{name}</Card.Header>
        <ListGroup className="list-group-flush">
            <ListGroupItem>
                <i className="fa fa-list-alt mr-2" aria-hidden="true"></i>
                {category}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-sign-out mr-2" aria-hidden="true"></i>
                $ {sale_price}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-sign-in mr-2" aria-hidden="true"></i>
                $ {purchase_price}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-percent mr-2" aria-hidden="true"></i>
                {taxes}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-file-text-o mr-2" aria-hidden="true"></i>
                {description}
            </ListGroupItem>
            <ListGroupItem>
                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                {created_at}
            </ListGroupItem>
        </ListGroup>
    </Card>);
};

export default ItemCard;
