import React from "react";
import ItemRow from "./ItemRow";

const ItemTableComponent = ({item, category, refresh}) => {

    return (
        <>
            <ItemRow
                item={item}
                refresh={refresh}
                category={category}
                name={item.name}
            />
        </>
    );
};

export default ItemTableComponent;
