import React from "react";
import {Container, Row, Col, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import MainNavbar from "../MainPages/Components/MainNavbar/MainNavbar";
import Footer from "../MainPages/Components/Footer/Footer";

function Error404Page() {
    return (<div>
        <MainNavbar/>
        <Container style={{maxHeight: "100vh"}}>
            <Row style={{marginTop: "30px", marginBottom: "20px"}}>
                <Col sm={12}>
                    <h1
                        style={{
                            fontFamily: "'Inter', arial", color: "#003172", float: "left",
                        }}
                    >
                        404 Page Not Found
                    </h1>
                </Col>
            </Row>

            <Row style={{marginBottom: "20px"}} zIndex={-1}>
                <Col>
                    <Card
                        style={{
                            border: "none",
                            borderRadius: "0px",
                            padding: "25px",
                            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                            background: "white",
                        }}
                    >
                        <Card.Body>
                            <p
                                style={{
                                    fontFamily: "'Inter', Arial",
                                }}
                            >
                                The page you requested could not be found.
                            </p>
                            <p
                                style={{
                                    fontFamily: "'Inter', Arial",
                                }}
                            >
                                Please check the URL or go back{" "}
                                <Link to="/" style={{color: "#003172"}}>
                                    home
                                </Link>
                                .
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Footer/>
    </div>);
}

export default Error404Page;
