import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ExpensesGraph from "../ExpensesGraph/ExpensesGraph";

import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "react-bootstrap/Card";

const Expenses = ({bills, categories, filters, refresh, companies}) => {
    const filteredBills = bills?.filter(bill => new Date(bill.invoiced_at) >= new Date(filters.start)
        && new Date(bill.invoiced_at) <= new Date(filters.end)
        && (filters.company === 'all' || bill.contact === filters.company)
        && (filters.contact === 'all' || companies[bill.contact]?.contact === filters.contact));

    return (<Col xs={12} sm={6} style={{marginBottom: "20px"}}>
        <Card
            style={{
                borderRadius: "0px",
                border: "none",
                padding: "20px 20px 20px 20px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
            }}
        >
            <Card.Body>
                <Row>
                    <Col>
                        <h5 style={{fontFamily: "'Inter', arial", color: "#003172"}}>
                            Cheltuieli pe categorii
                        </h5>
                    </Col>
                    <hr></hr>
                </Row>

                <ExpensesGraph
                    bills={filteredBills}
                    categories={categories}
                />
            </Card.Body>
        </Card>
    </Col>);
};

export default Expenses;
