import {loggedInHeaders} from "./apiUtils";
import {post} from "./requests";

export const sendContactUsEmail = async (params) => {
    try {
        const url = `/api/contact-us/`;

        const data = await (await post(url, params.args, loggedInHeaders())).data;

        params.setData(data);
        return data;
    } catch (err) {
        params.setError(err.response);
    } finally {
        params.setLoading(false);
    }
};
