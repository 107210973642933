import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { getAuthFromStorage } from "../cache/cache";

export const LoginRequired = () => {
  const auth = useAuth();

  //if user is not active, redirect to activate_account page
  //if (!auth.data?.is_active) {
    //return <Navigate to="/activate_account" />;
  //}

  return auth.isLoggedIn || getAuthFromStorage() ? (
    <Outlet />
  ) : (
    <Navigate to="/logout" />
  );
};
